import React, { Component } from 'react';
import { connect } from 'react-redux';
import DesktopLayerPopup from './DesktopLayerPopup';

const LoginInjector = (WrapperComponent) => {
  const HOCComponent = class _HOCComponent extends Component {
    render() {
      const { actorInfo, studentInfo, familyInfo } = this.props;

      const userInfo = {
        isLogin: actorInfo.id ? true : false,
        humanName: actorInfo.auth_human_name ? actorInfo.auth_human_name : null,
        userName: actorInfo.auth_username ? actorInfo.auth_username : null,
        actorId: actorInfo.id ? actorInfo.id : null,
        actorType: actorInfo.model_type ? actorInfo.model_type : null,
        familyId: familyInfo.id ? familyInfo.id : null,
        studentInfo: studentInfo.id ? studentInfo : null,
        studentId: studentInfo.id ? studentInfo.id : null,
        authId: actorInfo.auth ? actorInfo.auth : null,
        loginProcess: actorInfo.loginProcess ? actorInfo.loginProcess : null,
      };

      let history = this.props.history;
      history.loginAfterPush = {};
      history.loginAfterReplace = {};
      history.loginAfterPush = (url) => {
        if (userInfo.isLogin === true) {
          history.push(url);
        } else {
          DesktopLayerPopup.login({
            redirect: url,
            historyType: 'push',
            history,
          });
        }
      };

      history.loginAfterReplace = (url) => {
        if (userInfo.isLogin === true) {
          history.replace(url);
        } else {
          DesktopLayerPopup.login({
            redirect: url,
            historyType: 'replace',
            history,
          });
        }
      };

      const newProps = {
        ...this.props,
        userInfo: { ...userInfo },
        history: history,
      };
      return <WrapperComponent {...newProps} />;
    }
  };

  const mapStateToProps = (state, ownProps) => {
    return {
      studentInfo: state.MemberReducer.studentInfo,
      authInfo: state.MemberReducer.auth,
      actorInfo: state.MemberReducer.actor,
      familyInfo: state.MemberReducer.family,
      loginProcess: state.MemberReducer.loginProcess,
      history: ownProps.history,
    };
  };
  return connect(mapStateToProps)(HOCComponent);
};

export default LoginInjector;

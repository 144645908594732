import { ActionTypes as types } from './DeliveryASAction';
import simpleDotProp from '../simpleDotProp';

const initialState = {
  deliveryList: [],
  asList: [],
  asInfo: {},
  materialList: [],
  subMaterialList: [],
  etcItemList: [],
  deviceAccessoryItemList: [],
};

export default function DeliveryASReducer(state = initialState, action) {
  switch (action.type) {
    case types.SUCCESS_DELIVERY_GETLIST:
      return simpleDotProp.set(state, {
        deliveryList: action.payload.deliveryList,
      });
    case types.SUCCESS_AS_GETLIST:
      return simpleDotProp.set(state, {
        asList: action.payload.asList,
      });
    case types.SUCCESS_AS_GETINFO:
      return simpleDotProp.set(state, {
        asInfo: action.payload.asInfo,
      });
    case types.GET_MATERIAL_LIST:
      return simpleDotProp.set(state, {
        materialList: action.payload.materialList,
      });
    case types.APPEND_MATERIAL_LIST:
      return {
        ...state,
        materialList: [...state.materialList, ...action.payload.materialList],
      };
    case types.GET_SUBMATERIAL_LIST:
      return simpleDotProp.set(state, {
        subMaterialList: action.payload.subMaterialList,
      });
    case types.APPEND_SUBMATERIAL_LIST:
      return {
        ...state,
        subMaterialList: [
          ...state.materialList,
          ...action.payload.subMaterialList,
        ],
      };
    case types.GET_ETC_ITEM_LIST:
      return simpleDotProp.set(state, {
        etcItemList: action.payload.etcItemList,
      });
    case types.APPEND_ETC_ITEM_LIST:
      return {
        ...state,
        etcItemList: [...state.etcItemList, ...action.payload.etcItemList],
      };
    case types.GET_DEVICE_ACCESSORY_ITEM_LIST:
      return {
        ...state,
        deviceAccessoryItemList: action.payload.deviceAccessoryItemList,
      };
    case types.APPEND_DEVICE_ACCESSORY_ITEM_LIST:
      return {
        ...state,
        deviceAccessoryItemList: [
          ...state.deviceAccessoryItemList,
          ...action.payload.deviceAccessoryItemList,
        ],
      };
    default:
      return state;
  }
}

import React from 'react';
import { LayerPopup } from '../../library/layerpopup';
import ModalContainer from './components/layers/ModalContainer';
import LayerContainer from './components/layers/LayerContainer';
import ConfirmModal from './components/layers/ConfirmModal';
import NoticeModal from './components/layers/NoticeModal';
import LoginModal from './components/layers/LoginModal';
import ClipboardModal from './components/layers/ClipboardModal';
import PasswordResetModal from './components/layers/PasswordResetModal';

export default class DesktopLayerPopup {
  static layer(LayerPageComponent) {
    return LayerPopup.show(
      <LayerContainer>{LayerPageComponent}</LayerContainer>
    );
  }

  static modal(LayerPageComponent, modalProps) {
    return LayerPopup.show(
      <ModalContainer {...modalProps}>{LayerPageComponent}</ModalContainer>
    );
  }

  static confirm(props) {
    return LayerPopup.show(
      <ModalContainer>
        <ConfirmModal {...props} />
      </ModalContainer>
    );
  }

  static notice(props) {
    return LayerPopup.show(
      <ModalContainer>
        <NoticeModal {...props} />
      </ModalContainer>
    );
  }

  static login(props) {
    return LayerPopup.show(
      <ModalContainer>
        <LoginModal {...props} />
      </ModalContainer>
    );
  }

  static resetPassword(props) {
    return LayerPopup.show(
      <ModalContainer>
        <PasswordResetModal {...props} />
      </ModalContainer>
    );
  }

  /* 사용방법 DesktopLayerPopup.clipBoard({url:'www.naver.com',callback:(type, param)=>{}}) */

  static clipBoard(props) {
    return LayerPopup.show(
      <ModalContainer>
        <ClipboardModal {...props} />
      </ModalContainer>
    );
  }

  static hide(layerKey) {
    if (layerKey) {
      LayerPopup.hide(layerKey);
    }
  }
}

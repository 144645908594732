import React, { Component } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import DesktopLayerPopup from '../DesktopLayerPopup';

class SelectDate extends Component {
  initState = {
    year: '',
    month: '',
    day: '',
    yearOpen: false,
    monthOpen: false,
    dayOpen: false,
  };

  constructor(props) {
    super(props);

    this.state = this.getInitState();
  }

  getInitState() {
    let { year, month, day } = this.props;
    if (year || month || day) {
      let initState = { ...this.initState };
      initState.year = year ? year : '';
      initState.month = month ? month : '';
      initState.day = day ? day : '';
      return initState;
    } else {
      return { ...this.initState };
    }
  }

  getDateObjectList() {
    const { year, month } = this.state;
    let years = [];
    let months = [];
    let days = [];

    let targetYear = parseInt(moment().format('YYYY'), 10);

    for (let i = 0; i < 20; i++) {
      years.push(targetYear - i);
    }

    for (let i = 1; i <= 12; i++) {
      months.push(i);
    }

    if (year !== '' && month !== '') {
      let targetDay = parseInt(
        moment(`${year}-${month}-01`).endOf('month').format('DD'),
        10
      );
      for (let i = 1; i <= targetDay; i++) {
        days.push(i);
      }
    }

    return { years, months, days };
  }

  onClickOpenDate(e, key) {
    if (key === 'dayOpen') {
      if (this.state.year === '' || this.state.month === '') {
        DesktopLayerPopup.notice({ message: '년도와 월을 먼저 선택해주세요.' });
        return;
      }
    }
    this.setState({
      [key]: !this.state[key],
    });
  }

  onMouseLeaveHideDate(e, key) {
    this.timer = setTimeout(() => {
      this.timer = null;
      this.setState({
        [key]: false,
      });
    }, 200);
  }

  onMouseEnterHideDate() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  setDate(e, key, value) {
    if (key === 'month') {
      this.setState(
        {
          [key]: value.toString().padStart(2, '0'),
          day: '',
          yearOpen: false,
          monthOpen: false,
          dayOpen: false,
        },
        () => {
          if (this.props.callback) {
            let { year, month, day } = this.state;
            this.props.callback({ year, month, day });
          }
        }
      );
    } else {
      this.setState(
        {
          [key]: value.toString().padStart(2, '0'),
          yearOpen: false,
          monthOpen: false,
          dayOpen: false,
        },
        () => {
          if (this.props.callback) {
            let { year, month, day } = this.state;
            this.props.callback({ year, month, day });
          }
        }
      );
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { year, month, day } = nextProps;
    if (
      this.props.year !== nextProps.year ||
      this.props.month !== nextProps.month ||
      this.props.day !== nextProps.day
    ) {
      let state = { ...this.initState };
      state.year = year ? year : '';
      state.month = month ? month : '';
      state.day = day ? day : '';
      this.setState({ ...state });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    let { size } = this.props;
    let { yearOpen, monthOpen, dayOpen, year, month, day } = this.state;

    let dateList = this.getDateObjectList();
    const sizeClass = size ? `size-${size}` : null;

    return (
      <r_>
        <div
          className={classNames('select-mode', sizeClass, { on: yearOpen })}
          onMouseLeave={(e) => {
            this.onMouseLeaveHideDate(e, 'yearOpen');
          }}
          onMouseEnter={(e) => {
            this.onMouseEnterHideDate(e, 'yearOpen');
          }}
        >
          <button
            type="button"
            onClick={(e) => {
              this.onClickOpenDate(e, 'yearOpen');
            }}
          >
            <i>{year !== '' ? year : '선택'}</i>
          </button>
          <div>
            <ul>
              {dateList.years.map((item, index) => {
                return (
                  <li key={index}>
                    <button
                      type="button"
                      onClick={(e) => {
                        this.setDate(e, 'year', item);
                      }}
                    >
                      <i>{item}</i>
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <span>년</span>
        <div
          className={classNames('select-mode', sizeClass, { on: monthOpen })}
          onMouseLeave={(e) => {
            this.onMouseLeaveHideDate(e, 'monthOpen');
          }}
          onMouseEnter={(e) => {
            this.onMouseEnterHideDate(e, 'monthOpen');
          }}
        >
          <button
            type="button"
            onClick={(e) => {
              this.onClickOpenDate(e, 'monthOpen');
            }}
          >
            <i>{month !== '' ? month : '선택'}</i>
          </button>
          <div>
            <ul>
              {dateList.months.map((item, index) => {
                return (
                  <li key={index}>
                    <button
                      type="button"
                      onClick={(e) => {
                        this.setDate(e, 'month', item);
                      }}
                    >
                      <i>{item}</i>
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <span>월</span>
        <div
          className={classNames('select-mode', sizeClass, { on: dayOpen })}
          onMouseLeave={(e) => {
            this.onMouseLeaveHideDate(e, 'dayOpen');
          }}
          onMouseEnter={(e) => {
            this.onMouseEnterHideDate(e, 'dayOpen');
          }}
        >
          <button
            type="button"
            onClick={(e) => {
              this.onClickOpenDate(e, 'dayOpen');
            }}
          >
            <i>{day !== '' ? day : '선택'}</i>
          </button>
          <div>
            <ul>
              {dateList.days.map((item, index) => {
                return (
                  <li key={index}>
                    <button
                      type="button"
                      onClick={(e) => {
                        this.setDate(e, 'day', item);
                      }}
                    >
                      <i>{item}</i>
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <span>일</span>
      </r_>
    );
  }
}

export default SelectDate;

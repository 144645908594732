import Async from '../Async';
import UserServices from '../services/UserServices';
import * as ExceptionAction from '../error/ExceptionAction';

export const ActionTypes = Object.freeze({
  SUCCESS_FAKE_LOGIN: 'FAKE_LOGIN/SUCCESS_FAKE_LOGIN',
  GET_FAKE_LOGIN_LOG_LIST: 'FAKE_LOGIN/GET_FAKE_LOGIN_LOG_LIST',
  INIT_FAKE_LOGIN: 'FAKE_LOGIN/INIT_FAKE_LOGIN',
});

// server_tester : test_fake_login_actor.py - FakeLoginActorTest.test_fake_login_actor
export function fakeLogin(username, password, loginActorId, actorType) {
  return (dispatch, getState) =>
    dispatch(() => {
      return Async(
        function* (username, password, loginActorId, actorType) {
          const employeeAuth = yield UserServices.authenticate(
            username,
            password,
            true
          );
          const fakeLoginInfo = yield UserServices.fakeLogin(
            employeeAuth.data.id,
            loginActorId,
            actorType
          );

          return {
            employeeAuth: employeeAuth.data,
            fakeLoginInfo: fakeLoginInfo.data,
          };
        },
        username,
        password,
        loginActorId,
        actorType
      )
        .then((results) => {
          dispatch({
            type: 'FAKE_LOGIN/SUCCESS_FAKE_LOGIN',
            payload: {
              employeeAuth: results.employeeAuth,
              fakeLoginInfo: results.fakeLoginInfo,
            },
          });
        })
        .catch((e) => {
          return dispatch(
            ExceptionAction.exception(e, 'FakeLogin 중 오류가 발생하였습니다.')
          );
        });
    });
}

// server_tester : test_fake_login_actor.py - FakeLoginActorTest.test_fake_login_actor
export function getFakeLoginLogList(actorId) {
  return (dispatch, getState) =>
    dispatch(() => {
      return UserServices.getFakeLoginLogList(actorId)
        .then((response) => {
          return dispatch({
            type: 'FAKE_LOGIN/GET_FAKE_LOGIN_LOG_LIST',
            payload: {
              fakeLoginLogList: response.data,
            },
          });
        })
        .catch((e) => {
          return dispatch(
            ExceptionAction.exception(
              e,
              'FakeLogin 이력 조회중 오류가 발생하였습니다.'
            )
          );
        });
    });
}

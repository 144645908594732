import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as MemberJoinAction from './MemberJoinAction';

const MemberJoinInjector =
  (stateToProps = null, dispatchToProps = null) =>
  (WrapperComponent) => {
    const HOCComponent = class _HOCComponent extends Component {
      render() {
        return <WrapperComponent {...this.props} />;
      }
    };

    const mapDispatchToProps = (dispatch, ownProps) => {
      let props = {};
      if (dispatchToProps !== null) {
        props = dispatchToProps(dispatch, ownProps);
      }

      return {
        ...props,
        phoneDuplicateCheckAndOneTimePasswordForMemberJoin: (
          humanName,
          humanMdn,
          isKakaoTalk
        ) => {
          dispatch(
            MemberJoinAction.phoneDuplicateCheckAndOneTimePasswordForMemberJoin(
              humanName,
              humanMdn,
              isKakaoTalk
            )
          );
        },
        checkOneTimePasswordForMemberJoin: (
          human_name,
          human_mdn,
          certificationNumber
        ) => {
          dispatch(
            MemberJoinAction.checkOneTimePasswordForMemberJoin(
              human_name,
              human_mdn,
              certificationNumber
            )
          );
        },
        //휴대폰 중복검사, 인증번호 발송
        phoneDuplicateCheckAndOneTimePassword: (human_mdn) => {
          dispatch(
            MemberJoinAction.phoneDuplicateCheckAndOneTimePassword(human_mdn)
          );
        },
        //인증번호 확인
        checkOneTimePassword: (human_mdn, certificationNumber) => {
          dispatch(
            MemberJoinAction.checkOneTimePassword(
              human_mdn,
              certificationNumber
            )
          );
        },
        clearAction: () => {
          dispatch(MemberJoinAction.clearAction());
        },
        createAuths: (param) => {
          dispatch(MemberJoinAction.createAuths(param));
        },
        createAuthsForExperience: (param) => {
          dispatch(MemberJoinAction.createAuthsForExperience(param));
        },
        createFamilyAndApplyExperience: (param) => {
          dispatch(MemberJoinAction.createFamilyAndApplyExperience(param));
        },
        //비로그인 가입 & 휴대폰 인증문자 발송
        halfCreateAuthsAndOneTimePassword: (human_name, human_mdn) => {
          dispatch(
            MemberJoinAction.halfCreateAuthsAndOneTimePassword(
              human_name,
              human_mdn
            )
          );
        },
        //폰로그인 & 토큰획득
        phoneLoginAndGetToken: (human_mdn, human_name, certificationNumber) => {
          dispatch(
            MemberJoinAction.phoneLoginAndGetToken(
              human_mdn,
              human_name,
              certificationNumber
            )
          );
        },
        //폰로그인만
        phoneLogin: (human_mdn, human_name, certificationNumber) => {
          dispatch(
            MemberJoinAction.phoneLogin(
              human_mdn,
              human_name,
              certificationNumber
            )
          );
        },
        logout: () => {
          dispatch(MemberJoinAction.logout());
        },
        authTokenLogin: (token, actorType) => {
          dispatch(MemberJoinAction.authTokenLogin(token, actorType));
        },
        modifyAuth: (authId, params, exprienceParams, category_tag_name) => {
          dispatch(
            MemberJoinAction.modifyAuth(
              authId,
              params,
              exprienceParams,
              category_tag_name
            )
          );
        },
        modifyAuthForHomeShopping: (
          authId,
          params,
          exprienceParams,
          category_tag_name,
          issue
        ) => {
          dispatch(
            MemberJoinAction.modifyAuthForHomeShopping(
              authId,
              params,
              exprienceParams,
              category_tag_name,
              issue
            )
          );
        },
        clearMdnToken: () => {
          dispatch(MemberJoinAction.clearMdnToken());
        },
        clearAuthActor: () => {
          dispatch(MemberJoinAction.clearAuthActor());
        },
        clearDeliveryInfo: () => {
          dispatch(MemberJoinAction.clearDeliveryInfo());
        },
        clearErrorStep: () => {
          dispatch(MemberJoinAction.clearErrorStep());
        },
        successApplyExperience: () => {
          dispatch(MemberJoinAction.successApplyExperience());
        },
        deleteDropOut: () => {
          dispatch(MemberJoinAction.deleteDropOut());
        },
        getTodayStudyMap: () => {
          dispatch(MemberJoinAction.getTodayStudyMap());
        },
      };
    };

    const mapStateToProps = (state, ownProps) => {
      let props = {};
      if (stateToProps !== null) {
        props = stateToProps(state, ownProps);
      }

      return {
        ...props,
        action: state.MemberJoinReducer.action,
        auth_token: state.MemberJoinReducer.auth_token,
        mdn_token: state.MemberJoinReducer.mdn_token,
        human_name: state.MemberJoinReducer.human_name,
        address: state.MemberJoinReducer.address,
        address_detail: state.MemberJoinReducer.address_detail,
        postal_code: state.MemberJoinReducer.postal_code,
        human_email: state.MemberJoinReducer.human_email,
        human_mdn: state.MemberJoinReducer.human_mdn,
        deliveryMessage: state.MemberJoinReducer.deliveryMessage,
        auth: state.MemberJoinReducer.auth,
        actor: state.MemberJoinReducer.actor,
        errorStep: state.MemberJoinReducer.errorStep,
        existAuth: state.MemberJoinReducer.existAuth,
        studentList: state.MemberJoinReducer.studentList,
        logId: state.MemberJoinReducer.logId,
        todayStudyMap: state.MemberJoinReducer.todayStudyMap,
        history: ownProps.history,
      };
    };

    return connect(mapStateToProps, mapDispatchToProps)(HOCComponent);
  };

export default MemberJoinInjector;

import React, { Component } from 'react';

export default class ErrorLabel extends Component {
  render() {
    let { label, style = {} } = this.props;
    return (
      <p className="validation-check" style={style}>
        {label}
      </p>
    );
  }
}

import React, { Component } from 'react';

class ModalContainer extends Component {
  componentWillUnmount() {
    clearTimeout(this.imgPopup);
  }

  componentDidUpdate(prevProps, prevState) {
    this.resizeModal();
  }

  componentDidMount() {
    this.resizeModal();
  }

  resizeModal() {
    const popup = this.currentDom?.querySelector('.layer-wrap');
    // layer-wrap이 없는 경우 예외처리
    if (!popup) return;
    let popH = Math.floor(-parseInt(popup.offsetHeight / 2, 10));
    if (popup.querySelector('img')) {
      this.imgPopup = setTimeout(() => {
        popH = Math.floor(-parseInt(popup.offsetHeight / 2, 10));
        popup.style.marginTop = popH + 'px';
      }, 500);
    } else {
      popup.style.marginTop = popH + 'px';
    }
  }

  render() {
    const { onClickDim } = this.props;
    const newProps = {
      history: this.props.history,
      layerKey: this.props.layerKey,
      layerCount: this.props.layerCount,
    };
    return (
      <div
        className="layer"
        style={{ zIndex: this.props.layerKey }}
        ref={(ref) => (this.currentDom = ref)}
      >
        <div className="dim" onClick={onClickDim}></div>
        {React.cloneElement(this.props.children, newProps)}
      </div>
    );
  }
}

export default ModalContainer;

'use client';

import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { routes } from '@/constants/routes';

const FreeStudyBanner = ({ history }) => {
  const router = useRouter();
  const showBanner =
    history.pathname === '/' || history.pathname.startsWith('/subject');

  if (!showBanner) return null;

  return (
    <Link className="free-study-banner" href={routes.wink.freeStudy}>
      <img
        src="https://s.wink.co.kr/marketing/homepage/floating/free-study-btn-20240820.png"
        alt=""
      />
    </Link>
  );
};

export default FreeStudyBanner;

import React, { Component } from 'react';
import Link from 'next/link';
import FakeLogin from '@/features/fakelogin/FakeLogin';
import DesktopLayerPopup from '../../DesktopLayerPopup';
import { FOOTER_FAMILY_SITE, FOOTER_MENU } from '@/constants/menu';

class Footer extends Component {
  constructor(props) {
    super(props);

    this.timer = null;
    this.clickCount = 0;

    this.state = {
      siteMapOpened: false,
    };
  }

  onClickFakeLogin = () => {
    if (this.clickCount === null) {
      return;
    }

    if (this.timer) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(() => {
      this.clickCount = 0;
    }, 500);

    this.clickCount += 1;

    if (this.clickCount % 5 === 0) {
      clearTimeout(this.timer);
      this.clickCount = null;
      DesktopLayerPopup.modal(
        <FakeLogin
          {...this.props}
          callback={() => {
            this.clickCount = 0;
          }}
        />
      );
    }
  };

  onClickSiteMap = () => {
    this.setState((prev) => ({ siteMapOpened: !prev.siteMapOpened }));
  };

  render() {
    const { siteMapOpened } = this.state;

    return (
      <section id="footer">
        <div className={'footer-logo-container'}>
          <img
            src={'https://s.wink.co.kr/pc/renewalV3/common/danbiedu_logo.svg'}
            alt={'danbi-edu-logo'}
            onClick={this.onClickFakeLogin.bind(this)}
          />
        </div>
        <div className={'footer-info-container'}>
          <ul className={'footer-menu-list'}>
            {FOOTER_MENU.map(({ content, id, ...linkArgs }) => {
              return (
                <li key={id}>
                  <Link id={id} {...linkArgs}>
                    {content}
                  </Link>
                </li>
              );
            })}
          </ul>
          <div className={'footer-company-info'}>
            {'(주) 단비교육 l 대표이사 권영금, 최형순\n' +
              '주소 06339 서울특별시 강남구 개포로 640 단비교육 l 사업자 등록번호 629-87-00476\n' +
              '통신판매업 신고번호 제 2017-서울강남-03262호 l 전자출판물 인증번호 I410-ECN-0199-2021-000-000017348\n' +
              '제휴·마케팅 문의 이메일 marketing@danbiedu.co.kr 공식 이메일 wink@danbiedu.co.kr'}
          </div>
        </div>
        <div className={'footer-contact-container'}>
          <p className="title">윙크 학부모님 공감센터</p>
          <p className={'cs-number'}>1522-1244</p>
          <p className="time">평일 10:00 ~ 20:00</p>
          <div className={'family-site-container'}>
            <button onClick={this.onClickSiteMap}>
              <span>FAMILY SITE</span>
              <img
                src={
                  'https://s.wink.co.kr/pc/renewalV3/icons/icon_arrow_bottom.svg'
                }
                className={siteMapOpened ? 'open' : ''}
                alt={'arrow'}
              />
            </button>
            <ul className={`family-site-list ${siteMapOpened ? 'open' : ''}`}>
              {FOOTER_FAMILY_SITE.map(({ content, id, ...linkArgs }) => {
                return (
                  <li key={id}>
                    <Link id={id} {...linkArgs}>
                      {content}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </section>
    );
  }
}

export default Footer;

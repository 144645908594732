import React from 'react';
import Link from 'next/link';
import { EXTERNAL_LINK } from '@/constants/common';

const ErrorFallBack = () => {
  return (
    <div className="maintenance-container">
      <div className="maintenance-title">
        <div className="maintenance-icon">
          <img
            src="https://s.wink.co.kr/maintenance/resources/images/alert.png"
            alt="alert"
          />
        </div>
        <div className="maintenance-big-message">
          <p className="message">
            <span className="message-inline-group">서비스 이용 중&nbsp;</span>
            <span className="message-inline-group">
              <span className="emphasize">에러</span>가 발생하였습니다.
            </span>
          </p>
        </div>
      </div>
      <div className="maintenance-content">
        <div className="maintenance-message-detail">
          <p className="message">
            재미로 입학하여 실력으로 졸업하는 윙크입니다.
          </p>
          <p className="message">
            <span className="emphasize">
              <span className="message-inline-group">
                서비스 이용에 불편을 드려 죄송합니다.&nbsp;{' '}
              </span>
              <span className="message-inline-group">
                빠른 시일 내에 문제를 해결할 예정이오니, 양해 부탁드리겠습니다.
              </span>
            </span>
          </p>
          <p className="message">
            <span className="emphasize">
              <span className="message-inline-group">
                기존에 이용하던 서비스로 돌아가고 싶으시다면,&nbsp;
              </span>
              <span className="message-inline-group">
                뒤로 가기를 눌러주세요.
              </span>
            </span>
          </p>
          <p className="message">감사합니다.</p>
        </div>
      </div>
      <hr className="maintenance-divider" />
      <div className="maintenance-footer">
        <div className="maintenance-link">
          <div id="free" className="link-box" style={{ display: 'flex' }}>
            <div className="message-container-btn">
              <p className="message">윙크 무료체험 간편 신청</p>
            </div>
            <Link className="link" href={EXTERNAL_LINK.FREE_STUDY_FORM}>
              <i className="icon icon-shortcut"></i> 바로가기{' '}
              <i className="icon icon-arrow-right"></i>
            </Link>
          </div>
          <div id="cs-center" className="link-box" style={{ display: 'flex' }}>
            <div className="message-container-btn">
              <p className="message">윙크 학부모님 공감센터 1522-1244</p>
              <p className="message extra">(운영 시간: 평일 10시 ~ 20시)</p>
            </div>
          </div>
        </div>
        <a className="maintenance-comment" href={'tel:1522-1244'}>
          <p className="message">
            *윙크 학부모님 공감센터 1522-1244 (운영 시간: 평일 10시 ~ 20시)
          </p>
        </a>
      </div>
    </div>
  );
};

export default ErrorFallBack;

import APICaller from '../APICaller';
import Async from '../Async';
// import {ItemType} from '../constants/Code'

export default class UserServices {
  static getActorTypes() {
    const url = '/account/actor_types/?offset=0&limit=1000';

    return APICaller.getCache(url);
  }

  static getItemTypes() {
    const url = '/item/item_types/?offset=0&limit=1000';

    return APICaller.getCache(url);
  }

  static getItemInventoryTypes() {
    const url = '/item/inventory_types/?offset=0&limit=1000';

    return APICaller.getCache(url);
  }

  static getActorTypeInfoById(id) {
    return this.getActorTypes().then((response) => {
      for (const item of response.data.results) {
        if (item.id === id) return item;
      }
    });
  }

  static getActorTypeInfoByName(name) {
    return this.getActorTypes().then((response) => {
      for (const item of response.data.results) {
        if (item.name === name) return item;
      }
    });
  }

  static getActorTeamTypeInfoByName(teamName) {
    const url = '/account/actor_types/Team/get_named/';
    const params = {
      name: teamName,
    };
    return APICaller.get(url, params);
  }

  static getActorInfoByName(name, params) {
    const url = `/account/actor_types/${encodeURIComponent(name)}/get_named/`;
    return APICaller.get(url, params);
  }

  static getActor(id) {
    const url = `/account/actors/${id}/`;

    return APICaller.get(url);
  }

  static getMemberListByActorId(actorId) {
    const url = `/account/actors/${actorId}/members/`;

    return APICaller.get(url);
  }

  static getParentInfoByDeviceId(deviceId, deviceUserName) {
    const url = '/account/auths/get_parent_info/';
    const params = {
      id: deviceId,
      username: deviceUserName,
    };

    return APICaller.post(url, params);
  }

  static logout() {
    const url = '/account/auths/logout/';

    return APICaller.post(url);
  }

  static authenticate(username, password, isFakeLogin = false) {
    const url = '/account/auths/authenticate_new/';
    const params = { username, password, is_fakelogin: isFakeLogin };
    return APICaller.post(url, params);
  }

  static phoneAuthenticate(human_mdn, human_name, onetime_password) {
    const url = '/account/auths/authenticate/';
    const params = { human_mdn, human_name, onetime_password };

    return APICaller.post(url, params);
  }

  static tokenAuthenticate(auth_token) {
    const url = '/account/auths/authenticate/';
    const params = { auth_token };

    return APICaller.post(url, params);
  }

  static actorLogin(authId, actor_type) {
    const url = `/account/auths/${authId}/login_actor/`;
    const params = { actor_type };

    return APICaller.post(url, params);
  }

  static actorLoginForStudent(authId, studentId) {
    const url = `/account/auths/${authId}/login_actor/`;
    const params = { actor: studentId };

    return APICaller.post(url, params);
  }

  //로그인
  static login = async (userName, userPwd, employeeId) => {
    const auth = await UserServices.authenticate(userName, userPwd);
    const actor = await UserServices.actorLogin(auth.data.id, employeeId);
    return { auth, actor };
  };
  //폰번호로그인
  static phoneLogin = async (
    human_mdn,
    human_name,
    onetime_password,
    actorType
  ) => {
    const auth = await UserServices.phoneAuthenticate(
      human_mdn,
      human_name,
      onetime_password
    );
    const actor = await UserServices.actorLogin(auth.data.id, actorType);
    return { auth, actor };
  };
  //토큰로그인
  static authTokenLogin(token, actorType) {
    return Async(function* (token) {
      let auth = yield UserServices.tokenAuthenticate(token);
      let actor = yield UserServices.actorLogin(auth.data.id, actorType);
      return { auth, actor };
    }, token);
  }

  static addUser(userName, password, humanName, humanPhone, humanEmail) {
    const url = '/account/auths/';
    const params = {
      username: userName,
      password,
      human_name: humanName,
      human_mdn: humanPhone,
      human_email: humanEmail,
    };

    return APICaller.post(url, params);
  }

  static addActor(actorType) {
    const url = '/account/actors/';
    const params = {
      model_type: actorType,
    };

    return APICaller.post(url, params);
  }

  static getAuthsByUserName(userName) {
    const url = `/account/auths/?username=${encodeURIComponent(userName)}`;
    return APICaller.get(url);
  }

  //id중복체크
  static idDuplicateCheck(userName) {
    return UserServices.getAuthsByUserName(userName);

    // const url = `/account/auths/?username=${username}`;
    // return APICaller.get(url);
  }
  //phone중복체크
  static phoneDuplicateCheck(human_mdn) {
    const url = `/account/auths/?human_mdn=${human_mdn}`;

    return APICaller.get(url);
  }
  //email중복체크
  static emailDuplicateCheck(human_email) {
    const url = `/account/auths/?human_email=${human_email}&model_type=1`;

    return APICaller.get(url);
  }
  //휴대폰 인증번호 발송
  static oneTimePassword(mdn, isKakaoTalk = false) {
    const url = '/account/auths/send_sms_onetime_password/';
    const params = {
      human_mdn: mdn,
      is_kakao_talk: isKakaoTalk,
    };

    return APICaller.post(url, params);
  }

  // 로그인 보안 강화 휴대폰 인증번호 발송
  static oneTimePasswordNew = (params) => ({
    url: '/account/auths/send_sms_onetime_password/',
    param: {
      ...params,
    },
  });

  // 로그인 보안 강화 비밀번호 재설정 인증번호 확인
  static checkOnetimePassword = (params) => ({
    url: '/account/auths/check_mdn/',
    param: {
      ...params,
    },
  });

  // 로그인 보안 강화 비밀번호 재설정
  static changePassword = (params) => ({
    url: '/account/auths/change_password/',
    param: {
      ...params,
    },
  });

  static sendOneTimePasswordForStudent(deviceAuthId, deviceAuthUserName) {
    const url = '/account/auths/send_sms_onetime_password/';
    const params = {
      id: deviceAuthId,
      username: deviceAuthUserName,
    };

    return APICaller.post(url, params);
  }

  static getOTPAuthenticateForStudent(username, otp) {
    const url = '/account/auths/authenticate/';
    const params = {
      username: username,
      onetime_password: otp,
    };

    return APICaller.post(url, params);
  }

  //인증번호 체크
  static checkMdn(mdn, oneTimePassword) {
    const url = '/account/auths/check_mdn/';
    const params = {
      human_mdn: mdn,
      onetime_password: oneTimePassword,
    };
    return APICaller.post(url, params);
  }
  //auth 생성
  static createAuths(
    username,
    password,
    human_name,
    human_mdn,
    mdn_token,
    data
  ) {
    const url = '/account/auths/';

    const params = {
      username: username,
      password: password,
      human_name: human_name,
      human_mdn: human_mdn,
      mdn_token: mdn_token,
      data: data,
    };

    return APICaller.post(url, params);
  }

  //이름과 전화번호만으로 auth 생성
  static createAuthsForExperience(param) {
    const url = '/account/auths/';
    const params = {
      human_name: param.human_name,
      human_mdn: param.human_mdn,
      mdn_token: param.mdn_token,
      human_email: param.human_email,
      human_postal_code: param.human_postal_code,
      human_address: param.human_address,
      human_detail_address: param.human_detail_address,
      data: param.data,
    };

    return APICaller.post(url, params);
  }

  //로그인 상태 유지 auth_token을 받는다.
  static getAuthToken(id) {
    const url = `/account/auths/${id}/get_auth_token/`;

    return APICaller.get(url);
  }

  static modifyAuths(id, authInfo) {
    const url = `/account/auths/${id}/modify/`;
    const params = { ...authInfo };
    return APICaller.post(url, params);
  }

  static modifyActors(id, actorInfo) {
    const url = `/account/actors/${id}/modify/`;
    const params = { ...actorInfo };

    return APICaller.post(url, params);
  }

  // Family Group Actor 조회/생성
  static getOrCreateFamily(actorId, tagName) {
    const url = `/account/actors/${actorId}/get_or_create_family/`;
    const params = {
      parent_category_tag_name: tagName,
    };

    return APICaller.post(url, params);
  }

  // 홈쇼핑 아이정보 등록
  static setStudentMember(familyId, name, isMale, birthday) {
    const url = `/account/actors/${familyId}/set_student_member/`;
    const params = {
      human_name: name,
      is_male: isMale,
      birthday: birthday,
    };

    return APICaller.post(url, params);
  }
  //아이 등록
  static addStudentMember(familyId, name, isMale, birthday) {
    const url = `/account/actors/${familyId}/add_student_member/`;
    const params = {
      human_name: name,
      is_male: isMale,
      birthday: birthday,
    };

    return APICaller.post(url, params);
  }

  //Secondary Student Actor Login 을 위한 학생 선택
  //참고로 actor_type = 1 학생 입니다.
  static getMemberListByModelType(familyId, actor_type) {
    const url = `/account/actors/${familyId}/members/?model_type=${actor_type}`;
    const params = {
      limit: 200,
      offset: 0,
    };
    return APICaller.get(url, params);
  }

  //Secondary Student Actor Login
  static loginSecondary(actorId, secondary_actor) {
    const url = `/account/actors/${actorId}/login_secondary/`;
    const params = {
      secondary_actor: secondary_actor,
    };

    return APICaller.post(url, params);
  }

  static loginSecondaryForAuthId(authId, secondary_actorFamilyId) {
    const url = `/account/auths/${authId}/login_secondary_actor/`;
    const params = {
      secondary_actor: secondary_actorFamilyId,
    };

    return APICaller.post(url, params);
  }

  //팀 전체 목록 조회
  static getTeamList() {
    const url = '/account/actors/?model_type=14';

    return APICaller.get(url);
  }

  static getAuths(ids) {
    const url = '/account/auths/';
    const params = {
      id__in: ids,
    };

    return APICaller.get(url, params);
  }

  static deleteActor(actorId) {
    const url = `/account/actors/${actorId}/remove/`;

    return APICaller.post(url);
  }

  static deleteAuths(authId) {
    const url = `/account/auths/${authId}/remove/`;

    return APICaller.post(url);
  }

  static getWhoAmI() {
    const url = '/account/auths/whoami/';

    return APICaller.get(url);
  }

  static sendMessage(message) {
    const url = '/account/actors/send_message/';

    return APICaller.post(url, message);
  }
  static sendSmsDownloadLink(human_mdn) {
    const url = '/account/auths/send_sms_download_link/';
    const params = {
      human_mdn: human_mdn,
    };

    return APICaller.post(url, params);
  }
  //쿠폰
  static getInventoriesInfo(parentId) {
    const url = `/account/actors/${parentId}/inventories/?model_type=3&status=0`;

    return APICaller.get(url);
  }

  static getCoupon(couponItmeId) {
    const url = `/item/items/${couponItmeId}/`;

    return APICaller.get(url);
  }

  static addCoupon(parentId, couponName) {
    const url = `/account/actors/${parentId}/register_coupon/`;
    const params = {
      coupon_inventory_name: couponName,
    };
    return APICaller.post(url, params);
  }

  static regCoupon(parentId, couponCode) {
    const url = `/account/actors/${parentId}/register_public_coupon/`;
    const params = {
      coupon_code: couponCode,
    };
    return APICaller.post(url, params);
  }

  // 푸시 메세지를 발송한다.
  static sendPushMessage(message) {
    const url = '/account/actors/send_mobile_push/';

    return APICaller.post(url, message);
  }

  // 세팅 정보
  static getSettingInfo(model_type, name) {
    const url = `/aux/setting_types/${model_type}/get_named/?name=${encodeURIComponent(
      name
    )}`;

    return APICaller.get(url);
  }

  // 유저정보 임시저장
  static temporarySaveUserInfo(id, postData) {
    const url = `/aux/settings/${id}/modify/`;
    const data = {
      data: { ...postData },
    };

    return APICaller.post(url, data);
  }

  //guestFileUpload를 위하여 Guest Auth 정보 확인
  static getGuestAuth() {
    const url = '/account/auth_types/GUEST/get_named/?name=GUEST';

    return APICaller.get(url);
  }

  // 회원탈퇴
  static withdrawal(authId, params = null) {
    const url = `/account/auths/${authId}/membership_withdrawal/`;

    return APICaller.post(url, params);
  }

  static fakeLogin(authId, paretActorId, actorType) {
    const url = `/account/auths/${authId}/fake_login_actor/`;
    const params = {
      actor: paretActorId,
      actor_type: actorType,
    };

    return APICaller.post(url, params);
  }

  static getFakeLoginLogList(actorId) {
    const url = `/aux/logs/?model_type=11&actor=${actorId}`;

    return APICaller.get(url);
  }

  // 휴지회원 tests_33330_pause_service.py test_pause_service
  static getPausedMember(actorId) {
    const url = `/aux/logs/?model_type=16&student=${actorId}`;

    return APICaller.get(url);
  }

  // 무료학습 신청 중도 이탈 DB 저장 (신규 버전)
  static createTemporaryInformationActorWithMdnAuth(params) {
    const url = '/temporary-information/actor/information-with-mdn-auth/';
    return APICaller.post(url, params);
  }

  // 무료학습 신청 중도 이탈이 아닌 회원 로그 삭제 (신규 버전)
  static deleteTemporaryInformationActor(informationId, params) {
    const url = `/temporary-information/actor/${informationId}/`;
    return APICaller.delete(url, params);
  }
}

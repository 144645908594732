export const RegExp = {
  BIRTH: '([0-9]{2}(1[0-2]|0[1-9])([0-2][0-9]|3[0-1]))|[0-9]{10,13}',
  NUMBER: /^[0|1|2|3|4|5|6|7|8|9|-]+$/,
  PHONE: /^01([0|1|5|6|7|8|9]?)-?([0-9]{3,4})-?([0-9]{4})$/,
  //이미지 jpg gif png
  IMG: /([^\s]+(?=\.(jpg|gif|png))\.\2)/,
  //이메일 형식 체크
  EMAIL: /^[a-z0-9_+.-]+@([a-z0-9-]+\.)+[a-z0-9]{2,4}$/,
  //6~20자리 최소 1개의 숫자 혹은 특수 문자 포함
  PASSWORD: /^(?=.*[a-zA-Z])((?=.*\d)|(?=.*\W)).{6,20}$/,
  //8~20 소문자 숫자 특수문자 각 최소 1개 이상 [현재 사용중]
  PASSWORD2: /^(?=.*[a-z])((?=.*\d)(?=.*\W)).{8,20}$/,
  //6~20 소문자 숫자만
  ID: /^([a-z0-9-]){6,20}$/,
  //한글 과 숫자 [도로명/지번 주소 query에 사용]
  ONLY_KOREAN_NUMBER: /^[가-힣|0-9\s|-]+$/,
  //QNA 유저 이름에서 사용
  USER_NAME: /^[가-힣|a-z|A-Z| ]+$/,
  //대문자가 있는지 체크에 사용
  TO_UPPER_CASE: /^([A-Z]){6,20}$/,
};

import IssueTrackerServices from '../../../library/services/IssueTrackerServices';
import * as ExceptionAction from '../../../library/error/ExceptionAction';

import { service } from '@/features/common/configs';

export const ActionTypes = Object.freeze({
  GET_ISSUE_TYPE: 'REPORT/GET_ISSUE_TYPE',
  SUBMIT_INQUIRY_ISSUE: 'REPORT/SUBMIT_INQUIRY_ISSUE',
  SUBMIT_INQUIRY_FINISH: 'REPORT/SUBMIT_INQUIRY_FINISH',
  GET_MY_REPORT_LIST: 'REPORT/GET_MY_REPORT_LIST',
  GET_MY_QNA_DETAIL: 'REPORT/GET_MY_QNA_DETAIL',
  MODIFY_INQUIRY_ISSUE: 'REPORT/MODIFY_INQUIRY_ISSUE',
  COLLECT_INQUIRY_ISSUE: 'REPORT/COLLECT_INQUIRY_ISSUE',
  GET_ISSUE_FILE_LIST: 'REPOST/GET_ISSUE_FILE_LIST',
  SEND_EMAIL_NOT_LOGIN: 'REPORT/SEND_EMAIL_NOT_LOGIN',
  INIT_ISSUE_FILELIST: 'REPORT/INIT_ISSUE_FILELIST',
  // EXCEPTION: `EXCEPTION`,
});

function generateAPIQuery(params) {
  let query = { ...params };
  for (let key of Object.keys(query)) {
    if (Array.isArray(query[key]) === true) {
      query[key] = query[key].toString();
    } else if (query[key] === '') {
      delete query[key];
    }
  }
  return query;
}

export function getInquiryType(categoryTagName) {
  return (dispatch) =>
    dispatch(() => {
      return IssueTrackerServices.getInquiryIssueTypeSecond(categoryTagName)
        .then(({ data }) => {
          const issueTypeMembers = (
            data?.sdata?.hierachy?.members ?? []
          ).reduce((list, current) => {
            if (current.members) {
              current.members = current.members.map((obj) => {
                return { ...obj, parent: current.id };
              });
              list.push(current);
            }
            return list;
          }, []);
          return dispatch({
            type: ActionTypes.GET_ISSUE_TYPE,
            payload: {
              lastAction: ActionTypes.GET_ISSUE_TYPE,
              issueCategoryTag: {
                ...data.sdata.hierachy,
                members: issueTypeMembers,
              },
            },
          });
        })
        .catch((e) => {
          return dispatch(
            ExceptionAction.exception(
              e,
              '이슈 타입 받는 중 오류가 발생하였습니다.'
            )
          );
        });
    });
}

export function submitInquiryIssue(paramAddIssue, paramCompleteWork) {
  return (dispatch, getState) =>
    dispatch(() => {
      return IssueTrackerServices.addIssue(paramAddIssue)
        .then((result) => {
          return IssueTrackerServices.completeWork(
            result.data.current_work,
            'CSRGeneralInquiryProcessWork',
            paramCompleteWork
          ).then((result2) => {
            return dispatch({
              type: ActionTypes.SUBMIT_INQUIRY_ISSUE,
              payload: {
                lastAction: ActionTypes.SUBMIT_INQUIRY_ISSUE,
              },
            });
          });
        })
        .catch((e) => {
          return dispatch(
            ExceptionAction.exception(
              e,
              '문의하기 등록 중 오류가 발생하였습니다.'
            )
          );
        });
    });
}

export function modifyInquiryIssue(issueId, collectInquiryIssueInfo) {
  return (dispatch, getState) =>
    dispatch(() => {
      return IssueTrackerServices.setIssue(issueId, collectInquiryIssueInfo)
        .then((response) => {
          dispatch({
            type: ActionTypes.MODIFY_INQUIRY_ISSUE,
            payload: {
              issueId,
              collectInquiryIssueInfo,
            },
          });
          return dispatch(getMyQnaDetail(issueId));
        })
        .catch((e) => {
          return dispatch(
            ExceptionAction.exception(
              e,
              '이슈정보 수정중 오류가 발생하였습니다.'
            )
          );
        });
    });
}

export function setLastActionSubmitFinish() {
  return (dispatch, getState) => {
    return dispatch({
      type: ActionTypes.SUBMIT_INQUIRY_FINISH,
      payload: {
        lastAction: ActionTypes.SUBMIT_INQUIRY_FINISH,
      },
    });
  };
}

export function getMyReportList(actorId, pageNo, pageSize, query = {}) {
  return (dispatch, getState) =>
    dispatch(() => {
      const currentPageNo = pageNo || 1;
      const newPageSize = getState().BoardReducer.pageSize || pageSize;
      const offset = (currentPageNo - 1) * newPageSize;
      return IssueTrackerServices.getMyReportList(
        actorId,
        generateAPIQuery({ ...query, limit: newPageSize, offset })
      )
        .then((result) => {
          return dispatch({
            type: ActionTypes.GET_MY_REPORT_LIST,
            payload: {
              postList: result.data,
              lastAction: ActionTypes.GET_MY_REPORT_LIST,
            },
          });
        })
        .catch((e) => {
          return dispatch(
            ExceptionAction.exception(
              e,
              '나의 문의하기 리스트 받기 중 오류가 발생하였습니다.'
            )
          );
        });
    });
}

export function getMyQnaDetail(id) {
  return (dispatch, getState) =>
    dispatch(() => {
      return IssueTrackerServices.getIssueInfo(id)
        .then((result) => {
          if (!result.data.category_tag) {
            return dispatch({
              type: ActionTypes.GET_MY_QNA_DETAIL,
              payload: {
                myqnadetail: result.data,
                fullname: '',
                lastAction: ActionTypes.GET_MY_QNA_DETAIL,
              },
            });
          }
          return getTag(dispatch, result);
        })
        .catch((e) => {
          return dispatch(
            ExceptionAction.exception(
              e,
              '나의 문의하기 상세 받기 중 오류가 발생하였습니다.'
            )
          );
        });
    });
}

function getTag(dispatch, response) {
  return IssueTrackerServices.getTag(response.data.category_tag).then(
    (result) => {
      return dispatch({
        type: ActionTypes.GET_MY_QNA_DETAIL,
        payload: {
          myqnadetail: response.data,
          fullname: result.data.full_name,
          lastAction: ActionTypes.GET_MY_QNA_DETAIL,
        },
      });
    }
  );
}

export function getIssueFileList(issueId, propertyName) {
  return (dispatch, getState) =>
    dispatch(() => {
      return IssueTrackerServices.getIssueFileList(issueId, propertyName)
        .then((response) => {
          dispatch({
            type: ActionTypes.GET_ISSUE_FILE_LIST,
            payload: {
              issueFileList: response.data.results,
              propertyName,
            },
          });
        })
        .catch((e) => {
          return dispatch(
            ExceptionAction.exception(e, '파일 조회중 오류가 발생하였습니다.')
          );
        });
    });
}

export function initIssueFileList() {
  return {
    type: ActionTypes.INIT_ISSUE_FILELIST,
  };
}

export function sendEmailNotLogin(title, html, sender) {
  return (dispatch, getState) =>
    dispatch(() => {
      return IssueTrackerServices.sendEmailWithoutLoginQna(title, html, sender)
        .then((result) => {
          return dispatch({
            type: ActionTypes.SEND_EMAIL_NOT_LOGIN,
            payload: {
              lastAction: ActionTypes.SEND_EMAIL_NOT_LOGIN,
            },
          });
        })
        .catch((e) => {
          return dispatch(
            ExceptionAction.exception(
              e,
              '문의하기 등록 중 오류가 발생하였습니다.'
            )
          );
        });
    });
}

import React, { Component } from 'react';
import moment from 'moment';
import DesktopLayerPopup from '@/features/common/DesktopLayerPopup';
import SelectDate from '@/features/common/components/SelectDate';
import ErrorLabel from '@/features/common/components/ErrorLabel';
import {
  api,
  values as winkExperienceValues,
} from '@/features/wink/winkexperience/config';
import { DateFormat } from '../../../library/constants/Date';
import ButtonBlockUtil from '../../../library/util/ButtonBlockUtil';
import ParentsUtil from '../../../library/util/ParentsUtil';
import { Radio, RadioGroup } from '../../../library/Radio';
import { CheckBox } from '../../../library/CheckBox';
import { values } from './config';
import MyPageKidInfoPhoneNumberPop from './MyPageKidInfoPhoneNumberPop';
import { ApplyChannel } from '@/library/constants/Code';
import { APICaller } from '@/features/common/configs';
import MemberJoinInjector from '@/library/memberjoin/MemberJoinInjector';

const mapStateToProps = ({ MemberReducer }) => {
  return {
    memberAction: MemberReducer.action,
    member: MemberReducer.actor,
  };
};

class MyPageKidInfoPop extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitState();
    this.ButtonBlockUtil = new ButtonBlockUtil();

    this.onClickHide = this.onClickHide.bind(this);
    this.onClickSave = this.onClickSave.bind(this);
    this.selectDateCallback = this.selectDateCallback.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onRadio = this.onRadio.bind(this);
    this.validateChildInfoRegister = this.validateChildInfoRegister.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { errorType, exception } = this.state;
    const { memberAction, layerKey } = this.props;

    if (errorType === 'noError' && exception === 'noException') {
      if (
        memberAction === 'successSetStudentMember' ||
        memberAction === 'successSetStudentMemberAndLoginSecondary'
      ) {
        this.props.clearMemberAction();
        DesktopLayerPopup.notice({
          message: '등록 되었습니다.',
          callback: () => DesktopLayerPopup.hide(layerKey),
        });
      }

      if (memberAction === 'successModifyStudents') {
        this.props.clearMemberAction();
        DesktopLayerPopup.notice({
          message: '변경 되었습니다.',
          callback: () => DesktopLayerPopup.hide(layerKey),
        });
      }
    }
  }

  getInitState() {
    const initState = {
      errorType: 'initialError',
      exception: 'initialException',
      radio: 'female',
      name: '',
      dateOfBirth: '',
      year: '',
      month: '',
      day: '',
      moreThanFourteen: false,
    };
    const { studentInfo } = this.props;
    if (studentInfo) {
      const birthDay = ParentsUtil.getActorBirthday(studentInfo);
      const [year, month, day] = birthDay.split('.');
      initState.name = ParentsUtil.getActorHumanName(studentInfo);
      initState.radio = ParentsUtil.isActorMale(studentInfo)
        ? 'male'
        : 'female';
      initState.year = year;
      initState.month = month;
      initState.day = day;
    }
    return initState;
  }

  getModifyStudentsIndexObject() {
    const { studentList, studentInfo } = this.props;
    const targetIndex = studentList.findIndex(
      (student) => student.id === studentInfo.id
    );

    return targetIndex === -1 ? {} : { [targetIndex]: true };
  }

  getModifiedStudentList() {
    const { name, radio, year, month, day } = this.state;
    const { studentInfo } = this.props;
    const studentInfoAuth = { ...studentInfo };

    studentInfoAuth.auth_detail = {};
    studentInfoAuth.auth_detail.human_name = name;
    studentInfoAuth.auth_detail.is_male = radio === 'male';
    studentInfoAuth.auth_detail.birthday = moment(
      `${year}-${month}-${day}`
    ).toDate();
    studentInfoAuth.auth_detail.id = this.props.studentInfo.auth;

    return [studentInfoAuth];
  }

  selectDateCallback(date) {
    const { year, month, day } = date;
    this.setState({ year, month, day });
  }

  validateChildInfoRegister() {
    const { name, year, month, day, moreThanFourteen } = this.state;

    const isRegister = !this.props.studentInfo;
    const manAge = ParentsUtil.getManAge(`${year}${month}${day}`);

    if (!name.trim()) {
      this.setState({ errorType: 'nameError' });
    } else if (!year || !month || !day) {
      this.setState({ errorType: 'birthdayError' });
    } else if (
      moment(moment().format(DateFormat.half)).isBefore(
        `${year}-${month}-${day}`
      )
    ) {
      this.setState({ errorType: 'isAfterError' });
    } else if (
      isRegister &&
      !moreThanFourteen &&
      manAge < values.agreementAge
    ) {
      this.setState({ errorType: 'moreThanFourteenError' });
    } else {
      this.setState({ errorType: 'noError' });
      return true;
    }
    return false;
  }

  onChange(e, type) {
    const {
      target: { value },
    } = e;
    this.setState((prevState) => ({
      [type]: value,
      errorType:
        prevState.errorType === 'nameError' ? 'nextError' : prevState.errorType,
    }));
  }

  onCheckBox = (e, type) => {
    const {
      target: { checked },
    } = e;
    this.setState({
      [type]: checked,
    });
  };

  onRadio(value) {
    this.setState({
      radio: value,
    });
  }

  onClickException(type, exception) {
    if (type === 'CLOSE') {
      const url =
        exception === 'reApply'
          ? 'https://m.wink.co.kr/client/mobile/simple-apply?source=homepage&medium=reoffer&content=pc'
          : 'https://m.wink.co.kr/client/mobile/simple-apply?source=homepage&medium=pre-offer&content=under36m';
      window.open(url, '_blank');
      DesktopLayerPopup.hide(this.props.layerKey);
    }
  }

  onClickOneMoreRequest = (type) => {
    const { name } = this.state;

    if (this.props.onClose) {
      DesktopLayerPopup.hide(this.oneMorePopupId);
      if (type === 'CLOSE') {
        DesktopLayerPopup.hide(this.props.layerKey);
        this.props.onClose({
          type: winkExperienceValues.REGIST_CHILD_ANSWER_TYPE
            .IS_ONE_MORE_REQUEST,
          payload: {
            isOneMoreRequest: true,
            name,
          },
        });
      }
    }
  };

  onClickHide(e) {
    e.preventDefault();

    return DesktopLayerPopup.hide(this.props.layerKey);
  }

  onClickSave(e) {
    e.preventDefault();

    if (this.props.studentInfo) this.requestModifyChild();
    else this.requestChildInfoRegisterWithValidate();
  }

  requestChildInfoRegisterWithValidate = () => {
    const { name, radio, year, month, day } = this.state;
    const { authInfo } = this.props;

    if (!this.validateChildInfoRegister()) return;

    const birthday = `${year}${month}${day}`;
    const manAge = ParentsUtil.getManAge(birthday);
    // TODO 8월에만 8세 이상도 체험 신청 가능하도록 함. 캐츠홈 9월 배포에는 주석 해제 해야 함
    const isFalse = false;
    if (
      isFalse
      // ParentsUtil.isTargetForCatsHome(birthday)
    ) {
      const { url, params } = api.addTemporaryInformationActor({
        informationType: 'CatsHome',
        parentAuthHumanName: authInfo?.human_name,
        parentAuthHumanMdn: authInfo?.human_mdn,
        requestChannel: ApplyChannel.Pc,
        studentAuthHumanName: name,
        studentIsMale: 'male' === radio,
        studentBirthday: moment(birthday).format('YYYY-MM-DD HH:mm:ss'),
        studentHumanPostalCode: authInfo?.human_postal_code,
        studentHumanAddress: authInfo?.human_address,
        studentHumanDetailAddress: authInfo?.human_detail_address,
        registeredDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      });
      APICaller.post(url, params);
      return DesktopLayerPopup.notice({
        message: `<div>
            <div>윙크는 유아 전용 학습으로,</div>
            <div>초등학생인 우리아이를 위해 초등 전문학습 "캐츠홈"으로</div>
            <div>학습을 안내 받으실 수 있도록 곧 연락드리겠습니다.</div>
            <div>(영업일 기준 1~2일 이내)</div>
          </div>`,
      });
    }

    if (manAge < values.agreementAge) {
      if (this.phoneNumberPopup) DesktopLayerPopup.hide(this.phoneNumberPopup);

      this.phoneNumberPopup = DesktopLayerPopup.modal(
        <MyPageKidInfoPhoneNumberPop
          successCheckUser={() => {
            this.requestChildInfoRegister();
            DesktopLayerPopup.hide(this.phoneNumberPopup);
          }}
          onClosePopup={() => DesktopLayerPopup.hide(this.phoneNumberPopup)}
        />
      );
    } else {
      this.requestChildInfoRegister();
    }
  };

  requestChildInfoRegister = () => {
    if (this.ButtonBlockUtil.isBlocking()) return;

    const {
      setStudentMemberAndLoginSecondary,
      setStudentMember,
      studentList,
      human_name,
      member,
      isFreeStudy,
    } = this.props;
    const { name, radio, year, month, day } = this.state;
    const isMale = 'male' === radio;

    if (studentList.length === 0) {
      if (member.id) {
        this.setState({ exception: 'noException' });
        return setStudentMemberAndLoginSecondary(
          name.trim(),
          isMale,
          moment(`${year}${month}${day}`).toDate()
        );
      }
      return setStudentMember(
        name.trim(),
        isMale,
        moment(`${year}${month}${day}`).toDate()
      );
    } else {
      const existStudent = studentList.find(
        (student) => student.auth_human_name === name.trim()
      );
      if (existStudent) {
        this.setState({ exception: 'reApplyException' });
        if (isFreeStudy) {
          this.oneMorePopupId = DesktopLayerPopup.notice({
            message:
              '이미 윙크를 신청한 경험이 있는 아이입니다.</br>' +
              '아래의 버튼을 누르시면 특별 추가 무료학습 기회를 드립니다.',
            callback: this.onClickOneMoreRequest,
            buttonLabel: '이어서 신청하기',
          });
        } else {
          DesktopLayerPopup.notice({
            message:
              '이미 윙크학습에 등록되어 있는 아이입니다. </br>' +
              '윙크 무료학습 신청을 한번 더 하실 경우는 </br>' +
              '아래 링크를 통해 신청해 주시길 바랍니다.^^',
            callback: (type) => this.onClickException(type, 'reApply'),
            buttonLabel: '무료학습 한번 더 신청하기',
          });
        }
        return false;
      }

      if (human_name.trim() === name.trim()) {
        this.setState({ exception: 'reApplyException' });
        DesktopLayerPopup.notice({
          message:
            '학부모님 이름과 아이 이름이 동일합니다. </br>' +
            '윙크 무료학습 신청을 원하실 경우, 아래 버튼을 통해 </br>' +
            '신청해주시기 바랍니다.',
          callback: (type) => this.onClickException(type, 'reApply'),
          buttonLabel: '무료체험 한번 더 신청하기',
        });
        return false;
      }

      this.setState({ exception: 'noException' });
      setStudentMember(
        name.trim(),
        isMale,
        moment(`${year}${month}${day}`).toDate()
      );
    }
  };

  requestModifyChild() {
    if (!this.validateChildInfoRegister()) return;
    if (this.ButtonBlockUtil.isBlocking()) return;

    const modifiedStudentsArr = this.getModifiedStudentList();
    const modifyStudentsIndexObject = this.getModifyStudentsIndexObject();
    this.setState({ exception: 'noException' });
    this.props.modifyStudents(modifiedStudentsArr, modifyStudentsIndexObject);
  }

  render() {
    const { errorType, year, month, day, radio, moreThanFourteen } = this.state;
    const { studentInfo } = this.props;
    const isStudied = ParentsUtil.isStudied(studentInfo);
    const isRegister = !this.props.studentInfo;

    return (
      <div
        className="layer-wrap large"
        style={{ marginTop: '-200px', overflow: 'visible' }}
      >
        <h2>
          {ParentsUtil.getActorHumanName(studentInfo)
            ? '아이 정보 수정'
            : '아이 정보 등록'}
        </h2>
        <table className="form">
          <caption>아이 정보 입력</caption>
          <tbody>
            <tr>
              <th scope="row">
                이름<em>필수</em>
              </th>
              <td>
                <fieldset className="name">
                  <legend>이름 입력</legend>
                  <input
                    type="text"
                    name="human_name"
                    title="이름 입력"
                    defaultValue={ParentsUtil.getActorHumanName(studentInfo)}
                    placeholder="이름을 입력해 주세요."
                    maxLength={7}
                    onChange={(e) => this.onChange(e, 'name')}
                  />
                </fieldset>
                {errorType === 'nameError' && (
                  <ErrorLabel label={'이름을 입력해 주세요.'} />
                )}
              </td>
            </tr>
            <tr className="checkbox">
              <th scope="row">
                성별<em>필수</em>
              </th>
              <td>
                <fielset className="sex">
                  <RadioGroup
                    name="gender"
                    selectedValue={radio}
                    onChange={this.onRadio}
                    mode={'column'}
                  >
                    <div>
                      <Radio
                        value="female"
                        label="여자아이"
                        name="gender"
                        onDefaultChecked={!ParentsUtil.isActorMale(studentInfo)}
                      />
                      <Radio
                        value="male"
                        label="남자아이"
                        name="gender"
                        onDefaultChecked={ParentsUtil.isActorMale(studentInfo)}
                      />
                    </div>
                  </RadioGroup>
                </fielset>
              </td>
            </tr>
            <tr className="checkbox">
              <th scope="row">
                생년월일<em>필수</em>
              </th>
              <td>
                <fieldset className="year ver4">
                  {!isStudied && (
                    <SelectDate
                      callback={this.selectDateCallback}
                      year={year}
                      month={month}
                      day={day}
                      size="7"
                    />
                  )}
                  {isStudied && ParentsUtil.getActorBirthday(studentInfo)}
                  {errorType === 'birthdayError' && (
                    <ErrorLabel label={'생년월일을 선택해 주세요.'} />
                  )}
                  {errorType === 'isAfterError' && (
                    <ErrorLabel label={'유효하지 않은 생년월일입니다.'} />
                  )}
                </fieldset>
              </td>
            </tr>
            {isRegister && (
              <tr>
                <td colSpan={2} style={{ paddingLeft: 15 }}>
                  <CheckBox
                    id={'more-than-fourteen'}
                    name={'more-than-fourteen'}
                    checked={moreThanFourteen}
                    onChange={(e) => this.onCheckBox(e, 'moreThanFourteen')}
                  />
                  <label htmlFor="more-than-fourteen">
                    만 14세 미만 아동입니다. (필수)
                  </label>
                  {errorType === 'moreThanFourteenError' && (
                    <ErrorLabel
                      label={
                        '만 14세 미만 아동은 법정대리인의 확인이 필요합니다.'
                      }
                    />
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="btn-area">
          <a href="#" className="btn-type2" onClick={this.onClickHide}>
            취소
          </a>
          <a href="#" className="btn-type2 confirm" onClick={this.onClickSave}>
            등록
          </a>
        </div>
        <button className="close" onClick={this.onClickHide} />
      </div>
    );
  }
}

export default MemberJoinInjector(mapStateToProps, null)(MyPageKidInfoPop);

export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID;

export const ACTION = {
  APPLY_FREE_STUDY: 'apply_free_study',
};

export const EVENT_NAMES = {
  APPLY_FREE_STUDY: 'apply_free_study',
};

export const EVENT_CATEGORIES = {
  FREE_STUDY_LEVEL_CHOICE: 'free_study_level_choice',
};

export const CLICK_NAMES = {
  FREE_STUDY_LEVEL_RECOMMEND: 'free_study_level_recommend',
  FREE_STUDY_LEVEL_MANUALLY_WINK: 'free_study_level_manually_wink',
  FREE_STUDY_LEVEL_MANUALLY_HELLO: 'free_study_level_manually_hello',
};

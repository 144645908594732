import { routes } from '@/constants/routes';
import { EXTERNAL_LINK } from '@/constants/common';
import { AgreementSrc } from '@/features/common/configs/values';
import DanbiInfo from '@/library/constants/DanbiInfo';

export type MenuItem = {
  path: {
    href: string;
    target?: string;
    id?: string;
  };
  content: string;
  query?: Record<string, string>;
  children?: MenuItem[];
  requireLogin?: boolean;
  className?: string;
};

export const GNB_MENU: MenuItem[] = [
  {
    path: {
      href: routes.home,
      id: 'nav-home',
    },
    content: '홈',
  },
  {
    path: {
      href: routes.subject.ko,
      id: 'nav-subject',
    },
    content: '과목별 학습',
    children: [
      {
        path: {
          href: routes.subject.ko,
          id: 'nav-subject-kor',
        },
        content: '수준별 한글 · 국어',
      },
      {
        path: {
          href: routes.subject.math,
          id: 'nav-subject-math',
        },
        content: '수준별 수학 · 연산',
      },
      {
        path: {
          href: routes.subject.en,
          id: 'nav-subject-en',
        },
        content: '수준별 영어&파닉스',
      },
      {
        path: {
          href: routes.subject.prepare,
          id: 'nav-subject-prepare',
        },
        content: '초등 입학 대비',
      },
      {
        path: {
          href: routes.subject.choice,
          id: 'nav-subject-choice',
        },
        content: '선택 활동(배경 지식)',
      },
    ],
  },
  {
    path: {
      href: routes.wink.freeStudy,
      id: 'nav-apply',
    },
    content: '학습 신청',
    children: [
      {
        path: {
          href: routes.wink.freeStudy,
          id: 'nav-free-apply',
        },
        content: '무료 학습 신청',
      },
      {
        path: {
          href: routes.wink.paidStudy,
          id: 'nav-paid-apply',
        },
        requireLogin: true,
        content: '유료 학습 신청',
      },
      {
        path: {
          href: EXTERNAL_LINK.GROUP_CONSULTATION,
          target: '_blank',
          id: 'nav-apply-group',
        },
        content: '어린이집, 유치원\n단체/해외 특별가 가입 신청',
        className: 'two-lines new',
      },
    ],
  },
  {
    path: {
      href: `${routes.referenceRoom}?subject=total`,
      id: 'nav-reference-room',
    },
    content: '학습 자료실',
  },
  {
    path: {
      href: routes.customer.notice,
      id: 'nav-notice',
    },
    content: '고객센터',
    children: [
      {
        path: {
          href: routes.customer.notice,
          id: 'nav-notice',
        },
        content: '공지사항',
      },
      {
        path: {
          href: routes.customer.event,
          id: 'nav-event',
        },
        content: '이벤트',
      },
      {
        path: {
          href: `${routes.customer.question}?page=1&is_noticed=true`,
          id: 'nav-question',
        },
        content: '자주하는 질문',
      },
      {
        path: {
          href: routes.customer.report,
          id: 'nav-report',
        },
        content: '신고 · 건의 · 문의',
      },
      {
        path: {
          href: routes.customer.download,
          id: 'nav-download',
        },
        content: '학부모 앱 다운로드',
      },
      {
        path: {
          href: routes.customer.teacher,
          id: 'nav-teacher',
        },
        content: '윙크 선생님 모집',
      },
      {
        path: {
          href: routes.customer.promotionTeacher,
          id: 'nav-promotion-teacher',
        },
        content: '홍보 선생님 모집',
      },
    ],
  },
];

export const FOOTER_MENU = [
  {
    id: 'footer-introduce-company',
    href: 'https://career.danbiedu.co.kr/',
    content: '회사소개',
  },
  {
    id: 'footer-agreement',
    href: DanbiInfo.Agreement,
    content: '이용약관',
    target: '_blank',
  },
  {
    id: 'footer-privacy-policy',
    href: AgreementSrc.privacyPolicy,
    content: '개인정보처리방침',
    target: '_blank',
  },
  {
    id: 'footer-recruit-teacher',
    href: routes.customer.teacher,
    content: '윙크 선생님 모집',
    className: 'pink',
  },
  {
    id: 'footer-recruit-promotion-teacher',
    href: routes.customer.promotionTeacher,
    content: '홍보 선생님 모집',
    className: 'pink',
  },
  {
    id: 'footer-join-agency',
    href: 'https://danbiedu.career.greetinghr.com/o/97353',
    content: '판매 대리점 모집',
    className: 'blue',
    target: '_blank',
  },
];

export const FOOTER_FAMILY_SITE = [
  {
    id: 'footer-cats',
    href: 'https://study.cats4me.net/?utm_source=WINK&utm_medium=homepage&utm_campaign=wink_marketing&utm_content=footer_familysite',
    content: '캐츠홈',
    target: '_blank',
  },
  {
    id: 'footer-wink-books',
    href: 'https://www.winkbooks.co.kr/home/intro/',
    content: '윙크북스',
    target: '_blank',
  },
];

import Script from 'next/script';

export const Enliple = () => {
  return (
    <>
      <Script
        id={'enliple'}
        async
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
          (function mobRf(){
          if (typeof EN === 'function') {
            var rf = new EN();
            rf.setData("userid", "danbiedu1"); rf.setSSL(true);
            rf.sendRf();
          }
        })()
        `,
        }}
      ></Script>
    </>
  );
};

import moment from 'moment-timezone/builds/moment-timezone-with-data-2012-2022.min';
import { DateFormat } from '../constants/Date';
import { TIME_ZONE } from '../constants/Code';

export default class DateUtil {
  static _testToday = process.env.NODE_ENV === 'production' ? '' : '';
  static _serviceStartDate = '2017-08-01';

  static _getMoment(date) {
    if (date) {
      return moment.tz(date, TIME_ZONE);
    } else {
      if (DateUtil._testToday !== '') {
        return moment.tz(DateUtil._testToday, TIME_ZONE);
      } else {
        return moment().tz(TIME_ZONE);
      }
    }
  }

  static weekKorNameList = ['일', '월', '화', '수', '목', '금', '토'];
  static weekEngNameList = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

  static today() {
    return DateUtil._getMoment().format(DateFormat.half);
  }

  static todayFull() {
    return DateUtil._getMoment().format(DateFormat.full);
  }

  static todayFullUTC() {
    return DateUtil._getMoment().utc().format();
  }

  static isWeekend(date) {
    if (
      DateUtil._getMoment(date).weekday() === 0 ||
      DateUtil._getMoment(date).weekday() === 6
    ) {
      return true;
    }
    return false;
  }

  static weekday(date) {
    return DateUtil._getMoment(date).weekday();
  }

  static weekEngName(date) {
    return DateUtil.weekEngNameList[DateUtil.weekday(date)];
  }

  static weekKorName(date) {
    return DateUtil.weekKorNameList[DateUtil.weekday(date)];
  }

  static startOf(date) {
    return DateUtil._getMoment(date).startOf();
  }

  static endOf(date) {
    return DateUtil._getMoment(date).endOf();
  }

  static startOfMonth(date) {
    return DateUtil._getMoment(date).startOf('month').format(DateFormat.half);
  }

  static endOfMonth(date) {
    return DateUtil._getMoment(date).endOf('month').format(DateFormat.half);
  }

  static dayCode(date) {
    return DateUtil._getMoment(date).format('YYYYMM00');
  }

  static monthDayCode(date) {
    return DateUtil._getMoment(date).format('YYYYMM');
  }

  static getABSWeek(date) {
    const ORIGIN_YEAR = 2017;
    const ORIGIN_DATE = moment(ORIGIN_YEAR + '-01-02');
    const endDate = moment(date);
    const totalDays = endDate.diff(ORIGIN_DATE, 'days');
    const weekCount = parseInt(totalDays / 7, 10);

    return (weekCount % 52) + 1;
  }

  static getYearArr(num, format = 'YYYY') {
    let resultArr = [];
    let targetDate = this.today();
    if (typeof num !== 'number') {
      resultArr.push(moment(targetDate).format(format));
      return resultArr;
    }

    for (let i = 0; i < num; i++) {
      resultArr.push(moment(targetDate).format(format));
      targetDate = moment(targetDate).add(-1, 'year').format('YYYY-MM-DD');
      if (moment(targetDate).isBefore(this._serviceStartDate, 'year')) {
        break;
      }
    }
    return resultArr;
  }
}

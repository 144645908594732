import React, { Component } from 'react';
import LayerPopup from '../../../../library/layerpopup';

class NoticeModal extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick(e, type) {
    e.preventDefault();
    if (type === 'CLOSE' || type === 'XCLOSE') {
      let { callback, param } = this.props;
      let resultCallback = false;
      if (callback) {
        resultCallback = callback(type, param);
        if (resultCallback === undefined) {
          resultCallback = true;
        }
      } else {
        resultCallback = true;
      }
      if (resultCallback && this.props.layerKey) {
        LayerPopup.hide(this.props.layerKey);
      }
    }
  }

  render() {
    let { message, buttonLabel = '확인', className } = this.props;
    return (
      <div
        className={`layer-wrap small ${className ? className : ''}`}
        style={{ marginTop: '-246px' }}
      >
        <div className="layer-download">
          <p dangerouslySetInnerHTML={{ __html: message }}></p>
          <div className="btn-area">
            <a
              href="#"
              className="btn-type2 confirm"
              onClick={(e) => this.onClick(e, 'CLOSE')}
            >
              {buttonLabel}
            </a>
          </div>
        </div>
        <button className="close" onClick={(e) => this.onClick(e, 'XCLOSE')}>
          팝업 닫기
        </button>
      </div>
    );
  }
}

export default NoticeModal;

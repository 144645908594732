import moment from 'moment';
import APICaller from '../APICaller';
import APIJoin from './APIJoin';
import { IssueType, WorkType, ItemType } from '../constants/Code';

export default class PaymentServices {
  static getPayment(payId) {
    const url = `/account/payments/${payId}/`;

    return APICaller.get(url);
  }

  static getAutoPayment(payId) {
    const url = `/account/auto_payment_contracts/${payId}/`;

    return APICaller.get(url);
  }

  static addPayment(params) {
    const url = '/account/payments/';

    return APICaller.post(url, params);
  }

  static addAutoPayment(params) {
    const url = '/account/auto_payment_contracts/';

    return APICaller.post(url, params);
  }

  static addNaverPayPayment(params) {
    const url = '/account/auto_payment_contracts/';

    return APICaller.post(url, params, {
      headers: { ACCEPT: 'application/json, version=2.0' },
    });
  }

  static confirmPayment(payId, params) {
    const url = `/account/payments/${payId}/confirm/`;

    return APICaller.post(url, params);
  }

  static confirmNaverPayPayment(contractId, params) {
    const url = `/account/auto_payment_contracts/${contractId}/naverpay-confirm/`;

    return APICaller.post(url, params);
  }

  static confirmAutoPayment(payId, params) {
    const url = `/account/auto_payment_contracts/${payId}/confirm/`;

    return APICaller.post(url, params);
  }

  static getCashReceipt(payId) {
    const url = `/account/payments/${payId}/cash_receipt/`;
    const params = {
      LGD_METHOD: 'AUTH',
      LGD_CASHRECEIPTUSE: 1,
    };

    return APICaller.post(url, params);
  }

  static cancelPayment(payId) {
    const url = `/account/payments/${payId}/cancel/`;

    return APICaller.post(url);
  }

  static cancelAutoPayment(payId) {
    const url = `/account/auto_payment_contracts/${payId}/cancel/`;

    return APICaller.post(url);
  }

  static changeAutoPayment(payId, amount) {
    const url = `/account/auto_payment_contracts/${payId}/change_amount/`;
    const params = {
      amount: amount,
    };

    return APICaller.post(url, params);
  }

  static modifyDeliveryMessage(issueId, message) {
    const url = `/issue/issues/${issueId}/modify/`;
    const postData = {
      data: {
        delivery_message: message,
      },
    };

    return APICaller.post(url, postData);
  }

  static getExperienceCount(studentId) {
    const url = `/issue/issues/?model_type=${IssueType.CSRWinkServiceIssue}&actor=${studentId}&current_work_type=${WorkType.COMPLETE}`;

    return APICaller.get(url);
  }

  static postStudyStepDiagnosis(studentId, levels) {
    const url = `/account/actors/${studentId}/modify/`;
    const postData = {
      data: {
        levels,
        levels_regist_date: moment().utc().format(),
      },
    };
    return APICaller.post(url, postData);
  }

  static applyExperience(studentId, params = null) {
    const url = `/account/actors/${studentId}/experience_application/`;

    return APICaller.post(url, { ...params, exp_type: 'SIMPLE' });
  }

  // 상품
  static getProductInfo(subject) {
    const url = `/item/item_types/PaidProduct/get_named/?name=${encodeURIComponent(
      subject
    )}`;

    return APICaller.get(url);
  }

  static getProductPrice(itemId, startDate, contractYears, coupons) {
    const url = `/item/items/${itemId}/get_total_price_and_monthly_prices/?start_date=${startDate}&contract_years=${contractYears}&coupons=${encodeURIComponent(
      coupons
    )}`;

    return APICaller.get(url);
  }

  // 쿠폰
  static getInventoriesInfo(parentId) {
    const url = `/account/actors/${parentId}/inventories/?item_type=${encodeURIComponent(
      ItemType.Coupon
    )}`;

    return APICaller.get(url);
  }
  static getCoupon(name) {
    const url = `/item/item_types/Coupon/get_named/?name=${encodeURIComponent(
      name
    )}`;

    return APICaller.get(url);
  }

  static getCouponListByProductNo(parentId, productNo) {
    const url = `/account/actors/${parentId}/usable_coupons/?product=${productNo}`;

    return APICaller.get(url);
  }

  //정기 결제 계약건 조회

  static getAutoPaymentContractInfo(contractId) {
    const url = `/account/auto_payment_contracts/${contractId}/`;

    return APICaller.get(url);
  }

  static getAutoPaymentContractList(parentId) {
    const url = `/account/actors/${parentId}/auto_payment_contracts/`;

    return APICaller.get(url);
  }

  static getAutoPaymentContractListWithDetailList(parentId) {
    const url = `/account/actors/${parentId}/auto_payment_contracts/`;

    return APIJoin.countCall(
      url,
      'id',
      'monthly',
      PaymentServices.getAutoPaymentDetailList
    );
  }

  static getAutoPaymentDetailList(contractId) {
    const url = `/account/auto_payment_contracts/${contractId}/payments/`;

    return APICaller.get(url);
  }

  //추가결제 https://github.com/DanbiEduCorp/danbi_server/blob/master/danbi_server/tests/tests_for_1039_change_contract.py

  //- 1년약정 단과반 상품 정기결제계약중
  //- 1년 약정 종합반, 2년 약정 종합반 으로 변경 가능
  //단과반(1년약정) 수강중 -> 종합반(1년or2년약정)으로 변경
  static changeContract(payId, postData) {
    /*
			postData:{
				"model_type": 1,  # AutoPaymentContractType.get('lguplus_credit_card_monthly_billing')
				"product_name": self.pac_prod.name,
				"notify_email": self.parent_auth.human_email,
				"amount": 1000,
				"pg_code": self.pg_code,
				"platform": "mobile",
				"mobile_os_type": "A",
				"installment": self.installment,
				"buyer_ssn": self.buyer_ssn,
				"check_ssn": self.check_ssn,
				"paynow_only": self.paynow_only,
				"student": self.student1.id,
				"product": self.pac_prod.id,
				"contract_years": 1
			}
		*/
    const url = `/account/auto_payment_contracts/${payId}/change_contract/`;
    return APICaller.post(url, postData);
  }

  static payOuterStart(payId) {
    const url = `/account/auto_payment_contracts/${payId}/pay_outer_start/`;
    return APICaller.get(url);
  }

  static payInnerPost(payId, postData) {
    const url = `/account/auto_payment_contracts/${payId}/pay_inner_post/`;
    /*
			post_data={
				'LGD_RESPCODE': '0000',
				'LGD_RESPMSG': 'test',
				'LGD_BILLKEY': 0,
				'LGD_PAYTYPE': 0,
				'LGD_PAYDATE': 0,
				'LGD_FINANCECODE': 0,
				'LGD_FINANCENAME': 0,
			}
			}
		*/
    return APICaller.post(url, postData);
  }

  //단과반(1년약정) 수강중 -> 단과반(1년약정) 추가
  //- 1년약정 단과반 상품 정기결제계약중
  //- *다른* 1년약정 단과반 정기결제계약 추가만 가능
  //- 종합반 불가능
  static addContract(postData) {
    const url = '/account/auto_payment_contracts/add_contract/';
    /*
			post_data={
				"model_type": 1,  # AutoPaymentContractType.get('lguplus_credit_card_monthly_billing')
				"product_name": self.pac_prod.name,
				"notify_email": self.parent_auth.human_email,
				"amount": 1000,
				"pg_code": self.pg_code,
				"platform": "mobile",
				"mobile_os_type": "A",
				"installment": self.installment,
				"buyer_ssn": self.buyer_ssn,
				"check_ssn": self.check_ssn,
				"paynow_only": self.paynow_only,
				"student": self.student1.id,
				"product": self.e_prod.id,
				"contract_years": 1,
				"coupons": ""
			}
		*/
    return APICaller.post(url, postData);
  }

  static changeCreditCard(contractId, postData) {
    const url = `/account/auto_payment_contracts/${contractId}/change_credit_card_start/`;

    return APICaller.post(url, postData);
  }

  static getStudentPaymentContract(studentActorId) {
    const url = `account/auto_payment_contracts/?model_type=1&student=${studentActorId}`;
    return APICaller.get(url);
  }

  static unpaidPayments(contractId, date) {
    const url = `/account/auto_payment_contracts/${contractId}/billing_unpaid_payments/`;
    const postData = {
      base_date: date,
    };

    return APICaller.post(url, postData);
  }

  // 무료학습 신청 중도 이탈이 아닌 회원 로그 삭제 (신규 버전)
  static deleteTemporaryInformationActor(informationId, params) {
    const url = `/temporary-information/actor/${informationId}/`;
    return APICaller.delete(url, params);
  }

  /**
   * 윙크 프리미엄 상품을 이용하여 대여중인 교재의 정보를 확인하는 API
   * @param authIds ex) 300,400,500
   * @returns {Promise<unknown>|*}
   */
  static getRentalBookListForPremium(authIds) {
    const url = `/account/students/rental-books-premium/?auth_ids=${authIds}`;
    return APICaller.get(url);
  }
}

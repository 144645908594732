import { ActionTypes as types } from './PaymentAction';
import simpleDotProp from '../simpleDotProp';

const initialState = {
  action: '',
  applyType: '',
  platform: '',
  client_origin: '',
  pg_code: '',
  mobile_os_type: '',
  notify_email: '',
  model_type: '',
  payType: '',
  checkList: { 한글: 1, 수학: 1, 스토리영어: 0, 파닉스: 1 },
  isAutoPayment: false,
  installment: '',
  buyer_ssn: '',
  check_ssn: '',
  paynow_only: 'N',
  addInfo: {},
  confirmInfo: {},
  cancelInfo: {},
  changeInfo: {},
  isProcssPayment: false,
  experienceCount: {},
  //쿠폰
  coupons: '',
  couponList: [],
  selectCoupon: {},
  discountAmount: 0,
  //상품
  productInfo: {},
  amount: 0,
  monthly: 0,
  contract_years: '',
  contract_end_date: '',
  selectedProductItem: {},
  productList: [],
  shopProductList: {
    results: [],
  },
  user_info: {},

  selectedStudentInfoList: [],
  selectedStudentInfo: {},
  studentInfoList: [],

  autoPaymentList: {
    count: 0,
    results: [],
  },
  isProcessAutoPayment: false,
  isAutoPaymentError: false,
  autoPaymentInfo: {},
  autoPaymentDetailList: {},
  //변경 추가 계약 서버응답
  changeContractInfo: {},
  studentPaymentContractListInfo: {},
  //신규/갱신/변경/추가(add, change, change, add)
  paymentMode: '',

  changeCreditContractInfo: {},

  // 무료 학습신청 중도이탈 로그 제거용 id
  logId: 0,

  // 윙크북스 대여 도서 목록 [actorId]: obj{}
  rentalBookListInfo: {},
};

export default function PaymentReducer(state = initialState, action) {
  switch (action.type) {
    case types.COMPLETED_ACTION:
      return simpleDotProp.set(state, {
        action: initialState.action,
      });
    case types.APPLY_EXPERIENCE:
      return simpleDotProp.set(state, {
        action: 'successapply',
      });
    case types.MODIFY_DELIVERY_MESSAGE:
      return simpleDotProp.set(state, {
        action: 'modifymassage',
      });
    case types.INIT_ADD_PAYMENT:
      return simpleDotProp.set(state, {
        platform: action.payload.platform,
        client_origin: action.payload.client_origin,
        pg_code: action.payload.pg_code,
        notify_email: action.payload.notify_email,
        mobile_os_type: action.payload.mobile_os_type,
        addInfo: initialState.addInfo,
      });
    case types.INIT_CHECK_LIST:
      return simpleDotProp.set(state, {
        checkList: initialState.checkList,
      });
    case types.INIT_PRODUCT:
      return simpleDotProp.set(state, {
        productInfo: initialState.productInfo,
        amount: initialState.amount,
        monthly: initialState.monthly,
        coupons: initialState.coupons,
        contract_years: initialState.contract_years,
        contract_end_date: initialState.contract_end_date,
        selectedProductItem: initialState.selectedProductItem,
        selectCoupon: initialState.selectCoupon,
        discountAmount: initialState.discountAmount,
      });
    case types.INIT_COUPON:
      return simpleDotProp.set(state, {
        selectCoupon: initialState.selectCoupon,
        discountAmount: initialState.discountAmount,
        coupons: initialState.coupons,
      });
    case types.INIT_APPLY_INFO:
      return simpleDotProp.set(state, {
        productInfo: initialState.productInfo,
        amount: initialState.amount,
        monthly: initialState.monthly,
        coupons: initialState.coupons,
        contract_years: initialState.contract_years,
        contract_end_date: initialState.contract_end_date,
        selectedProductItem: initialState.selectedProductItem,
        selectedStudentInfoList: initialState.selectedStudentInfoList,
        selectedStudentInfo: initialState.selectedStudentInfo,
        checkList: initialState.checkList,
        applyType: initialState.applyType,
        selectCoupon: initialState.selectCoupon,
        discountAmount: initialState.discountAmount,
        user_info: initialState.user_info,
      });
    case types.SET_NOTIFY_EMAIL:
      return simpleDotProp.set(state, {
        notify_email: action.payload.notify_email,
      });
    case types.SET_USER_INFO:
      return simpleDotProp.set(state, {
        user_info: action.payload.user_info,
      });
    case types.PROCESS_PAYMENT:
      return simpleDotProp.set(state, {
        isProcssPayment: action.payload.isProcssPayment,
      });
    case types.SET_PAYMENT_PRODUCT:
      return simpleDotProp.set(state, {
        action: action.payload.action,
        monthly: action.payload.monthly,
        contract_years: action.payload.contract_years,
        contract_end_date: action.payload.contract_end_date,
        amount: action.payload.amount,
        selectedProductItem: action.payload.selectedProductItem,
        productInfo: action.payload.productInfo,
      });
    case types.SET_PAYMENT_PAYTYPE:
      return simpleDotProp.set(state, {
        model_type: action.payload.payType,
        payType: action.payload.payType,
      });

    case types.SET_AUTO_PAYMENT:
      return simpleDotProp.set(state, {
        isAutoPayment: action.payload.isAutoPayment,
        installment: action.payload.installment,
        buyer_ssn: action.payload.buyer_ssn,
        check_ssn: action.payload.check_ssn,
        paynow_only: action.payload.paynow_only,
      });
    case types.SET_PAYMENT_CHECKLIST:
      return simpleDotProp.set(state, {
        checkList: action.payload.checkList,
      });
    case types.REQUEST_ADD_AUTO_PAYMENT:
      return simpleDotProp.set(state, {
        addInfo: initialState.addInfo,
        confirmInfo: initialState.confirmInfo,
        isProcessAutoPayment: false,
        isAutoPaymentError: false,
      });
    case types.ADD_AUTO_PAYMENT:
      return simpleDotProp.set(state, {
        addInfo: action.payload.info,
        confirmInfo: initialState.confirmInfo,
        isProcessAutoPayment: true,
        isAutoPaymentError: false,
      });
    case types.FAIL_ADD_AUTO_PAYMENT:
      return simpleDotProp.set(state, {
        isProcessAutoPayment: false,
        isAutoPaymentError: true,
      });
    case types.CONFIRM_AUTO_PAYMENT:
      return simpleDotProp.set(state, {
        confirmInfo: action.payload.info,
      });
    case types.CANCEL_AUTO_PAYMENT:
      return simpleDotProp.set(state, {
        cancelInfo: action.payload.info,
      });
    case types.CHANGE_AUTO_PAYMENT:
      return simpleDotProp.set(state, {
        changeInfo: action.payload.info,
      });
    case types.GET_EXPERIENCE_COUNT:
      return simpleDotProp.set(state, {
        experienceCount: action.payload.experienceCount,
      });
    case types.GET_EXPERIENCE_PROGRESS:
      return simpleDotProp.set(state, {
        experienceProgress: action.payload.experienceProgress,
      });
    case types.SET_APPLY_TYPE:
      return simpleDotProp.set(state, {
        applyType: action.payload.applyType,
      });
    case types.REQUEST_GET_COUPON_LIST:
      return simpleDotProp.set(state, {
        couponList: initialState.couponList,
      });
    case types.GET_COUPON_LIST:
      return simpleDotProp.set(state, {
        couponList: action.payload.couponList,
      });
    case types.GET_PRODUCT_LIST:
      return simpleDotProp.set(state, {
        productList: action.payload.productList,
      });
    case types.REQUEST_GET_SHOP_PRODUCT_LIST:
      return simpleDotProp.set(state, {
        shopProductList: initialState.shopProductList,
      });
    case types.SUCCESS_GET_SHOP_PRODUCT_LIST:
      return simpleDotProp.set(state, {
        shopProductList: action.payload.shopProductList,
      });
    case types.SET_COUPONS:
      return simpleDotProp.set(state, {
        selectCoupon: action.payload.selectCoupon,
        discountAmount: action.payload.discountAmount,
      });
    case types.ADD_COUPONS:
      return simpleDotProp.set(state, {
        coupons: action.payload.coupons,
      });
    case types.SET_PAYMENT_SELECTED_STUDENT_INFO:
      return simpleDotProp.set(state, {
        selectedStudentInfo: action.payload.selectedStudentInfo,
      });

    case types.REQUEST_AUTO_PAYMENT_CONTRACT_GET_LIST:
      return simpleDotProp.set(state, {
        autoPaymentList: initialState.autoPaymentList,
      });
    case types.SUCCESS_AUTO_PAYMENT_CONTRACT_GET_LIST:
      return simpleDotProp.set(state, {
        autoPaymentList: action.payload.autoPaymentList,
      });
    case types.REQUEST_AUTO_PAYMENT_GET_INFO:
      return simpleDotProp.set(state, {
        autoPaymentInfo: initialState.autoPaymentInfo,
      });
    case types.SUCCESS_AUTO_PAYMENT_GET_INFO:
      return simpleDotProp.set(state, {
        autoPaymentInfo: action.payload.autoPaymentInfo,
      });
    case types.REQUEST_AUTO_PAYMENT_DETAIL_GETLIST:
      return simpleDotProp.set(state, {
        autoPaymentDetailList: initialState.autoPaymentDetailList,
      });
    case types.SUCCESS_AUTO_PAYMENT_DETAIL_GETLIST:
      return simpleDotProp.set(state, {
        autoPaymentDetailList: action.payload.autoPaymentDetailList,
      });
    case types.INIT_DATA_FOR_NOT_LOGIN:
      return initialState;
    case types.INIT_PAYMENT_REDUCER:
      return { ...initialState, action: action.payload.action };
    case types.SUCCESS_CHANGE_CONTRACT:
      return simpleDotProp.set(state, {
        changeContractInfo: action.payload.changeContractInfo,
      });
    case types.CLEAR_STUDENT_PAYMENT_CONTRACT:
      return simpleDotProp.set(state, {
        studentPaymentContractListInfo: {},
      });
    case types.SUCCESS_STUDENT_PAYMENT_CONTRACT:
      return simpleDotProp.set(state, {
        studentPaymentContractListInfo:
          action.payload.studentPaymentContractListInfo,
      });
    case types.SET_PAYMENT_MODE:
      return simpleDotProp.set(state, {
        paymentMode: action.payload.paymentMode,
      });
    case types.CLEAR_PAYMENT_MODE:
      return simpleDotProp.set(state, {
        paymentMode: '',
      });
    case types.CLEAR_ACTION:
      return simpleDotProp.set(state, {
        action: '',
      });
    case types.REQUEST_CHANGE_AUTO_PAYMENT_CREDITCARD:
      return simpleDotProp.set(state, {
        changeCreditContractInfo: {},
      });
    case types.CHANGE_AUTO_PAYMENT_CREDITCARD:
      return simpleDotProp.set(state, {
        changeCreditContractInfo: action.payload.info,
      });
    case types.SET_LOG_ID:
      return simpleDotProp.set(state, {
        logId: action.payload.log_id.data.id,
      });
    case types.SET_SELECTED_STUDENT_INFO_LIST:
      return {
        ...state,
        selectedStudentInfoList: action.payload.selectedStudentInfoList,
      };
    case types.SET_STUDENT_INFO_LIST:
      return {
        ...state,
        studentInfoList: action.payload.studentInfoList,
      };
    case types.SET_RENTAL_BOOK_LIST_INFO:
      return {
        ...state,
        rentalBookListInfo: action.payload.rentalBookListInfo,
      };
    default:
      return state;
  }
}

import Async from '../Async';
import moment from 'moment';
import BoardServices from '../services/BoardServices';
import IssueTrackerServices from '../services/IssueTrackerServices';
import { ModelStatus, IssueType } from '../constants/Code';
import httpErrorMessage from '../httpErrorMessage';
import * as ExceptionAction from '../error/ExceptionAction';

export const ActionTypes = Object.freeze({
  EXCEPTION: 'EXCEPTION',
  INITIALIZE_BOARD: '@@BOARD/INITIALIZE_BOARD',
  GET_BOARD_INFO: '@@BOARD/GET_BOARD_INFO',

  INIT_POST_MAIN: '@@BOARD/INIT_POST_MAIN',
  INIT_POST_CATEGORY: '@@BOARD/INIT_POST_CATEGORY',
  INIT_POST_LIST: '@@BOARD/INIT_POST_LIST',
  INIT_POST_DETAIL: '@@BOARD/INIT_POST_DETAIL',
  INIT_POST_WRITE: '@@BOARD/INIT_POST_WRITE',
  INIT_POST_MODIFY: '@@BOARD/INIT_POST_WRITE',
  INIT_POST_CLEAN: '@@BOARD/INIT_POST_CLEAN',
  INIT_POST_PREV_NEXT: '@@BOARD/INIT_POST_PREV_NEXT',
  INIT_POST_MORE_LIST: '@@BOARD/INIT_POST_MORE_LIST',
  INIT_COMMENT: '@@BOARD/INIT_COMMENT',

  LOAD_POST_LIST: '@@BOARD/LOAD_POST_LIST',
  LOAD_POST_DETAIL: '@@BOARD/LOAD_POST_DETAIL',
  LOAD_POST_WRITE: '@@BOARD/LOAD_POST_WRITE',
  LOAD_POST_MODIFY: '@@BOARD/LOAD_POST_MODIFY',
  LOAD_CHILD_POST: '@@BOARD/LOAD_CHILD_POST',
  CLEAR_POST_DATA: '@@BOARD/CLEAR_POST_DATA',

  GET_TAG_MEMBER_LIST: '@@BOARD/GET_TAG_MEMBER_LIST',
  RESET_TAG_POST_CATEGORY: '@@BOARD/RESET_TAG_POST_CATEGORY',
  GET_TAG_TOP_MEMBER_LIST: '@@BOARD/GET_TAG_TOP_MEMBER_LIST',
  GET_TAG_TOP_MIDDLE_MEMBER_LIST: '@@BOARD/GET_TAG_TOP_MIDDLE_MEMBER_LIST',
  GET_TAG_MIDDLE_MEMBER_LIST: '@@BOARD/GET_TAG_MIDDLE_MEMBER_LIST',
  GET_TAG_LEAF_MEMBER_LIST: '@@BOARD/GET_TAG_LEAF_MEMBER_LIST',
  GET_POST_CATEGORY_LIST: '@@BOARD/GET_POST_CATEGORY_LIST',

  GET_POST_LIST_MAIN: '@@BOARD/GET_POST_LIST_MAIN',
  GET_POST_LIKE_LIST_MAIN: '@@BOARD/GET_POST_LIKE_LIST_MAIN',

  COLLECT_POST_INFO: '@@BOARD/COLLECT_POST_INFO',
  COMPLETED_ACTION: '@@BOARD/COMPLETED_ACTION',
  GET_POST_LIST: '@@BOARD/GET_POST_LIST',
  GET_POST_MORE_LIST: '@@BOARD/GET_POST_MORE_LIST',
  GET_POST_INFOFORMORELIST: '@BOARD/GET_POST_INFOFORMORELIST',
  GET_POST_LIKE_MORE_LIST: '@@BOARD/GET_POST_LIKE_MORE_LIST',
  GET_POST_LIKE_LIST: '@@BOARD/GET_POST_LIKE_LIST',
  GET_POST_INFO: '@@BOARD/GET_POST_INFO',
  GET_REPRESENTATIVECHILD_LIST: '@@BOARD/GET_REPRESENTATIVECHILD_LIST',
  GET_POST_FILE_LIST: '@@BOARD/GET_POST_FILE_LIST',
  WRITE_POST_SUCCESS: '@@BOARD/WRITE_POST_SUCCESS',
  MODIFY_POST_SUCCESS: '@@BOARD/MODIFY_POST_SUCCESS',
  REMOVE_POST_SUCCESS: '@@BOARD/REMOVE_POST_SUCCESS',
  STATUS_POST_SUCCESS: '@@BOARD/STATUS_POST_SUCCESS',
  CHANGE_POST_LIST_PAGESIZE: '@@BOARD/CHANGE_POST_LIST_PAGESIZE',

  WRITE_POST_STAFFCOMMENT_SUCCESS: '@@BOARD/WRITE_POST_STAFFCOMMENT_SUCCESS',
  SET_POST_RECOMMEND_SUCCESS: '@@BOARD/SET_POST_RECOMMEND_SUCCESS',
  SET_POST_LIKE_SUCCESS: '@@BOARD/SET_POST_LIKE_SUCCESS',
  SET_POST_UNLIKE_SUCCESS: '@@BOARD/SET_POST_UNLIKE_SUCCESS',
  SET_POST_HIDE_SUCCESS: '@@BOARD/SET_POST_HIDE_SUCCESS',
  SET_POST_SHOW_SUCCESS: '@@BOARD/SET_POST_SHOW_SUCCESS',

  COLLECT_CHILD_POST_INFO: '@@BOARD/COLLECT_CHILD_POST_INFO',
  GET_CHILD_POST_RELATED_LIST: '@@BOARD/GET_CHILD_POST_RELATED_LIST',
  SET_CHILD_POST_INFO: '@@BOARD/SET_CHILD_POST_INFO',
  GET_CHILD_POST_LIST: '@@BOARD/GET_CHILD_POST_LIST',
  GET_STAFF_CHILD_POST_LIST: '@@BOARD/GET_STAFF_CHILD_POST_LIST',
  GET_USER_CHILD_POST_LIST: '@@BOARD/GET_USER_CHILD_POST_LIST',

  WRITE_CHILD_POST_SUCCESS: '@@BOARD/WRITE_CHILD_POST_SUCCESS',
  REMOVE_CHILD_POST_SUCCESS: '@@BOARD/REMOVE_CHILD_POST_SUCCESS',
  MODIFY_CHILD_POST_SUCCESS: '@@BOARD/MODIFY_CHILD_POST_SUCCESS',

  WRITE_CHILD_POST_STAFFCOMMENT_SUCCESS:
    '@@BOARD/WRITE_CHILD_POST_STAFFCOMMENT_SUCCESS',
  SET_CHILD_POST_RELATED_SUCCESS: '@@BOARD/SET_CHILD_POST_RELATED_SUCCESS',
  SET_CHILD_POST_LIKE_SUCCESS: '@@BOARD/SET_CHILD_POST_LIKE_SUCCESS',
  SET_CHILD_POST_UNLIKE_SUCCESS: '@@BOARD/SET_CHILD_POST_UNLIKE_SUCCESS',
  SET_CHILD_POST_HIDE_SUCCESS: '@@BOARD/SET_CHILD_POST_HIDE_SUCCESS',
  SET_CHILD_POST_SHOW_SUCCESS: '@@BOARD/SET_CHILD_POST_SHOW_SUCCESS',

  COMPLETED_COMMENT_ACTION: '@@BOARD/COMPLETED_COMMENT_ACTION',
  GET_COMMENT_LIST: '@@BOARD/GET_COMMENT_LIST',
  GET_COMMENT_MORE_LIST: '@@BOARD/GET_COMMENT_MORE_LIST',
  GET_COMMENT_CHILD_LIST: '@@BOARD/GET_COMMENT_CHILD_LIST',
  CLEAR_COMMENT_CHILD_LIST: '@@BOARD/CLEAR_COMMENT_CHILD_LIST',
  WRITE_COMMENT_SUCCESS: '@@BOARD/WRITE_COMMENT_SUCCESS',
  MODIFY_COMMENT_SUCCESS: '@@BOARD/MODIFY_COMMENT_SUCCESS',
  REMOVE_COMMENT_SUCCESS: '@@BOARD/REMOVE_COMMENT_SUCCESS',
  WRITE_COMMENT_CHILD_SUCCESS: '@@BOARD/WRITE_COMMENT_CHILD_SUCCESS',
  MODIFY_COMMENT_CHILD_SUCCESS: '@@BOARD/MODIFY_COMMENT_CHILD_SUCCESS',
  REMOVE_COMMENT_CHILD_SUCCESS: '@@BOARD/REMOVE_COMMENT_CHILD_SUCCESS',
  COLLECT_COMMENT: '@@BOARD/COLLECT_COMMENT',

  GET_COMMENT_ACCUSE_CATEGORY: '@BOARD/GET_COMMENT_ACCUSE_CATEGORY',
  GET_COMMENT_ACCUSE_ISSUE_TYPE: '@BOARD/GET_COMMENT_ACCUSE_ISSUE_TYPE',
  SET_COMMENT_ACCUSE: '@BOARD/SET_COMMENT_ACCUSE',

  SET_POST_PREV_NEXT: '@@BOARD/SET_POST_PREV_NEXT',
  GET_POST_PREV_NEXT: '@@BOARD/GET_POST_PREV_NEXT',
});

function generateAPIQuery(params) {
  let query = { ...params };
  for (let key of Object.keys(query)) {
    if (Array.isArray(query[key]) === true) {
      query[key] = query[key].toString();
    } else if (query[key] === '') {
      delete query[key];
    }
  }
  return query;
}

// server_tester : tests_for_board.py - PostWriteTest.test_simple
export function checkBoardInfoForChildPostWrite(boardType, boardName) {
  return (dispatch, getState) =>
    dispatch(() => {
      return Async(function* () {
        const board = getState().BoardReducer;
        if (board.boardInfo) {
          if (board.boardType === boardType && board.boardName === boardName) {
            return {
              boardInfo: board.boardInfo,
              isDispatch: false,
            };
          }
        }
        const boardInfo = yield BoardServices.getBoardInfoByName(
          boardType,
          boardName
        );
        return {
          boardInfo: boardInfo.data,
          isDispatch: true,
        };
      })
        .then((result) => {
          if (result.isDispatch === true) {
            dispatch({
              type: ActionTypes.GET_BOARD_INFO,
              payload: {
                boardType,
                boardName,
                boardInfo: result.boardInfo,
              },
            });
          }
          return result.boardInfo;
        })
        .catch((e) => {
          dispatch({
            type: ActionTypes.INITIALIZE_BOARD,
            payload: {
              boardType,
              boardName,
            },
          });
          dispatch(
            ExceptionAction.exception(e, '게시판 조회중 오류가 발생하였습니다.')
          );
        });
    });
}

// 게시판 정보
// server_tester : tests_for_board.py - PostWriteTest.test_simple
export function checkBoardInfo(dispatch, getState, boardType, boardName) {
  return Async(
    function* (getState, boardType, boardName) {
      const board = getState().BoardReducer;
      if (board.boardInfo) {
        if (board.boardType === boardType && board.boardName === boardName) {
          return {
            boardInfo: board.boardInfo,
            isDispatch: false,
          };
        }
      }
      const boardInfo = yield BoardServices.getBoardInfoByName(
        boardType,
        boardName
      );
      return {
        boardInfo: boardInfo.data,
        isDispatch: true,
      };
    },
    getState,
    boardType,
    boardName
  )
    .then((result) => {
      if (result.isDispatch === true) {
        dispatch({
          type: ActionTypes.GET_BOARD_INFO,
          payload: {
            boardType,
            boardName,
            boardInfo: result.boardInfo,
          },
        });
      }
      return result.boardInfo;
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.INITIALIZE_BOARD,
        payload: {
          boardType,
          boardName,
        },
      });
      dispatch(
        ExceptionAction.exception(e, '게시판 조회중 오류가 발생하였습니다.')
      );
    });
}

// 페이지 초기화 //
export function initPostMain() {
  return {
    type: ActionTypes.INIT_POST_MAIN,
  };
}

export function initPostList() {
  return {
    type: ActionTypes.INIT_POST_LIST,
  };
}

export function initPostCategory() {
  return {
    type: ActionTypes.INIT_POST_CATEGORY,
  };
}

export function initPostDetail() {
  return (dispatch, getState) =>
    dispatch(() => {
      return dispatch({
        type: ActionTypes.INIT_POST_DETAIL,
      });
    });
}

export function initPostWrite() {
  return {
    type: ActionTypes.INIT_POST_WRITE,
  };
}

export function initPostModify() {
  return {
    type: ActionTypes.INIT_POST_MODIFY,
  };
}

export function initPostCleanUp() {
  return {
    type: ActionTypes.INIT_POST_CLEAN,
  };
}

export function initPostMoreList() {
  return {
    type: ActionTypes.INIT_POST_MORE_LIST,
  };
}

export function initComment() {
  return {
    type: ActionTypes.INIT_COMMENT,
  };
}

// 페이지 이동시 기본 dispatch
export function loadPostList(
  boardType,
  boardName,
  pageNo,
  pageSize = 10,
  query = null
) {
  return (dispatch, getState) =>
    dispatch(() => {
      checkBoardInfo(dispatch, getState, boardType, boardName)
        .then((boardInfo) => {
          dispatch({
            type: ActionTypes.LOAD_POST_LIST,
            payload: {
              boardType,
              boardName,
              query,
            },
          });
          return dispatch(
            getPostList(
              boardType,
              boardName,
              pageNo,
              pageSize,
              generateAPIQuery(query)
            )
          );
        })
        .catch((e) => {
          return dispatch(
            ExceptionAction.exception(
              e,
              '게시물 조회중 오류가 발생하였습니다-2.'
            )
          );
        });
    });
}

export function loadPostWrite(boardType, boardName) {
  return (dispatch, getState) =>
    dispatch(() => {
      return checkBoardInfo(dispatch, getState, boardType, boardName).then(
        (boardInfo) => {
          dispatch({
            type: ActionTypes.LOAD_POST_WRITE,
            payload: {
              boardType,
              boardName,
            },
          });
          return dispatch(
            collectPost({
              id: 0,
              status: ModelStatus.Normal,
            })
          );
        }
      );
    });
}

export function loadPostModify(boardType, boardName, postId) {
  return (dispatch, getState) =>
    dispatch(() => {
      return checkBoardInfo(dispatch, getState, boardType, boardName).then(
        (boardInfo) => {
          dispatch(collectPostInfoById(postId));
          dispatch({
            type: ActionTypes.LOAD_POST_MODIFY,
            payload: {
              boardType,
              boardName,
            },
          });
        }
      );
    });
}

export function loadChildPost() {
  return (dispatch, getState) =>
    dispatch(() => {
      dispatch({
        type: ActionTypes.LOAD_CHILD_POST,
      });
    });
}

export function loadPostDetail(boardType, boardName, postId) {
  return (dispatch, getState) =>
    dispatch(() => {
      return checkBoardInfo(dispatch, getState, boardType, boardName).then(
        (boardInfo) => {
          dispatch({
            type: ActionTypes.LOAD_POST_DETAIL,
          });
          return dispatch(
            getPostInfo(boardType, boardName, postId, false, true)
          );
        }
      );
    });
}

export function clearPostData() {
  return {
    type: ActionTypes.CLEAR_POST_DATA,
  };
}

export function changePageSize(boardType, boardName, pageSize, query) {
  return (dispatch, getState) =>
    dispatch(() => {
      dispatch({
        type: ActionTypes.CHANGE_POST_LIST_PAGESIZE,
        payload: {
          pageSize: pageSize,
        },
      });

      return dispatch(getPostList(boardType, boardName, 1, pageSize, query));
    });
}

export function changePageSizeOnlyDispatch(pageSize) {
  return (dispatch, getState) =>
    dispatch(() => {
      return dispatch({
        type: ActionTypes.CHANGE_POST_LIST_PAGESIZE,
        payload: {
          pageSize: pageSize,
        },
      });
    });
}

export function completedAction() {
  return {
    type: ActionTypes.COMPLETED_ACTION,
  };
}

// server_tester : tests_for_board.py - PostReadTest.test_simple
export function getPostListForMain(
  boardType,
  boardName,
  pageNo,
  pageSize,
  searchOption,
  requester = 'main'
) {
  return (dispatch, getState) =>
    dispatch(() => {
      return checkBoardInfo(dispatch, getState, boardType, boardName).then(
        (boardInfo) => {
          let offset, limit;
          offset = (pageNo - 1) * pageSize;
          limit = pageSize;
          let _query = {};
          if (searchOption) {
            _query = { ...searchOption, limit, offset };
          } else {
            _query = { limit, offset };
          }
          return BoardServices.getPostList(
            boardInfo.id,
            generateAPIQuery(_query)
          )
            .then((response) => {
              dispatch({
                type: ActionTypes.GET_POST_LIST_MAIN,
                payload: {
                  postForMain: response.data,
                  requester,
                },
              });
            })
            .catch((e) => {
              return dispatch(
                ExceptionAction.exception(
                  e,
                  '게시물 조회중 오류가 발생하였습니다'
                )
              );
            });
        }
      );
    });
}

// server_tester : tests_for_board.py - PostReadTest.test_simple
export function getPostLikeListForMain(
  actorId,
  boardType,
  boardName,
  postIdList,
  requester = null
) {
  return (dispatch, getState) =>
    dispatch(() => {
      return BoardServices.getPostLikeList(actorId, postIdList)
        .then((response) => {
          return dispatch({
            type: ActionTypes.GET_POST_LIKE_LIST_MAIN,
            payload: {
              postLikeListForMain: response.data,
              requester,
            },
          });
        })
        .catch((e) => {
          return dispatch(
            ExceptionAction.exception(e, '게시물 조회중 오류가 발생하였습니다')
          );
        });
    });
}

// get
// server_tester : tests_for_board.py - PostReadTest.test_simple
export function getPostList(boardType, boardName, pageNo, pageSize, query) {
  return (dispatch, getState) =>
    dispatch(() => {
      return checkBoardInfo(dispatch, getState, boardType, boardName).then(
        (boardInfo) => {
          let offset, limit, currentPageNo;
          if (getState().BoardReducer.pageSize) {
            pageSize = getState().BoardReducer.pageSize;
          }
          if (pageNo === null || pageNo === 0) {
            currentPageNo = 1;
          } else if (!offset) {
            currentPageNo = pageNo;
          }
          offset = (currentPageNo - 1) * pageSize;
          limit = pageSize;
          let _query = {};
          if (query) {
            _query = { ...query, limit, offset };
          } else {
            _query = { limit, offset };
          }
          return BoardServices.getPostList(
            boardInfo.id,
            generateAPIQuery(_query)
          )
            .then((response) => {
              dispatch({
                type: ActionTypes.GET_POST_LIST,
                payload: {
                  pageNo: pageNo,
                  postList: response.data,
                  query,
                },
              });
            })
            .catch((e) => {
              return dispatch(
                ExceptionAction.exception(
                  e,
                  '게시물 조회중 오류가 발생하였습니다'
                )
              );
            });
        }
      );
    });
}

// server_tester : tests_for_board.py - PostReadTest.test_simple
export function getPostMoreList(boardType, boardName, pageNo, pageSize, query) {
  return (dispatch, getState) =>
    dispatch(() => {
      return checkBoardInfo(dispatch, getState, boardType, boardName).then(
        (boardInfo) => {
          let offset, limit, currentPageNo;
          if (getState().BoardReducer.pageSize) {
            pageSize = getState().BoardReducer.pageSize;
          }
          if (pageNo === null || pageNo === 0) {
            currentPageNo = 1;
          } else if (!offset) {
            currentPageNo = pageNo;
          }
          offset = (currentPageNo - 1) * pageSize;
          limit = pageSize;
          let _query = {};
          if (query) {
            _query = { ...query, limit, offset };
          } else {
            _query = { limit, offset };
          }
          return BoardServices.getPostList(
            boardInfo.id,
            generateAPIQuery(_query)
          )
            .then((response) => {
              const list = getState().BoardReducer.postMoreList.results.concat(
                response.data.results
              );
              return dispatch({
                type: ActionTypes.GET_POST_MORE_LIST,
                payload: {
                  pageNo: pageNo,
                  postMoreList: {
                    results: list,
                    count: response.data.count,
                    postList: response.data,
                  },
                  query,
                },
              });
            })
            .catch((e) => {
              return dispatch(
                ExceptionAction.exception(
                  e,
                  '게시물 조회중 오류가 발생하였습니다'
                )
              );
            });
        }
      );
    });
}

// server_tester : tests_for_board.py - PostReadTest.test_simple
export function getPostInfoForMoreList(postId, isReadCount = true) {
  return (dispatch, getState) =>
    dispatch(() => {
      return BoardServices.getPostInfo(postId, isReadCount)
        .then((response) => {
          const postInfo = response.data;
          return dispatch({
            type: ActionTypes.GET_POST_INFOFORMORELIST,
            payload: {
              postId,
              postInfo,
            },
          });
        })
        .catch((e) => {
          return dispatch(
            ExceptionAction.exception(e, '게시물 조회중 오류가 발생하였습니다')
          );
        });
    });
}

// server_tester : tests_for_board.py - PostReadTest.test_simple
export function getPostLikeList(boardType, boardName, actorId, postIdList) {
  return (dispatch, getState) =>
    dispatch(() => {
      BoardServices.getPostLikeList(actorId, postIdList)
        .then((response) => {
          return dispatch({
            type: ActionTypes.GET_POST_LIKE_LIST,
            payload: {
              postLikeList: response.data,
            },
          });
        })
        .catch((e) => {
          return dispatch(
            ExceptionAction.exception(
              e,
              '??게시물 조회중 오류가 발생하였습니다'
            )
          );
        });
    });
}

// server_tester : tests_for_board.py - PostReadTest.test_simple
export function getPostLikeMoreList(boardType, boardName, actorId, postIdList) {
  return (dispatch, getState) =>
    dispatch(() => {
      BoardServices.getPostLikeList(actorId, postIdList)
        .then((response) => {
          let postLikeMoreList = getState().BoardReducer.postLikeMoreList;
          if (postLikeMoreList.results) {
            postLikeMoreList.results = postLikeMoreList.results.concat(
              response.data.results
            );
            postLikeMoreList.count = postLikeMoreList.results.length;
          } else {
            postLikeMoreList = response.data;
          }
          return dispatch({
            type: ActionTypes.GET_POST_LIKE_MORE_LIST,
            payload: {
              postLikeMoreList: postLikeMoreList,
            },
          });
        })
        .catch((e) => {
          return dispatch(
            ExceptionAction.exception(e, '게시물 조회중 오류가 발생하였습니다')
          );
        });
    });
}

// get
// server_tester : tests_for_board.py - PostReadTest.test_simple
export function getPostInfo(
  boardType,
  boardName,
  postId,
  isCategory = true,
  isReadCount = true
) {
  return (dispatch, getState) =>
    dispatch(() => {
      return BoardServices.getPostInfo(postId, isReadCount)
        .then((response) => {
          const postInfo = response.data;
          return dispatch({
            type: ActionTypes.GET_POST_INFO,
            payload: {
              boardType,
              boardName,
              postInfo,
            },
          });
        })
        .catch((e) => {
          return dispatch(
            ExceptionAction.exception(e, '게시물 조회중 오류가 발생하였습니다')
          );
        });
    });
}

// server_tester : tests_for_board.py - PostReadTest.test_simple
export function getPostListByPostId(representativeChildList) {
  return (dispatch, getState) =>
    dispatch(() => {
      const { boardId } = getState().BoardReducer;
      return BoardServices.getPostListByPostId(boardId, representativeChildList)
        .then((response) => {
          return dispatch({
            type: ActionTypes.GET_REPRESENTATIVECHILD_LIST,
            payload: {
              representativeChild: response.data.results,
            },
          });
        })
        .catch((e) => {
          return dispatch({
            type: ActionTypes.EXCEPTION,
            ex: e,
            message: httpErrorMessage(e, '답변조회중 오류가 발생하였습니다.'),
          });
        });
    });
}

// server_tester : tests_for_board.py - FileUploadAjaxTest.test_simple_case
export function getFileList(postId) {
  return (dispatch, getState) =>
    dispatch(() => {
      BoardServices.getPostFileList(postId).then((response) => {
        return dispatch({
          type: ActionTypes.GET_POST_FILE_LIST,
          payload: {
            files: response.data.results,
          },
        });
      });
    });
}

// server_tester : tests_for_board.py - PostReadTest.test_simple
export function getPostCategory(topCategoryName) {
  return (dispatch, getState) =>
    dispatch(() => {
      const postCategoryTags =
        getState().BoardReducer.postCategoryTags[topCategoryName];
      if (
        !postCategoryTags ||
        !postCategoryTags.lastUpdate ||
        Date.now() - postCategoryTags.lastUpdate > 60 * 1000
      ) {
        return BoardServices.getPostCacheCategoryTag(topCategoryName).then(
          (response) => {
            dispatch({
              type: ActionTypes.GET_POST_CATEGORY_LIST,
              payload: {
                topCategoryName,
                postCategoryTags: { ...response.data, lastUpdate: Date.now() },
              },
            });
          }
        );
      } else {
        dispatch({
          type: ActionTypes.GET_POST_CATEGORY_LIST,
          payload: {
            topCategoryName,
            postCategoryTags: postCategoryTags,
          },
        });
      }
    });
}

// server_tester : tests_for_board.py - PostReadTest.test_simple
export function getTagMemberList(
  tagId,
  type = ActionTypes.GET_TAG_MEMBER_LIST
) {
  return (dispatch, getState) =>
    dispatch(() => {
      return BoardServices.getTagMembers(tagId).then((response) => {
        const tag = response.data.results;
        return dispatch({
          type: type,
          payload: {
            tag,
          },
        });
      });
    });
}

// server_tester : tests_for_board.py - PostReadTest.test_simple
export function getTopTagMemberList(boardType, boardName) {
  return (dispatch, getState) =>
    dispatch(() => {
      return checkBoardInfo(dispatch, getState, boardType, boardName).then(
        (boardInfo) => {
          return dispatch(
            getTagMemberList(
              boardInfo.post_category_tag,
              ActionTypes.GET_TAG_TOP_MEMBER_LIST
            )
          );
        }
      );
    });
}

export function getMiddleTagMemberList(tagId) {
  return getTagMemberList(tagId, ActionTypes.GET_TAG_MIDDLE_MEMBER_LIST);
}

export function getLeafTagMemberList(tagId) {
  return getTagMemberList(tagId, ActionTypes.GET_TAG_LEAF_MEMBER_LIST);
}

//set
export function collectPostInfoById(postId) {
  return (dispatch, getState) =>
    dispatch(() => {
      return BoardServices.getPostInfo(postId, false).then((response) => {
        const postInfo = response.data;
        return dispatch(collectPost(postInfo));
      });
    });
}

export function collectPost(postInfo) {
  return {
    type: ActionTypes.COLLECT_POST_INFO,
    payload: {
      postInfo,
    },
  };
}

// server_tester : tests_for_board.py - PostWriteTest.test_simple
export function createPost(boardType, boardName, postType, postInfo, pageUrl) {
  return (dispatch, getState) =>
    dispatch(() => {
      return checkBoardInfo(dispatch, getState, boardType, boardName).then(
        (boardInfo) => {
          return BoardServices.addPost(
            boardInfo.id,
            postType,
            postInfo.title,
            postInfo.text,
            postInfo.html,
            postInfo.data
          )
            .then((response) => {
              dispatch({
                type: ActionTypes.WRITE_POST_SUCCESS,
              });
            })
            .catch((e) => {
              return dispatch(
                ExceptionAction.exception(e, '등록중 오류가 발생하였습니다.')
              );
            });
        }
      );
    });
}

// server_tester : tests_for_board.py - PostWriteTest.test_simple
export function publicPrivateAfterCreatePost(
  boardType,
  boardName,
  postType,
  postInfo,
  pageUrl,
  status
) {
  return (dispatch, getState) =>
    dispatch(() => {
      checkBoardInfo(dispatch, getState, boardType, boardName).then(
        (boardInfo) => {
          BoardServices.addPost(
            boardInfo.id,
            postType,
            postInfo.title,
            postInfo.text,
            postInfo.html,
            postInfo.data
          )
            .then((response) => {
              dispatch({
                type: ActionTypes.WRITE_POST_SUCCESS,
              });
              BoardServices.postSetStatus(response.data.id, status).then(
                (res) => {}
              );
            })
            .catch((e) => {
              return dispatch(
                ExceptionAction.exception(e, '등록중 오류가 발생하였습니다.')
              );
            });
        }
      );
    });
}

// server_tester : tests_for_board.py - PostWriteTest.test_simple
export function removePost(boardType, boardName, postId) {
  return (dispatch, getState) =>
    dispatch(() => {
      return checkBoardInfo(dispatch, getState, boardType, boardName).then(
        (boardInfo) => {
          return BoardServices.removePost(postId).then((response) => {
            dispatch({
              type: ActionTypes.REMOVE_POST_SUCCESS,
            });
          });
        }
      );
    });
}

// server_tester : tests_for_board.py - PostWriteTest.test_simple
export function modifyPost(boardType, boardName, postType, postInfo, pageUrl) {
  return (dispatch, getState) =>
    dispatch(() => {
      checkBoardInfo(dispatch, getState, boardType, boardName)
        .then((boardInfo) => {
          BoardServices.modifyPost(
            boardInfo.id,
            postInfo.id,
            postType,
            postInfo.title,
            postInfo.text,
            postInfo.html,
            postInfo.data
          ).then((response) => {
            return dispatch({
              type: ActionTypes.MODIFY_POST_SUCCESS,
            });
          });
        })
        .catch((e) => {
          return dispatch(
            ExceptionAction.exception(e, '등록중 오류가 발생하였습니다.')
          );
        });
    });
}

// server_tester : tests_for_board.py - PostWriteTest.test_simple
export function publicPrivateAfterModifyPost(
  boardType,
  boardName,
  postType,
  postInfo,
  pageUrl,
  status
) {
  return (dispatch, getState) =>
    dispatch(() => {
      return Async(
        function* (boardType, boardName, postType, postInfo, pageUrl) {
          //글수정
          yield dispatch(
            modifyPost(boardType, boardName, postType, postInfo, pageUrl)
          );

          //공개비공개 설정
          yield BoardServices.postSetStatus(postInfo.id, status);
        },
        boardType,
        boardName,
        postType,
        postInfo,
        pageUrl
      );
    });
}

// server_tester : tests_for_board.py - PostWriteTest.test_simple
// 게시판 기타 행동 //
export function recommendPost(postId, isOn) {
  return (dispatch, getState) =>
    dispatch(() => {
      BoardServices.recommendPost(postId, isOn)
        .then((response) => {
          return dispatch({
            type: ActionTypes.SET_POST_RECOMMEND_SUCCESS,
            payload: {
              postId: postId,
            },
          });
        })
        .catch((e) => {
          return dispatch(
            ExceptionAction.exception(e, '등록중 오류가 발생하였습니다.')
          );
        });
    });
}

// server_tester : tests_for_board.py - PostWriteTest.test_simple
export function likePost(postId) {
  return (dispatch, getState) =>
    dispatch(() => {
      return BoardServices.likePost(postId)
        .then((response) => {
          dispatch({
            type: ActionTypes.SET_POST_LIKE_SUCCESS,
            payload: {
              postId: postId,
            },
          });
        })
        .catch((e) => {
          return dispatch(
            ExceptionAction.exception(e, '좋아요 선택중 오류가 발생하였습니다.')
          );
        });
    });
}

// server_tester : tests_for_board.py - PostWriteTest.test_simple
export function unlikePost(postId) {
  return (dispatch, getState) =>
    dispatch(() => {
      return BoardServices.unlikePost(postId)
        .then((response) => {
          dispatch({
            type: ActionTypes.SET_POST_UNLIKE_SUCCESS,
            payload: {
              postId: postId,
            },
          });
        })
        .catch((e) => {
          return dispatch(
            ExceptionAction.exception(e, '좋아요 취소중 오류가 발생하였습니다.')
          );
        });
    });
}

// server_tester : tests_for_board.py - PostWriteTest.test_simple
export function showPost(postId) {
  return (dispatch, getState) =>
    dispatch(() => {
      BoardServices.showPost(postId)
        .then((response) => {
          return dispatch({
            type: ActionTypes.SET_POST_SHOW_SUCCESS,
            payload: {
              postId: postId,
            },
          });
        })
        .catch((e) => {
          return dispatch(
            ExceptionAction.exception(e, '비공개 설정중 오류가 발생하였습니다.')
          );
        });
    });
}

// server_tester : tests_for_board.py - PostWriteTest.test_simple
export function hidePost(postId) {
  return (dispatch, getState) =>
    dispatch(() => {
      BoardServices.hidePost(postId)
        .then((response) => {
          return dispatch({
            type: ActionTypes.SET_POST_HIDE_SUCCESS,
            payload: {
              postId: postId,
            },
          });
        })
        .catch((e) => {
          return dispatch(
            ExceptionAction.exception(e, '비공개 설정중 오류가 발생하였습니다.')
          );
        });
    });
}

// server_tester : tests_for_board.py - PostWriteTest.test_simple
export function addStaffComment(postId, actor, content) {
  return (dispatch, getState) =>
    dispatch(() => {
      const { boardType, boardName } = getState().BoardReducer;
      BoardServices.getPostInfo(postId, false)
        .then((response) => {
          const postInfo = response.data;
          let data = postInfo.data;
          if (!data) {
            data = {};
          }
          if (!data.staff_data) {
            data.staff_data = { comment: [] };
          }
          if (!data.staff_data.comment) {
            data.staff_data.comment = [];
          }

          const date = moment(new Date(Date.now())).format('YYYY-MM-DD');
          data.staff_data.comment.push({
            actor,
            content,
            date,
          });
          BoardServices.modifyPostData(postId, data).then((response) => {
            dispatch({
              type: ActionTypes.WRITE_POST_STAFFCOMMENT_SUCCESS,
            });
            return dispatch(
              getPostInfo(boardType, boardName, postId, false, false)
            );
          });
        })
        .catch((e) => {
          return dispatch(
            ExceptionAction.exception(
              e,
              '관리자 comment 등록중 오류가 발생하였습니다.'
            )
          );
        });
    });
}

//child
// server_tester : tests_for_board.py - PostWriteTest.test_simple
export function getChildPostList(
  boardType,
  boardName,
  postId,
  pageNo,
  pageSize
) {
  return (dispatch, getState) =>
    dispatch(() => {
      return checkBoardInfo(dispatch, getState, boardType, boardName).then(
        (boardInfo) => {
          const offset = (pageNo - 1) * pageSize;
          const limit = pageSize;
          return BoardServices.getChildPostList(postId, offset, limit).then(
            (response) => {
              return dispatch({
                type: ActionTypes.GET_CHILD_POST_LIST,
                payload: {
                  childPostList: response.data,
                },
              });
            }
          );
        }
      );
    });
}
// server_tester : tests_for_board.py - PostReadTest.test_simple
export function getStaffChildPostList(
  boardType,
  boardName,
  postId,
  childPageNo,
  childPageSize
) {
  return (dispatch, getState) =>
    dispatch(() => {
      return checkBoardInfo(dispatch, getState, boardType, boardName).then(
        (boardInfo) => {
          const offset = (childPageNo - 1) * childPageSize;
          const limit = childPageSize;
          return BoardServices.getChildPostList(postId, offset, limit, {
            actor_type: 4,
          })
            .then((response) => {
              dispatch({
                type: ActionTypes.GET_STAFF_CHILD_POST_LIST,
                payload: {
                  staffChildPostList: response.data,
                },
              });
            })
            .catch((e) => {
              return dispatch(
                ExceptionAction.exception(
                  e,
                  '답변글 조회중 오류가 발생하였습니다.'
                )
              );
            });
        }
      );
    });
}

// server_tester : tests_for_board.py - PostReadTest.test_simple
export function getUserChildPostList(
  boardType,
  boardName,
  postId,
  childPageNo,
  childPageSize,
  isNew = false
) {
  return (dispatch, getState) =>
    dispatch(() => {
      return checkBoardInfo(dispatch, getState, boardType, boardName).then(
        (boardInfo) => {
          const offset = (childPageNo - 1) * childPageSize;
          const limit = childPageSize;
          return BoardServices.getChildPostList(postId, offset, limit, {
            actor_type: 2,
          }).then((response) => {
            let { userChildPostList } = getState().BoardReducer;
            if (isNew === false) {
              if (userChildPostList.results) {
                userChildPostList = {
                  ...userChildPostList,
                  results: userChildPostList.results.concat(
                    response.data.results
                  ),
                };
              } else {
                userChildPostList = response.data;
              }
            } else {
              userChildPostList = response.data;
            }
            return dispatch({
              type: ActionTypes.GET_USER_CHILD_POST_LIST,
              payload: {
                userChildPostList: userChildPostList,
              },
            });
          });
        }
      );
    });
}

// server_tester : tests_for_board.py - PostReadTest.test_simple
export function getChildPostRelatedList(postId) {
  return (dispatch, getState) =>
    dispatch(() => {
      return BoardServices.getRelatedPostList(postId).then((response) => {
        return dispatch({
          type: ActionTypes.GET_CHILD_POST_RELATED_LIST,
          payload: {
            postId,
            relatedList: response.data.results,
          },
        });
      });
    });
}

// server_tester : tests_for_board.py - PostWriteTest.test_simple
export function createOnlyChildPost(
  postType,
  parentPostId,
  collectChildPostInfo
) {
  return (dispatch, getState) =>
    dispatch(() => {
      const { boardId } = getState().BoardReducer;
      BoardServices.addChildPost(
        boardId,
        postType,
        parentPostId,
        collectChildPostInfo.html,
        collectChildPostInfo.text,
        collectChildPostInfo.data
      )
        .then((response) => {
          dispatch({
            type: ActionTypes.WRITE_CHILD_POST_SUCCESS,
          });
        })
        .catch((e) => {
          return dispatch(
            ExceptionAction.exception(
              e,
              '관리자 comment 등록중 오류가 발생하였습니다.'
            )
          );
        });
    });
}

// server_tester : tests_for_board.py - PostWriteTest.test_simple
export function createChildPost(
  postType,
  parentPostId,
  collectChildPostInfo,
  childPageNo,
  childPageSize
) {
  return (dispatch, getState) =>
    dispatch(() => {
      const { boardType, boardName, boardId } = getState().BoardReducer;
      return BoardServices.addChildPost(
        boardId,
        postType,
        parentPostId,
        collectChildPostInfo.html,
        collectChildPostInfo.text,
        collectChildPostInfo.data
      )
        .then((response) => {
          dispatch({
            type: ActionTypes.WRITE_CHILD_POST_SUCCESS,
          });
          if (childPageNo) {
            return dispatch(
              getChildPostList(
                boardType,
                boardName,
                parentPostId,
                childPageNo,
                childPageSize
              )
            );
          }
          return dispatch(collectChildPost({ id: 0 }));
        })
        .catch((e) => {
          return dispatch(
            ExceptionAction.exception(
              e,
              '관리자 comment 등록중 오류가 발생하였습니다.'
            )
          );
        });
    });
}

// server_tester : tests_for_board.py - PostWriteTest.test_simple
export function modifyChildPost(postType, collectChildPostInfo) {
  return (dispatch, getState) =>
    dispatch(() => {
      const { boardId } = getState().BoardReducer;
      return BoardServices.modifyChildPost(
        boardId,
        postType,
        collectChildPostInfo.id,
        collectChildPostInfo.html,
        collectChildPostInfo.text,
        collectChildPostInfo.data
      )
        .then((response) => {
          dispatch({
            type: ActionTypes.MODIFY_CHILD_POST_SUCCESS,
            payload: {
              successPostId: collectChildPostInfo.id,
            },
          });
        })
        .catch((e) => {
          return dispatch(
            ExceptionAction.exception(
              e,
              '관리자 comment 등록중 오류가 발생하였습니다.'
            )
          );
        });
    });
}

export function collectChildPostInfoById(childPostId) {
  return (dispatch, getState) =>
    dispatch(() => {
      BoardServices.getChildPostInfo(childPostId, false).then((response) => {
        const childPostInfo = response.data;
        dispatch(collectChildPost(childPostInfo));
      });
    });
}

export function collectChildPost(collectChildPostInfo) {
  return {
    type: ActionTypes.COLLECT_CHILD_POST_INFO,
    payload: {
      collectChildPostInfo,
    },
  };
}

// server_tester : tests_for_board.py - PostWriteTest.test_simple
export function removeChildPost(
  boardType,
  boardName,
  postId,
  childPostId,
  childPageNo,
  childPageSize
) {
  return (dispatch, getState) =>
    dispatch(() => {
      return checkBoardInfo(dispatch, getState, boardType, boardName).then(
        (boardInfo) => {
          return BoardServices.removeChildPost(childPostId).then((response) => {
            if (childPageNo) {
              dispatch(
                getChildPostList(
                  boardType,
                  boardName,
                  postId,
                  childPageNo,
                  childPageSize
                )
              );
            }
            dispatch({
              type: ActionTypes.REMOVE_CHILD_POST_SUCCESS,
            });
          });
        }
      );
    });
}

// server_tester : tests_for_board.py - PostWriteTest.test_simple
export function removeOnlyChildPost(boardType, boardName, postId, childPostId) {
  return (dispatch, getState) =>
    dispatch(() => {
      checkBoardInfo(dispatch, getState, boardType, boardName).then(
        (boardInfo) => {
          BoardServices.removeChildPost(childPostId).then((response) => {
            dispatch({
              type: ActionTypes.REMOVE_CHILD_POST_SUCCESS,
            });
          });
        }
      );
    });
}

// server_tester : tests_for_board.py - PostWriteTest.test_simple
export function recommendChildPost(postId) {
  return (dispatch, getState) =>
    dispatch(() => {
      BoardServices.recommendPost(postId)
        .then((response) => {
          dispatch({
            type: ActionTypes.SET_CHILD_POST_RELATED_SUCCESS,
            payload: {
              successPostId: postId,
            },
          });
        })
        .catch((e) => {
          return dispatch(
            ExceptionAction.exception(
              e,
              '관리자 comment 등록중 오류가 발생하였습니다.'
            )
          );
        });
    });
}

// server_tester : tests_for_board.py - PostWriteTest.test_simple
export function likeChildPost(postId) {
  return (dispatch, getState) =>
    dispatch(() => {
      BoardServices.likePost(postId)
        .then((response) => {
          dispatch({
            type: ActionTypes.SET_CHILD_POST_LIKE_SUCCESS,
            payload: {
              successPostId: postId,
            },
          });
        })
        .catch((e) => {
          ExceptionAction.exception(e, '좋아요 선택중 오류가 발생하였습니다.');
        });
    });
}

// server_tester : tests_for_board.py - PostWriteTest.test_simple
export function unlikeChildPost(postId) {
  return (dispatch, getState) =>
    dispatch(() => {
      BoardServices.unlikePost(postId)
        .then((response) => {
          dispatch({
            type: ActionTypes.SET_CHILD_POST_UNLIKE_SUCCESS,
            payload: {
              successPostId: postId,
            },
          });
        })
        .catch((e) => {
          ExceptionAction.exception(e, '좋아요 취소중 오류가 발생하였습니다.');
        });
    });
}

// server_tester : tests_for_board.py - PostWriteTest.test_simple
export function showChildPost(postId) {
  return (dispatch, getState) =>
    dispatch(() => {
      BoardServices.showPost(postId)
        .then((response) => {
          dispatch({
            type: ActionTypes.SET_CHILD_POST_SHOW_SUCCESS,
            payload: {
              successPostId: postId,
            },
          });
        })
        .catch((e) => {
          ExceptionAction.exception(e, '비공개 설정중 오류가 발생하였습니다.');
        });
    });
}

// server_tester : tests_for_board.py - PostWriteTest.test_simple
export function hideChildPost(postId) {
  return (dispatch, getState) =>
    dispatch(() => {
      BoardServices.hidePost(postId)
        .then((response) => {
          dispatch({
            type: ActionTypes.SET_CHILD_POST_HIDE_SUCCESS,
            payload: {
              successPostId: postId,
            },
          });
        })
        .catch((e) => {
          ExceptionAction.exception(e, '비공개 설정중 오류가 발생하였습니다.');
        });
    });
}

// server_tester : tests_for_board.py - PostWriteTest.test_simple
export function addChildPostStaffComment(
  postId,
  actor,
  content,
  childPageNo,
  childPageSize
) {
  return (dispatch, getState) =>
    dispatch(() => {
      const { boardType, boardName } = getState().BoardReducer;
      BoardServices.getPostInfo(postId, false)
        .then((response) => {
          const postInfo = response.data;
          let data = postInfo.data;
          if (!data) {
            data = {};
          }
          if (!data.staff_data) {
            data.staff_data = { comment: [] };
          }
          if (!data.staff_data.comment) {
            data.staff_data.comment = [];
          }

          const date = moment(new Date(Date.now())).format('YYYY-MM-DD');
          data.staff_data.comment.push({
            actor,
            content,
            date,
          });
          BoardServices.modifyPostData(postId, data).then((response) => {
            dispatch({
              type: ActionTypes.WRITE_CHILD_POST_STAFFCOMMENT_SUCCESS,
              payload: {
                successPostId: postId,
              },
            });
            dispatch(
              getChildPostList(
                boardType,
                boardName,
                postInfo.parent,
                childPageNo,
                childPageSize
              )
            );
          });
        })
        .catch((e) => {
          ExceptionAction.exception(
            e,
            '관리자 comment 등록중 오류가 발생하였습니다.'
          );
        });
    });
}

// server_tester : tests_for_board.py - CommentTest.test_comment
export function getCommentList(postId, pageNo, pageSize) {
  const offset = (pageNo - 1) * pageSize;
  const limit = pageSize;
  return (dispatch, getState) =>
    dispatch(() => {
      return BoardServices.getCommentList(postId, offset, limit)
        .then((response) => {
          dispatch({
            type: ActionTypes.GET_COMMENT_LIST,
            payload: {
              commentList: response.data,
            },
          });
        })
        .catch((e) => {
          ExceptionAction.exception(
            e,
            'getCommentList중 에러가 발생하였습니다.'
          );
        });
    });
}

// server_tester : tests_for_board.py - CommentTest.test_comment
export function getCommentMoreList(
  postId,
  pageNo,
  pageSize,
  refreshComment = false
) {
  return (dispatch, getState) =>
    dispatch(() => {
      let { commentMoreList, commentPageSize, commentPageNo } =
        getState().BoardReducer;
      const offset = (pageNo - 1) * (pageSize ? pageSize : commentPageSize);
      const limit = pageSize ? pageSize : commentPageSize;

      if (commentPageNo !== 1 && commentPageNo === pageNo) {
        return;
      }

      BoardServices.getCommentList(postId, offset, limit, {
        parent__isnull: true,
      }).then((response) => {
        if (refreshComment === false) {
          if (commentMoreList.results) {
            commentMoreList = {
              ...response.data,
              results: commentMoreList.results.concat(response.data.results),
            };
          } else {
            commentMoreList = response.data;
          }
        } else {
          commentMoreList = response.data;
        }

        return dispatch({
          type: ActionTypes.GET_COMMENT_MORE_LIST,
          payload: {
            refreshComment: refreshComment,
            commentMoreList: commentMoreList,
            commentPageSize: pageSize ? pageSize : commentPageSize,
            commentPageNo: pageNo,
          },
        });
      });
    });
}

// server_tester : tests_for_board.py - CommentTest.test_comment
export function getCommentChildList(parentCommentId, pageNo, pageSize) {
  const offset = (pageNo - 1) * pageSize;
  const limit = pageSize;
  return (dispatch, getState) =>
    dispatch(() => {
      BoardServices.getCommentChildList(parentCommentId, offset, limit).then(
        (response) => {
          dispatch({
            type: ActionTypes.GET_COMMENT_CHILD_LIST,
            payload: {
              commentChildList: response.data,
              parentCommentId,
            },
          });
        }
      );
    });
}

export function clearCommentChildList(parentCommentId) {
  return {
    type: ActionTypes.CLEAR_COMMENT_CHILD_LIST,
    payload: {
      parentCommentId,
    },
  };
}

export function collectComment(collectCommentInfo) {
  return {
    type: ActionTypes.COLLECT_COMMENT,
    payload: {
      collectCommentInfo: collectCommentInfo,
    },
  };
}

function setCommentChildCountUpdate(parentCommentId, count) {
  return (dispatch, getState) =>
    dispatch(() => {
      if (parentCommentId && parentCommentId !== 0) {
        let { commentMoreList, commentPageSize, commentPageNo } =
          getState().BoardReducer;
        if (parentCommentId !== 0) {
          if (commentMoreList.results) {
            commentMoreList.results = commentMoreList.results.map(
              (item, idx) => {
                let commentInfo = { ...item };
                if (commentInfo.id === parentCommentId) {
                  commentInfo = {
                    ...commentInfo,
                    child_count: item.child_count + count,
                  };
                }
                return commentInfo;
              }
            );

            return dispatch({
              type: ActionTypes.GET_COMMENT_MORE_LIST,
              payload: {
                commentMoreList: commentMoreList,
                commentPageSize,
                commentPageNo,
              },
            });
          }
        }
      }
    });
}

function setPostCommentCountUpdate(postId, count) {
  return (dispatch, getState) =>
    dispatch(() => {
      let { postInfo } = getState().BoardReducer;
      if (postId === postInfo.id) {
        dispatch({
          type: ActionTypes.GET_POST_INFO,
          payload: {
            postInfo: {
              ...postInfo,
              comment_count: postInfo.comment_count + count,
            },
          },
        });
      }
    });
}

export function completedCommentAction() {
  return {
    type: ActionTypes.COMPLETED_COMMENT_ACTION,
  };
}

// server_tester : tests_for_board.py - CommentTest.test_comment
export function addComment(collectCommentInfo, parentCommentId = 0) {
  return (dispatch, getState) =>
    dispatch(() => {
      return BoardServices.addComment(collectCommentInfo)
        .then((response) => {
          dispatch(setCommentChildCountUpdate(parentCommentId, 1));
          dispatch(setPostCommentCountUpdate(collectCommentInfo.post, 1));
          return dispatch({
            type:
              parentCommentId === 0
                ? ActionTypes.WRITE_COMMENT_SUCCESS
                : ActionTypes.WRITE_COMMENT_CHILD_SUCCESS,
            payload: {
              successCommentId: response.data.id,
              successParentCommentId: parentCommentId,
            },
          });
        })
        .catch((e) => {
          ExceptionAction.exception(e, 'comment 쓰기중 에러가 발생하였습니다.');
        });
    });
}

// server_tester : tests_for_board.py - CommentTest.test_comment
export function modifyComment(
  commentId,
  collectCommentInfo,
  parentCommentId = 0
) {
  return (dispatch, getState) =>
    dispatch(() => {
      return BoardServices.modifyComment(commentId, collectCommentInfo)
        .then((response) => {
          let {
            commentMoreList,
            commentChildList,
            commentPageSize,
            commentPageNo,
          } = getState().BoardReducer;

          if (parentCommentId === 0) {
            if (commentMoreList.results && commentMoreList.results.length > 0) {
              let results = commentMoreList.results.map((comment, index) => {
                if (comment.id === commentId) {
                  comment.html = collectCommentInfo.html;
                }
                return comment;
              });

              dispatch({
                type: ActionTypes.GET_COMMENT_MORE_LIST,
                payload: {
                  commentMoreList: {
                    ...commentMoreList,
                    results: results,
                  },
                  commentPageSize,
                  commentPageNo,
                },
              });
            }
          } else {
            if (
              commentChildList[parentCommentId] &&
              commentChildList[parentCommentId].results
            ) {
              if (commentChildList[parentCommentId].results.length > 0) {
                let results = commentChildList[parentCommentId].results.map(
                  (comment, index) => {
                    if (comment.id === commentId) {
                      comment.html = collectCommentInfo.html;
                    }
                    return comment;
                  }
                );
                dispatch({
                  type: ActionTypes.GET_COMMENT_CHILD_LIST,
                  payload: {
                    commentChildList: {
                      ...commentChildList[parentCommentId],
                      results: results,
                    },
                    parentCommentId,
                  },
                });
              }
            }
          }

          return dispatch({
            type:
              parentCommentId === 0
                ? ActionTypes.MODIFY_COMMENT_SUCCESS
                : ActionTypes.MODIFY_COMMENT_CHILD_SUCCESS,
            payload: {
              successCommentId: commentId,
              successParentCommentId: parentCommentId,
            },
          });
        })
        .catch((e) => {
          ExceptionAction.exception(e, 'comment 수정중 에러가 발생하였습니다.');
        });
    });
}

// server_tester : tests_for_board.py - CommentTest.test_comment
export function removeComment(postId, commentId, parentCommentId = 0) {
  return (dispatch, getState) =>
    dispatch(() => {
      return BoardServices.removeComment(commentId)
        .then((response) => {
          dispatch(setCommentChildCountUpdate(parentCommentId, -1));
          dispatch(setPostCommentCountUpdate(postId, -1));

          return dispatch({
            type:
              parentCommentId === 0
                ? ActionTypes.REMOVE_COMMENT_SUCCESS
                : ActionTypes.REMOVE_COMMENT_CHILD_SUCCESS,
            payload: {
              successCommentId: commentId,
              successParentCommentId: parentCommentId,
            },
          });
        })
        .catch((e) => {
          ExceptionAction.exception(e, 'comment 삭제중 에러가 발생하였습니다.');
        });
    });
}

export function getCommentAccuseIssueType() {
  return {
    type: ActionTypes.GET_COMMENT_ACCUSE_ISSUE_TYPE,
    payload: {
      commentAccuseIssueType: IssueType.CSRCommentAccuseIssue,
    },
  };
}

// server_tester : tests_for_374_comment_accuse.py - CommentAccuseFlowTest.test_comment_accuse_flow
export function getCommentAccuseIssueCategory() {
  return (dispatch, getState) =>
    dispatch(() => {
      dispatch(getCommentAccuseIssueType());
      return IssueTrackerServices.getIssueCategoryGroupId(
        'CSRCommentAccuseIssueCategory'
      )
        .then((response) => {
          dispatch({
            type: ActionTypes.GET_COMMENT_ACCUSE_CATEGORY,
            payload: {
              commentAccuseIssueCategory: response.data,
            },
          });
        })
        .catch((e) => {
          ExceptionAction.exception(
            e,
            'getCommentAccuseIssueCategory중 에러가 발생하였습니다.'
          );
        });
    });
}

// server_tester : tests_for_374_comment_accuse.py - CommentAccuseFlowTest.test_comment_accuse_flow
export function setCommentAccuse(
  commentActorId,
  commentId,
  issueType,
  title,
  category_tag,
  reason,
  parentCommentId,
  postId
) {
  return (dispatch, getState) =>
    dispatch(() => {
      return Async(
        function* (
          commentActorId,
          commentId,
          issueType,
          title,
          category_tag,
          reason,
          parentCommentId
        ) {
          const addIssueResult = yield IssueTrackerServices.addIssue({
            model_type: issueType,
            title,
            category_tag,
          });

          const setAccuseCommentResult = yield BoardServices.setAccuseComment(
            commentId
          );

          const completeWorkResult = yield IssueTrackerServices.completeWork(
            addIssueResult.data.current_work,
            'CSRCommentAccuseProcessWork',
            {
              input: {
                accused_comment_actor: commentActorId,
                accused_comment_id: commentId,
                accuse_reason: reason,
              },
            }
          );

          return {
            addIssue: addIssueResult.data,
            completeWork: completeWorkResult.data,
            setAccuseComment: setAccuseCommentResult.data,
          };
        },
        commentActorId,
        commentId,
        issueType,
        title,
        category_tag,
        reason,
        parentCommentId
      )
        .then((results) => {
          if (parentCommentId) {
            dispatch(setCommentChildCountUpdate(parentCommentId, -1));
          }
          dispatch(setPostCommentCountUpdate(postId, -1));
          return dispatch({
            type: ActionTypes.SET_COMMENT_ACCUSE,
            payload: {
              data: {
                addIssueResult: results.addIssueResult,
                completeWork: results.completeWork,
                setAccuseComment: results.setAccuseComment,
              },
              successCommentId: commentId,
              successParentCommentId: parentCommentId,
            },
          });
        })
        .catch((e) => {
          ExceptionAction.exception(
            e,
            'setCommentAccuse 에러가 발생하였습니다.'
          );
        });
    });
}

function _sendPostPrevNext(
  tempPostPrevNext,
  next,
  now,
  prev,
  nextPageNo,
  prevPageNo,
  tempCount
) {
  return {
    type: ActionTypes.GET_POST_PREV_NEXT,
    payload: {
      tempPostPrevNext,
      prev,
      next,
      now,
      nextPageNo,
      prevPageNo,
      tempCount,
    },
  };
}

function _getPostPrevNext(
  boardInfo,
  tempPostPrevNext,
  postId,
  _nextPageNo,
  _prevPageNo,
  pageSize,
  query,
  tempCount
) {
  return (dispatch, getState) =>
    dispatch(() => {
      let prev;
      let now;
      let next;
      let nextPageNo = _nextPageNo;
      let prevPageNo = _prevPageNo;
      let currentPoint = -1;

      tempPostPrevNext.forEach((postInfo, idx) => {
        if (postInfo.id === postId) {
          currentPoint = idx;
          now = postInfo;
        }
      });

      if (currentPoint === -1) {
        return;
      }

      next = tempPostPrevNext[currentPoint - 1];
      prev = tempPostPrevNext[currentPoint + 1];

      if (!next) {
        if (nextPageNo > 1) {
          nextPageNo -= 1;
          let offset, limit;
          offset = (nextPageNo - 1) * pageSize;
          limit = pageSize;
          let _query = {};
          if (query) {
            _query = { ...query, limit, offset };
          } else {
            _query = { limit, offset };
          }
          return BoardServices.getPostList(
            boardInfo.id,
            generateAPIQuery(_query)
          ).then((response) => {
            tempPostPrevNext = response.data.results.concat(tempPostPrevNext);
            next = response.data.results[response.data.results.length - 1];
            return dispatch(
              _sendPostPrevNext(
                tempPostPrevNext,
                next,
                now,
                prev,
                nextPageNo,
                prevPageNo,
                tempCount
              )
            );
          });
        } else {
          return dispatch(
            _sendPostPrevNext(
              tempPostPrevNext,
              next,
              now,
              prev,
              nextPageNo,
              prevPageNo,
              tempCount
            )
          );
        }
      } else if (!prev) {
        let offset, limit;
        let totalPageNo = Math.ceil(tempCount / pageSize);
        if (totalPageNo >= prevPageNo + 1) {
          prevPageNo += 1;
          offset = (prevPageNo - 1) * pageSize;
          limit = pageSize;
          let _query = {};
          if (query) {
            _query = { ...query, limit, offset };
          } else {
            _query = { limit, offset };
          }
          return BoardServices.getPostList(
            boardInfo.id,
            generateAPIQuery(_query)
          ).then((response) => {
            if (response.data.results.length > 0) {
              tempPostPrevNext = tempPostPrevNext.concat(response.data.results);
              prev = response.data.results[0];
            }
            return dispatch(
              _sendPostPrevNext(
                tempPostPrevNext,
                next,
                now,
                prev,
                nextPageNo,
                prevPageNo,
                tempCount
              )
            );
          });
        } else {
          return dispatch(
            _sendPostPrevNext(
              tempPostPrevNext,
              next,
              now,
              prev,
              nextPageNo,
              prevPageNo,
              tempCount
            )
          );
        }
      } else {
        return dispatch(
          _sendPostPrevNext(
            tempPostPrevNext,
            next,
            now,
            prev,
            nextPageNo,
            prevPageNo,
            tempCount
          )
        );
      }
    });
}

export function getPostPrevNext(
  boardType,
  boardName,
  postId,
  pageNo,
  pageSize,
  query
) {
  return (dispatch, getState) =>
    dispatch(() => {
      let prevPageNo = getState().BoardReducer.postPrevNext.prevPageNo;
      let nextPageNo = getState().BoardReducer.postPrevNext.nextPageNo;
      checkBoardInfo(dispatch, getState, boardType, boardName).then(
        (boardInfo) => {
          let tempPostPrevNext = getState().BoardReducer.tempPostPrevNext;
          let tempCount = getState().BoardReducer.tempCount;
          if (!tempPostPrevNext || tempPostPrevNext.length === 0) {
            nextPageNo = prevPageNo = pageNo;
            let offset, limit;
            offset = (pageNo - 1) * pageSize;
            limit = pageSize;
            let _query = {};
            if (query) {
              _query = { ...query, limit, offset };
            } else {
              _query = { limit, offset };
            }
            return BoardServices.getPostList(
              boardInfo.id,
              generateAPIQuery(_query)
            ).then((response) => {
              tempCount = response.data.count;
              dispatch({
                type: ActionTypes.SET_POST_PREV_NEXT,
                payload: {
                  tempPostPrevNext: response.data.results,
                  tempCount: tempCount,
                },
              });
              return dispatch(
                _getPostPrevNext(
                  boardInfo,
                  response.data.results,
                  postId,
                  nextPageNo,
                  prevPageNo,
                  pageSize,
                  query,
                  tempCount
                )
              );
            });
          } else {
            dispatch(
              _getPostPrevNext(
                boardInfo,
                tempPostPrevNext,
                postId,
                nextPageNo,
                prevPageNo,
                pageSize,
                query,
                tempCount
              )
            );
          }
        }
      );
    });
}

export function initPostPrevNext() {
  return (dispatch, getState) =>
    dispatch(() => {
      return dispatch({
        type: ActionTypes.INIT_POST_PREV_NEXT,
      });
    });
}

export function postSetStatus(postId, status) {
  return (dispatch, getState) =>
    dispatch(() => {
      return BoardServices.postSetStatus(postId, status)
        .then((res) => {
          dispatch({
            type: ActionTypes.STATUS_POST_SUCCESS,
            payload: {
              status: res.data.status,
            },
          });
        })
        .catch((e) => {
          ExceptionAction.exception(e, '공개 비공개 설정에서 에러가 났습니다.');
        });
    });
}

import FileServices from '../services/FileServices';
import UserServices from '../services/UserServices';
import httpErrorMessage from '../httpErrorMessage';
import Async from '../Async';
export const ActionTypes = Object.freeze({
  REQUEST_FILE_UPLOAD: '@@FILE/REQUEST_FILE_UPLOAD',
  ERROR_FILE_UPLOAD: '@@FILE/ERROR_FILE_UPLOAD',
  SUCCESS_FILE_UOLOAD: '@@FILE/SUCCESS_FILE_UOLOAD',

  REQUEST_STUDY_BOOK_FILE_UPLOAD: '@@FILE/REQUEST_STUDY_BOOK_FILE_UPLOAD',
  SUCCESS_STUDY_BOOK_FILE_UPLOAD: '@@FILE/SUCCESS_STUDY_BOOK_FILE_UPLOAD',
  ERROR_STUDY_BOOK_FILE_UPLOAD: '@@FILE/ERROR_STUDY_BOOK_FILE_UPLOAD',
});

export function fileUpload(formData, requester) {
  return (dispatch, getState) =>
    dispatch(() => {
      dispatch({
        type: ActionTypes.REQUEST_FILE_UPLOAD,
        payload: {
          requester,
        },
      });
      return FileServices.fileUpload(formData)
        .then((response) => {
          dispatch({
            type: ActionTypes.SUCCESS_FILE_UOLOAD,
            payload: {
              fileInfo: response.data,
              requester,
            },
          });
        })
        .catch((e) => {
          dispatch({
            type: ActionTypes.ERROR_FILE_UPLOAD,
            payload: {
              requester,
              message: httpErrorMessage(
                e,
                '파일업로드중 오류가 발생하였습니다.'
              ),
            },
          });
        });
    });
}

export function uploadStudyBook(formData) {
  return (dispatch, getState) =>
    dispatch(() => {
      dispatch({
        type: ActionTypes.REQUEST_STUDY_BOOK_FILE_UPLOAD,
        payload: {},
      });
      return FileServices.bookFileUpload(formData)
        .then((response) => {
          dispatch({
            type: ActionTypes.SUCCESS_STUDY_BOOK_FILE_UPLOAD,
            payload: {
              studyBookFileInfo: response.data,
            },
          });
        })
        .catch((e) => {
          dispatch({
            type: ActionTypes.ERROR_STUDY_BOOK_FILE_UPLOAD,
            payload: {
              message: httpErrorMessage(
                e,
                '파일업로드중 오류가 발생하였습니다.'
              ),
            },
          });
        });
    });
}

export function fileUploadNotLogin(formData, requester) {
  return (dispatch, getState) =>
    dispatch(() => {
      return Async(function* () {
        let guestAuth = yield UserServices.getGuestAuth();
        let guestActor = yield UserServices.getActorInfoByName('GUEST', {
          name: 'GUEST',
        });

        formData.append('auth_token', guestAuth.data.auth_token);
        formData.append('actor', guestActor.data.id);

        formData.forEach((item) => {
          console.log(item);
        });

        let returnVal = yield FileServices.fileUpload(formData);
        console.log(returnVal);

        dispatch({
          type: ActionTypes.SUCCESS_FILE_UOLOAD,
          payload: {
            fileInfo: returnVal.data,
            requester,
          },
        });
      }).catch((e) => {
        dispatch({
          type: ActionTypes.ERROR_FILE_UPLOAD,
          payload: {
            requester,
            message: httpErrorMessage(
              e,
              '비회원 파일업로드중 오류가 발생하였습니다.'
            ),
          },
        });
      });
    });
}

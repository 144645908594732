const api = {
  createTemporaryInformationActorWithMdnAuth: (params) => ({
    url: '/temporary-information/actor/information-with-mdn-auth/',
    params: {
      ...params,
    },
  }),
  addTemporaryInformationActor: (params) => ({
    url: '/temporary-information/actor/',
    params: {
      ...params,
    },
  }),
};

export default api;

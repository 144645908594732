import { Component } from 'react';
import { connect } from 'react-redux';
import * as MemberAction from './MemberAction';
import { routes } from 'src/constants/routes';
import * as PaymentAction from '@/library/payment/PaymentAction';

class MemberLogout extends Component {
  componentDidMount() {
    this.props.initApplyInfo();
    this.props.logout();
    this.props.history.replace(routes.home);
  }

  render() {
    return null;
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    logout: () => {
      dispatch(MemberAction.logout());
    },
    initApplyInfo: () => {
      dispatch(PaymentAction.initApplyInfo());
    },
  };
};
export default connect(null, mapDispatchToProps)(MemberLogout);

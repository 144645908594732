import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import MemberJoinBase from './MemberJoinBase';
import * as MemberAction from '../MemberAction';
import Timer from '@/features/common/components/Timer';
import { RadioGroup, Radio } from '../../../library/Radio';
import ErrorLabel from '@/features/common/components/ErrorLabel';
import DesktopLayerPopup from '@/features/common/DesktopLayerPopup';
import MemberIdIssueFinishPop from './MemberIdIssueFinishPop';
import ParentsUtil from '../../../library/util/ParentsUtil';
import { RegExp } from '../../../library/constants/RegExp';
import ButtonBlockUtil from '../../../library/util/ButtonBlockUtil';
import { AgreementSrc } from '@/features/common/configs/values';

class MemberInfoRegisterPop extends MemberJoinBase {
  Const = {
    speed: '0.5s',
  };

  constructor(props) {
    super(props);

    this.inputRef = {};

    this.state = {
      name: this.props.auth.human_name || '',
      //엄마 아빠 할머니 할아버지 기타
      choiced: '엄마',
      id: this.props.auth.username || '',
      //id 중복 체크했는지
      idDuplicateCheck: false,
      phone: '',
      //폰 중복 체크했는지
      phoneDuplicateCheck: false,
      //원타임 인증번호
      certificationNumber: '',
      //certificationCheck:false,
      password1: '',
      password2: '',
      // 동의정보
      agreement: {
        wink_terms_agreement: false,
        private_info_agreement: false,
        marketing_and_information_receive_agreement: false,
        advertising_information_receive_agreement: false,
        more_than_fourteen: false,
      },
      //타이머 온오프
      timerOn: false,
      //문자인증 요청 카운터
      onTimePasswordCount: 0,
      //인증번호가 맞았는지
      checkOneTimePassword: false,
      //인증번호 체크 성공하면 받아오는 토큰
      mdn_token: '',
      //validity 유효기간
      validityDate: true,
      errorFlag: false,
      error: {
        name: false,
        id: false,
        idForm: false,
        idDuplicateCheck: false,
        phone: false,
        phoneDuplicateCheck: false,
        phoneNumberConfirm: false,
        password1: false,
        password1Form: false,
        password2: false,
        samePassword: false,
        wink_terms_agreement: false,
        private_info_agreement: false,
        more_than_fourteen: false,
      },
      isSkipOptionalAgree: false,
    };

    this.idDuplicateCheck = this.idDuplicateCheck.bind(this);
    this.phoneDuplicateCheck = this.phoneDuplicateCheck.bind(this);
    this.openDuplicatedModal = this.openDuplicatedModal.bind(this);
    this.closeDuplicatedModal = this.closeDuplicatedModal.bind(this);
    this.changeAuth = this.changeAuth.bind(this);
    this.registerUserInfo = this.registerUserInfo.bind(this);
    this.oneTimePassword = this.oneTimePassword.bind(this);
    this.checkOneTimePassword = this.checkOneTimePassword.bind(this);
    this.showErrorLabel = this.showErrorLabel.bind(this);
    this.hideErrorLabel = this.hideErrorLabel.bind(this);
    this.timeoutCallback = this.timeoutCallback.bind(this);
    this.scrollTop = this.scrollTop.bind(this);
    this.goBack = this.goBack.bind(this);
    this.onChangeRadio = this.onChangeRadio.bind(this);
    this.modifyAuthDataSuccess = this.modifyAuthDataSuccess.bind(this);
    this.ButtonBlockUtil = new ButtonBlockUtil();
  }

  registerUserInfo(e) {
    e.preventDefault();
    let isShowError = true;
    //유효성검사
    if (!this.validateIsSignUpButtonActive(isShowError)) {
      return;
    }
    //선택 약관 동의 여부 체크
    if (!this.state.isSkipOptionalAgree) {
      this.showOptionalAgreementPopup(
        () => {
          // 동의 안하고 진행 -> 팝업 닫힘
          this.setState({ isSkipOptionalAgree: true });
        },
        this.changeAuth // 팝업 동의
      );
      return;
    }
    if (this.ButtonBlockUtil.isBlocking()) {
      //2초동안 재요청올때는 이곳에 걸립니다.
      return;
    }

    this.changeAuth();
  }

  changeAuth() {
    const {
      id,
      password1,
      name,
      phone,
      mdn_token,
      choiced,
      agreement,
      validityDate,
    } = this.state;
    let human_mdn = this.props.auth.human_mdn || '';
    human_mdn = human_mdn.replace(/-/g, '');

    let decideRearerType = choiced;
    //데이터 세팅
    const data = {
      parent_category_tag_name: decideRearerType.trim(),
      terms_agreement: {
        ...agreement,
        alive_until_withdrawal_agreement: validityDate,
      },
    };

    let authData = {};
    authData.username = id.trim().toLowerCase();
    authData.password = password1.trim();
    authData.human_name = name.trim();
    if (ParentsUtil.isReliefNumber(human_mdn)) {
      authData.human_mdn = phone.trim();
    }
    authData.mdn_token = mdn_token;
    authData.data = data;
    //auth 수정
    this.props.changeAuth(authData);

    // this.props.addUser(id.trim().toLowerCase(), password1.trim(), name.trim(), phone.trim(), mdn_token, data, ActorType.Parent);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if ('changeAuth' === nextProps.action) {
      const { choiced } = this.state;
      this.props.modifyParentCategoryTag(choiced);
      this.props.clearAction();
      this.modifyAuthDataSuccess();
      //actor 수정
    }
    if ('successIdDuplicateCheck' === nextProps.action) {
      this.ButtonBlockUtil.stop();
      this.props.clearAction();
      this.setState({ idDuplicateCheck: true });
    }
    if ('successPhoneDuplicateCheck' === nextProps.action) {
      this.ButtonBlockUtil.stop();
      this.props.clearAction();
      this.setState({ phoneDuplicateCheck: true });
    }
    if ('successCheckOneTimePassword' === nextProps.action) {
      this.setState({
        mdn_token: nextProps.carrier,
        certificationNumber: '',
        timerOn: false,
      });
      this.ButtonBlockUtil.stop();
      this.props.clearAction();
    }
    if ('failCheckOneTimePassword' === nextProps.action) {
      //'인증번호를 잘못 입력하였습니다.','다시 확인 후 입력해 주세요.'
      DesktopLayerPopup.notice({
        message: '인증번호를 잘못 입력하였습니다.다시 확인 후 입력해 주세요.',
        buttonLabel: '확인',
      });
      this.ButtonBlockUtil.stop();
      this.props.clearAction();
    }
  }

  modifyAuthDataSuccess() {
    if (this.props.studentList.length === 0) {
      DesktopLayerPopup.modal(<MemberIdIssueFinishPop />);
    }
    DesktopLayerPopup.hide(this.props.layerKey);
  }

  goBack(e) {
    if (e) {
      e.preventDefault();
    }
    DesktopLayerPopup.hide(this.props.layerKey);
  }

  validateIsSignUpButtonActive(isShowError) {
    let { idDuplicateCheck, phoneDuplicateCheck, agreement } = this.state;
    const { wink_terms_agreement, private_info_agreement, more_than_fourteen } =
      agreement;
    let human_mdn = this.props.auth.human_mdn || '';
    human_mdn = human_mdn.replace(/-/g, '');

    //이름없음
    if ('' === this.state.name) {
      this.showErrorLabel('name', isShowError);
      this.inputRef.name.focus();
      return false;
    }
    //아이디 없음
    if ('' === this.state.id.trim()) {
      this.showErrorLabel('id', isShowError);
      this.inputRef.id.focus();
      return false;
    }
    //아이디 형식 이상
    if (!RegExp.ID.test(this.state.id.trim())) {
      this.showErrorLabel('idForm', isShowError);
      this.inputRef.id.focus();
      return false;
    }
    //id중복 미확인
    if (!idDuplicateCheck) {
      this.showErrorLabel('idDuplicateCheck', isShowError);
      return false;
    }

    if (ParentsUtil.isReliefNumber(human_mdn)) {
      //휴대폰번호 없음
      if ('' === this.state.phone.trim()) {
        this.showErrorLabel('phone', isShowError);
        this.inputRef.phone.focus();
        return false;
      }

      //휴대폰번호 중복 미확인
      if (!phoneDuplicateCheck) {
        this.showErrorLabel('phoneDuplicateCheck', isShowError);
        return false;
      }

      //휴대폰번호 형식 이상
      if (!ParentsUtil.validatePhoneNumber(this.state.phone)) {
        this.showErrorLabel('phoneForm', isShowError);
        this.inputRef.phone.focus();
        return false;
      }
      //인증번호 확인
      if ('' === this.state.mdn_token) {
        this.showErrorLabel('certificationNumber', isShowError);
        if (this.inputRef.certificationNumber) {
          this.inputRef.certificationNumber.focus();
        }
        return false;
      }
    }

    //패스워드 비어있는지
    if ('' === this.state.password1.trim()) {
      this.showErrorLabel('password1', isShowError);
      this.inputRef.password1.focus();
      return false;
    }
    //패스워드 최소6자리 숫자1개이상 포함
    if (!RegExp.PASSWORD2.test(this.state.password1.trim())) {
      this.showErrorLabel('password1Form', isShowError);
      this.inputRef.password1.focus();
      return false;
    }
    //패스워드2 비어있는지
    if ('' === this.state.password2.trim()) {
      this.showErrorLabel('password2', isShowError);
      this.inputRef.password2.focus();
      return false;
    }
    //비번이 같은지 체크
    if (this.state.password1.trim() !== this.state.password2.trim()) {
      this.showErrorLabel('samePassword', isShowError);
      this.inputRef.password1.focus();
      return false;
    }
    //이용동의
    if (!wink_terms_agreement) {
      this.showErrorLabel('wink_terms_agreement', isShowError);
      return false;
    }
    //개인정보 이용동의
    if (!private_info_agreement) {
      this.showErrorLabel('private_info_agreement', isShowError);
      return false;
    }
    // 만 14세 이상 여부
    if (!more_than_fourteen) {
      this.showErrorLabel('more_than_fourteen', isShowError);
      return false;
    }

    return true;
  }

  render() {
    let {
      name,
      idDuplicateCheck,
      phoneDuplicateCheck,
      onTimePasswordCount,
      mdn_token,
      error,
      agreement,
      certificationNumber,
      timerOn,
    } = this.state;
    const {
      wink_terms_agreement,
      private_info_agreement,
      marketing_and_information_receive_agreement,
      advertising_information_receive_agreement,
      more_than_fourteen,
    } = agreement;
    let human_mdn = this.props.auth.human_mdn || '';
    human_mdn = human_mdn.replace(/-/g, '');
    const allAgree = Object.values(agreement).every((value) => value);

    const isReliefNumber = ParentsUtil.isReliefNumber(human_mdn);
    return (
      <div className="layer-wrap large">
        <div className="layer-setting">
          <h2>회원정보 등록</h2>
          {/* layer scroll */}

          <div className="layer-scroll">
            <div className="scroll member">
              <table className="form join-form">
                <tbody>
                  <tr>
                    <th scope="row">
                      이름<em>필수</em>
                    </th>
                    <td>
                      <fieldset className="name">
                        <legend>이름 입력</legend>
                        <input
                          type="text"
                          name
                          title="이름 입력"
                          placeholder="이름을 입력해 주세요."
                          onChange={(e) => {
                            this.onChange(e, e.target.value, 'name');
                          }}
                          ref={(ref) => {
                            this.inputRef.name = ref;
                          }}
                          value={name}
                        />
                      </fieldset>
                      {error.name && (
                        <ErrorLabel label={'이름을 입력해주세요.'} />
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="parent-select">
                      양육자 구분<em>필수</em>
                    </th>
                    <td>
                      <ul className="family-select">
                        <li>
                          <input
                            type="radio"
                            name="choice"
                            id="choice1"
                            defaultChecked
                            onClick={(e) => {
                              this.onChoice('엄마');
                            }}
                          />
                          <label htmlFor="choice1">엄마</label>
                        </li>
                        <li>
                          <input type="radio" name="choice" id="choice2" />
                          <label
                            htmlFor="choice2"
                            onClick={(e) => {
                              this.onChoice('아빠');
                            }}
                          >
                            아빠
                          </label>
                        </li>
                        <li>
                          <input type="radio" name="choice" id="choice3" />
                          <label
                            htmlFor="choice3"
                            onClick={(e) => {
                              this.onChoice('할머니');
                            }}
                          >
                            할머니
                          </label>
                        </li>
                        <li>
                          <input type="radio" name="choice" id="choice4" />
                          <label
                            htmlFor="choice4"
                            onClick={(e) => {
                              this.onChoice('할아버지');
                            }}
                          >
                            할아버지
                          </label>
                        </li>
                        <li>
                          <input type="radio" name="choice" id="choice5" />
                          <label
                            htmlFor="choice5"
                            onClick={(e) => {
                              this.onChoice('기타');
                            }}
                          >
                            기타
                          </label>
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      아이디<em>필수</em>
                    </th>
                    <td>
                      <fieldset>
                        <legend>아이디 입력</legend>
                        <div className="item">
                          <input
                            type="text"
                            name
                            title="아이디 입력"
                            placeholder="소문자+숫자 6자 이상 입력해주세요."
                            onChange={(e) => {
                              this.onChange(e, e.target.value, 'id');
                            }}
                            ref={(ref) => {
                              this.inputRef.id = ref;
                            }}
                          />
                          <a
                            href="#"
                            className="btn-type form"
                            onClick={this.idDuplicateCheck}
                          >
                            {idDuplicateCheck ? '확인완료' : '중복확인'}
                          </a>
                        </div>
                      </fieldset>
                      {error.id && (
                        <ErrorLabel label={'아이디를 입력해주세요.'} />
                      )}
                      {error.idForm && (
                        <ErrorLabel
                          label={
                            '아이디 형식에 맞지 않습니다. 소문자, 숫자 포함 6자리 이상 입력해 주세요.'
                          }
                        />
                      )}
                      {error.idDuplicateCheck && (
                        <ErrorLabel label={'아이디 중복확인이 필요합니다.'} />
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      휴대폰 번호<em>필수</em>
                    </th>
                    <td>
                      <fieldset className="phone">
                        <legend>휴대폰 번호 입력</legend>
                        <input
                          type="text"
                          className="disabled"
                          placeholder={human_mdn}
                          disabled
                          defaultValue={human_mdn}
                        />
                      </fieldset>
                    </td>
                  </tr>

                  {isReliefNumber && (
                    <tr>
                      <th scope="row">
                        휴대폰 번호<em>필수</em>
                      </th>
                      <td>
                        <fieldset className="phone">
                          <legend>휴대폰 번호 입력</legend>
                          <div className="item">
                            <input
                              type="text"
                              name
                              title="휴대폰 번호 입력"
                              placeholder="휴대폰 번호를 입력해 주세요."
                              onChange={(e) => {
                                this.onChange(e, e.target.value, 'phone');
                              }}
                              ref={(ref) => {
                                this.inputRef.phone = ref;
                              }}
                            />
                            <a
                              href="#"
                              className="btn-type form"
                              onClick={this.phoneDuplicateCheck}
                            >
                              {phoneDuplicateCheck ? '확인완료' : '중복확인'}
                            </a>
                            <a
                              href="#"
                              className="btn-type2 confirm-num"
                              id="registermodal-certification"
                              onClick={this.oneTimePassword}
                            >
                              {0 === onTimePasswordCount
                                ? '인증번호 발송'
                                : '인증번호 재발송'}
                            </a>
                          </div>
                          <div className="item">
                            <input
                              type="text"
                              className={classNames('confirm', {
                                disabled:
                                  0 === onTimePasswordCount || '' !== mdn_token,
                              })}
                              placeholder={
                                '' === mdn_token
                                  ? '인증번호 입력'
                                  : '인증번호 입력완료'
                              }
                              onChange={(e) => {
                                this.onChange(
                                  e,
                                  e.target.value,
                                  'certificationNumber'
                                );
                              }}
                              ref={(ref) => {
                                this.inputRef.certificationNumber = ref;
                              }}
                              disabled={
                                0 === onTimePasswordCount || '' !== mdn_token
                              }
                              value={certificationNumber}
                            />

                            <Timer
                              label="유효시간"
                              format={'mm:ss'}
                              resetId={onTimePasswordCount}
                              on={timerOn}
                              timeoutCallback={this.timeoutCallback}
                            />

                            <a
                              href="#"
                              className="btn-type2 confirm-num"
                              onClick={this.checkOneTimePassword}
                            >
                              {'' !== mdn_token ? '인증 완료' : '인증번호 확인'}
                            </a>
                            <p className="notice">
                              회원가입 완료 후 휴대폰번호를 아이디처럼 사용
                              가능합니다.
                            </p>
                          </div>
                        </fieldset>
                        {error.phone && (
                          <ErrorLabel label={'휴대폰번호를 입력해주세요.'} />
                        )}
                        {error.phoneDuplicateCheck && (
                          <ErrorLabel
                            label={'휴대폰번호 중복확인이 필요합니다.'}
                          />
                        )}
                        {error.phoneNumberConfirm && (
                          <ErrorLabel label={'인증번호 확인이 필요합니다.'} />
                        )}
                      </td>
                    </tr>
                  )}

                  <tr>
                    <th scope="row">
                      비밀번호<em>필수</em>
                    </th>
                    <td>
                      <fieldset className="password">
                        <legend>비밀번호 입력</legend>
                        <input
                          type="password"
                          name
                          title="비밀번호 입력"
                          placeholder="비밀번호를 입력해 주세요."
                          onChange={(e) => {
                            this.onChange(e, e.target.value, 'password1');
                          }}
                          ref={(ref) => {
                            this.inputRef.password1 = ref;
                          }}
                        />
                      </fieldset>
                      {error.password1 && (
                        <ErrorLabel label={'비밀번호를 입력해주세요.'} />
                      )}
                      {error.password1Form && (
                        <ErrorLabel
                          label={
                            '비밀번호 형식에 맞지 않습니다. 영문, 숫자, 특수문자 포함 8자리 이상 입력해 주세요.'
                          }
                        />
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      비밀번호 확인<em>필수</em>
                    </th>
                    <td>
                      <fieldset className="password">
                        <legend>비밀번호 입력</legend>
                        <input
                          type="password"
                          name
                          title="비밀번호 재입력"
                          placeholder="비밀번호 확인을 입력해 주세요."
                          onChange={(e) => {
                            this.onChange(e, e.target.value, 'password2');
                          }}
                          ref={(ref) => {
                            this.inputRef.password2 = ref;
                          }}
                        />
                      </fieldset>
                      {error.password2 && (
                        <ErrorLabel label={'비밀번호 확인을 입력해 주세요.'} />
                      )}
                      {error.samePassword && (
                        <ErrorLabel
                          label={'입력한 비밀번호와 일치하지 않습니다.'}
                        />
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="agree-wrap terms">
                <fieldset className="all-check">
                  <legend>약관 전체 동의</legend>
                  <input
                    type="checkbox"
                    id="agree01"
                    name="agree01"
                    onChange={(e) => {
                      this.onCheckBox(e, 'all');
                    }}
                    checked={allAgree}
                    ref={(ref) => {
                      this.inputRef.allAgree = ref;
                    }}
                  />
                  <label htmlFor="agree01">
                    서비스 이용 약관에 모두 동의합니다.
                  </label>
                  <small>
                    (선택 항목은 동의를 하지 않으셔도 서비스의 이용은 가능하나
                    가입 우대 혜택 안내 등을 받지 못할 수 있습니다.)
                  </small>
                </fieldset>
                <ul className="agree-box">
                  <li>
                    <div>
                      <input
                        type="checkbox"
                        id="agree02"
                        name="agree02"
                        onClick={(e) => {
                          this.onCheckBox(e, 'wink_terms_agreement');
                        }}
                        checked={wink_terms_agreement}
                      />
                      <label htmlFor="agree02">
                        윙크(wink) 이용약관 동의 (필수)
                      </label>
                      <a
                        className="btn-link"
                        href={AgreementSrc.winkAgree}
                        target="_blank"
                      >
                        보기
                      </a>
                    </div>
                  </li>

                  <li>
                    <div>
                      <input
                        type="checkbox"
                        id="agree03"
                        name="agree03"
                        onClick={(e) => {
                          this.onCheckBox(e, 'private_info_agreement');
                        }}
                        checked={private_info_agreement}
                      />
                      <label htmlFor="agree03">
                        개인정보수집 및 활용 동의 (필수)
                      </label>
                      <a
                        className="btn-link"
                        href={AgreementSrc.privacyPolicy}
                        target="_blank"
                      >
                        보기
                      </a>
                    </div>
                  </li>

                  <li>
                    <div>
                      <input
                        type="checkbox"
                        id="agree04"
                        name="agree04"
                        onClick={(e) => {
                          this.onCheckBox(
                            e,
                            'marketing_and_information_receive_agreement'
                          );
                        }}
                        checked={marketing_and_information_receive_agreement}
                      />
                      <label htmlFor="agree04">
                        마케팅 목적 활용 동의 (선택)
                      </label>
                      <a
                        className="btn-link"
                        href={AgreementSrc.marketingPolicy}
                        target="_blank"
                      >
                        보기
                      </a>
                    </div>
                  </li>

                  <li>
                    <div>
                      <input
                        type="checkbox"
                        id="agree05"
                        name="agree05"
                        onClick={(e) => {
                          this.onCheckBox(
                            e,
                            'advertising_information_receive_agreement'
                          );
                        }}
                        checked={advertising_information_receive_agreement}
                      />
                      <label htmlFor="agree05">
                        광고성 정보 수신 동의 안내 (선택)
                      </label>
                      <a
                        className="btn-link"
                        href={AgreementSrc.advertisingPolicy}
                        target="_blank"
                      >
                        보기
                      </a>
                    </div>
                  </li>

                  <li>
                    <div>
                      <input
                        type="checkbox"
                        id="agree06"
                        name="agree06"
                        onClick={(e) => {
                          this.onCheckBox(e, 'more_than_fourteen');
                        }}
                        checked={more_than_fourteen}
                      />
                      <label htmlFor="agree06">
                        만 14세 이상입니다. (필수)
                      </label>
                    </div>
                  </li>
                  {error.wink_terms_agreement && (
                    <ErrorLabel
                      label={'윙크(wink) 이용약관에 동의해 주세요.'}
                    />
                  )}
                  {error.private_info_agreement && (
                    <ErrorLabel
                      label={'개인정보수집 및 활용에 대해 동의해 주세요.'}
                    />
                  )}
                  {error.more_than_fourteen && (
                    <ErrorLabel label={'만 14세 이상 여부를 확인해주세요.'} />
                  )}
                </ul>
              </div>
              <div className="agree-wrap">
                <p className="title">개인정보 유효기간 (필수)</p>
                <div className="agree-box">
                  <p>
                    개인정보를 파기 또는 분리 저장·관리하여야 하는 서비스 미이용
                    기간을 아래 기간으로 요청합니다.
                  </p>
                  <RadioGroup
                    name="validityDate"
                    onChange={this.onChangeRadio}
                    selectedValue={'aliveUntil'}
                  >
                    <div className="form-area">
                      <Radio label={'1년'} name="validityDate" value={'year'} />
                      <Radio
                        label={'회원 탈퇴 시까지'}
                        name="validityDate"
                        value={'aliveUntil'}
                      />
                    </div>
                  </RadioGroup>

                  <p className="text-type-small">
                    ※ {'회원 탈퇴 시까지'}로 기간 설정시 유익한 교육 정보 등을
                    계속 제공 받기 위해 휴면 해지 절차 등의 번거로움을 줄일 수
                    있습니다.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="btn-area">
            <a href="#" className="btn-type2" onClick={this.goBack}>
              취소
            </a>
            <a
              href="#"
              className="btn-type2 on"
              onClick={this.registerUserInfo}
            >
              회원정보 등록
            </a>
          </div>
        </div>
        <button className="close" onClick={this.goBack}>
          팝업 닫기
        </button>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    clearAction: () => {
      dispatch(MemberAction.clearAction());
    },
    addUser: (
      username,
      password,
      human_name,
      human_mdn,
      mdn_token,
      data,
      actorType
    ) => {
      dispatch(
        MemberAction.addUser(
          username,
          password,
          human_name,
          human_mdn,
          mdn_token,
          data,
          actorType
        )
      );
    },
    idDuplicateCheck: (id, openDuplicatedModal) => {
      dispatch(MemberAction.idDuplicateCheck(id, openDuplicatedModal));
    },
    phoneDuplicateCheck: (phoneNum, openDuplicatedModal) => {
      dispatch(MemberAction.phoneDuplicateCheck(phoneNum, openDuplicatedModal));
    },
    checkOneTimePassword: (mdn, certificationNumber) => {
      dispatch(MemberAction.checkOneTimePassword(mdn, certificationNumber));
    },
    oneTimePassword: (mdn, isKakaoTalk) => {
      dispatch(MemberAction.oneTimePassword(mdn, isKakaoTalk));
    },
    changeAuth: (authData) => {
      dispatch(MemberAction.changeAuth(authData));
    },
    modifyParentCategoryTag: (category_tag_name) => {
      dispatch(MemberAction.modifyParentCategoryTag(category_tag_name));
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    action: state.MemberReducer.action,
    carrier: state.MemberReducer.carrier,
    auth: state.MemberReducer.auth,
    studentList: state.MemberReducer.studentList,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberInfoRegisterPop);

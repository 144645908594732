import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as MemberAction from 'src/features/member/MemberAction';
import * as MyPageMyInfoAction from './MyPageMyInfoAction';

//import simpleDotProp from '../simpleDotProp';

const MyPageInjector =
  (stateToProps = null, dispatchToProps = null) =>
  (WrapperComponent) => {
    const HOCComponent = class _HOCComponent extends Component {
      render() {
        return <WrapperComponent {...this.props} />;
      }
    };

    const mapDispatchToProps = (dispatch, ownProps) => {
      let props = {};
      if (dispatchToProps !== null) {
        props = dispatchToProps(dispatch, ownProps);
      }

      return {
        ...props,
        clearAction: () => {
          dispatch(MyPageMyInfoAction.clearAction());
        },
        modifyAuths: (authData, categoryTagName, customAction) => {
          dispatch(
            MyPageMyInfoAction.modifyAuths(
              authData,
              categoryTagName,
              customAction
            )
          );
        },
        phoneDuplicateCheck: (phoneNum, failCallback) => {
          dispatch(MemberAction.phoneDuplicateCheck(phoneNum, failCallback));
        },
        oneTimePassword: (mdn, isKakaoTalk) => {
          dispatch(MemberAction.oneTimePassword(mdn, isKakaoTalk));
        },
        checkOneTimePassword: (mdn, certificationNumber) => {
          return dispatch(
            MemberAction.checkOneTimePassword(mdn, certificationNumber)
          );
        },
        checkAuth: (oldPassword) => {
          return dispatch(MyPageMyInfoAction.checkAuth(oldPassword));
        },
        changePW: (password) => {
          dispatch(MyPageMyInfoAction.changePW(password));
        },
        setStudentMember: (name, isMale, dateOfBirth) => {
          dispatch(MemberAction.setStudentMember(name, isMale, dateOfBirth));
        },
        modifyAuthData: (authData, option) => {
          dispatch(MyPageMyInfoAction.modifyAuthData(authData, option));
        },
        modifyParentCategoryTag: (category_tag_name) => {
          dispatch(
            MyPageMyInfoAction.modifyParentCategoryTag(category_tag_name)
          );
        },
        clearMemberAction: () => {
          dispatch(MemberAction.clearAction());
        },
        deleteChild: (actorId, authId, targetIndex) => {
          dispatch(MemberAction.deleteChild(actorId, authId, targetIndex));
        },
        modifyStudents: (students, indexObject) => {
          dispatch(MemberAction.modifyStudents(students, indexObject));
        },
        setStudentMemberAndLoginSecondary: (name, isMale, dateOfBirth) => {
          dispatch(
            MemberAction.setStudentMemberAndLoginSecondary(
              name,
              isMale,
              dateOfBirth
            )
          );
        },
        changeChild: (actorId, studentId) => {
          dispatch(MemberAction.changeChild(actorId, studentId));
        },
        checkOneTimePasswordAndModifyAuths: (
          mdn,
          certificationNumber,
          authData
        ) => {
          dispatch(
            MyPageMyInfoAction.checkOneTimePasswordAndModifyAuths(
              mdn,
              certificationNumber,
              authData
            )
          );
        },
        setWithdrawal: (authId, reason) => {
          return dispatch(MyPageMyInfoAction.setWithdrawal(authId, reason));
        },
        getStudentList: (familyId) => {
          dispatch(MemberAction.getStudentList(familyId));
        },
      };
    };

    const mapStateToProps = (state, ownProps) => {
      let props = {};
      if (stateToProps !== null) {
        props = stateToProps(state, ownProps);
      }
      let auth = state.MemberReducer.auth;
      let actor = state.MemberReducer.actor;
      let studentList = state.MemberReducer.studentList;
      let studentInfo = state.MemberReducer.studentInfo;
      return {
        ...props,
        memberAction: state.MemberReducer.action,
        memberCarrier: state.MemberReducer.carrier,
        auth: auth,
        studentList: studentList,
        studentInfo: studentInfo,
        actor: actor,
        phoneDuplicate: state.MemberReducer.phoneDuplicate,
        action: state.MyPageMyInfoReducer.action,
        type: state.MyPageMyInfoReducer.type,
        human_name: auth.human_name,
        username: auth.username,
        human_mdn: auth.human_mdn,
        category_tag_name: actor.category_tag_name,
      };
    };

    return connect(mapStateToProps, mapDispatchToProps)(HOCComponent);
  };

export default MyPageInjector;

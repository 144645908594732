/* global AM_PL */
/* global _PL */

export default class AceCounter {
  static AMPL(url) {
    return new Promise((resolve, reject) => {
      try {
        console.log('AM_PL log : ' + url);
        AM_PL(url);
        resolve('ok');
      } catch (e) {
        reject('fail');
      }
    });
  }

  static DesktopPL(url) {
    return new Promise((resolve, reject) => {
      try {
        console.log('_PL log : ' + url);
        _PL(url);
        resolve('ok');
      } catch (e) {
        reject('fail');
      }
    });
  }
}

import React, { Component } from 'react';
import LoginInjector from './LoginInjector';
import Storage from '../../library/Storage';

const LoginPageInjector = (WrapperComponent, redirectUrl = '/') => {
  const HOCComponent = class _HOCComponent extends Component {
    render() {
      if (
        typeof window === 'undefined' ||
        (Storage.getToken() !== false && this.props.userInfo.isLogin === false)
      ) {
        return null;
      }

      if (this.props.userInfo.isLogin === false) {
        this.props?.history?.push('/');
        return null;
      } else {
        return <WrapperComponent {...this.props} />;
      }
    }
  };

  return LoginInjector(HOCComponent);
};

export default LoginPageInjector;

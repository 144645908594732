import React, { Component } from 'react';
import PropTypes from 'prop-types';

class OptionalAgreementPop extends Component {
  render() {
    const { content, btnContexts } = this.props;
    const btnCnt = btnContexts.length;

    return (
      <div className={`layer-wrap ${btnCnt === 1 ? 'small' : 'large'}`}>
        {this.props.onClose && (
          <button className="close" onClick={this.props.onClose}></button>
        )}
        <div className="optional-agreement-modal">
          <div>{content}</div>
          <div className="btn-area">
            {btnContexts.map((btnContext, idx) => (
              <button
                key={idx}
                className={`btn-type2 ${btnContext.className}`}
                onClick={btnContext.onClick}
              >
                <span>{btnContext.mainText}</span>
                {btnContext.subText && (
                  <span className="small">{btnContext.subText}</span>
                )}
              </button>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

OptionalAgreementPop.propTypes = {
  onClose: PropTypes.func,
  content: PropTypes.string,
  btnContexts: PropTypes.arrayOf(
    PropTypes.shape({
      mainText: PropTypes.string,
      subText: PropTypes.string,
      className: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
};

export default OptionalAgreementPop;

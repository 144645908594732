import { routes } from '@/constants/routes';

export const HOST = process.env.NEXT_PUBLIC_HOST ?? 'client.danbi.work';

export const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL ?? '';

export const REDIRECT_LEGACY_PAGE_NAME: Record<string, string> = {
  main: routes.home,
  'wink/freestudy': routes.wink.freeStudy,
  'wink/freestudycomplete': routes.wink.freeStudyComplete,
  'wink/freestudynotlogincomplete': routes.wink.freeStudyNotLoginComplete,
  'wink/freestudynotloginfail': routes.wink.freeStudyNotLoginFail,
  'wink/freestudyrightafterjoin': routes.wink.freeStudyRightAfterJoin,
  'wink/paidstudy': routes.wink.paidStudy,
  'wink/paidstudy2': routes.wink.paidStudyStep02,
  'wink/paidstudy3': routes.wink.paidStudyStep03,
  'wink/paidstudycomplete': routes.wink.paidStudyComplete,
  'wink/payment': routes.wink.paidStudyPayment,
  'subject/ko': routes.subject.ko,
  'subject/math': routes.subject.math,
  'subject/en': routes.subject.en,
  'subject/prepare': routes.subject.prepare,
  'subject/choice': routes.subject.choice,
  'member/memberjoinstep01': routes.member.memberJoinStep01,
  'member/memberjoinfinish': routes.member.joinComplete,
  'reference-room': routes.referenceRoom,
  'reference-room/total': routes.referenceRoom,
  'reference-room/kor': `${routes.referenceRoom}?subject=kor`,
  'reference-room/math': `${routes.referenceRoom}?subject=math`,
  'reference-room/eng': `${routes.referenceRoom}?subject=eng`,
  'reference-room/play': `${routes.referenceRoom}?subject=play`,
  'reference-room/hanja': `${routes.referenceRoom}?subject=hanja`,
  'customer/noticelist': routes.customer.notice,
  'customer/report': routes.customer.report,
  'customer/myreport': routes.customer.myReport,
  'customer/myreportdetail': routes.customer.myReport, // postid
  'customer/question': routes.customer.question,
  'customer/event': routes.customer.event,
  'customer/eventdetail': routes.customer.event, // postid
  'customer/download': routes.customer.download,
  'customer/teacher': routes.customer.teacher,
  'customer/promotionTeacher': routes.customer.promotionTeacher,
  'mypage/main': routes.myPage.main,
  'mypage/mypageout': routes.myPage.memberOut,
  'mypage/mypagekidinfo': routes.myPage.kidsInfo,
  'mypage/memberinfo': routes.myPage.memberInfo,
  'mypage/studyinfo': routes.myPage.studyInfo,
  'mypage/paylist': routes.myPage.paymentHistory,
  'mypage/paydetail': routes.myPage.paymentHistory, // contractid
  'mypage/singlepaydetail': routes.myPage.payDetailSingle, // contractid
  'mypage/shippinglist': routes.myPage.shippingHistory,
  'mypage/shippingdetail': routes.myPage.shippingHistory, // deliveryid
  company: routes.company,
  'company/history': routes.company,
};

export const HOST_BY_ENV = {
  client: {
    local: /client\.local\.danbi|\.danbi\.work/,
  },
  server: {
    staging: /\.danbi\.biz/,
    prod: /\.wink\.co\.kr/,
    local: /\.server\.local\.danbi:8080/,
  },
} as const;

export const EXTERNAL_LINK = {
  GROUP_CONSULTATION: 'https://naver.me/xjgfBaVU',
  FREE_STUDY_FORM: 'https://walla.my/survey/ug5TpiVYtsNWTbg02B4W',
};

import { getHost } from '@/utils/miscUtils';

export const INVALID_VERSION_NO = '-.-.-';
export const TIME_ZONE = 'Asia/Seoul';
export const ParentMsg = {
  general: 'parent_general',
};

export const VCallState = {
  CALL_WAIT: 'wait',
  CALLING: 'calling',
  CALL_END: 'called',
  CALL_CANCEL: 'cancel',
  CALL_FAIL: 'fail',
  CALL_REJECT: 'reject',
  MODE_ACTIVE: 'active',
};

export const ChatVCallState = {
  CALL_END: 'called', // 통화 정상적으로 종료
  CALL_FAIL: 'fail', // 통화 연결 실패 ( timeout )
  CALL_REJECT: 'reject', // 통화 거절
  CALL_CANCEL: 'cancel', // 통화 연결자가 취소
};

export const ActorType = {
  Student: 1,
  Parent: 2,
  Teacher: 3,
  Employee: 4,
  JobSeeker: 5,
  ItemPalette: 11,
  ResidenceGroup: 12,
  Family: 13,
  Team: 14,
  ParentStudentPair: 15,
  ClassRoom: 16,
  ParentTeacherPair: 17,
};

export const FileType = {
  SystemSmallFile: 1,
  SystemLargeFile: 2,
  UserSmallFile: 3,
  UserLargeFile: 4,
  UserThumbnailFile: 6,
};

export const PostType = {
  HtmlPost: 1,
  JsonPost: 2,
  TextPost: 3,
  VideoPost: 4,
  ImagePost: 5,
  MyKidsPost: 6,
};

export const CommentType = {
  HTMLComment: 1,
  JsonComment: 2,
  TextComment: 3,
};

export const IssueType = {
  DevPlanIssue: 100,
  DevDFYIssue: 101,
  EmployeeConfirmIssue: 7,
  NewCountedOfflineItemIssue: 1,
  StoreWarehouseIssue: 2,
  PageProgrammingIssue: 3,
  ClientProgrammingIssue: 4,
  ServerProgrammingIssue: 5,
  TechProgrammingIssue: 6,

  //미사용
  CSRRecommendTeacherIssue: 1002,
  CSRAutoRecommendTeacherIssue: 1003,
  CSRExperiencingRecommendTeacherIssue: 1004,

  CSRCommentAccuseIssue: 1005,
  CSRGeneralInquiryIssue: 1006,
  CSRWinkServiceIssue: 1007,
  CSRPeriodicDeliveryIssue: 1008,
  CSRAfterServiceIssue: 1011,
  CSRAddDeliveryIssue: 1013,
  CSRChangeTeacherIssue: 1014,
  CSRPreDeliveryAfterServiceIssue: 1016,
  CSRPayOnArrivalAfterServiceIssue: 1017,
};

export const IssueCompleteType = {
  1002: 'CSRRecommendTeacherConsultWork',
  1003: 'CSRAutoRecommendTeacherReplyWork',
  1004: 'CSRExperiencingRecommendTeacherReplyWork',
  1101: 'CSRFirstLoginWork',
};

export const WorkType = {
  REJECTION: -4,
  NOT_IMPLEMENTED_YET: -3,
  DROP: -2,
  COMPLETE: -1,

  TeacherJobApplicationIssue: 114,
  TeacherJobApplicationWork: 131,

  CSRTeacherRecommendTeacherCenterTeamConfirmWork: 1004,
  CSRAutoRecommendTeacherReplyWork: 1006,
  CSRExperiencingRecommendTeacherReplyWork: 1008,

  /* 학습신청 ( 체험/홈쇼핑/유료 ) */
  CSRWinkServiceRequestWork: 1013,
  /* 학습신청 ( 체험/홈쇼핑/유료 ) */

  /*수준진단 및 정보확인 */
  CSRExperienceInfoReplyWork: 1105,
  CSRExperienceExaminationWork: 1014,
  CSRCreateTemporaryClassRoomWork: 1107,
  CSRTeacherAdjustmentDecisionWork: 1015,
  CSRExperienceHappyCallWork: 1108,
  /*수준진단 및 정보확인 */

  /* 배송 준비 */
  CSRDeliveryPrepareWork: 1016,
  CSRDeliveryInvoiceNumberPrintingWork: 1019,
  CSRDeliveryTransferWork: 1089,
  /* 배송 준비 */

  // 첫로그인 //
  CSRFirstLoginNotifyWork: 1100,
  CSRFirstLoginWork: 1101,
  // 첫로그인 //

  /*  학습중 */
  CSROnServiceWork: 1110,
  CSRCheckServiceWork: 1111,
  CSRWaitStopServiceWork: 1118,
  /* 학습중 */

  /* 학습종료 */
  // Machine 이 알아서 진행
  /* 학습종료 */

  /* 회수 */
  CSRCheckReturnDeliveryWork: 1113,
  /* 회수 */

  // 회원 상담 및 화상학습 스케줄링 */
  CSRProcessNewStudentWork: 1102,

  // 수준 진단 SMS 발송 //
  CSRExperienceInfoRequestWork: 1104,

  // 체험학습 상담
  CSRExperiencePreConsultWork: 1159,
};
//work type 이름
export const WorkTypeName = {
  CSRAvailableWinkServiceCheckWork: 'CSRAvailableWinkServiceCheckWork',
};

export const IsExplorer = process.browser
  ? (navigator.appName === 'Netscape' &&
      navigator.userAgent.search('Trident') !== -1) ||
    navigator.userAgent.toLowerCase().indexOf('msie') !== -1
  : false;

export const ApplyChannel = {
  App: 'APP',
  Pc: 'PC_WEB',
  Mobile: 'M_WEB',
};

export function getFileType() {
  let fileType;
  fileType = FileType.UserSmallFile;

  return fileType;
}

export const ProductionType = {
  Test: 'Test',
  Pre: 'Pre',
  Live: 'Live',
};

export const StorageKey = {
  SESSIONINFO: '__SESSION:USERINFO:' + getHost().toLocaleUpperCase(),
  TUTORIAL: '__TUTORIAL:VER1',
  SAVE_LOCATION: '__LOCATION:SAVE_SEARCH',
  LAST_CHAT_ID: '__CHAT:LASTCHATID',

  // 로그인 관련 //
  AUTH_TOKENNAME: '__MEMBER:AUTH_TOKENNAME:' + getHost().toLocaleUpperCase(),
  STUDENT_ACTORID: '__MEMBER:STUDENT_ACTORID:' + getHost().toLocaleUpperCase(),
  FAMILY_OWNERID: '__MEMBER:FAMILY_OWNERID:' + getHost().toLocaleUpperCase(),
  MAINTAIN_LOGIN: '__MEMBER:MAINTAINLOGIN:' + getHost().toLocaleUpperCase(),
  LOGIN_TYPE: 'MEMBER:MAINTAINLOGIN:' + getHost().toLocaleUpperCase(),
  ID_SAVE: '__MEMBER:ID_SAVE' + getHost().toLocaleUpperCase(),
  PHONE_SAVE: '__MEMBER:PHONE_SAVE' + getHost().toLocaleUpperCase(),

  // 학습기 첫 실행 시, 설정 화면 진행 여부를 저장하기 위한 키
  //INITIAL_SETUP: '__LOCATION:INITIAL_SETUP',
  STUDENTAPP_PAGE_RELOAD: '__STUDENTAPP:PAGERELOAD',
  STUDENTAPP_AUTH_TOKENNAME: '__STUDENTAPP:AUTH_TOKENNAME',
  STUDENTAPP_STUDENT_ACTORID: '__STUDENTAPP:STUDENT_ACTORID',
  STUDENTAPP_FAMILY_OWNERID: '__STUDENTAPP:FAMILY_OWNERID',
  STUDENTAPP_FIRSTLOGIN: (studentActorId) =>
    `__STUDENTAPP:STUDENT_FIRSTLOGIN:${studentActorId}`,
  STUDENTAPP_ATTENDANCE: (studentActorId) =>
    `__STUDENTAPP:STUDENT_ATTENDANCE:${studentActorId}`,
  STUDENTAPP_TODAYSTUDY: (studentActorId) =>
    `__STUDENTAPP:STUDENT_TODAYSTUDY:${studentActorId}`,
  STUDENTAPP_STUDYINFO_POPUP: (studentActorId) =>
    `__STUDENTAPP:STUDENT_STUDYINFO:POPUP:${studentActorId}`,
  STUDENTAPP_REQUEST_STUDY: (studentActorId) =>
    `__STUDENTAPP:STUDENT_REQUEST_STUDY:${studentActorId}`,
  STUDENTAPP_STUDY_LIMIT_TIME: (studentActorId) =>
    `__STUDENTAPP:STUDENT_STUDY_LIMIT_TIME:${studentActorId}`,
  STUDENTAPP_SHOW_WEEKEND: '__STUDENTAPP:SHOW_WEEKEND',
  STUDENTAPP_LAST_STICKERID: (studentActorId) =>
    `__STUDENTAPP:STUDENT_LAST_STICKERID:${studentActorId}`,
  STUDENTAPP_LAST_ALBUMID: (studentActorId) =>
    `__STUDENTAPP:STUDENT_LAST_ALBUMID:${studentActorId}`,
  STUDENTAPP_SEND_MAIL: '__STUDENTAPP:STUDENT_SEND_MAIL',
  STUDENTAPP_SAMPLE_SMS_DATE_FOR_STUDENT: (studentActorId) =>
    `__STUDENTAPP:STUDENT_SAMPLE_SMS_DATE_FOR_STUDENT:${studentActorId}`,

  STUDENTAPP_PREDOWNLOAD: '__STUDENTAPP:STUDENT_PREDOWNLOAD',
  STUDENTAPP_PREDOWNLOAD_LASTDATE: '__STUDENTAPP:STUDENT_PREDOWNLOAD_LASTDATE',
  STUDENTAPP_PATCHINFO: '__STUDENTAPP:STUDENTAPP_PATCHINFO',
  STUDENTAPP_IS_RELOAD: '__STUDENTAPP:STUDENTAPP_IS_RELOAD',

  // 푸시 아이디 저장
  PUSH_IDS: '__PUSH:IDS',

  MOBILEWEB_MAIN_VIDEO_AUTO_PLAY: '__MOBILEWEB_MAIN_VIDEO_AUTO_PLAY',

  // IRDA
  STUDENTAPP_IRDA_POWER: '__STUDENTAPP:STUDENTAPP_IRDA_POWER',
  STUDENTAPP_IRDA_INPUT: '__STUDENTAPP:STUDENTAPP_IRDA_INPUT',

  STUDENTAPP_DEVICE_BRIGHTNESS: '__STUDENTAPP:STUDENTAPP_DEVICE_BRIGHTNESS',

  PARENT_AUTH_ALERT: '__PARENT:AUTH_ALERT',
};

export const RearerType = {
  mom: '엄마',
  dad: '아빠',
  grandmother: '할머니',
  grandfather: '할아버지',
  etc: '기타',
  empty: '없음',
};

export const TalkType = {
  TextTalk: 1,
  VoiceTalk: 2,
  VideoTalk: 3,
  TabletOnlyResponseTalk: 4,
  TabletOnlyRequestTalk: 5,
  AbsenceTalk: 6,
  ComplimentTalk: 7,
  ImageTalk: 8,

  ScheduleAlarmTalk: 9,
  LearningAlarmTalk: 10,
  DeliveryAlarmTalk: 11,

  SystemAlarmTalk: 99,
  RealtimeVoiceTalk: 102,
  RealtimeVideoTalk: 103,
};

export const RealTimeTalkType = {
  complete: 'complete',
  fail: 'fail',
};

export const ScheduleType = {
  NormalSchedule: 1,
  IssueSchedule: 2,
  ItemSchedule: 3,
  HolidaySchedule: 4,
};

export const StatDataType = {
  DailyIssueTrackerStat: 1,
  MonthlyIssueTrackerStat: 2,
  MonthlyActorClientStat: 3,
  DailyTotalStat: 4,
  DailyTotalStockCountStat: 5,
  MonthlyStudentStat: 6,
  DailyStudentStat: 7,
  DailyExperienceMemberListStat: 8,
  DailyRegularMemberListStat: 9,
};

export const ScheduleStatus = {
  Done: 4,
};

export const ModelStatus = {
  Normal: 0,
  Removed: 1,
  Hided: 2,
  Sleeping: 3,
  Closed: 4,
  Standby: 5,
  InProgress: 6,
  ForcePublic: 7,
  StudyStarted: 8,
  StudyCompleted: 9,
  DeliveryRequested: 11,
  DeliveryReceipt: 12,
  DeliveryCompleted: 13,
  ReturnRequested: 14,
  ReturnReceipt: 15,
  DeliveryVerified: 16,
  Accused: 17,
  PaymentStart: 20,
  PaymentAborted: 21,
  PaymentPending: 22,
  PaymentCanceled: 23,
  PaymentError: 24,
  PaymentComplete: 25,
  PaymentEnded: 26,
  ReturnDeliveryCompleted: 27,
  PaymentPaused: 28,
  RepairNecessary: 29,

  Failed: 99,
  준회원: 100,
  Reserved6: 101,
  미체험회원: 103,
  체험거부회원: 106,
  체험회원: 110,
  체험대기회원: 113,
  체험중회원: 116,
  정회원: 120,
  유료회원: 123,
  휴지회원: 126,
  '무료(무결제)회원': 128,
  종료회원: 130,
  체험학습종료: 133,
  유료학습종료: 136,
  중도해지회원: 138,
};

export const ContractType = {
  FreeContract: 2,
  ExperienceContract: 3,
  CashPaymentContract: 100,
};

export const MembershipName = {
  삭제회원: '삭제회원',
  탈퇴회원: '탈퇴회원',
  휴면회원: '휴면회원',
  준회원: '준회원',
  체험회원: '체험회원',
  정회원: '정회원',
  종료회원: '종료회원',
};

export const MembershipDetailName = {
  삭제회원: '삭제회원',
  탈퇴회원: '탈퇴회원',
  휴면회원: '휴면회원',
  준회원: '준회원',
  체험회원: '체험회원',
  정회원: '정회원',
  종료회원: '종료회원',
  미체험회원: '미체험회원',
  체험거부회원: '체험거부회원',
  체험대기회원: '체험대기회원',
  체험중회원: '체험중회원',
  유료회원: '유료회원',
  체험학습종료: '체험학습종료',
  유료학습종료: '유료학습종료',
  중도해지회원: '중도해지회원',
};

export const ReverseModelStatus = {
  0: 'Normal',
  1: 'Removed',
  2: 'Hided',
  3: 'Sleeping',
  4: 'Closed',
  5: 'Standby',
  6: 'Reserved7',
  7: 'ForcePublic',
  8: 'Reserved4',
  9: 'Reserved5',
  11: 'DeliveryRequested',
  12: 'DeliveryReceipt',
  13: 'DeliveryCompleted',
  14: 'ReturnRequested',
  15: 'ReturnReceipt',
  16: 'DeliveryVerified',
  17: 'Accused',
  20: 'PaymentStart',
  21: 'PaymentAborted',
  22: 'PaymentPending',
  23: 'PaymentCanceled',
  24: 'PaymentError',
  25: 'PaymentComplete',
  100: '준회원',
  101: 'Reserved6',
  103: '미체험회원',
  106: '체험거부회원',
  110: '체험회원',
  113: '체험대기회원',
  116: '체험중회원',
  120: '정회원',
  123: '유료회원',
  126: '휴지회원',
  128: '무료(무결제)회원',
  130: '종료회원',
  133: '체험학습종료',
  136: '유료학습종료',
  138: '중도해지회원',
};

export const PayType = {
  Credit: 1,
  Transfer: 2,
  VirtualAccount: 3,
};

export const PaymentPGCode = {
  LGUPlus: 1,
};

export const PaymentPlatform = {
  PC: 'pc',
  Mobile: 'mobile',
};

export const PaymentMobileOSType = {
  Android: 'A',
  IOS: 'N',
};

export function getMobileOsType() {
  if (navigator.userAgent.match(/Android/i)) {
    return PaymentMobileOSType.Android;
  } else if (navigator.userAgent.match(/iPhone/i)) {
    return PaymentMobileOSType.IOS;
  } else if (navigator.userAgent.match(/iPad/i)) {
    return PaymentMobileOSType.IOS;
  }
  return false;
}

export function getIOSVersion() {
  let version;
  if (/(iPhone|iPod|iPad)/i.test(navigator.userAgent)) {
    version = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
  }
  return version;
}

//ios 11이상
export function isIOS11OrLater() {
  const versionList = getIOSVersion();
  if (parseInt(versionList[1], 10) < 11) {
    return false;
  }
  return true;
}

export const IosTypeNoticeText = 'iOS에서 영상통화는 추후 지원예정입니다.';
export const isIOS11OrLaterNoticeText =
  'ios10이하 버전에서는 지원되지 않습니다.';

export const Standard = {
  MaxAge: 7,
  MinAge: 4,
};

// 추후 삭제예정
export const StandardAge = 7;
export const SubjectList = [
  '종합반',
  '한글・수학',
  '영어',
  '엄마표한글・수학',
  '엄마표종합반',
  '엄마표영어',
];
export const SubjectText = {
  OverAgeKor: '국어',
  UnderAgeKor: '한글',
  OverKorMath: '국어・수학',
  UnderKorMath: '한글・수학',
  Eng: '영어',
  Math: '수학',
};

export const newSubjectText = {
  KorMath: '한글・수학',
  Eng: '영어',
};

export const ProductName = {
  종합반: '종합반',
  국어수학: '국어・수학',
  영어: '영어',
};

export const SubjectKorTitleByAge = {
  3: SubjectText.UnderAgeKor,
  4: SubjectText.UnderAgeKor,
  5: SubjectText.UnderAgeKor,
  6: SubjectText.UnderAgeKor,
  7: SubjectText.OverAgeKor,
  8: SubjectText.OverAgeKor,
  9: SubjectText.OverAgeKor,
  10: SubjectText.OverAgeKor,
};

export const SubjectKorNMathTitleByAge = {
  3: SubjectText.UnderKorMath,
  4: SubjectText.UnderKorMath,
  5: SubjectText.UnderKorMath,
  6: SubjectText.UnderKorMath,
  7: SubjectText.OverKorMath,
  8: SubjectText.OverKorMath,
  9: SubjectText.OverKorMath,
  10: SubjectText.OverKorMath,
};

export const MaleCode = {
  Boy: true,
  Girl: false,
};

export const MaleName = {
  [MaleCode.Boy]: '남자 아이',
  [MaleCode.Girl]: '여자 아이',
};

export const MaleDefaultImg = {
  [MaleCode.Boy]:
    'https://s.wink.co.kr/app/parents/images/img_character_boy.png',
  [MaleCode.Girl]:
    'https://s.wink.co.kr/app/parents/images/img_character_girl.png',
};

export const ParentCategoryImg = {
  [RearerType.mom]: 'https://s.wink.co.kr/pc/images/mypage/icon_family_on.png',
  [RearerType.dad]: 'https://s.wink.co.kr/pc/images/mypage/icon_family2_on.png',
  [RearerType.grandmother]:
    'https://s.wink.co.kr/pc/images/mypage/icon_family3_on.png',
  [RearerType.grandfather]:
    'https://s.wink.co.kr/pc/images/mypage/icon_family4_on.png',
  기타: 'https://s.wink.co.kr/pc/images/mypage/icon_family5_on.png',
};

//학생앱 셀렉트박스 부분 디폴트 이미지
export const MailFaceImage = {
  [MaleCode.Boy]: 'https://s.wink.co.kr/app/student/images/main/thum_face2.jpg',
  [MaleCode.Girl]:
    'https://s.wink.co.kr/app/student/images/main/thum_face1.jpg',
};

//학생 수강 과목 11110000 > 국어/수학/스토리영어/파닉스영어 | 2년약정/1년약정/예비/예비
export const StudentSubject = {
  StudentSubjectTotal: 240, //1111 | 0000
  StudentSubjectKorMath: 192, //1100 | 0000
  StudentSubjectEng: 48, //0011 | 0000
  StudentSubjectNone: 0, //0000 | 0000

  //현재기준 위만 사용
  STUDENT_SUBJECT_KOR: 128, //10000000
  STUDENT_SUBJECT_MATH: 64, //01000000
  STUDENT_SUBJECT_ENG_STORY: 32, //00100000
  STUDENT_SUBJECT_ENG_PHONICS: 16, //00010000
};

export const ShopProductSubjectList = [
  '종합반',
  '한글・수학',
  '영어',
  '엄마표종합반',
  '엄마표한글・수학',
  '엄마표영어',
];

export const ShopProductItemName = {
  // 종합반무약정: '종합반무약정',
  종합반2년약정: '종합반2년약정',
  종합반1년약정: '종합반1년약정',
  단과반한글수학1년학습: '단과반한글수학1년학습',
  단과반한글수학2년학습: '단과반한글수학2년학습',
  단과반영어1년학습: '단과반영어1년학습',
  단과반영어2년학습: '단과반영어2년학습',
  엄마표종합반2년학습: '엄마표종합반2년학습',
  엄마표종합반1년학습: '엄마표종합반1년학습',
  엄마표2단과1년학습: '엄마표2단과1년학습',
  엄마표2단과2년학습: '엄마표2단과2년학습',
  엄마표1단과1년학습: '엄마표1단과1년학습',
  엄마표1단과2년학습: '엄마표1단과2년학습',
};

export const ShopProductCategoryName = {
  윙크학습: '윙크학습',
  엄마표윙크학습: '엄마표윙크학습',
};

export const ApplyTypeFrom = {
  ExpAdd: 'EXP_ADD',
  PaidAdd: 'PAID_ADD',
};

export const PaymentMode = {
  new: 'new', //신규
  add: 'add', //계약추가
  change: 'change', //상품 즉시 변경
  maintain: 'maintain', //계약 갱신
  reservation: 'reservation', //상품 변경 예약
};

export const SubjectCodeToLabel = {
  240: '[종합반]',
  192: '[단과반] 한글(국어) • 수학',
  48: '[단과반] 스토리영어 / 파닉스',
};

export const ItemType = {
  StudentDevice: 1,
  StudentDeviceAccessory: 2,
  WinkPhone: 3,
  EducationMaterial: 4,
  RewardSticker: 5,
  Coupon: 6,
  HeartSticker: 101,
  SunSticker: 102,
  EarthSticker: 103,
  RainSticker: 104,
  Resource: 1001,
  Content: 2001,
};

export const ReverseItemType = {
  1: 'StudentDevice',
  2: 'StudentDeviceAccessory',
  3: 'WinkPhone',
  4: 'EducationMaterial',
  5: 'RewardSticker',
  101: 'HeartSticker',
  102: 'SunSticker',
  103: 'EarthSticker',
  104: 'RainSticker',
  1001: 'Resource',
  2001: 'Content',
};

export const InventoryType = {
  TraceableOfflineInventory: 1,
  StackableOfflineInventory: 2,
  StickerInventory: 100,
  ResourceInventory: 1000,
  ContentInventory: 2000,
};

export const ScheduleItemLabels = {
  초등준비: '초등',
  오늘의이야기: '독서',
};

export const ItemTopCategoryTagName = {
  선생님만나는날: '선생님만나는날',
  오늘의공부: '오늘의공부',
  오늘의이야기: '오늘의이야기',
  신나는주말: '신나는주말',
  선생님추천: '선생님추천',
  초등준비: '초등준비',
  학교공부: '학교공부',
  놀이부가콘텐츠: '놀이부가콘텐츠',
};

export const ItemMiddleCategoryTagName = {
  [ItemTopCategoryTagName.오늘의공부]: {
    한글: '한글',
    수학: '수학',
    스토리영어: '스토리영어',
    파닉스: '파닉스',
  },
  [ItemTopCategoryTagName.초등준비]: {
    개념국어: '개념국어',
    개념수학: '개념수학',
  },
  [ItemTopCategoryTagName.학교공부]: {
    교과국어: '교과국어',
    교과수학: '교과수학',
  },
  [ItemTopCategoryTagName.놀이부가콘텐츠]: {
    동화: [
      '생활・인성',
      '전래',
      '명작',
      '수학',
      '과학',
      '한글익히기',
      '교과연계',
      '윙크영어동화',
    ],
    동요: ['한글동요', '율동동요', '윙크동요', '영어동요', '클래식'],
    영어애니: ['MaxAndRuby', 'BenjaminBear', 'TruckTown', 'Caillou'],
    테마학습: [
      '자연・과학',
      '미술・요리',
      '한글이야호',
      '수학이야호',
      '속담이야호',
      '한자',
      '위인극장',
      '학교에가면',
    ],
    윙크학습놀이터: ['윙크한글놀이', '윙크수학놀이', '윙크영어놀이'],
    신나는주말: ['장금이의꿈', 'CSI키즈수사대', '라임아트'],
  },
};

export const StickerItemTypeName = {
  HeartSticker: 'HeartSticker',
  SunSticker: 'SunSticker',
  EarthSticker: 'EarthSticker',
  RainSticker: 'RainSticker',
};

export const StickerObjectName = {
  한글: '한글',
  국어: '국어',
  수학: '수학',
  스토리영어: '영어',
  파닉스: '영어',
  영어: '영어',
  초등준비: '초등준비',
  학교공부: '학교공부',
  오늘의이야기: '오늘의이야기',
  출석: '출석',
  선생님선물: '선생님선물',
  오늘의공부: '오늘의공부',
};

export const StickerItemTypeNameByStickerObjectName = {
  [StickerObjectName.한글]: StickerItemTypeName.SunSticker,
  [StickerObjectName.국어]: StickerItemTypeName.SunSticker,
  [StickerObjectName.수학]: StickerItemTypeName.EarthSticker,
  [StickerObjectName.영어]: StickerItemTypeName.RainSticker,
  [StickerObjectName.초등준비]: StickerItemTypeName.HeartSticker,
  [StickerObjectName.학교공부]: StickerItemTypeName.HeartSticker,
  [StickerObjectName.오늘의이야기]: StickerItemTypeName.HeartSticker,
  [StickerObjectName.출석]: StickerItemTypeName.HeartSticker,
  [StickerObjectName.선생님선물]: StickerItemTypeName.HeartSticker,

  // 영어가 2개로 나눠져서 이렇게 사용 함 //
  // 예외 처리 //
  스토리영어: StickerItemTypeName.RainSticker,
  파닉스: StickerItemTypeName.RainSticker,
};

export const DanbiCharacterName = {
  1: '다래',
  2: '불라불라',
  3: '단비',
  4: '수피',
  5: '철로',
  6: '땅이',
  7: '해태',
  8: '보석함',
};

export const WeekNameForSprite = {
  1: '월요일',
  2: '화요일',
  3: '수요일',
  4: '목요일',
  5: '금요일',
  6: '토요일',
  7: '일요일',
  8: '보석함',
};

export const StickerItemName = {
  [StickerObjectName.국어]: '번뜩이 스티커',
  [StickerObjectName.한글]: '번뜩이 스티커',
  [StickerObjectName.수학]: '덩이 스티커',
  [StickerObjectName.영어]: '뿌우 스티커',
  [StickerObjectName.초등준비]: '하트 스티커',
  [StickerObjectName.학교공부]: '하트 스티커',
  [StickerObjectName.오늘의이야기]: '하트 스티커',
};

export const MapPieceTitle = {
  1: '첫번째',
  2: '두번째',
  3: '세번째',
  4: '네번째',
  5: '다섯번째',
  6: '여섯번째',
  7: '일곱번째',
};

export const PushType = {
  PUSH_NORMAL: 1000,
};

export const PaymentProduct = {
  종합반: 1655,
  영어: 1653,
  한글수학: 1651,
};

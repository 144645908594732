import { ActionTypes as types } from './MemberAction';
import simpleDotProp from '../../library/simpleDotProp';

const initialState = {
  action: '',
  carrier: '',
  message: '',
  auth: {},
  actor: {},
  //가족 정보
  family: {},
  //모든 자녀
  studentList: [],
  //선택한 자녀의 디테일 정보
  studentInfo: {},
  loginProcess: false,
  noIdPopupFlag: false,
};

export default function MemberReducer(state = initialState, action) {
  switch (action.type) {
    case types.PROCESS_LOGIN:
      return simpleDotProp.set(state, {
        loginProcess: true,
      });
    case types.LOGIN:
      return simpleDotProp.set(state, {
        action: action.payload.action,
        auth: action.payload.auth,
        actor: action.payload.actor,
        loginProcess: false,
      });
    case types.REGISTER:
      return simpleDotProp.set(state, {
        action: action.payload.action,
        message: action.payload.message,
      });
    case types.FAIL_REGISTER:
      return simpleDotProp.set(state, {
        action: action.payload.action,
        message: action.payload.message,
      });
    case types.MODIFY_AUTHS:
      return simpleDotProp.set(state, {
        action: action.payload.action,
        auth: action.payload.auth,
      });
    case types.ALL_FAMILY:
      return simpleDotProp.set(state, {
        action: action.payload.action,
        family: action.payload.family,
        studentList: action.payload.studentList,
        studentInfo: action.payload.studentInfo,
      });
    case types.FAMILY:
      return simpleDotProp.set(state, {
        action: action.payload.action,
        family: action.payload.family,
        studentList: [],
        studentInfo: {},
      });
    case types.STUDENT:
      return simpleDotProp.set(state, {
        action: action.payload.action,
        studentInfo: action.payload.studentInfo,
      });
    case types.SET_STUDENT_MEMBER:
      return simpleDotProp.set(state, {
        action: action.payload.action,
        studentList: action.payload.studentList,
      });
    case types.GET_STUDENT_MEMBER:
      return simpleDotProp.set(state, {
        studentList: action.payload.studentList,
        studentInfo: action.payload.studentInfo,
      });
    case types.CLEAR_ACTION:
      return simpleDotProp.set(state, {
        action: '',
        carrier: '',
        loginProcess: false,
      });
    case types.SELECTED_STUDENT:
      return simpleDotProp.set(state, {
        studentInfo: action.payload.studentInfo,
      });
    case types.MODIFY_ACTION:
    case types.SUCCESS_ONETIME_PASSWORD:
    case types.SUCCESS_ADD_USER:
    case types.SUCCESS_ID_DUPLICATE_CHECK:
    case types.SUCCESS_PHONE_DUPLICATE_CHECK:
    case types.FAIL_CHECK_ONETIME_PASSWORD:
    case types.SUCCESS_SET_STUDENT_MEMBER_AND_LOGIN_SECONDARY:
    case types.FAIL_RECOMMENDER:
    case types.SUCCESS_MODIFY_PARENT_CATEGORY_TAG:
    case types.CHANGE_AUTH:
    case types.FAIL_LOGIN:
      return simpleDotProp.set(state, {
        action: action.payload.action,
        loginProcess: false,
      });
    case types.MDN_TOKEN:
      return simpleDotProp.set(state, {
        action: action.payload.action,
        carrier: action.payload.carrier,
      });
    case types.SET_NOID_POPUPFLAG:
      return simpleDotProp.set(state, {
        noIdPopupFlag: action.payload.noIdPopupFlag,
      });
    case types.LOGOUT:
      return simpleDotProp.set(state, {
        ...initialState,
        action: action.payload.action,
        loginProcess: false,
      });
    case types.EXCEPTION:
      return simpleDotProp.set(state, {
        action: action.payload.action,
        loginProcess: false,
      });
    default:
      return state;
  }
}

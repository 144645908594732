import Script from 'next/script';

const KakaoDaum = () => (
  <>
    <Script
      id={'kakaoPixel'}
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: `
          if (typeof kakaoPixel === 'function') {
            kakaoPixel('6818168787396902769').pageView();
          }
        `,
      }}
    ></Script>
    <Script
      type="text/javascript"
      id={'daum'}
      dangerouslySetInnerHTML={{
        __html: `
          //<![CDATA[
        var DaumConversionDctSv="type=W,orderID=,amount=";
        var DaumConversionAccountID="YY4T-UV-bzoU7COiBgT99A00";
        //]]>
        `,
      }}
    ></Script>
    <Script
      type="text/javascript"
      id={'kako'}
      dangerouslySetInnerHTML={{
        __html: `
          if(window.Kakao) {
      Kakao.init('f90ebd57e0eec6f05ffa382e34c97a21');
    }
        `,
      }}
    ></Script>
  </>
);

export default KakaoDaum;

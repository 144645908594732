import moment from 'moment';
import {
  INITIAL_LEVELS,
  HELLO_LEVEL,
  CHECKLIST_STUDY_NAME,
} from '../../WinkConstants';
import isEmpty from 'lodash/isEmpty';
import { LEVEL_CHOICE, LEVEL_CHOICE_MANUALLY } from './values';
import { GaUtils, GaValues } from '../../../../utils/gaservices';

export const getCurrentAgeLevels = (birthday) => {
  if (!birthday) {
    return 'NORMAL';
  }

  const thirtyNineMonthsAgo = moment().subtract(39, 'month');
  const thirtyMonthsAgo = moment().subtract(30, 'month');

  if (
    moment(birthday).isBetween(
      thirtyNineMonthsAgo,
      thirtyMonthsAgo,
      'day',
      '(]'
    )
  ) {
    return 'HELLO';
  } else {
    return 'NORMAL';
  }
};

export const getCurrentLevels = (birthday) => {
  const todayMonth = moment().month();

  const monthAge = moment().diff(moment(birthday), 'month');
  const age = birthday ? moment().year() - moment(birthday).year() + 1 : 4;

  let levelKey;
  if (monthAge <= 38) {
    return HELLO_LEVEL;
  } else {
    levelKey = age >= 9 ? 9 : age;
  }

  if (todayMonth < 6) {
    return INITIAL_LEVELS.firstHalf[levelKey];
  } else {
    return INITIAL_LEVELS.secondHalf[levelKey];
  }
};

export const getTemporaryInformationLevelParams = (checkList) => {
  if (checkList.마음동화) {
    return {
      h_k_level: CHECKLIST_STUDY_NAME[checkList.안녕한글].level,
      h_m_level: CHECKLIST_STUDY_NAME[checkList.안녕수학].level,
      h_e_level: CHECKLIST_STUDY_NAME[checkList.Hello_English].level,
      m_s_level: CHECKLIST_STUDY_NAME[checkList.마음동화].level,
    };
  }

  return {
    k_level: CHECKLIST_STUDY_NAME[checkList.한글].level,
    m_level: CHECKLIST_STUDY_NAME[checkList.수학].level,
    e_s_level: CHECKLIST_STUDY_NAME[checkList.스토리영어].level,
    e_p_level: CHECKLIST_STUDY_NAME[checkList.파닉스].level,
    k_experience_level: CHECKLIST_STUDY_NAME[checkList.한글].experienceLevel,
    m_experience_level: CHECKLIST_STUDY_NAME[checkList.수학].experienceLevel,
    e_s_experience_level:
      CHECKLIST_STUDY_NAME[checkList.스토리영어].experienceLevel,
    e_p_experience_level:
      CHECKLIST_STUDY_NAME[checkList.파닉스].experienceLevel,
  };
};

export const getMappedCheckList = (todayStudyMap, checkList) => {
  if (!checkList || isEmpty(checkList)) {
    return undefined;
  }

  if (checkList.마음동화) {
    return {
      H_K_content: todayStudyMap[CHECKLIST_STUDY_NAME[checkList.안녕한글].name],
      H_M_content: todayStudyMap[CHECKLIST_STUDY_NAME[checkList.안녕수학].name],
      H_E_content:
        todayStudyMap[CHECKLIST_STUDY_NAME[checkList.Hello_English].name],
      M_S_content: todayStudyMap[CHECKLIST_STUDY_NAME[checkList.마음동화].name],
    };
  }

  return {
    K_content: todayStudyMap[CHECKLIST_STUDY_NAME[checkList.한글].name],
    M_content: todayStudyMap[CHECKLIST_STUDY_NAME[checkList.수학].name],
    E_S_content: todayStudyMap[CHECKLIST_STUDY_NAME[checkList.스토리영어].name],
    E_P_content: todayStudyMap[CHECKLIST_STUDY_NAME[checkList.파닉스].name],
  };
};

export const getCurrentCheckList = (currentLevel) => {
  return Object.keys(currentLevel).reduce((acc, key) => {
    acc[key] = CHECKLIST_STUDY_NAME[currentLevel[key]].level;
    return acc;
  }, {});
};

export const isCheckedLevel = (checkList) => {
  if (!checkList) {
    return false;
  }

  return (
    (checkList.한글 !== undefined &&
      checkList.수학 !== undefined &&
      checkList.스토리영어 !== undefined &&
      checkList.파닉스 !== undefined) ||
    (checkList.안녕한글 !== undefined &&
      checkList.안녕수학 !== undefined &&
      checkList.Hello_English !== undefined &&
      checkList.마음동화 !== undefined)
  );
};

export const getDefaultStudentInfo = () => {
  const currentLevel = getCurrentLevels();

  return {
    kid_name: '',
    kid_gender: 'male',
    kid_birth_year: null,
    kid_birth_month: null,
    kid_birth_day: null,
    checkList: currentLevel,
  };
};

export const sendLevelChoiceGaEvent = (studentList, levelChoices) => {
  if (!studentList || studentList.length === 0) {
    return;
  }

  for (let idx = 0; idx < studentList.length; idx++) {
    const currentLevelChoices = levelChoices[idx];
    const clickName =
      currentLevelChoices.levelChoice === LEVEL_CHOICE.beginner
        ? GaValues.CLICK_NAMES.FREE_STUDY_LEVEL_RECOMMEND
        : currentLevelChoices.manuallyLevelChoice ===
          LEVEL_CHOICE_MANUALLY.hello
        ? GaValues.CLICK_NAMES.FREE_STUDY_LEVEL_MANUALLY_HELLO
        : GaValues.CLICK_NAMES.FREE_STUDY_LEVEL_MANUALLY_WINK;
    GaUtils.event(GaValues.ACTION.APPLY_FREE_STUDY, {
      event_name: GaValues.EVENT_NAMES.APPLY_FREE_STUDY,
      event_category: GaValues.EVENT_CATEGORIES.FREE_STUDY_LEVEL_CHOICE,
      click_name: clickName,
    });
  }
};

import APICaller from '../APICaller';
import { WorkType } from '../constants/Code';
import Async from '../Async';

export default class IssueTrackerServices {
  static getIssueTypes() {
    const url = '/issue/issue_types/';

    return APICaller.getCache(url);
  }

  static getDevIssue() {
    const url = '/issue/issue_types/?is_dev_issue=true';

    return APICaller.getCache(url);
  }

  static getWorkTypes() {
    const url = '/issue/work_types/';

    return APICaller.getCache(url);
  }

  static getDevWork() {
    const url = '/issue/work_types/?is_dev_work=true';

    return APICaller.getCache(url);
  }

  static getInquiryIssueTypeFirst() {
    const url = '/issue/issue_types/CSRGeneralInquiryIssue/';

    return APICaller.get(url);
  }

  static getInquiryIssueTypeSecond(category_tag_name) {
    const url = `/aux/tag_types/IssueCategoryTag/get_named/?name=${encodeURIComponent(
      category_tag_name
    )}`;

    return APICaller.get(url);
  }

  static getWorkTypeInfoById(id) {
    return this.getWorkTypes().then((response) => {
      for (const item of response.data.results) {
        if (item.id === id) return item;
      }
    });
  }

  static getWorkTypeInfoByName(name) {
    return this.getWorkTypes().then((response) => {
      for (const item of response.data.results) {
        if (item.name === name) return item;
      }
    });
  }

  static getIssueTypeInfoById(id) {
    return this.getIssueTypes().then((response) => {
      for (const item of response.data.results) {
        if (item.id === id) return item;
      }
    });
  }

  static addIssue(issueInfo) {
    const url = '/issue/issues/';

    return APICaller.post(url, issueInfo);
  }

  static getCompleteWorkListByIssueId(issueId) {
    const url = `/issue/issues/${issueId}/works/`;
    const params = {
      is_complete: true,
    };

    return APICaller.get(url, params);
  }

  static getWorkList(id, type, workTypeId = null, offset = 0, limit = 2) {
    const url = `/account/actors/${id}/${type}/`;
    let params = { offset, limit };
    if (type !== null) {
      params.model_type = workTypeId;
    }

    return APICaller.get(url, params);
  }

  static getWorkInfo(workId) {
    const url = `/issue/works/${workId}/`;

    return APICaller.get(url);
  }

  static getIssueInfo(issueId) {
    const url = `/issue/issues/${issueId}/`;

    return APICaller.get(url);
  }

  static getDetail(workId) {
    return Async(function* (workId) {
      const workInfo = yield IssueTrackerServices.getWorkInfo(workId);
      const issueInfo = yield IssueTrackerServices.getIssueInfo(
        workInfo.data.issue
      );

      return {
        workInfo: workInfo,
        issueInfo: issueInfo,
      };
    }, workId);
  }

  static getDetailByIssueId(issueId) {
    return Async(function* (issueId) {
      const issueInfo = yield IssueTrackerServices.getIssueInfo(issueId);
      const workInfo = yield IssueTrackerServices.getWorkInfo(
        issueInfo.data.current_work
      );

      return {
        workInfo: workInfo,
        issueInfo: issueInfo,
      };
    }, issueId);
  }

  static getSplitWorkList(workId) {
    const url = `/issue/works/${workId}/splits/`;

    return APICaller.get(url);
  }

  static addSplitWork(sourceWorkId, collectIssueInfo) {
    const url = `/issue/works/${sourceWorkId}/split/`;

    return APICaller.post(url, collectIssueInfo);
  }

  static takeWork(workId) {
    const url = `/issue/works/${workId}/take/`;

    return APICaller.post(url);
  }

  static assignWork(workId, actorId) {
    const url = `/issue/works/${workId}/assign/`;
    let params = {
      worker: actorId,
    };

    return APICaller.post(url, params);
  }

  static completeWork(workId, route_to, data) {
    const url = `/issue/works/${workId}/complete/`;
    let params = {
      route_to: route_to,
    };

    if (data) {
      params.data = data;
    }

    return APICaller.post(url, params);
  }

  static setWork(workId, collectWorkInfo) {
    const url = `/issue/works/${workId}/modify/`;

    return APICaller.post(url, collectWorkInfo);
  }

  static setIssue(issueId, collectIssueInfo) {
    const url = `/issue/issues/${issueId}/modify/`;

    return APICaller.post(url, collectIssueInfo);
  }

  static getWorkFileList(workId, propertyName) {
    const url = `/issue/works/${workId}/${encodeURIComponent(propertyName)}/`;

    return APICaller.get(url);
  }

  static getIssueFileList(issueId, propertyName) {
    const url = `/issue/issues/${issueId}/${encodeURIComponent(propertyName)}/`;

    return APICaller.get(url);
  }

  static getIssueMemberList(issueId) {
    const url = `/issue/issues/${issueId}/members/`;

    return APICaller.get(url);
  }

  static getIssueList(
    offset,
    limit,
    issueTypeId,
    workTypeId,
    categoryTagId,
    actorId,
    searchValue,
    created_date,
    is_dev_issue
  ) {
    const url = '/issue/issues/';
    let params = {
      offset,
      limit,
    };

    if (issueTypeId) {
      params['model_type'] = issueTypeId;
    }

    if (workTypeId) {
      params['current_work_type'] = workTypeId;
    }

    if (categoryTagId) {
      params['category_tag'] = categoryTagId;
    }

    if (actorId) {
      params['actor'] = actorId;
    }

    if (searchValue) {
      params['search'] = searchValue;
    }

    if (created_date) {
      params['created_date'] = created_date;
    }

    if (is_dev_issue) {
      params['is_dev_issue'] = is_dev_issue;
    }

    return APICaller.get(url, params);
  }

  static sendEmail(actorId, title, text) {
    const url = `/account/actors/${actorId}/send_email_to_representative/`;
    let params = {
      title,
      html: text,
    };

    return APICaller.post(url, params);
  }

  static sendEmailWithoutLoginQna(title, html, sender) {
    const url = '/account/auths/send_email_to_wink/';
    let params = {
      title: title,
      html: html,
      sender: sender,
    };

    return APICaller.post(url, params);
  }

  static getIssueCategoryGroupId(category_tag_name) {
    const url = '/aux/tag_types/IssueCategoryTag/get_named/';
    const params = {
      name: category_tag_name,
    };

    return APICaller.get(url, params);
  }

  static getIssueCategoryMemberList(id) {
    const url = `/aux/tags/${id}/members/`;

    return APICaller.get(url);
  }

  static getMyQnaList(actorId, offset, limit) {
    const url = `/issue/issues/?actor=${actorId}&offset=${offset}&model_type=1006&limit=${limit}&order_by=-dpo,-id`;
    return APICaller.get(url);
  }

  static getTag(id) {
    const url = `/aux/tags/${id}/`;
    return APICaller.get(url);
  }

  static getStudentFirstLogin(studentActorId) {
    const url = `/account/actors/${studentActorId}/works/?model_type=${WorkType.CSRFirstLoginNotifyWork}&is_complete=false&limit=1`;

    return APICaller.get(url);
  }
  static getMyReportList(actorId, params) {
    const url = `/issue/issues/?actor=${actorId}&model_type=1006&order_by=-dpo,-id`;
    return APICaller.get(url, params);
  }

  static getIssuesByItemList(idList) {
    const url = `/issue/issues/?id__in=${idList}`;

    return APICaller.get(url);
  }

  static getDeployList(offset, limit, startDate, endDate) {
    const url = `/issue/works/?model_type=106&created_date=${startDate},${endDate}&order_by=id`;

    let params = {
      offset,
      limit,
    };

    return APICaller.get(url, params);
  }
}

import APICaller from '../../../library/APICaller';
import UserServices from '../../../library/services/UserServices';
import * as ExceptionAction from '../../../library/error/ExceptionAction';

let TAG = 'mypage';
export const ActionTypes = Object.freeze({
  CLEAR_ACTION: `${TAG}/CLEAR_ACTION`,
  GET_COUPON_LIST: `${TAG}/GET_COUPON_LIST`,
  ADD_COUPON: `${TAG}/ADD_COUPON`,
  FAIL_ADD_COUPON: `${TAG}/FAIL_ADD_COUPON`,
});

export function clearAction() {
  return {
    type: ActionTypes.CLEAR_ACTION,
  };
}

// server_test : tests_for_1133_coupon_list.py - CouponListTest.test_retrieve_coupon_list_for_admin
export function getCoupon(parentId) {
  return (dispatch, getState) =>
    dispatch(() => {
      return UserServices.getInventoriesInfo(parentId)
        .then((inventoriesInfo) => {
          let arrApis = [];
          let results = inventoriesInfo.data.results;
          arrApis = results.map((coupons) => {
            return UserServices.getCoupon(coupons.item);
          });
          return APICaller.all(...arrApis)
            .then((arrApisResponse) => {
              let couponList = [];
              if (arrApisResponse !== 0) {
                Object.keys(arrApisResponse).forEach((index) => {
                  couponList.push(arrApisResponse[index].data);
                });
              }
              return dispatch({
                type: ActionTypes.GET_COUPON_LIST,
                payload: {
                  couponInventories: inventoriesInfo.data.results,
                  couponList,
                },
              });
            })
            .catch((e) => {
              return dispatch(
                ExceptionAction.exception(
                  e,
                  '쿠폰 조회 중 오류가 발생하였습니다.'
                )
              );
            });
        })
        .catch((e) => {
          return dispatch(
            ExceptionAction.exception(
              e,
              '인벤토리정보 및 쿠폰 조회 중 오류가 발생하였습니다.'
            )
          );
        });
    });
}

// server_test : tests_for_1133_coupon_list.py - CouponListTest.test_register_coupon
export function addNewCoupon(parentId, couponName) {
  return (dispatch, getState) =>
    dispatch(() => {
      return UserServices.addCoupon(parentId, couponName)
        .then(() => {
          return dispatch({
            type: ActionTypes.ADD_COUPON,
          });
        })
        .catch((e) => {
          return dispatch({
            type: ActionTypes.FAIL_ADD_COUPON,
          });
        });
    });
}

// server_test : tests_for_8370_public_coupon.py - PublicCouponProcessTest.test_public_coupon_process
export function regCoupon(parentId, couponCode) {
  return (dispatch, getState) =>
    dispatch(() => {
      return UserServices.regCoupon(parentId, couponCode)
        .then(() => {
          return dispatch({
            type: ActionTypes.ADD_COUPON,
          });
        })
        .catch((e) => {
          return dispatch({
            type: ActionTypes.FAIL_ADD_COUPON,
          });
        });
    });
}

import React, { Component } from 'react';
import LayerPopup from '../../../../library/layerpopup';

class ConfirmModal extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick(e, type) {
    e.preventDefault();
    let { callback, param } = this.props;
    let resultCallback = false;
    if (callback) {
      resultCallback = callback(type, param);
      if (resultCallback === undefined) {
        resultCallback = true;
      }
    } else {
      resultCallback = true;
    }
    if (resultCallback && this.props.layerKey) {
      LayerPopup.hide(this.props.layerKey);
    }
  }

  render() {
    let { message, buttonLabel1 = '취소', buttonLabel2 = '확인' } = this.props;
    return (
      <div className="layer-wrap small" style={{ marginTop: '-246px' }}>
        <div className="layer-download">
          {React.isValidElement(message) && <div>{message}</div>}
          {!React.isValidElement(message) && (
            <p dangerouslySetInnerHTML={{ __html: message }}></p>
          )}
          <div className="btn-area">
            <a
              href="#"
              className="btn-type2"
              onClick={(e) => this.onClick(e, 'CANCEL')}
            >
              {buttonLabel1}
            </a>
            <a
              href="#"
              className="btn-type2 confirm"
              onClick={(e) => this.onClick(e, 'OK')}
            >
              {buttonLabel2}
            </a>
          </div>
        </div>
        <button className="close" onClick={(e) => this.onClick(e, 'CANCEL')}>
          팝업 닫기
        </button>
      </div>
    );
  }
}

export default ConfirmModal;

import { ActionTypes as types } from './FakeLoginAction';
import simpleDotProp from '../simpleDotProp';

const initState = {
  employeeAuth: {},
  fakeLoginInfo: {},
  fakeLoginLogList: {},
};

export default function FakeLoginReducer(state = initState, action) {
  switch (action.type) {
    case types.SUCCESS_FAKE_LOGIN:
      return simpleDotProp.set(state, {
        employeeAuth: action.payload.employeeAuth,
        fakeLoginInfo: action.payload.fakeLoginInfo,
      });
    case types.GET_FAKE_LOGIN_LOG_LIST:
      return simpleDotProp.set(state, {
        fakeLoginLogList: action.payload.fakeLoginLogList,
      });
    case types.INIT_FAKE_LOGIN:
      return simpleDotProp.set(state, initState);
    default:
      return state;
  }
}

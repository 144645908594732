import React, { useEffect, useRef } from 'react';

const TopButton = () => {
  const topBtnRef = useRef();

  useEffect(() => {
    window.addEventListener('scroll', changeBannerVertical);
    window.addEventListener('resize', changeBannerHorizontal);

    changeBannerHorizontal();

    return () => {
      window.removeEventListener('scroll', changeBannerVertical);
      window.removeEventListener('scroll', changeBannerHorizontal);
    };
  }, []);

  const onClickTopButton = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const changeBannerVertical = () => {
    const topButton = topBtnRef.current;
    if (topButton) {
      if (window.scrollY > 800) {
        topButton.style.display = 'block';
      } else {
        topButton.style.display = 'none';
      }
    }
  };

  const changeBannerHorizontal = () => {
    if (topBtnRef.current && topBtnRef.current.style) {
      if (document.body.clientWidth - window.scrollX < 1700) {
        topBtnRef.current.style.left = null;
        topBtnRef.current.style.right = '10px';
      } else {
        const headerRight = document
          .querySelector('#gnb > ul > li:last-child')
          .getBoundingClientRect().right;
        topBtnRef.current.style.left = `${headerRight + 150}px`;
        topBtnRef.current.style.right = null;
      }
    }
  };

  return (
    <div
      ref={topBtnRef}
      className="top-btn"
      onClick={onClickTopButton}
      style={{ display: 'none' }}
    >
      <img
        src="https://s.wink.co.kr/marketing/homepage/floating/top-btn.png"
        alt=""
      />
    </div>
  );
};

export default TopButton;

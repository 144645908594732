import React, { Component } from 'react';
import { connect } from 'react-redux';

import Timer from '../Timer';
import DesktopLayerPopup from '../../DesktopLayerPopup';
import ParentsUtil from '../../../../library/util/ParentsUtil';
import { APICaller } from '../../configs';
import UserServices from '../../../../library/services/UserServices';
import OnetimeConfirmContent from '../OnetimeConfirmContent';

const mdnPattern = /^\d{2,3}-\d{3,4}-\d{4}$/;
const passwordPattern =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]{8,}$/;

class PasswordResetModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // step1
      username: '',
      mdnValue: '',
      mdnToken: '',
      disabledSmsButton: true,
      isSendCodeDone: false,
      isTimeOut: false,
      onetimePassword: '',
      validState: '',
      toastMsg: '',
      codeError: '',
      codeErrorVisible: false,
      onetimePasswordError: '',
      onetimePasswordErrorVisible: false,

      // step2
      newPassword: '',
      rePassword: '',
      newPasswordType: 'password',
      rePasswordType: 'password',
      newPasswordError: '영문, 숫자, 특수문자 포함 8자리 이상 입력해 주세요.',
      rePasswordError:
        '변경할 비밀번호와 다시 입력하신 비밀번호가 일치하지 않습니다.',
      newPasswordErrorVisible: false,
      rePasswordErrorVisible: false,
      btnDisabled: true,
      isDuplicatePassword: false,

      step: 1,
      onTimePasswordCount: 0,
      timerOn: false,
    };
  }

  timeoutCallback = () => {
    this.setState({
      timerOn: false,
      isTimeOut: true,
      onetimePasswordError:
        '인증 유효시간을 초과하였습니다.\n다시 인증번호 발송 버튼을 눌러주세요.',
      onetimePasswordErrorVisible: true,
    });
  };

  onClickSendCode = (e) => {
    e.preventDefault();
    let { username, mdnValue, onTimePasswordCount } = this.state;

    if (!mdnValue || !ParentsUtil.validatePhoneNumberForLogin(mdnValue)) {
      return DesktopLayerPopup.notice({
        message:
          '등록된 아이디 또는 휴대폰번호가 아닙니다. 다시 확인해 주세요.',
      });
    }

    const apiParams = {
      human_mdn: mdnValue,
      username,
      is_kakao_talk: true,
    };
    const { url, param } = UserServices.oneTimePasswordNew(apiParams);

    return APICaller.post(url, param)
      .then(() => {
        this.onetimePasswordLayerPopupKey =
          this.openOneTimePasswordLayerPopup();

        //타이머 시작
        this.setState({
          timerOn: true,
          isTimeOut: false,
          onTimePasswordCount: ++onTimePasswordCount,
          codeError: '',
          codeErrorVisible: false,
          isSendCodeDone: true,
          onetimePasswordError: '',
          onetimePasswordErrorVisible: false,
        });
      })
      .catch((error) => {
        this.setState({
          codeError:
            '등록된 아이디 또는 휴대폰번호가 아닙니다.\n다시 확인해 주세요.',
          codeErrorVisible: true,
          isSendCodeDone: false,
        });
      });
  };

  openOneTimePasswordLayerPopup = () => {
    let { username, mdnValue } = this.state;

    return DesktopLayerPopup.confirm({
      message: <OnetimeConfirmContent />,
      buttonLabel1: '문자로 다시 받기',
      callback: (type) => {
        if ('CANCEL' === type) {
          const apiParams = {
            human_mdn: mdnValue,
            username,
            is_kakao_talk: false,
          };
          const { url, param } = UserServices.oneTimePasswordNew(apiParams);
          APICaller.post(url, param);
        }
      },
    });
  };

  onClickNextStep = (e) => {
    e.preventDefault();

    const { mdnValue, onetimePassword } = this.state;

    const apiParams = {
      human_mdn: mdnValue,
      onetime_password: onetimePassword,
    };
    const { url, param } = UserServices.checkOnetimePassword(apiParams);
    return APICaller.post(url, param)
      .then((res) => {
        this.setState({
          step: 2,
          btnDisabled: true,
          newPassword: '',
          rePassword: '',
          mdnToken: res.data.mdnToken,
        });
      })
      .catch((error) => {
        this.setState({
          onetimePasswordError:
            '인증 번호를 잘못 입력하셨습니다.\n다시 확인 후 입력해 주세요.',
          onetimePasswordErrorVisible: true,
        });
      });
  };

  onClickDone = (e) => {
    e.preventDefault();
    const { mdnValue, mdnToken, newPassword, rePassword } = this.state;

    const apiParams = {
      human_mdn: mdnValue,
      new_password: newPassword,
      re_password: rePassword,
      mdn_token: mdnToken,
    };

    const { url, param } = UserServices.changePassword(apiParams);

    return APICaller.post(url, param)
      .then(() => {
        this.onClose();
        DesktopLayerPopup.login({ toastMsg: '비밀번호가 재설정되었습니다.' });
      })
      .catch((error) => {
        const detail = error.response.data.detail;
        if (
          detail ===
          '변경할 비밀번호가 기존 비밀번호와 동일하여 사용할 수 없습니다.'
        ) {
          this.setState({
            btnDisabled: true,
            newPasswordError:
              '변경할 비밀번호가 기존 비밀번호와 동일하여 사용할 수 없습니다.',
            newPasswordErrorVisible: true,
            isDuplicatePassword: true,
          });
        }
        console.error(error);
      });
  };

  onChangeUsername = (username) => {
    const { mdnValue } = this.state;
    this.setState({
      username: username.target.value,
      validState: '',
      disabledSmsButton: !(
        mdnPattern.exec(mdnValue) && username.target.value.length !== 0
      ),
      codeError: '',
      codeErrorVisible: false,
    });
  };

  onChangeMdn = (mdn) => {
    this.setState(
      {
        validState: '',
        mdnValue: mdn.target.value
          .replace(/[^0-9]/g, '')
          .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, '$1-$2-$3'),
        codeError: '',
        codeErrorVisible: false,
      },
      () => {
        this.setState({
          disabledSmsButton: !(
            mdnPattern.exec(this.state.mdnValue) &&
            this.state.username.length !== 0
          ),
        });
      }
    );
  };

  onChangeCode = (e) => {
    this.setState({
      onetimePassword: e.target.value,
      onetimePasswordError: '',
      onetimePasswordErrorVisible: false,
    });
  };

  onChangePassword = (e) => {
    if (e.target.id === 'newPassword') {
      this.setState(
        {
          isDuplicatePassword: false,
          newPassword: e.target.value,
          newPasswordError:
            '영문, 숫자, 특수문자 포함 8자리 이상 입력해 주세요.',
          newPasswordErrorVisible:
            !passwordPattern.exec(e.target.value) &&
            e.target.value.length !== 0,
        },
        () => {
          if (
            this.state.newPassword !== this.state.rePassword &&
            this.state.rePassword.length !== 0
          ) {
            this.setState({
              rePasswordErrorVisible: true,
            });
          } else {
            this.setState({
              rePasswordErrorVisible: false,
            });
          }
          this.setState({
            btnDisabled: !(
              passwordPattern.exec(this.state.newPassword) &&
              this.state.newPassword === this.state.rePassword
            ),
          });
        }
      );
    } else {
      this.setState(
        {
          rePassword: e.target.value,
        },
        () => {
          if (
            this.state.newPassword !== this.state.rePassword &&
            this.state.rePassword.length !== 0
          ) {
            this.setState({
              rePasswordErrorVisible: true,
            });
          } else {
            this.setState({
              rePasswordErrorVisible: false,
            });
          }
          this.setState({
            btnDisabled: !(
              passwordPattern.exec(this.state.newPassword) &&
              this.state.newPassword === this.state.rePassword
            ),
          });
        }
      );
    }
  };

  onClickEyeIcon = (type) => {
    const { newPasswordType, rePasswordType } = this.state;

    if (type === 'new') {
      this.setState({
        newPasswordType: newPasswordType === 'password' ? 'text' : 'password',
      });
    } else {
      this.setState({
        rePasswordType: rePasswordType === 'password' ? 'text' : 'password',
      });
    }
  };

  renderEyeIcon = (type, visible, hide) => {
    if (visible === 'password') {
      return (
        <svg
          onClick={() => this.onClickEyeIcon(type)}
          className={`bi bi-eye${type}`}
          style={{ display: hide ? 'none' : 'block' }}
        />
      );
    } else {
      return (
        <i
          onClick={() => this.onClickEyeIcon(type)}
          className={`bi bi-eye-slash${type}`}
          style={{ display: hide ? 'none' : 'block' }}
        />
      );
    }
  };

  renderCodeError = (message) => {
    return (
      <div className="code-error">
        <i className="bi bi-exclamation-triangle-fill" />
        {message}
      </div>
    );
  };

  renderStep1 = () => {
    const {
      username,
      mdnValue,
      onTimePasswordCount,
      timerOn,
      isTimeOut,
      isSendCodeDone,
      onetimePassword,
      disabledSmsButton,
      codeError,
      codeErrorVisible,
      onetimePasswordError,
      onetimePasswordErrorVisible,
    } = this.state;

    return (
      <div className="password-reset-step1">
        <div className="password-reset-banner">
          <img
            src="https://us.wink.co.kr/pc/renewalV3/password-reset-modal/banner.png"
            alt="비밀번호 재설정 배너"
          />
        </div>
        <fieldset className="id-phone-check">
          <legend>아이디 휴대폰번호 입력</legend>
          <input
            disabled={isSendCodeDone}
            value={username}
            onChange={this.onChangeUsername}
            type={codeErrorVisible ? 'error' : 'text'}
            title="아이디 입력"
            placeholder="아이디를 입력해 주세요."
          />
          <input
            disabled={isSendCodeDone}
            value={mdnValue}
            onChange={this.onChangeMdn}
            style={{ width: '65%' }}
            type={codeErrorVisible ? 'error' : 'text'}
            title="휴대폰번호 입력"
            placeholder="휴대폰번호를 입력해 주세요."
          />
          <button
            disabled={disabledSmsButton || codeErrorVisible}
            className="btn-type form resetmodal-certification"
            id="resetmodal-certification"
            onClick={this.onClickSendCode}
          >
            {0 === onTimePasswordCount ? '인증번호 발송' : '인증번호 재발송'}
          </button>
          {codeErrorVisible &&
            this.renderCodeError(codeError.split('. ').join('.\n'))}
          <input
            disabled={!isSendCodeDone || isTimeOut}
            style={{ width: '65%' }}
            type={onetimePasswordErrorVisible ? 'error' : 'text'}
            maxLength="6"
            title="인증번호 입력"
            value={onetimePassword}
            onChange={this.onChangeCode}
            placeholder="인증번호 입력"
          />
          <Timer
            label="유효시간"
            format={'mm:ss'}
            resetId={onTimePasswordCount}
            on={timerOn}
            timeoutCallback={this.timeoutCallback}
          />
          {onetimePasswordErrorVisible &&
            this.renderCodeError(onetimePasswordError)}
          <button
            style={
              onetimePasswordErrorVisible
                ? { marginTop: '10px' }
                : { marginTop: '30px' }
            }
            disabled={
              isTimeOut ||
              onetimePasswordErrorVisible ||
              onetimePassword.length === 0 ||
              !isSendCodeDone
            }
            className="btn-type form password-reset-next"
            onClick={this.onClickNextStep}
          >
            인증 확인 및 다음단계 `{'>'}`
          </button>
        </fieldset>
      </div>
    );
  };

  renderStep2 = () => {
    const {
      newPassword,
      rePassword,
      newPasswordType,
      rePasswordType,
      newPasswordError,
      newPasswordErrorVisible,
      rePasswordError,
      rePasswordErrorVisible,
      btnDisabled,
      isDuplicatePassword,
    } = this.state;
    return (
      <div className="password-reset-step2">
        <div className="password-reset-banner">
          <img
            src="https://us.wink.co.kr/pc/renewalV3/password-reset-modal/banner2.png"
            alt="비밀번호 재설정 배너"
          />
        </div>
        <fieldset className="new-password">
          <legend>새 비밀번호 설정</legend>
          <div className="new-password-wrapper">
            <input
              id="newPassword"
              className={isDuplicatePassword ? 'warning' : ''}
              disabled={false}
              value={newPassword}
              onChange={this.onChangePassword}
              onBlur={this.onBlur}
              type={newPasswordType}
              title="새 비밀번호 입력"
              placeholder="변경할 비밀번호를 입력해 주세요."
            />
            {this.renderEyeIcon(
              'new',
              newPasswordType,
              newPassword.length === 0
            )}
          </div>
          {newPasswordErrorVisible && this.renderCodeError(newPasswordError)}
          <div className="new-password-wrapper">
            <input
              id="rePassword"
              disabled={false}
              value={rePassword}
              onChange={this.onChangePassword}
              onBlur={this.onBlur}
              type={rePasswordType}
              title="새 비밀번호 다시 입력"
              placeholder="변경할 비밀번호를 다시 입력해 주세요"
            />
            {this.renderEyeIcon('re', rePasswordType, rePassword.length === 0)}
          </div>
          {rePasswordErrorVisible && this.renderCodeError(rePasswordError)}
          <button
            disabled={btnDisabled}
            className="btn-type form password-reset-next"
            onClick={this.onClickDone}
          >
            비밀번호 재설정 완료
          </button>
        </fieldset>
      </div>
    );
  };

  onClose = () => {
    DesktopLayerPopup.hide(this.props.layerKey);
  };

  render() {
    const { step } = this.state;
    return (
      <div className="layer-wrap member password-reset-modal">
        {step === 1 ? this.renderStep1() : this.renderStep2()}
        <button className="close" onClick={this.onClose}>
          팝업 닫기
        </button>
      </div>
    );
  }
}

export default connect()(PasswordResetModal);

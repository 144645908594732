import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import DesktopLayerPopup from '../../DesktopLayerPopup';
import Timer from '../Timer';
import { KeyCode } from '../../../../library/constants/KeyCode';
import { RegExp } from '../../../../library/constants/RegExp';
import { ActorType, StorageKey } from '../../../../library/constants/Code';
import * as MemberAction from 'src/features/member/MemberAction';
import Storage from '../../../../library/Storage';
import ParentsUtil from '../../../../library/util/ParentsUtil';
import OnetimeConfirmContent from '../OnetimeConfirmContent';
import { routes } from 'src/constants/routes';
import { getPageName } from '@/utils/miscUtils';

class LoginModal extends Component {
  constructor(props) {
    super(props);

    this.onClickJoinMember = this.onClickJoinMember.bind(this);
    this.onClickLogin = this.onClickLogin.bind(this);
    this.onChangeSave = this.onChangeSave.bind(this);
    this.onClose = this.onClose.bind(this);
    this.timeoutCallback = this.timeoutCallback.bind(this);
    this.onIdKeyDownFocus = this.onIdKeyDownFocus.bind(this);
    this.oneTimePassword = this.oneTimePassword.bind(this);
    this.onPasswordKeyDown = this.onPasswordKeyDown.bind(this);
    this.inputRef = {};

    //id저장, phone저장기능 있어야함.
    this.state = {
      tab: 1,
      isSave: Storage.get(StorageKey.PHONE_SAVE) ? true : false,
      id: Storage.get(StorageKey.ID_SAVE)
        ? Storage.get(StorageKey.ID_SAVE).replace(/"/g, '')
        : '',
      password: '',
      phone: Storage.get(StorageKey.PHONE_SAVE)
        ? Storage.get(StorageKey.PHONE_SAVE).replace(/"/g, '')
        : '',
      certificationNumber: '',
      name: '',
      onTimePasswordCount: 0,
      timerOn: false,
      toastVisible: false,
    };
  }

  componentDidMount() {
    if (this.props.toastMsg) {
      this.setState(
        {
          toastVisible: true,
        },
        () => {
          setTimeout(() => {
            this.setState({
              toastVisible: false,
            });
          }, 3000);
        }
      );
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if ('successLogin' === nextProps.action) {
      this.props.clearAction();
      if (this.props.redirect) {
        this.props.history.push(this.props.redirect);
      }
    } else if ('failLogin' === nextProps.action) {
      this.props.clearAction();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.actor.id) {
      DesktopLayerPopup.hide(this.props.layerKey);
      if (this.props.redirect) {
        if (this.props.historyType) {
          this.props.history[this.props.historyType](this.props.redirect);
        } else {
          this.props.history.push(this.props.redirect);
        }
      }
    }
  }

  onClickTab(e, tab) {
    e.preventDefault();
    if (tab !== this.state.tab) {
      let newState = { ...this.state };
      if (tab === 0) {
        if (Storage.get(StorageKey.ID_SAVE)) {
          newState.isSave = true;
        } else {
          newState.isSave = false;
        }
      }

      if (tab === 1) {
        if (Storage.get(StorageKey.PHONE_SAVE)) {
          newState.isSave = true;
        } else {
          newState.isSave = false;
        }
      }

      newState.tab = tab;
      //id저장, phone저장기능 있어야함.
      this.setState(newState);
    }
  }

  timeoutCallback() {
    DesktopLayerPopup.notice({
      message:
        '인증 유효시간을 초과하였습니다. <br />다시 인증번호 발송 버튼을 눌러주세요.',
    });

    this.setState({ timerOn: false });
  }

  onChangeSave(e) {
    if (this.state.isSave === true) {
      Storage.deleteItem(StorageKey.ID_SAVE);
      Storage.deleteItem(StorageKey.PHONE_SAVE);
    }

    this.setState({
      isSave: !this.state.isSave,
    });
  }

  onClose() {
    DesktopLayerPopup.hide(this.props.layerKey);
  }

  onClickJoinMember(e) {
    e.preventDefault();
    const { history } = this.props;
    const pageName = getPageName(history);
    if (/reference-room/.test(pageName)) {
      const pageData = {
        pageName,
        pageNo: history.query.pageno || '1',
        scrollPosition: window.scrollY,
      };
      sessionStorage.setItem('referenceRoomPageData', JSON.stringify(pageData));
    }
    this.props.history.push(routes.member.memberJoinStep01);
    this.onClose();
  }

  onClickLogin(e) {
    e.preventDefault();
    let { tab } = this.state;
    if (tab === 0) {
      this.processIdPasswordLogin();
    } else if (tab === 1) {
      this.processPhoneLogin();
    }
  }

  onClickPasswordReset = () => {
    this.onClose();
    DesktopLayerPopup.resetPassword();
  };

  idPasswordLoginValidate() {
    let { id, password } = this.state;
    if (id.trim() === '') {
      DesktopLayerPopup.notice({
        showXButton: false,
        message: '아이디를 입력해주세요.',
      });
      return true;
    }

    if (RegExp.NUMBER.test(this.state.id.trim())) {
      DesktopLayerPopup.notice({
        showXButton: false,
        message:
          '아이디 형식에 맞지 않습니다. 소문자, 숫자 포함 6자리 이상 입력해 주세요.',
      });
      return true;
    }

    if (password.trim() === '') {
      DesktopLayerPopup.notice({
        showXButton: false,
        message: '패스워드를 입력해주세요.',
      });
      return true;
    }
    return false;
  }

  processIdPasswordLogin() {
    let { id, password, isSave } = this.state;

    if (this.idPasswordLoginValidate()) {
      return;
    }

    //@mac @named 이면 소문자로 변환 하지 않고 서버에 로그인 요청
    if (
      id.trim().indexOf('@mac') !== -1 ||
      id.trim().indexOf('@named') !== -1
    ) {
      this.props.loginUser(
        id.trim(),
        password.trim(),
        isSave,
        ActorType.Parent
      );
    } else {
      this.props.loginUser(
        id.trim().toLowerCase(),
        password.trim(),
        isSave,
        ActorType.Parent
      );
    }
  }

  phoneLoginValidate() {
    let { phone, certificationNumber, name } = this.state;
    if (phone.trim() === '') {
      DesktopLayerPopup.notice({
        showXButton: false,
        message: '휴대폰번호를 입력해주세요.',
      });
      return true;
    }

    if (!ParentsUtil.validatePhoneNumberForLogin(phone)) {
      DesktopLayerPopup.notice({
        showXButton: false,
        message: '올바른 휴대폰번호를 입력해주세요.',
      });
      return true;
    }

    if (certificationNumber.trim() === '') {
      DesktopLayerPopup.notice({
        showXButton: false,
        message: '인증번호를 입력해주세요.',
      });
      return true;
    }

    if (name.trim() === '') {
      DesktopLayerPopup.notice({
        showXButton: false,
        message: '이름을 입력해주세요.',
      });
      return true;
    }
    return false;
  }

  processPhoneLogin() {
    let { phone, certificationNumber, name, isSave } = this.state;
    if (this.phoneLoginValidate()) {
      return;
    }

    this.props.phoneLoginUser(
      phone,
      name,
      certificationNumber,
      isSave,
      ActorType.Parent
    );
  }

  onBlurValue(e, key) {
    this.setState({
      [key]: e.target.value,
    });
  }

  onIdKeyDownFocus(e) {
    if (e.keyCode === KeyCode.ENTER) {
      this.inputRef.password.focus();
    }
  }

  onPasswordKeyDown(e) {
    if (e.keyCode === KeyCode.ENTER) {
      this.setState(
        {
          password: e.target.value,
        },
        () => {
          this.processIdPasswordLogin();
        }
      );

      e.preventDefault();
      return false;
    }
  }

  oneTimePassword(e) {
    e.preventDefault();
    let { phone, onTimePasswordCount } = this.state;

    if (!phone || !ParentsUtil.validatePhoneNumberForLogin(phone)) {
      return DesktopLayerPopup.notice({
        message: '휴대폰 번호를 확인해주세요.',
      });
    }

    this.openOneTimePasswordLayerPopup();

    this.props.oneTimePassword(phone, true);
    //타이머 시작
    this.setState({
      timerOn: true,
      onTimePasswordCount: ++onTimePasswordCount,
      certificationNumber: '',
    });
  }

  openOneTimePasswordLayerPopup = () => {
    let { phone } = this.state;

    return DesktopLayerPopup.confirm({
      message: <OnetimeConfirmContent />,
      buttonLabel1: '문자로 다시 받기',
      callback: (type) => {
        if ('CANCEL' === type) {
          this.props.oneTimePassword(phone, false);
        }
      },
    });
  };

  renderToast = () => {
    return (
      <div className="toast">
        <i className="bi bi-check-circle-fill" />
        {this.props.toastMsg}
      </div>
    );
  };

  render() {
    const {
      tab,
      isSave,
      id,
      phone,
      onTimePasswordCount,
      timerOn,
      toastVisible,
    } = this.state;
    return (
      <div className="layer-wrap member login-modal">
        <div className="layer-download">
          <div className="sign-up-banner">
            <img
              src="https://us.wink.co.kr/pc/renewalV3/login-modal/banner.png"
              alt=""
            />
            <img
              className="button"
              src="https://us.wink.co.kr/pc/renewalV3/login-modal/sign_up_button.png"
              onClick={this.onClickJoinMember}
              alt=""
            />
          </div>
          <ul className="tab-type cell-2">
            <li className={classNames({ on: tab === 0 })}>
              <a
                href="#"
                onClick={(e) => {
                  this.onClickTab(e, 0);
                }}
              >
                아이디 로그인
              </a>
            </li>
            <li className={classNames({ on: tab === 1 })}>
              <a
                href="#"
                onClick={(e) => {
                  this.onClickTab(e, 1);
                }}
              >
                휴대폰번호 로그인
              </a>
            </li>
          </ul>

          {tab === 0 && (
            <fieldset className="id-login">
              <legend>아이디 비밀번호 입력</legend>
              <input
                type="text"
                title="아이디 입력"
                placeholder="아이디를 입력해 주세요."
                ref={(ref) => {
                  this.inputRef.id = ref;
                }}
                onBlur={(e) => {
                  this.onBlurValue(e, 'id');
                }}
                defaultValue={id}
                onKeyDown={this.onIdKeyDownFocus}
              />
              <input
                type="password"
                title="비밀번호 번호 입력"
                placeholder="비밀번호를 입력해 주세요."
                ref={(ref) => {
                  this.inputRef.password = ref;
                }}
                onBlur={(e) => {
                  this.onBlurValue(e, 'password');
                }}
                onInput={(e) => this.setState({ password: e.target.value })}
                onKeyDown={this.onPasswordKeyDown}
              />
            </fieldset>
          )}

          {tab === 1 && (
            <fieldset className="phone">
              <legend>아이디 비밀번호 입력</legend>
              <input
                type="text"
                title="휴대폰번호 입력"
                placeholder="휴대폰번호를 입력해 주세요."
                defaultValue={phone}
                onBlur={(e) => {
                  this.onBlurValue(e, 'phone');
                }}
              />
              <a
                href="#"
                className="btn-type form"
                id="loginmodal-certification"
                onClick={this.oneTimePassword}
              >
                {0 === onTimePasswordCount
                  ? '인증번호 발송'
                  : '인증번호 재발송'}
              </a>
              <input
                type="text"
                maxLength="6"
                title="인증번호 입력"
                placeholder="인증번호 입력"
                className={classNames({ disabled: 0 === onTimePasswordCount })}
                onBlur={(e) => {
                  this.onBlurValue(e, 'certificationNumber');
                }}
              />
              <Timer
                label="유효시간"
                format={'mm:ss'}
                resetId={onTimePasswordCount}
                on={timerOn}
                timeoutCallback={this.timeoutCallback}
              />
              <input
                type="text"
                title="이름 입력"
                placeholder="이름을 입력해 주세요."
                className="name"
                onBlur={(e) => {
                  this.onBlurValue(e, 'name');
                }}
              />
            </fieldset>
          )}

          <fieldset className="save">
            <input
              type="checkbox"
              name="save"
              id="save"
              onChange={this.onChangeSave}
              checked={isSave}
            />
            <label htmlFor="save">
              {tab === 0 ? '아이디 저장' : '휴대폰번호 저장'}
            </label>
            {tab === 0 && (
              <label
                className="reset-password"
                onClick={this.onClickPasswordReset}
              >
                비밀번호 재설정
              </label>
            )}
          </fieldset>
        </div>

        <button className="close" onClick={this.onClose}>
          팝업 닫기
        </button>

        <a
          href="#"
          className="login-button"
          id="loginmodal-login"
          onClick={this.onClickLogin}
        >
          <img
            src="https://us.wink.co.kr/pc/renewalV3/login-modal/login_button.png"
            alt=""
          />
        </a>
        {toastVisible && this.renderToast()}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    clearAction: () => {
      dispatch(MemberAction.clearAction());
    },
    loginUser: (userName, password, isMaintainLogin, actorType) => {
      dispatch(
        MemberAction.loginUser(userName, password, isMaintainLogin, actorType)
      );
    },
    phoneLoginUser: (
      phone,
      name,
      certificationNumber,
      isMaintainLogin,
      actorType
    ) => {
      dispatch(
        MemberAction.phoneLoginUser(
          phone,
          name,
          certificationNumber,
          isMaintainLogin,
          actorType
        )
      );
    },
    oneTimePassword: (mdn, isKakaoTalk) => {
      dispatch(MemberAction.oneTimePassword(mdn, isKakaoTalk));
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    action: state.MemberReducer.action,
    actor: state.MemberReducer.actor,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginModal);

// lib/redux.js
import { thunk } from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { applyMiddleware, createStore } from 'redux';
import { createWrapper } from 'next-redux-wrapper';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reducer from '@/configs/combinedReducers';

const isDev = process.env.NODE_ENV !== 'production';
const middlewares = [thunk];
const logger = createLogger({
  level: 'info',
  collapsed: true,
});

if (isDev) {
  middlewares.push(logger);
}

const makeConfiguredStore = (reducer) => {
  if (isDev) {
    return createStore(
      reducer,
      composeWithDevTools(applyMiddleware(...middlewares))
    );
  }

  return createStore(reducer, undefined, applyMiddleware(...middlewares));
};

export const makeStore = () => {
  const isServer = typeof window === 'undefined';

  if (isServer) {
    return makeConfiguredStore(reducer);
  } else {
    // we need it only on client side
    const persistConfig = {
      key: 'nextjs',
      storage,
    };

    const persistedReducer = persistReducer(persistConfig, reducer);
    const store = makeConfiguredStore(persistedReducer);

    store.__persistor = persistStore(store);

    return store;
  }
};

const wrapper = createWrapper(makeStore, {
  debug: false,
});

export default wrapper;

import UserServices from '../../library/services/UserServices';
import * as ExceptionAction from '../../library/error/ExceptionAction';

export const ActionTypes = Object.freeze({
  CLEAR_ACTION: 'MAIN/CLEAR_ACTION',
  GET_BANNER_SETTING: 'MAIN/GET_BANNER_SETTING',
});

export function clearAction() {
  return (dispatch, getState) => {
    return dispatch({
      type: ActionTypes.CLEAR_ACTION,
    });
  };
}

// server_test : tests_for_2085_pc_web_main_banner_setting.py - PCWebMainBannerSettingTest.test_banner_setting
export function getBannerSetting() {
  return (dispatch, getState) =>
    dispatch(() => {
      const bannerNameType = 'PCWebBannerSettingType';
      return UserServices.getSettingInfo(bannerNameType, bannerNameType)
        .then((response) => {
          dispatch({
            type: 'MAIN/GET_BANNER_SETTING',
            payload: {
              action: 'successGetBannerInfo',
              bannerSettingInfo: response.data,
            },
          });
        })
        .catch((e) => {
          dispatch(
            ExceptionAction.exception(
              e,
              'BannerSetting 정보 조회중 에러가 발생하였습니다.'
            )
          );
        });
    });
}

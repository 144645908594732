import { ActionTypes as types } from './ReportAction';
import simpleDotProp from '../../../library/simpleDotProp';

const initialState = {
  issueCategoryTag: {},
  lastAction: '',
  postList: {
    count: 0,
    results: [],
  },
  issueFileList: [],
};

export default function ReportReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_ISSUE_TYPE:
      return simpleDotProp.set(state, {
        issueCategoryTag: action.payload.issueCategoryTag,
      });
    case types.SUBMIT_INQUIRY_ISSUE:
      return simpleDotProp.set(state, {
        lastAction: types.SUBMIT_INQUIRY_ISSUE,
      });
    case types.SUBMIT_INQUIRY_FINISH:
      return simpleDotProp.set(state, {
        lastAction: types.SUBMIT_INQUIRY_FINISH,
      });
    case types.GET_MY_REPORT_LIST:
      return simpleDotProp.set(state, {
        lastAction: types.GET_MY_REPORT_LIST,
        postList: action.payload.postList,
      });
    case types.GET_MY_QNA_DETAIL:
      return simpleDotProp.set(state, {
        lastAction: types.GET_MY_QNA_DETAIL,
        myqnadetail: action.payload.myqnadetail,
        fullname: action.payload.fullname,
      });
    case types.MODIFY_INQUIRY_ISSUE:
      return simpleDotProp.set(state, {
        type: action.payload.type,
        myqnadetail: action.payload.myqnadetail,
      });
    case types.GET_ISSUE_FILE_LIST:
      return simpleDotProp.set(state, {
        type: action.payload.type,
        issueFileList: action.payload.issueFileList,
      });
    case types.INIT_ISSUE_FILELIST:
      return simpleDotProp.set(state, {
        issueFileList: initialState.issueFileList,
      });
    case types.SEND_EMAIL_NOT_LOGIN:
      return simpleDotProp.set(state, {
        lastAction: types.SEND_EMAIL_NOT_LOGIN,
      });
    default:
      return state;
  }
}

import APICaller from '../APICaller';
import { ModelStatus, ItemType } from '../constants/Code';
import APIJoin from './APIJoin';

export default class StudyServices {
  static getSchedules(studentActorId, start, end) {
    const url = `/account/actors/${studentActorId}/schedules/?start_date=${start},${end}&limit=200`;

    return APICaller.get(url);
  }

  static getSchedulesWithItem(studentActorId, start, end) {
    const url = `/account/actors/${studentActorId}/schedules/?start_date=${start},${end}&limit=200`;

    return APIJoin.outerCall(
      url,
      'item',
      'item',
      StudyServices.getItemsByIdList,
      'id'
    );
  }

  static getItemsByIdList(idList) {
    const url = `/item/items/?id__in=${idList}`;

    return APICaller.get(url);
  }

  static getItemsWithInventory(modelType, studentActorId, absWeek, age) {
    const url = `/item/items/?model_type=${modelType}&absweek=${absWeek}&age__in=${age},0`;

    return APIJoin.outerCall(
      url,
      'id',
      'inventory',
      StudyServices.getInventorys,
      'item',
      studentActorId
    );
  }

  static getItemsRangeABSWeekWithInventory(
    modelType,
    studentActorId,
    startAbsWeek,
    endAbsWeek,
    age
  ) {
    const url = `/item/items/?model_type=${modelType}&absweek__range=${startAbsWeek},${endAbsWeek}&age__in=${age},${
      startAbsWeek < 8 && age > 7 ? age - 1 : 0
    }&limit=1000`;

    return APIJoin.outerCall(
      url,
      'id',
      'inventory',
      StudyServices.getInventorys,
      'item',
      studentActorId
    );
  }

  static getItems(modelType, absWeek, age) {
    const url = `/item/items/?model_type=${modelType}&absweek=${absWeek}&age__in=${age},0`;

    return APICaller.get(url);
  }

  static getInventorys(studentActorId, itemIdList) {
    const url = `/account/actors/${studentActorId}/inventories/?item__in=${itemIdList}&status=${ModelStatus.StudyCompleted}`;

    return APICaller.get(url);
  }

  static getSamplingStudyInfo(studentActorId, date) {
    const url = `/account/actors/${studentActorId}/get_sampling_study_info/?today=${encodeURIComponent(
      date
    )}`;

    return APICaller.get(url);
  }

  static getInventoryByItemId(studentActorId, itemId) {
    const url = `/account/actors/${studentActorId}/inventories/?item=${itemId}`;

    return APICaller.get(url);
  }

  static setStudyStart(itemId) {
    const url = `/item/items/${itemId}/set_content_inventory/`;
    const params = {
      status: ModelStatus.StudyStarted,
    };

    return APICaller.post(url, params);
  }

  static getInventoryInfo(inventoryId) {
    const url = `/item/inventories/${inventoryId}/`;

    return APICaller.get(url);
  }

  static modifyInventory(inventoryId, data) {
    const url = `/item/inventories/${inventoryId}/modify/`;
    const params = {
      data: data,
    };

    return APICaller.post(url, params);
  }

  static setStudyComplete(itemId) {
    const url = `/item/items/${itemId}/set_content_inventory/`;
    const params = {
      status: ModelStatus.StudyCompleted,
    };

    return APICaller.post(url, params);
  }

  //day_code=20170800,      # Monthly 인 경우 날짜 부분이 00. int type 임
  static getAttendance(studentActorId, day_code) {
    const url = `/aux/stat_types/MonthlyActorClientStat/get_named/?name=${studentActorId}_${day_code}`;

    return APICaller.get(url);
  }

  static setAttendance(stateId, attendanceData) {
    const url = `/aux/stats/${stateId}/modify/`;
    const params = {
      data: attendanceData,
    };

    return APICaller.post(url, params);
  }

  static getContentCategoryTag(name) {
    const url = `/aux/tag_types/ContentCategoryTag/get_named/?name=${encodeURIComponent(
      name
    )}`;

    return APICaller.get(url);
  }

  static getContentItemBySearch(searchText, limit, offset) {
    const url = `/item/items/?model_type=${
      ItemType.Content
    }&search=${encodeURIComponent(searchText)}`;

    const params = { limit, offset };

    return APICaller.get(url, params);
  }

  static getItemInfo(id) {
    const url = `/item/items/${id}/`;

    return APICaller.get(url);
  }

  static getSawContentsByDate(studentId) {
    const url = `/item/inventories/?owner=${studentId}&item_type=${ItemType.Content}&order_by=-modified_date,-id`;

    return APIJoin.outerCall(
      url,
      'item',
      'item',
      StudyServices.getItemsByIdList,
      'id'
    );
  }

  static getSawContentsByCount(studentId) {
    const url = `/item/inventories/?owner=${studentId}&item_type=${ItemType.Content}&order_by=-count,-id&limit=50`;

    return APIJoin.outerCall(
      url,
      'item',
      'item',
      StudyServices.getItemsByIdList,
      'id'
    );
  }

  static getSawContentsForParentApp(
    studentId,
    categoryId,
    categoryId2,
    startDate,
    endDate
  ) {
    const url = `/item/inventories/?owner=${studentId}&item_type=${ItemType.Content}&item_top_category_tag__in=${categoryId},${categoryId2}&modified_date=${startDate},${endDate}&order_by=-modified_date,-id`;

    return APIJoin.outerCall(
      url,
      'item',
      'item',
      StudyServices.getItemsByIdList,
      'id'
    );
  }

  static getSawContentsByCountNoTodayStudy(
    studentId,
    categoryId1,
    categoryId2
  ) {
    const url = `/item/inventories/?owner=${studentId}&item_type=${ItemType.Content}&item_top_category_tag__in=${categoryId1},${categoryId2}&order_by=-count,-id&limit=50`;

    return APIJoin.outerCall(
      url,
      'item',
      'item',
      StudyServices.getItemsByIdList,
      'id'
    );
  }
}

import { ActionTypes as types } from './MyPageMyInfoAction';
import simpleDotProp from '../../../library/simpleDotProp';

const initialState = {
  action: '',
  message: '',
};

export default function Reducer(state = initialState, action) {
  switch (action.type) {
    case types.CLEAR_ACTION:
      return simpleDotProp.set(state, {
        action: '',
      });
    case types.CHANGE_ACTION:
      return simpleDotProp.set(state, {
        action: action.payload.action,
      });
    case types.EXCEPTION:
      return simpleDotProp.set(state, {
        action: action.payload.action,
        message: action.payload.message,
      });
    default:
      return state;
  }
}

import APICaller from '../APICaller';
import { IssueType } from '../constants/Code';
export default class DeliveryASServices {
  static getDeliveryList(parentActorId) {
    const url = `/issue/issues/?model_type__in=${IssueType.CSRWinkServiceIssue},${IssueType.CSRPeriodicDeliveryIssue},${IssueType.CSRAddDeliveryIssue}&parent=${parentActorId}&order_by=-id`;

    return APICaller.get(url);
  }

  static getAsList(parentActorId) {
    const url = `/issue/issues/?model_type__in=${IssueType.CSRAfterServiceIssue},${IssueType.CSRPreDeliveryAfterServiceIssue},${IssueType.CSRPayOnArrivalAfterServiceIssue}&parent=${parentActorId}&order_by=-id`;

    return APICaller.get(url);
  }

  static getAsInfo(issueId) {
    const url = `/issue/issues/?id=${issueId}`;

    return APICaller.get(url);
  }
}

import moment from 'moment';

const DanbiInfo = {
  APP_DOWNLOAD_URL: 'https://m.wink.co.kr/client/mobile/download.html',
  APP_STORE_URL: {
    A: 'https://play.google.com/store/apps/details?id=com.danbiedu.wink.parent',
    I: 'https://itunes.apple.com/kr/app/%EC%9C%99%ED%81%AC-%ED%95%99%EB%B6%80%EB%AA%A8/id1294082776?mt=8',
  },
  //약관
  Agreement: 'https://s.wink.co.kr/danbi_common/html/agreement.html',
  //정책
  PrivacyPolicy: 'https://s.wink.co.kr/danbi_common/html/privacy_policy.html',
  //위약금 규정
  PenaltyRole: '',
  //금융거래
  FinancialTransaction: '',
  //배송대행서비스
  Delivery: '',
  상호: '(주)단비교육',
  영문상호: 'Danbi Edu',
  회사주소: moment().isBefore('2021-10-01', 'day')
    ? '서울 강남구 대치동 967-5 정우빌딩'
    : '서울특별시 강남구 개포로 640',
  대표이사: '권영금, 최형순',
  개인정보취급책임자: '김철영',
  사업자등록번호: '629-87-00476',
  통신판매업신고번호: '제 2017-서울강남-03262호',
  고객센터전화번호: '1522-1244',
  이메일: '0000@danbiedu.co.kr',
  평일업무시간: '10:00~20:00',
  평일업무시간오전오후: '오전 10시~오후 8시',
  토요일업무시간: '준비중입니다.',
  Copyright: 'Copyright © 2017',
  고객센터: '학부모님 공감센터',
  //테스트
  윙크학습기설치가이드주소: 'http://www.pdf995.com/samples/pdf.pdf',
};

export default DanbiInfo;

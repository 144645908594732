import { ActionTypes as types } from './MainAction';
import simpleDotProp from '../../library/simpleDotProp';

const initState = {
  action: '',
  bannerSettingInfo: {},
};

export default function MainReducer(state = initState, action) {
  switch (action.type) {
    case types.CLEAR_ACTION:
      return simpleDotProp.set(state, {
        action: initState.action,
      });
    case types.GET_BANNER_SETTING:
      return simpleDotProp.set(state, {
        action: action.payload.action,
        bannerSettingInfo: action.payload.bannerSettingInfo,
      });
    default:
      return state;
  }
}

import { ActionTypes as types } from './MyPageBenefitAction';
import simpleDotProp from '../../../library/simpleDotProp';

const initialState = {
  action: '',
  couponList: [],
  couponInventories: [],
};

export default function MyPageBenefitReducer(state = initialState, action) {
  switch (action.type) {
    case types.CLEAR_ACTION:
      return simpleDotProp.set(state, {
        action: '',
      });
    case types.GET_COUPON_LIST:
      return simpleDotProp.set(state, {
        couponInventories: action.payload.couponInventories,
        couponList: action.payload.couponList,
      });
    case types.ADD_COUPON:
      return simpleDotProp.set(state, {
        action: 'successaddcoupon',
      });
    case types.FAIL_ADD_COUPON:
      return simpleDotProp.set(state, {
        action: 'failaddcoupon',
      });
    default:
      return state;
  }
}

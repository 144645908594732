export const DateFormat = {
  full: 'YYYY-MM-DD HH:mm:ss',
  RemoveSecondFull: 'YYYY-MM-DD HH:mm',
  half: 'YYYY-MM-DD',
  number: 'YYYYMMDD',
  time0To12: 'hh:mm',
  fullTime0To12: 'hh:mm:ss',
  time0To24: 'HH:mm',
  fullTime0To24: 'HH:mm:ss',
  year: 'year',
  month: 'month',
  day: 'day',
  hour: 'hour',
  minute: 'minute',
  second: 'second',
  serverDateFormat: 'YYYY-MM-DDTHH:mm:ssZ',
};

import React, { Component } from 'react';
import FakeLoginInjector from '../../library/fakelogin/FakeLoginInjector';
import DesktopLayerPopup from '@/features/common/DesktopLayerPopup';
import { ActorType } from '../../library/constants/Code';
import * as FakeLoginAction from './FakeLoginAction';

class FakeLogin extends Component {
  constructor(props) {
    super(props);

    this.onClickHide = this.onClickHide.bind(this);
    this.onClickLogin = this.onClickLogin.bind(this);
    this.onChangeState = this.onChangeState.bind(this);

    this.state = {
      id: '',
      password: '',
      actorId: 0,
    };
  }

  onClickHide(e) {
    if (e) {
      e.preventDefault();
    }
    this.props.callback();
    DesktopLayerPopup.hide(this.props.layerKey);
  }

  onClickLogin(e) {
    e.preventDefault();
    const { id, password, actorId } = this.state;
    this.props.fakeLogin(id, password, actorId, ActorType.Parent);
  }

  onChangeState(e, stateName) {
    this.setState({ [stateName]: e.target.value });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.fakeLoginInfo.id !== prevProps.fakeLoginInfo.id) {
      this.props.loginUser(this.props.fakeLoginInfo);
      this.onClickHide();
    }
  }

  render() {
    return (
      <div className="layer-wrap large" style={{ marginTop: '-200px' }}>
        <h2>관리자 로그인</h2>
        <table className="form">
          <caption>정보 입력</caption>
          <tbody>
            <tr>
              <th scope="row">
                이름<em>필수</em>
              </th>
              <td>
                <fieldset className="name">
                  <legend>ID 입력</legend>
                  <input
                    type="text"
                    title="ID 입력"
                    placeholder="관리자 ID"
                    onChange={(e) => this.onChangeState(e, 'id')}
                  />
                </fieldset>
              </td>
            </tr>
            <tr>
              <th scope="row">
                비밀번호<em>필수</em>
              </th>
              <td>
                <fieldset className="name">
                  <legend>비밀번호 입력</legend>
                  <input
                    type="password"
                    title="비밀번호 입력"
                    placeholder="비밀번호"
                    onChange={(e) => this.onChangeState(e, 'password')}
                  />
                </fieldset>
              </td>
            </tr>
            <tr>
              <th scope="row">
                학부모 ActorId<em>필수</em>
              </th>
              <td>
                <fieldset className="name">
                  <legend>학부모 ActorId 입력</legend>
                  <input
                    type="text"
                    title="학부모 ActorId 입력"
                    placeholder="학부모 ActorId"
                    onChange={(e) => this.onChangeState(e, 'actorId')}
                  />
                </fieldset>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="btn-area">
          <a href="#" className="btn-type2" onClick={this.onClickHide}>
            취소
          </a>
          <a href="#" className="btn-type2 confirm" onClick={this.onClickLogin}>
            로그인
          </a>
        </div>
        <button className="close" onClick={this.onClickHide}>
          팝업 닫기
        </button>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loginUser: (info) => {
      dispatch(FakeLoginAction.loginUser(info));
    },
  };
};

export default FakeLoginInjector(null, mapDispatchToProps)(FakeLogin);

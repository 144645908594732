import { combineReducers } from 'redux';
import { HYDRATE } from 'next-redux-wrapper';
import * as LibraryReducer from '../library/LibraryReducer';
import PaymentReducer from '../library/payment/PaymentReducer';
import MemberReducer from '@/features/member/MemberReducer';
import ExceptionReducer from '../library/error/ExceptionReducer';
import ReportReducer from '@/features/customer/report/ReportReducer';
import * as MyPageReducer from '@/features/mypage/MyPageReducer';
import * as FetchReducers from '../redux/reducers';
import PostNumberReducer from '../library/postnumber/PostNumberReducer';
import MainReducer from '@/features/main/MainReducer';
import FakeLoginReducer from '../library/fakelogin/FakeLoginReducer';
import DeliveryASReducer from '../library/deliveryas/DeliveryASReducer';

export const combinedReducer = combineReducers({
  ...FetchReducers,
  ...LibraryReducer,
  ExceptionReducer,
  DeliveryASReducer,
  PaymentReducer,
  FakeLoginReducer,
  MemberReducer,
  ReportReducer,
  MainReducer,
  PostNumberReducer,
  ...MyPageReducer,
});

const reducer = (state, action) => {
  if (action.type === HYDRATE) {
    return { ...state, ...action.payload };
  } else {
    return combinedReducer(state, action);
  }
};

export default reducer;

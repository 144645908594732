import React from 'react';

const OnetimeConfirmContent = () => {
  return (
    <div>
      <p>
        인증번호가 카카오톡으로 발송되었으므로
        <br />
        확인 후 입력해 주세요
      </p>
      <br />
      <p>
        만약 카카오톡이 설치되어 있지 않은 경우는
        <br />
        {'문자로 다시 받기'} 버튼을 클릭해 주세요.
      </p>
    </div>
  );
};

export default OnetimeConfirmContent;

import { ActionTypes as types } from './BoardAction';
import simpleDotProp from '../../library/simpleDotProp';

export const initialPostState = {
  action: '',
  commentAction: '',

  boardName: '',
  boardType: '',
  boardId: 0,
  boardInfo: {},

  postForMain: {},
  postLikeListForMain: {},

  postList: {
    count: 0,
    results: [],
  },

  postMoreList: {
    count: 0,
    results: [],
    postList: {},
  },

  postInfoForMoreList: {},
  postLikeMoreList: {},
  postLikeList: {},
  postInfo: {},
  childPostList: {
    count: 0,
    results: [],
  },
  childPostInfo: {},
  staffChildPostList: {},
  userChildPostList: {},
  childPostRelatedList: {
    count: 0,
    results: [],
  },
  commentList: {
    count: 0,
    results: [],
  },
  commentChildList: {},
  commentMoreList: {
    count: 0,
    results: [],
  },
  commentAccuseIssueCategory: {
    sdata: {},
  },
  successParentCommentId: 0,
  successCommentId: 0,
  pageNo: 1,
  pageSize: 0,
  commentPageNo: 1,
  commentPageSize: 10,
  tagList: [],
  tagCategory: {
    top: {},
    middle: {},
    topTag: {},
    leaf: [],
    middles: {},
  },
  postCategoryTags: {},
  files: [],
  collectPostInfo: {},
  collectChildPostInfo: {},
  collectCommentInfo: {},
  pageUrl: '',
  query: {},
  successPostId: 0,
  tempPostPrevNext: [],
  tempCount: 0,
  postPrevNext: {
    prev: null,
    now: null,
    next: null,
    nextPageNo: 0,
    prevPageNo: 0,
  },
  representativeChild: [],
};

function BoardReducerException(state, action) {
  switch (action.type) {
    case types.EXCEPTION:
      return simpleDotProp.set(state, {
        action: 'boardException',
        message: action.payload.message,
      });
    default:
      return state;
  }
}

function BoardReducerInfo(state, action) {
  switch (action.type) {
    case types.GET_BOARD_INFO:
      if (
        action.payload.boardType !== state.boardType ||
        action.payload.boardName !== state.boardName
      ) {
        return simpleDotProp.set(state, {
          boardInfo: action.payload.boardInfo,
          boardType: action.payload.boardType,
          boardName: action.payload.boardName,
          boardId: action.payload.boardInfo.id,
        });
      }
      return state;
    default:
      return state;
  }
}

function ReducerInit(state, action) {
  switch (action.type) {
    case types.INIT_POST_MAIN:
      return simpleDotProp.set(state, {
        postForMain: initialPostState.postForMain,
      });
    case types.INIT_POST_CATEGORY:
      return simpleDotProp.set(state, {
        tagCategory: initialPostState.tagCategory,
      });
    case types.INIT_POST_LIST:
      return simpleDotProp.set(state, {
        pageNo: 1,
        postList: initialPostState.postList,
        postLikeList: initialPostState.postLikeList,
      });
    case types.INIT_POST_MORE_LIST:
      return simpleDotProp.set(state, {
        pageNo: 1,
        postMoreList: initialPostState.postMoreList,
        postLikeMoreList: initialPostState.postLikeMoreList,
        postInfoForMoreList: initialPostState.postInfoForMoreList,
      });
    case types.INIT_POST_DETAIL:
      return simpleDotProp.set(state, {
        postInfo: initialPostState.postInfo,
        postLikeList: initialPostState.postLikeList,
        tagCategory: initialPostState.tagCategory,
        childPostList: initialPostState.childPostList,
        childPostInfo: initialPostState.childPostInfo,
        childPostRelatedList: initialPostState.childPostRelatedList,
        collectChildPostInfo: initialPostState.collectChildPostInfo,
        collectCommentInfo: initialPostState.collectCommentInfo,
        commentList: initialPostState.commentList,
        files: initialPostState.files,
        userChildPostList: initialPostState.userChildPostList,
        staffChildPostList: initialPostState.staffChildPostList,
      });
    case types.INIT_POST_WRITE:
      return simpleDotProp.set(state, {
        postInfo: initialPostState.postInfo,
        collectPostInfo: initialPostState.collectPostInfo,
      });
    case types.INIT_POST_MODIFY:
      return simpleDotProp.set(state, {
        postInfo: initialPostState.postInfo,
        collectPostInfo: initialPostState.collectPostInfo,
      });
    case types.INIT_POST_CLEAN:
      return simpleDotProp.set(state, {
        postMoreList: initialPostState.postMoreList,
        postLikeList: initialPostState.postLikeList,
        postInfoForMoreList: initialPostState.postInfoForMoreList,
        postList: initialPostState.postList,
        tagCategory: initialPostState.tagCategory,
        postInfo: initialPostState.postInfo,
        childPostList: initialPostState.childPostList,
        childPostInfo: initialPostState.childPostInfo,
        childPostRelatedList: initialPostState.childPostRelatedList,
        collectChildPostInfo: initialPostState.collectChildPostInfo,
        staffChildPostList: initialPostState.staffChildPostList,
        userChildPostList: initialPostState.userChildPostList,
        files: initialPostState.files,
        collectPostInfo: initialPostState.collectPostInfo,
        tempPostPrevNext: initialPostState.tempPostPrevNext,
        tempCount: initialPostState.tempCount,
        postPrevNext: initialPostState.postPrevNext,
      });
    case types.INIT_POST_PREV_NEXT:
      return simpleDotProp.set(state, {
        tempPostPrevNext: initialPostState.tempPostPrevNext,
        tempCount: initialPostState.tempCount,
        postPrevNext: initialPostState.postPrevNext,
      });
    case types.INIT_COMMENT:
      return simpleDotProp.set(state, {
        commentList: initialPostState.commentList,
        commentMoreList: initialPostState.commentChildList,
        commentChildList: initialPostState.commentChildList,
        commentPageNo: initialPostState.commentPageNo,
        commentPageSize: initialPostState.commentPageSize,
        successCommentId: initialPostState.successCommentId,
        successParentCommentId: initialPostState.successParentCommentId,
      });
    default:
      return state;
  }
}

function ReducerPage(state, action) {
  switch (action.type) {
    case types.LOAD_POST_DETAIL:
      return simpleDotProp.set(state, {
        action: initialPostState.action,
      });
    case types.LOAD_POST_LIST:
    case types.LOAD_POST_MODIFY:
      return simpleDotProp.set(state, {
        collectChildPostInfo: initialPostState.collectChildPostInfo,
        action: initialPostState.action,
      });
    case types.LOAD_POST_WRITE:
      return simpleDotProp.set(state, {
        collectChildPostInfo: initialPostState.collectChildPostInfo,
        action: initialPostState.action,
      });
    case types.LOAD_CHILD_POST:
      return simpleDotProp.set(state, {
        collectChildPostInfo: initialPostState.collectChildPostInfo,
        action: initialPostState.action,
      });
    case types.CLEAR_POST_DATA:
      return simpleDotProp.set(state, {
        collectChildPostInfo: initialPostState.collectChildPostInfo,
        action: initialPostState.action,
      });
    default:
      return state;
  }
}

function ReducerPostMain(state, action) {
  switch (action.type) {
    case types.GET_POST_LIST_MAIN:
      return simpleDotProp.set(state, {
        postForMain: simpleDotProp.set(state.postForMain, {
          [action.payload.requester]: action.payload.postForMain,
        }),
        requester: action.payload.requester,
      });
    case types.GET_POST_LIKE_LIST_MAIN:
      return simpleDotProp.set(state, {
        postLikeListForMain: simpleDotProp.set(state.postLikeListForMain, {
          [action.payload.requester]: action.payload.postLikeListForMain,
        }),
      });
    default:
      return state;
  }
}

function ReducerPost(state, action) {
  switch (action.type) {
    case types.GET_POST_LIST:
      return simpleDotProp.set(state, {
        postList: action.payload.postList,
        query: action.payload.query,
        postLikeList: initialPostState.postLikeList,
      });
    case types.GET_POST_MORE_LIST:
      return simpleDotProp.set(state, {
        postMoreList: action.payload.postMoreList,
        pageNo: action.payload.pageNo,
        query: action.payload.query,
      });
    case types.GET_POST_INFOFORMORELIST:
      return simpleDotProp.set(state, {
        postInfoForMoreList: simpleDotProp.set(state.postInfoForMoreList, {
          [action.payload.postId]: action.payload.postInfo,
        }),
      });
    case types.GET_POST_LIKE_MORE_LIST:
      return simpleDotProp.set(state, {
        postLikeMoreList: action.payload.postLikeMoreList,
      });
    case types.GET_POST_LIKE_LIST:
      return simpleDotProp.set(state, {
        postLikeList: action.payload.postLikeList,
      });
    case types.GET_POST_INFO:
      return simpleDotProp.set(state, {
        postInfo: action.payload.postInfo,
      });
    case types.GET_REPRESENTATIVECHILD_LIST:
      return simpleDotProp.set(state, {
        representativeChild: action.payload.representativeChild,
      });
    case types.GET_POST_FILE_LIST:
      return simpleDotProp.set(state, {
        files: action.payload.files,
      });
    case types.COLLECT_POST_INFO:
      return simpleDotProp.set(state, {
        collectPostInfo: action.payload.postInfo,
      });
    case types.WRITE_POST_SUCCESS:
      return simpleDotProp.set(state, {
        action: 'successPostWrite',
      });
    case types.MODIFY_POST_SUCCESS:
      return simpleDotProp.set(state, {
        action: 'successPostModify',
      });
    case types.WRITE_POST_STAFFCOMMENT_SUCCESS:
      return simpleDotProp.set(state, {
        action: 'successPostStaffComment',
      });
    case types.SET_POST_RECOMMEND_SUCCESS:
      return simpleDotProp.set(state, {
        action: 'successPostRecommend',
        successPostId: action.payload.postId,
      });
    case types.SET_POST_LIKE_SUCCESS:
      return simpleDotProp.set(state, {
        action: 'successPostLike',
        successPostId: action.payload.postId,
      });
    case types.SET_POST_UNLIKE_SUCCESS:
      return simpleDotProp.set(state, {
        action: 'successPostUnlike',
        successPostId: action.payload.postId,
      });
    case types.SET_POST_HIDE_SUCCESS:
      return simpleDotProp.set(state, {
        action: 'successPostHide',
        successPostId: action.payload.postId,
      });
    case types.SET_POST_SHOW_SUCCESS:
      return simpleDotProp.set(state, {
        action: 'successPostShow',
        successPostId: action.payload.postId,
      });
    case types.REMOVE_POST_SUCCESS:
      return simpleDotProp.set(state, {
        action: 'successPostRemove',
      });
    case types.STATUS_POST_SUCCESS:
      return simpleDotProp.set(state, {
        action: `successPostStatus:${action.payload.status}`,
      });
    case types.CHANGE_POST_LIST_PAGESIZE:
      return simpleDotProp.set(state, {
        pageSize: action.payload.pageSize,
      });
    case types.COMPLETED_ACTION:
      return simpleDotProp.set(state, {
        action: '',
        commentAction: '',
        successPostId: 0,
        successCommentId: 0,
        successParentCommentId: 0,
      });
    case types.SET_POST_PREV_NEXT:
      return simpleDotProp.set(state, {
        tempPostPrevNext: action.payload.tempPostPrevNext,
        tempCount: action.payload.tempCount,
      });
    case types.GET_POST_PREV_NEXT:
      return simpleDotProp.set(state, {
        tempPostPrevNext: action.payload.tempPostPrevNext,
        tempCount: action.payload.tempCount,
        postPrevNext: {
          prev: action.payload.prev,
          now: action.payload.now,
          next: action.payload.next,
          nextPageNo: action.payload.nextPageNo,
          prevPageNo: action.payload.prevPageNo,
        },
      });
    default:
      return state;
  }
}

function ReducerChildPost(state, action) {
  switch (action.type) {
    case types.GET_CHILD_POST_LIST:
      return simpleDotProp.set(state, {
        childPostList: action.payload.childPostList,
      });
    case types.GET_STAFF_CHILD_POST_LIST:
      return simpleDotProp.set(state, {
        staffChildPostList: action.payload.staffChildPostList,
      });
    case types.GET_USER_CHILD_POST_LIST:
      return simpleDotProp.set(state, {
        userChildPostList: action.payload.userChildPostList,
      });
    case types.GET_CHILD_POST_RELATED_LIST:
      return simpleDotProp.set(state, {
        relatedPostId: action.payload.postId,
        childPostRelatedList: simpleDotProp.set(state.childPostRelatedList, {
          [action.payload.postId]: action.payload.relatedList,
        }),
      });
    case types.WRITE_CHILD_POST_SUCCESS:
      return simpleDotProp.set(state, {
        action: 'successChildPostWrite',
        successPostId: initialPostState.successPostId,
      });
    case types.COLLECT_CHILD_POST_INFO:
      return simpleDotProp.set(state, {
        collectChildPostInfo: action.payload.collectChildPostInfo,
      });
    case types.MODIFY_CHILD_POST_SUCCESS:
      return simpleDotProp.set(state, {
        action: 'successChildPostModify',
        successPostId: action.payload.successPostId,
      });
    case types.WRITE_CHILD_POST_STAFFCOMMENT_SUCCESS:
      return simpleDotProp.set(state, {
        action: 'postChildStaffComment',
        successPostId: action.payload.successPostId,
      });
    case types.SET_CHILD_POST_RELATED_SUCCESS:
      return simpleDotProp.set(state, {
        action: 'successChildPostRelated',
        successPostId: action.payload.successPostId,
      });
    case types.SET_CHILD_POST_LIKE_SUCCESS:
      return simpleDotProp.set(state, {
        action: 'successChildPostLike',
        successPostId: action.payload.successPostId,
      });
    case types.SET_CHILD_POST_UNLIKE_SUCCESS:
      return simpleDotProp.set(state, {
        action: 'successChildPostUnlike',
        successPostId: action.payload.successPostId,
      });
    case types.SET_CHILD_POST_HIDE_SUCCESS:
      return simpleDotProp.set(state, {
        action: 'successChildPostHide',
        successPostId: action.payload.successPostId,
      });
    case types.SET_CHILD_POST_SHOW_SUCCESS:
      return simpleDotProp.set(state, {
        action: 'successChildPostShow',
        successPostId: action.payload.successPostId,
      });
    case types.REMOVE_CHILD_POST_SUCCESS:
      return simpleDotProp.set(state, {
        action: 'successChildPostRemove',
      });
    default:
      return state;
  }
}

function ReducerComment(state, action) {
  switch (action.type) {
    case types.COMPLETED_COMMENT_ACTION:
      return simpleDotProp.set(state, {
        commentAction: '',
        successCommentId: 0,
        successParentCommentId: 0,
      });
    case types.GET_COMMENT_LIST:
      return simpleDotProp.set(state, {
        commentList: action.payload.commentList,
      });
    case types.GET_COMMENT_CHILD_LIST:
      return simpleDotProp.set(state, {
        commentChildList: simpleDotProp.set(state.commentChildList, {
          [action.payload.parentCommentId]: action.payload.commentChildList,
        }),
      });
    case types.CLEAR_COMMENT_CHILD_LIST: {
      let commentChildList = { ...state.commentChildList };
      delete commentChildList[action.payload.parentCommentId];
      return simpleDotProp.set(state, {
        commentChildList: simpleDotProp.set({}, commentChildList),
      });
    }
    case types.GET_COMMENT_MORE_LIST:
      return simpleDotProp.set(state, {
        commentMoreList: action.payload.commentMoreList,
        commentPageSize: action.payload.commentPageSize,
        commentPageNo: action.payload.commentPageNo,
      });
    case types.WRITE_COMMENT_SUCCESS:
      return simpleDotProp.set(state, {
        commentAction: 'successCommentWrite',
        successCommentId: action.payload.successCommentId,
      });
    case types.MODIFY_COMMENT_SUCCESS:
      return simpleDotProp.set(state, {
        commentAction: 'successCommentModify',
        successCommentId: action.payload.successCommentId,
      });
    case types.REMOVE_COMMENT_SUCCESS:
      return simpleDotProp.set(state, {
        commentAction: 'successCommentRemove',
        successCommentId: action.payload.successCommentId,
      });
    case types.WRITE_COMMENT_CHILD_SUCCESS:
      return simpleDotProp.set(state, {
        commentAction: 'successChildCommentWrite',
        successCommentId: action.payload.successCommentId,
        successParentCommentId: action.payload.successParentCommentId,
      });
    case types.MODIFY_COMMENT_CHILD_SUCCESS:
      return simpleDotProp.set(state, {
        commentAction: 'successChildCommentModify',
        successCommentId: action.payload.successCommentId,
        successParentCommentId: action.payload.successParentCommentId,
      });
    case types.REMOVE_COMMENT_CHILD_SUCCESS:
      return simpleDotProp.set(state, {
        commentAction: 'successChildCommentRemove',
        successCommentId: action.payload.successCommentId,
        successParentCommentId: action.payload.successParentCommentId,
      });
    case types.COLLECT_COMMENT:
      return simpleDotProp.set(state, {
        collectCommentInfo: action.payload.collectCommentInfo,
      });
    default:
      return state;
  }
}

function ReducerTag(state, action) {
  switch (action.type) {
    case types.GET_TAG_MEMBER_LIST:
      return simpleDotProp.set(state, {
        tag: action.payload.tag,
      });
    case types.GET_TAG_POST_CATEGORY:
      return simpleDotProp.set(state, {
        tagCategory: simpleDotProp.set(
          {},
          {
            top: action.payload.top,
            middle: action.payload.middle,
            leaf: action.payload.leaf,
            middles: action.payload.middles,
          }
        ),
      });
    case types.GET_TAG_TOP_MIDDLE_MEMBER_LIST:
      return simpleDotProp.set(state, {
        tagCategory: simpleDotProp.set(
          {},
          {
            top: action.payload.top,
            middles: action.payload.middles,
          }
        ),
      });
    case types.GET_TAG_TOP_MEMBER_LIST:
      return simpleDotProp.set(state, {
        tagCategory: simpleDotProp.set(
          {},
          {
            top: action.payload.tag,
            middle: initialPostState.tagCategory.middle,
            leaf: initialPostState.tagCategory.leaf,
            middles: initialPostState.tagCategory.middles,
          }
        ),
      });
    case types.GET_TAG_MIDDLE_MEMBER_LIST:
      return simpleDotProp.set(state, {
        tagCategory: simpleDotProp.set(state.tagCategory, {
          middle: action.payload.tag,
          leaf: initialPostState.tagCategory.leaf,
        }),
      });
    case types.GET_TAG_LEAF_MEMBER_LIST:
      return simpleDotProp.set(state, {
        tagCategory: simpleDotProp.set(state.tagCategory, {
          leaf: action.payload.tag,
        }),
      });
    case types.GET_POST_CATEGORY_LIST:
      return simpleDotProp.set(state, {
        postCategoryTags: simpleDotProp.set(state.postCategoryTags, {
          [action.payload.topCategoryName]: action.payload.postCategoryTags,
        }),
      });
    default:
      return state;
  }
}

function ReducerCommentAccuse(state, action) {
  switch (action.type) {
    case types.GET_COMMENT_ACCUSE_CATEGORY:
      return simpleDotProp.set(state, {
        commentAccuseIssueCategory: action.payload.commentAccuseIssueCategory,
      });
    case types.GET_COMMENT_ACCUSE_ISSUE_TYPE:
      return simpleDotProp.set(state, {
        commentAccuseIssueType: action.payload.commentAccuseIssueType,
      });
    case types.SET_COMMENT_ACCUSE:
      return {
        ...state,
        commentAction: 'successCommentAccuse',
        successCommentId: action.payload.successCommentId,
        successParentCommentId: action.payload.successParentCommentId,
      };
    default:
      return state;
  }
}

export default function BoardReducer(state = initialPostState, action) {
  switch (action.type) {
    case types.EXCEPTION:
      return BoardReducerException(state, action);
    case types.GET_BOARD_INFO:
      return BoardReducerInfo(state, action);
    case types.INIT_POST_CATEGORY:
    case types.INIT_POST_MAIN:
    case types.INIT_POST_LIST:
    case types.INIT_POST_MORE_LIST:
    case types.INIT_POST_DETAIL:
    case types.INIT_POST_WRITE:
    case types.INIT_POST_MODIFY:
    case types.INIT_POST_CLEAER:
    case types.INIT_POST_PREV_NEXT:
    case types.INIT_POST_CLEAN:
    case types.INIT_COMMENT:
      return ReducerInit(state, action);
    case types.LOAD_POST_LIST:
    case types.LOAD_POST_DETAIL:
    case types.LOAD_POST_WRITE:
    case types.LOAD_POST_MODIFY:
    case types.LOAD_CHILD_POST:
    case types.CLEAR_POST_DATA:
      return ReducerPage(state, action);

    case types.GET_POST_LIST_MAIN:
    case types.GET_POST_LIKE_LIST_MAIN:
      return ReducerPostMain(state, action);

    case types.GET_POST_LIST:
    case types.GET_POST_INFO:
    case types.GET_REPRESENTATIVECHILD_LIST:
    case types.GET_POST_LIKE_MORE_LIST:
    case types.GET_POST_FILE_LIST:
    case types.WRITE_POST_SUCCESS:
    case types.MODIFY_POST_SUCCESS:
    case types.COLLECT_POST_INFO:
    case types.WRITE_POST_STAFFCOMMENT_SUCCESS:
    case types.SET_POST_RECOMMEND_SUCCESS:
    case types.SET_POST_LIKE_SUCCESS:
    case types.SET_POST_UNLIKE_SUCCESS:
    case types.SET_POST_HIDE_SUCCESS:
    case types.SET_POST_SHOW_SUCCESS:
    case types.CHANGE_POST_LIST_PAGESIZE:
    case types.REMOVE_POST_SUCCESS:
    case types.STATUS_POST_SUCCESS:
    case types.COMPLETED_ACTION:
    case types.GET_POST_PREV_NEXT:
    case types.SET_POST_PREV_NEXT:
    case types.GET_POST_LIKE_LIST:
    case types.GET_POST_INFOFORMORELIST:
    case types.GET_POST_MORE_LIST:
      return ReducerPost(state, action);

    case types.GET_CHILD_POST_LIST:
    case types.GET_STAFF_CHILD_POST_LIST:
    case types.GET_USER_CHILD_POST_LIST:
    case types.GET_CHILD_POST_RELATED_LIST:
    case types.COLLECT_CHILD_POST_INFO:
    case types.WRITE_CHILD_POST_SUCCESS:
    case types.MODIFY_CHILD_POST_SUCCESS:
    case types.WRITE_CHILD_POST_STAFFCOMMENT_SUCCESS:
    case types.SET_CHILD_POST_RELATED_SUCCESS:
    case types.SET_CHILD_POST_LIKE_SUCCESS:
    case types.SET_CHILD_POST_UNLIKE_SUCCESS:
    case types.SET_CHILD_POST_HIDE_SUCCESS:
    case types.SET_CHILD_POST_SHOW_SUCCESS:
    case types.REMOVE_CHILD_POST_SUCCESS:
      return ReducerChildPost(state, action);

    case types.COMPLETED_COMMENT_ACTION:
    case types.GET_COMMENT_LIST:
    case types.GET_COMMENT_MORE_LIST:
    case types.GET_COMMENT_CHILD_LIST:
    case types.WRITE_COMMENT_SUCCESS:
    case types.REMOVE_COMMENT_SUCCESS:
    case types.MODIFY_COMMENT_SUCCESS:
    case types.WRITE_COMMENT_CHILD_SUCCESS:
    case types.REMOVE_COMMENT_CHILD_SUCCESS:
    case types.MODIFY_COMMENT_CHILD_SUCCESS:
    case types.COLLECT_COMMENT:
    case types.CLEAR_COMMENT_CHILD_LIST:
      return ReducerComment(state, action);

    case types.GET_COMMENT_ACCUSE_ISSUE_TYPE:
    case types.GET_COMMENT_ACCUSE_CATEGORY:
    case types.SET_COMMENT_ACCUSE:
      return ReducerCommentAccuse(state, action);

    case types.GET_TAG_MEMBER_LIST:
    case types.GET_TAG_POST_CATEGORY:
    case types.GET_TAG_TOP_MEMBER_LIST:
    case types.GET_TAG_TOP_MIDDLE_MEMBER_LIST:
    case types.GET_TAG_MIDDLE_MEMBER_LIST:
    case types.GET_TAG_LEAF_MEMBER_LIST:
    case types.GET_POST_CATEGORY_LIST:
      return ReducerTag(state, action);

    default:
      return state;
  }
}

export const EMAIL_LABLES = [
  { label: '직접입력', data: '', index: 0 },
  { label: 'naver.com', data: 'naver.com', index: 1 },
  { label: 'hanmail.net', data: 'hanmail.net', index: 2 },
  { label: 'nate.com', data: 'nate.com', index: 3 },
  { label: 'gmail.com', data: 'gmail.com', index: 4 },
  { label: 'hotmail.com', data: 'hotmail.com', index: 5 },
];

export const DELIVERY_LABLE = [
  { label: '부재 시 경비(관리)실에 맡겨 주세요.', data: 'lable0', index: 0 },
  { label: '부재 시 문 앞에 놓아주세요.', data: 'lable1', index: 1 },
  { label: '배송 전에 연락 주세요.', data: 'lable2', index: 2 },
  { label: '메시지 직접 입력', data: '', index: 3 },
];

export const BRANCH_LABLE = [{ label: 'A01', data: 'A01', index: 0 }];

export const CLICK_DELAY = 2000;

export const DEFAULT_URL = 'http://www.naver.com';

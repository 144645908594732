import APICaller from '../APICaller';
import { ScheduleType, StatDataType } from '../constants/Code';

export default class AuxServices {
  static getAuxScheduleTypes() {
    const url = '/aux/schedule_types/?offset=0&limit=1000';

    return APICaller.getCache(url);
  }

  static getAuxTalkTypes() {
    const url = '/aux/talk_types/?offset=0&limit=1000';

    return APICaller.getCache(url);
  }

  static getAuxFileTypes() {
    const url = '/aux/file_types/?offset=0&limit=1000';

    return APICaller.getCache(url);
  }

  static getAuxTagTypes() {
    const url = '/aux/tag_types/?offset=0&limit=1000';

    return APICaller.getCache(url);
  }

  static setAuxTalkTypes(data) {
    const url = '/aux/talks/';
    const params = {
      ...data,
    };
    return APICaller.post(url, params);
  }

  static getSchedule(actorId, start_date, end_date) {
    const url = `/account/actors/${actorId}/schedules/?period__overlap=${start_date},${end_date}&model_type__in=1,2,4`;
    return APICaller.get(url);
  }

  static parentScheduleWrite(data) {
    const url = '/aux/schedules/';
    const params = {
      ...data,
    };
    return APICaller.post(url, params);
  }

  static parentScheduleModify(scheduleId, data) {
    const url = `/aux/schedules/${scheduleId}/modify/`;
    const params = {
      ...data,
    };
    return APICaller.post(url, params);
  }

  static parentScheduleRemove(scheduleId) {
    const url = `/aux/schedules/${scheduleId}/remove/`;
    const params = {};
    return APICaller.post(url, params);
  }

  static getParentCategoryTag(category_tag_name) {
    const url = `/aux/tag_types/ParentCategoryTag/get_named/?name=${encodeURIComponent(
      category_tag_name
    )}`;
    return APICaller.get(url);
  }

  static getPostCategoryTag(category_tag_name) {
    const url = `/aux/tag_types/PostCategoryTag/get_named/?name=${encodeURIComponent(
      category_tag_name
    )}`;
    return APICaller.get(url);
  }

  static getStudentAppVersion() {
    const url =
      '/aux/setting_types/StudentAppVersionSettingType/get_named/?name=StudentAppVersionSettingType';

    return APICaller.get(url);
  }

  static getParentAppVersion() {
    const url =
      '/aux/setting_types/ParentAppVersionSettingType/get_named/?name=ParentAppVersionSettingType';

    return APICaller.getCache(url);
  }

  static getParentAppRecommendContents() {
    const url =
      '/aux/setting_types/ParentAppContentsRecommendSettingType/get_named/?name=ParentAppContentsRecommendSettingType';

    return APICaller.get(url);
  }
  static getMyChildTodayIssueSchedules(parentActorId) {
    const url = `/account/actors/${parentActorId}/my_child_today_issue_schedules/`;

    return APICaller.get(url);
  }

  static getParentTodayIssueSchedules(parentActorId, today_date) {
    const url = `/account/actors/${parentActorId}/schedules/?model_type=${ScheduleType.NormalSchedule}&start_date=${today_date}`;

    return APICaller.get(url);
  }

  static getRemoteCallPassword() {
    const url =
      '/aux/setting_types/RemoteCallPasswordSettingType/get_named/?name=RemoteCallPasswordSettingType';

    return APICaller.get(url);
  }

  static getMonthlyStudentStats(studentActorId, dayCode) {
    const url = `/aux/stats/?owner=${studentActorId}&model_type=${StatDataType.MonthlyStudentStat}&day_code__in=${dayCode}`;

    return APICaller.get(url);
  }

  static getDailyActorClientStat(studentActorId, dayCode) {
    const url = `/aux/stat_types/DailyActorClientStat/get_named/?name=${studentActorId}_${dayCode}`;

    return APICaller.get(url);
  }

  static getMonthlyStat(actorId, dateCode, params = null) {
    const url = `/aux/stat_types/MonthlyActorClientStat/get_named/?name=${actorId}_${dateCode}`;

    return APICaller.get(url, params);
  }

  static modifyStat(statId, data) {
    const url = `/aux/stats/${statId}/modify/`;
    const params = {
      ...data,
    };

    return APICaller.post(url, params);
  }
}

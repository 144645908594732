import React, { Component } from 'react';
import { RegExp } from '@/library/constants/RegExp';
import DesktopLayerPopup from '@/features/common/DesktopLayerPopup';
import ParentsUtil from '../../../library/util/ParentsUtil';
import OptionalAgreementPop from './OptioanlAgreementPop';
import OnetimeConfirmContent from '@/features/common/components/OnetimeConfirmContent';

class MemberJoinBase extends Component {
  onChange(e, value, type) {
    let { errorFlag } = this.state;

    this.setState({
      [type]: value,
    });
    if (errorFlag) {
      this.hideErrorLabel();
    }
    //만약 타입이 id or phone 라면 중복 체크를 해제합니다.
    if ('id' === type || 'phone' === type) {
      this.setState({ [type + 'DuplicateCheck']: false });
    }
  }

  onChoice(type) {
    this.setState({
      choiced: type,
    });
  }

  onCheckBox(e, type) {
    e.stopPropagation();
    const value = e.target.checked;
    const keys = Object.keys(this.state.agreement);
    let agreement;

    if (keys.includes(type)) {
      agreement = {
        ...this.state.agreement,
        [type]: value,
      };
    } else if (type === 'all') {
      agreement = keys.reduce((acc, cur) => ({ ...acc, [cur]: value }), {});
    } else {
      return;
    }

    this.setState({
      agreement,
    });

    this.hideErrorLabel();
  }

  validateIsSignUpButtonActive(isShowError) {
    let { idDuplicateCheck, phoneDuplicateCheck, mdn_token } = this.state;

    //이름없음
    if ('' === this.state.name) {
      this.showErrorLabel('name', isShowError);
      this.inputRef.name.focus();
      return false;
    }
    //아이디 없음
    if ('' === this.state.id.trim()) {
      this.showErrorLabel('id', isShowError);
      this.inputRef.id.focus();
      return false;
    }
    if (
      !RegExp.ID.test(this.state.id.trim()) ||
      RegExp.NUMBER.test(this.state.id.trim())
    ) {
      this.showErrorLabel('idForm', isShowError);
      this.inputRef.id.focus();
      return false;
    }
    //id중복 미확인
    if (!idDuplicateCheck) {
      this.showErrorLabel('idDuplicateCheck', isShowError);
      this.inputRef.id.focus();
      return false;
    }
    //휴대폰번호 없음
    if ('' === this.state.phone.trim()) {
      this.showErrorLabel('phone', isShowError);
      this.inputRef.phone.focus();
      return false;
    }

    //휴대폰번호 중복 미확인
    if (!phoneDuplicateCheck) {
      this.showErrorLabel('phoneDuplicateCheck', isShowError);
      this.inputRef.phone.focus();
      return false;
    }

    if (mdn_token === '') {
      this.showErrorLabel('phoneNumberConfirm', isShowError);
      this.inputRef.phone.focus();
      return false;
    }

    //패스워드 비어있는지
    if ('' === this.state.password1.trim()) {
      this.showErrorLabel('password1', isShowError);
      this.inputRef.password1.focus();
      return false;
    }
    //패스워드 최소6자리 숫자1개이상 포함
    if (!RegExp.PASSWORD2.test(this.state.password1.trim())) {
      this.showErrorLabel('password1Form', isShowError);
      this.inputRef.password1.focus();
      return false;
    }
    //패스워드2 비어있는지
    if ('' === this.state.password2.trim()) {
      this.showErrorLabel('password2', isShowError);
      this.inputRef.password2.focus();
      return false;
    }
    //비번이 같은지 체크
    if (this.state.password1.trim() !== this.state.password2.trim()) {
      this.showErrorLabel('samePassword', isShowError);
      this.inputRef.password1.focus();
      return false;
    }
    //이용동의
    if (!this.state.agreement.wink_terms_agreement) {
      this.showErrorLabel('wink_terms_agreement', isShowError);
      this.inputRef.allAgree.focus();
      return false;
    }
    //개인정보 이용동의
    if (!this.state.agreement.private_info_agreement) {
      this.showErrorLabel('private_info_agreement', isShowError);
      this.inputRef.allAgree.focus();
      return false;
    }
    // 만 14세 이상 여부
    if (!this.state.agreement.more_than_fourteen) {
      this.showErrorLabel('more_than_fourteen', isShowError);
      this.inputRef.allAgree.focus();
      return false;
    }

    return true;
  }

  //id중복을 체크합니다.
  idDuplicateCheck(e) {
    e.preventDefault();

    if (
      '' === this.state.id.trim() ||
      !RegExp.ID.test(this.state.id.trim()) ||
      RegExp.NUMBER.test(this.state.id.trim())
    ) {
      let isShowError = true;
      this.showErrorLabel('idForm', isShowError);
      this.inputRef.id.focus();
      return false;
    }
    this.props.idDuplicateCheck(
      this.state.id.trim().toLowerCase(),
      this.openDuplicatedModal
    );
  }

  //phone중복을 체크합니다.
  phoneDuplicateCheck(e) {
    e.preventDefault();
    let isShowError = true;
    let { phone, mdn_token, phoneDuplicateCheck } = this.state;
    //이미 중복체크 했으면 실행되지 않습니다.
    if (phoneDuplicateCheck) {
      return;
    }
    //이미 인증번호 확인이 끝난상태에서 버튼클릭이 되지 않게 막습니다.
    if ('' !== mdn_token) {
      this.inputRef.phone.focus();
      return;
    }
    if ('' === phone.trim()) {
      this.showErrorLabel('phone', isShowError);
      this.inputRef.phone.focus();
      return false;
    }
    if (!ParentsUtil.validatePhoneNumber(this.state.phone)) {
      this.showErrorLabel('phoneForm', isShowError);
      return false;
    }

    this.props.phoneDuplicateCheck(this.state.phone, this.openDuplicatedModal);
  }

  //id phone 중복일때 failCallback 함수 중복되었다는 메시지를 띄웁니다.
  openDuplicatedModal(label) {
    DesktopLayerPopup.notice({
      showXButton: true,
      message: label + '은 이미 가입되어 있습니다.',
      callback: (type, param) => {
        if (type === 'CLOSE') {
          DesktopLayerPopup.login();
        }
      },
      buttonLabel: '로그인',
    });
  }

  closeDuplicatedModal() {
    DesktopLayerPopup.hide(this.DuplicatedModalId);
  }

  oneTimePassword(e) {
    e.preventDefault();
    let { phone, phoneDuplicateCheck, onTimePasswordCount, mdn_token } =
      this.state;
    let isShowError = true;
    //이미 인증번호 확인이 끝난상태에서 버튼클릭이 되지 않게 막습니다.
    if ('' !== mdn_token) {
      return;
    }
    //휴대폰번호인지 체크
    if ('' === phone) {
      this.showErrorLabel('phone', isShowError);
      return false;
    }

    //휴대폰번호 중복 미확인
    if (!phoneDuplicateCheck) {
      this.showErrorLabel('phoneDuplicateCheck', isShowError);
      return false;
    }

    this.openOneTimePasswordLayerPopup();

    this.props.oneTimePassword(phone, true);
    this.setState({
      timerOn: true,
      onTimePasswordCount: ++onTimePasswordCount,
    });
  }

  openOneTimePasswordLayerPopup = () => {
    let { phone } = this.state;

    return DesktopLayerPopup.confirm({
      message: <OnetimeConfirmContent />,
      buttonLabel1: '문자로 다시 받기',
      callback: (type) => {
        if ('CANCEL' === type) {
          this.props.oneTimePassword(phone, false);
        }
      },
    });
  };

  checkOneTimePassword(e) {
    e.preventDefault();
    let isShowError = true;
    let {
      certificationNumber,
      phone,
      mdn_token,
      phoneDuplicateCheck,
      onTimePasswordCount,
    } = this.state;
    //이미 인증번호 확인이 끝난상태에서 버튼클릭이 되지 않게 막습니다.
    if ('' !== mdn_token) {
      return;
    }
    if ('' === phone) {
      this.showErrorLabel('phone', isShowError);
      return;
    }
    if ('' === certificationNumber) {
      this.showErrorLabel('certificationNumberEmpty', isShowError);
      return;
    }
    if (phoneDuplicateCheck === false) {
      this.showErrorLabel('phoneDuplicateCheck', isShowError);
      return;
    }
    if (onTimePasswordCount === 0) {
      return;
    }
    this.hideErrorLabel();
    this.props.checkOneTimePassword(phone, certificationNumber);
  }

  //타입과 일치하는 에러라벨만 출력됩니다.
  showErrorLabel(type, isShowError) {
    //isShowError가 false이면 아래 코드를 실행하지 않습니다.
    if (!isShowError) {
      return;
    }

    let errorState = { ...this.state.error };

    Object.keys(errorState).forEach((key, idx) => {
      if (key === type) {
        errorState[key] = true;
        if ('name' === type) {
          this.scrollTop();
        }
      } else {
        errorState[key] = false;
      }
    });

    this.setState({
      error: errorState,
      errorFlag: true,
    });
  }

  scrollTop() {
    window.scroll(0, 0);
  }

  //모든 에러라벨을 숨깁니다.
  hideErrorLabel() {
    let errorState = { ...this.state.error };

    Object.keys(errorState).forEach((key, idx) => {
      errorState[key] = false;
    });

    this.setState({
      error: errorState,
      errorFlag: false,
    });
  }

  timeoutCallback() {
    DesktopLayerPopup.notice({
      message:
        '인증 유효시간을 초과하였습니다. 다시 인증번호 발송 버튼을 눌러주세요.',
      buttonLabel: '확인',
      showXButton: false,
    });
  }

  onChangeRadio(value) {
    //out : true , year : false
    this.setState({
      validityDate: 'aliveUntil' === value,
    });
  }

  // 무료 체험 미동의 & 선택 약관 미동의
  showOptionalAgreementPopup = (cancelCb, agreeCb) => {
    this.layerPopupKey = DesktopLayerPopup.modal(
      <OptionalAgreementPop
        content={
          '윙크의 가입 우대 혜택 등을 받기 위해서는 "마케팅 목적 활용 동의" 등에 대해 동의해 주셔야 합니다. 지금 모두 동의를 하시고 우대 혜택과 자녀 맞춤 정보 등을 받아보시겠습니까?'
        }
        btnContexts={[
          {
            mainText: '계속 진행하기',
            onClick: () => {
              cancelCb();
              this.closeOptionalAgreementPopup();
            },
          },
          {
            mainText: '우대 혜택 받아보기',
            subText: '(마케팅 및 광고성 정보 수신 동의하기)',
            className: 'confirm',
            onClick: () => {
              this.setState(
                (prev) => ({
                  agreement: {
                    ...prev.agreement,
                    marketing_and_information_receive_agreement: true,
                    advertising_information_receive_agreement: true,
                  },
                }),
                agreeCb
              );
            },
          },
        ]}
      />
    );
  };

  // 무료 체험 동의 & 선택 약관 미동의
  showOptionalAgreementRequirePopup = (agreeCb) => {
    this.layerPopupKey = DesktopLayerPopup.modal(
      <OptionalAgreementPop
        onClose={this.closeOptionalAgreementPopup}
        content={
          '윙크의 무료혜택(무료체험)을 받기 위해서는 "마케팅 목적 활용 동의" 및 "광고성 정보 수신 동의 안내"에 대한 동의가 필요합니다.'
        }
        btnContexts={[
          {
            mainText: '무료혜택 계속 신청하기',
            subText: '(마케팅 및 광고성 정보 수신 동의하기)',
            className: 'confirm',
            onClick: () => {
              this.setState(
                (prev) => ({
                  agreement: {
                    ...prev.agreement,
                    marketing_and_information_receive_agreement: true,
                    advertising_information_receive_agreement: true,
                  },
                }),
                agreeCb
              );
              this.closeOptionalAgreementPopup();
            },
          },
        ]}
      />
    );
  };

  closeOptionalAgreementPopup = () => {
    DesktopLayerPopup.hide(this.layerPopupKey);
  };
}

export default MemberJoinBase;

import { Component } from 'react';
import ExceptionInjector from '../../library/error/ExceptionInjector';
import DesktopLayerPopup from '@/features/common/DesktopLayerPopup';
import LoginInjector from '@/features/common/LoginInjector';

class ExceptionContainer extends Component {
  componentDidUpdate(prevProps, prevState) {
    const { ex, message, command, isLogin } = this.props;

    if (!ex) {
      return;
    }

    if (ex.response && ex.response.status >= 400 && ex.response.status < 500) {
      if (isLogin) {
        if (ex.response.status === 401 || ex.response.status === 403) {
          window.location.replace(window.location.href);
        }
      } else {
        let newMessage;
        if (message.includes('로그인 시도 가능 횟수')) {
          newMessage = message.split('\n').slice(0, -1).join('<br/>');
          newMessage += `<br/><span style="color: #F63C78">${
            message.split('\n')[2]
          }</span>`;
        } else {
          newMessage = message.split('\n').join('<br/>');
        }
        DesktopLayerPopup.notice({
          showXButton: false,
          message: newMessage,
          callback: () => {
            this.props.clearException();
            if (command === 'gomain') {
              this.props.history.replace('/');
            } else if (command === 'goback') {
              this.props.history.back();
            }
          },
        });
      }
    } else if (ex.response && ex.response.status >= 500) {
      throw new Error(ex);
    } else {
      DesktopLayerPopup.notice({
        showXButton: false,
        message: message,
        callback: () => {
          this.props.clearException();
          if (command === 'gomain') {
            this.props.history.replace('/');
          } else if (command === 'goback') {
            this.props.history.back();
          }
        },
      });
    }
  }

  render() {
    return null;
  }
}

export default ExceptionInjector()(LoginInjector(ExceptionContainer));

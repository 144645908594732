import Script from 'next/script';

const Naver = () => (
  <Script
    id="naver-search-ads"
    type="text/javascript"
    dangerouslySetInnerHTML={{
      __html: `if(!wcs_add) var wcs_add = {};
wcs_add["wa"] = "s_256d4a7e2b6e";
if (!_nasa) var _nasa={};
if (window.wcs) {
    wcs.inflow("wink.co.kr");
    wcs_do(_nasa);
}`,
    }}
  />
);

export default Naver;

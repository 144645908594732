import { ActionTypes as types } from './PostNumberAction';
import simpleDotProp from '../simpleDotProp';
import { DISPLAY_STATUS } from './PostNumberConstants';

const initialState = {
  addressData: [],
  addressState: DISPLAY_STATUS.displayStatusInit,
};

export default function PostNumberReducer(state = initialState, action) {
  switch (action.type) {
    case types.INIT_ROAD_ADDRESS:
      return simpleDotProp.set(state, initialState);
    case types.GET_ROAD_ADDRESS:
      return simpleDotProp.set(state, {
        addressData: action.payload.addressData,
        addressState: action.payload.addressState,
      });
    case types.GET_ROAD_ADDRESS_NO_LIST:
      return simpleDotProp.set(state, {
        addressState: action.payload.addressState,
      });
    default:
      return state;
  }
}

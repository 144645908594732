export const routes = {
  home: '/',
  wink: {
    lookView: '/wink/lookView',
    freeStudy: '/wink/free-study',
    freeStudyComplete: '/wink/free-study/complete',
    freeStudyDirectForm: '/wink/free-study#request-study-form-id',
    freeStudyNotLoginComplete: '/wink/free-study/not-login/complete',
    freeStudyNotLoginFail: '/wink/free-study/not-login/fail',
    freeStudyRightAfterJoin: '/wink/free-study/right-after-join',
    paidStudy: '/wink/paid-study/step01',
    paidStudyStep01: '/wink/paid-study/step01',
    paidStudyStep02: '/wink/paid-study/step02',
    paidStudyStep03: '/wink/paid-study/step03',
    paidStudyComplete: '/wink/paid-study/complete',
    paidStudyPayment: '/wink/paid-study/payment',
    paymentConfirm: '/wink/payment/confirm',
    paymentError: '/wink/payment/error',
  },
  subject: {
    math: '/subject/math',
    ko: '/subject/kor',
    en: '/subject/en',
    prepare: '/subject/prepare',
    choice: '/subject/choice',
  },
  referenceRoom: '/reference-room',
  member: {
    join: '/member/join/step01',
    memberJoinStep01: '/member/join/step01',
    joinComplete: '/member/join/complete',
  },
  customer: {
    download: '/customer/download',
    event: '/customer/event',
    notice: '/customer/notice',
    promotionTeacher: '/customer/promotion-teacher',
    question: '/customer/question',
    report: '/customer/report',
    myReport: '/customer/report/my-report',
    teacher: '/customer/teacher',
  },
  company: '/company',
  myPage: {
    main: '/my-page',
    studyApplyStep03: '/my-page/study-apply/step03',
    studyInfo: '/my-page/study-info',
    paymentHistory: '/my-page/payment-history',
    payDetailSingle: '/my-page/pay-detail-single',
    shippingHistory: '/my-page/shipping-history',
    memberInfo: '/my-page/member-info',
    memberOut: '/my-page/member-out',
    kidsInfo: '/my-page/kids-info',
  },
};

import { ActionTypes as types } from './MemberJoinAction';
import simpleDotProp from '../simpleDotProp';

const initialState = {
  action: '',
  mdn_token: '',
  auth_token: '',
  human_name: '',
  address: '',
  address_detail: '',
  postal_code: '',
  human_email: '',
  human_mdn: '',
  deliveryMessage: '',
  errorStep: '',
  auth: {},
  actor: {},
  studentList: [],
  existAuth: false,
  logId: 0,
  todayStudyMap: {},
};

export default function MemberJoinReducer(state = initialState, action) {
  switch (action.type) {
    case types.SUCCESS_ONE_TIME_PASSWORD:
      return simpleDotProp.set(state, {
        action: action.payload.action,
        existAuth: action.payload.existAuth,
      });
    case types.PROCESS_PHONE_LOGIN:
      return simpleDotProp.set(state, {
        auth: action.payload.auth,
        actor: action.payload.actor,
      });
    case types.PHONE_DUPLICATE:
    case types.FAIL_CHECK_ONE_TIME_PASSWORD:
    case types.LOGOUT:
    case types.FAIL_TOKEN_LOGIN:
    case types.SUCCESS_PHONE_LOGIN:
    case types.FAIL_HALF_CREATE_AUTHS:
    case types.SUCCESS_HALF_CREATE_AUTH:
    case types.APPLY_EXPERIENCE:
    case types.SUCCESS_TOKEN_LOGIN_PASS:
      return simpleDotProp.set(state, {
        action: action.payload.action,
        actor: action.payload.actor,
      });
    case types.SUCCESS_APPLY_EXPERIENCE:
      return simpleDotProp.set(state, {
        action: action.payload.action,
      });
    case types.FAIL_CREATE_FAMILY_AND_APPLY_EXPERIENCE:
      return simpleDotProp.set(state, {
        errorStep: action.payload.errorStep,
      });
    case types.FAIL_CREATE_AUTHS_FOR_EXPERIENCE:
      return simpleDotProp.set(state, {
        action: action.payload.action,
        human_name: '',
        address: '',
        address_detail: '',
        postal_code: '',
        human_mdn: '',
        human_email: '',
        deliveryMessage: '',
      });
    case types.SUCCESS_GET_MDN_TOKEN:
      return simpleDotProp.set(state, {
        action: action.payload.action,
        mdn_token: action.payload.mdn_token,
      });
    case types.SUCCESS_GET_AUTH_TOKEN:
      return simpleDotProp.set(state, {
        action: action.payload.action,
        auth_token: action.payload.auth_token,
      });
    case types.CLEAR_MDN_TOKEN:
      return simpleDotProp.set(state, {
        mdn_token: '',
      });
    case types.CLEAR_AUTH_ACTOR:
      return simpleDotProp.set(state, {
        auth: {},
        actor: {},
        studentList: [],
        existAuth: false,
      });
    case types.CLEAR_DELIVERY_INFO:
      return simpleDotProp.set(state, {
        human_name: '',
        address: '',
        address_detail: '',
        postal_code: '',
        human_mdn: '',
        human_email: '',
        deliveryMessage: '',
      });
    case types.CLEAR_ACTION:
      return simpleDotProp.set(state, {
        action: '',
      });
    case types.CLEAR_ERROR_STEP:
      return simpleDotProp.set(state, {
        errorStep: '',
      });
    case types.SET_DELIVERY_INFO:
      return simpleDotProp.set(state, {
        human_name: action.payload.human_name,
        address: action.payload.address,
        address_detail: action.payload.address_detail,
        postal_code: action.payload.postal_code,
        human_email: action.payload.human_email,
        human_mdn: action.payload.human_mdn,
        deliveryMessage: action.payload.deliveryMessage,
      });
    case types.SET_HUMAN_NAME:
      return simpleDotProp.set(state, {
        human_name: action.payload.human_name,
      });
    case types.SET_ADDRESS:
      return simpleDotProp.set(state, {
        address: action.payload.address,
      });
    case types.SET_HUMAN_MDN:
      return simpleDotProp.set(state, {
        human_mdn: action.payload.human_mdn,
      });
    case types.SET_DELIVERY_MESSAGE:
      return simpleDotProp.set(state, {
        deliveryMessage: action.payload.deliveryMessage,
      });

    case types.SUCCESS_TOKEN_LOGIN:
      return simpleDotProp.set(state, {
        action: action.payload.action,
        auth: action.payload.auth,
        actor: action.payload.actor,
      });
    case types.SUCCESS_CSRAVILABLE_WORK_COMPLETE:
      return simpleDotProp.set(state, {
        action: action.payload.action,
      });
    case types.STUDY_APPLIED:
      return simpleDotProp.set(state, {
        action: action.payload.action,
      });
    case types.SUCCESS_GET_STUDENT_LIST:
      return simpleDotProp.set(state, {
        studentList: action.payload.studentList,
      });
    case types.SET_LOG_ID:
      return simpleDotProp.set(state, {
        logId: action.payload.log_id.data.id,
      });
    case types.DELETE_DROP_OUT:
      return simpleDotProp.set(state, {
        logId: action.payload.logId,
      });
    case types.GET_TODAY_STUDY_MAP:
      return {
        ...state,
        todayStudyMap: action.payload.todayStudyMap,
      };
    default:
      return state;
  }
}

import React, { useState, useEffect } from 'react';
import { values } from '../configs';
import { BANNER_SHOW_PAGE } from '../../common/configs/values';
import axios from '@DanbiEduCorp/axios';

const MainContentsTop = ({ history, pagename, setBannerHeight }) => {
  const [mainTopBannerColors, setMainTopBannerColors] = useState({
    left: values.bgColorDefault,
    right: values.bgColorDefault,
  });
  const [showBanner, setShowBanner] = useState(false);

  const getMainContentColors = async () => {
    const response = await axios.get(values.mainTopBannerColorsUrl, {
      withCredentials: false,
    });
    setMainTopBannerColors({ ...response.data });
  };

  useEffect(() => {
    getMainContentColors();
  }, []);

  useEffect(() => {
    const isShowBannerPage =
      BANNER_SHOW_PAGE.some((page) => pagename?.startsWith(page)) ||
      pagename === '/';
    setShowBanner(isShowBannerPage);
    if (!isShowBannerPage) {
      setBannerHeight(0);
    }
  }, [pagename]);

  const goBgLink = (link) => {
    if (link) {
      if (link.startsWith('http')) {
        location.href = link;
      } else {
        history.push(link);
      }
    }
  };

  const onError = (event) => {
    event.target.style.display = 'none';
    setBannerHeight(0);
  };

  if (!showBanner) {
    return null;
  }

  return (
    <div
      className="main-wrapper"
      style={{
        background: `linear-gradient(90deg, ${mainTopBannerColors.left} 50%, ${mainTopBannerColors.right} 50%)`,
      }}
    >
      <div className="main-top-container">
        <img
          id="main-top-banner"
          src={values.top.bgUrl}
          alt="main-top-banner"
          onError={onError}
          onClick={() => goBgLink(values.top.bgLink)}
          style={{ cursor: values.top.bgLink ? 'pointer' : 'unset' }}
          onLoad={(e) => setBannerHeight(e.target.height)}
        />
      </div>
    </div>
  );
};

export default MainContentsTop;

export const EventName = {
  show_spin: 'show_spin',
  hide_spin: 'hide_spin',
  clear_spin: 'clear_spin',
  api_call: 'api_call',
};
export const EventType = {
  request: 'request',
  requestShowSpin: 'requestShowSpin',
  requestError: 'requestError',
  response: 'response',
  responseError: 'responseError',
  showSpin: 'showSpin',
  hideSpin: 'hideSpin',
  clearSpin: 'clearSpin',
};

import React, { Component } from 'react';
import moment from 'moment';
import { CertificationTime } from '../../../library/constants/Constants';

class Timer extends Component {
  constructor(props) {
    super(props);
    const { countTime = CertificationTime } = this.props;
    this.state = {
      countTime,
    };
  }

  componentWillUnmount() {
    this.deleteInterval();
  }

  componentDidMount() {
    if (this.props.on) {
      this.runInterval();
    }
  }

  runInterval() {
    let { timeoutCallback } = this.props;
    this.deleteInterval();

    this.interval = setInterval(() => {
      let { countTime } = this.state;

      if (countTime > 0) {
        this.setState({
          countTime: countTime - 1000,
        });
      } else {
        if (timeoutCallback) {
          timeoutCallback();
        }
        clearInterval(this.interval);
      }
    }, 1000);
  }

  deleteInterval() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.resetId !== nextProps.resetId) {
      let { countTime = CertificationTime } = this.props;
      this.setState({
        resetId: nextProps.resetId,
        countTime: countTime,
      });
      this.runInterval();
    }
    if (nextProps.on) {
      let { countTime } = this.state;
      if (countTime > 0) {
        this.runInterval();
      }
    } else {
      this.deleteInterval();
    }
  }

  render() {
    const { label, format = 'mm:ss' } = this.props;
    return (
      <span
        style={{ marginLeft: '4px' }}
        className="text-valid"
        onClick={() => (this.props.onClick ? this.props.onClick : () => {})}
      >
        {label}
        <span>{moment(this.state.countTime).format(format)}</span>
      </span>
    );
  }
}

export default Timer;

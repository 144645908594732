import React, { Component } from 'react';
import LayerPopup from '../../../../library/layerpopup';
import Clipboard from 'clipboard';
import DesktopLayerPopup from '../../DesktopLayerPopup';
import { DEFAULT_URL } from '../../Constants';

class ClipboardModal extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
    this.clipboardLisner = this.clipboardLisner.bind(this);
  }

  clipboard = null;

  onClick(e, type) {
    if (e) {
      e.preventDefault();
    }
    if (type === 'CLOSE' || type === 'XCLOSE') {
      let { callback, param } = this.props;
      let resultCallback = false;
      if (callback) {
        resultCallback = callback(type, param);
        if (resultCallback === undefined) {
          resultCallback = true;
        }
      } else {
        resultCallback = true;
      }
      if (resultCallback && this.props.layerKey) {
        LayerPopup.hide(this.props.layerKey);
      }
    }
  }

  onCopy(e) {
    e.preventDefault();
  }

  clipboardLisner() {
    const targetDom = this.currentDom?.querySelector('#copyButton');
    this.clipboard = new Clipboard(targetDom);
    this.clipboard.on('success', (e) => {
      DesktopLayerPopup.notice({
        message: '주소가 복사되었습니다.',
        callback: () => {
          this.onClick(null, 'CLOSE');
          DesktopLayerPopup.hide(this.props.layerKey);
        },
      });
      e.clearSelection();
    });
  }

  componentDidMount() {
    this.clipboardLisner();
  }

  componentWillUnmount() {
    if (this.clipboard) {
      this.clipboard.destroy();
    }
  }

  onClose(e, type) {
    if (this.props.close) {
      this.props.close(type);
    }
  }

  render() {
    let { url = DEFAULT_URL } = this.props;

    return (
      <div
        className="layer-wrap small"
        style={{ marginTop: '-130px' }}
        ref={(ref) => (this.currentDom = ref)}
      >
        <div className="layer-share">
          <fieldset>
            <legend>URL 복사</legend>
            <label>URL</label>
            <input
              type="text"
              name
              defaultValue={url}
              title
              className="disabled"
              disabled
            />
            <a
              href="#"
              className="btn-type"
              id="copyButton"
              data-clipboard-text={url}
              onClick={this.onCopy}
            >
              복사
            </a>
          </fieldset>
        </div>
        <button className="close" onClick={(e) => this.onClick(e, 'XCLOSE')}>
          팝업 닫기
        </button>
      </div>
    );
  }
}

export default ClipboardModal;

import React, { useEffect } from 'react';
import Head from 'next/head';
import { GetServerSidePropsContext } from 'next';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import axios from '@DanbiEduCorp/axios';
import wrapper from '@/configs/reduxWrapper';
import SpinController from '@/library/spin/SpinController';
import APICaller, { APIMonitor } from '@/library/APICaller';
import APICache from '@/library/APICache';
import dynamic from 'next/dynamic';
import { useUserAgent } from 'next-useragent';

const LayerPopupWrapper = dynamic(() => import('@/wrapper/LayerPopupWrapper'), {
  ssr: false,
});
import '@/styles/tailwind.css';
import '@/styles/new_common.css';
import '@/styles/new_style.css';

import AdsScripts from '@/scripts';
import PageContainer from '@/container/PageContainer';
import NoSsrWrapper from '@/wrapper/NoSsrWrapper';
import { HOST_BY_ENV } from '@/constants/common';

export function getServerSideProps(context: GetServerSidePropsContext) {
  return {
    props: {
      uaString: context.req.headers['user-agent'],
    },
  };
}

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const ua = useUserAgent(
    pageProps?.uaString ?? typeof window === 'function'
      ? window?.navigator?.userAgent
      : ''
  );

  // API caller
  useEffect(() => {
    APIMonitor.defaults.healthCheckTime = 10000; // 10s
    APICaller.defaults.caching = false;
    APICaller.defaults.offlineMode = false;
    APICaller.defaults.debug = false;
    APICaller.defaults.timeout = 60000; // 5s
    APICache.initialize();
  }, []);

  axios.interceptors.request.use(
    SpinController.requestShowSpin,
    SpinController.requestError
  );
  axios.interceptors.response.use(
    SpinController.response,
    SpinController.responseError
  );

  useEffect(() => {
    if (
      !HOST_BY_ENV.client.local.test(window.location.hostname) &&
      window.location.protocol === 'http:'
    ) {
      window.location.protocol = 'https:';
    }

    if (!window.location.origin) {
      window.location.href =
        window.location.protocol +
        '//' +
        window.location.hostname +
        (window.location.port ? ':' + window.location.port : '');
    }
  }, []);

  // react-facebook-pixel
  useEffect(() => {
    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init('420194915299099');
        ReactPixel.pageView();

        router.events.on('routeChangeComplete', () => {
          ReactPixel.pageView();
        });
      });
  }, [router.events]);

  useEffect(() => {
    if (ua?.isMobile) {
      if (window.location.hostname.toLowerCase().indexOf('wink.co.kr') !== -1) {
        window.location.href = `https://m.wink.co.kr/${window.location.search}`;
      } else {
        window.location.href = `https://m.danbi.biz/${window.location.search}`;
      }
      return;
    }

    if (ua.isIE) {
      if (Math.floor(ua.browserVersion / 10) <= 9) {
        alert(
          '윙크 홈페이지는 안정화된 서비스 제공을 위하여,\r\n구 버전 브라우저(IE 9이하)에서는 동작하지 않습니다.\r\n\r\n브라우저 업데이트(IE 10 이상)를 진행하시거나,\r\nChrome 브라우저를 이용해주세요.'
        );
        window.location.href =
          'https://www.google.com/chrome/browser/desktop/index.html';
      }
    }
  }, [ua]);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="viewport" content="width=1280" />
        <title>WINK - 가장 많이 선택하는 유초등 1위학습</title>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-10803452974"
        />
        <script
          async
          type="text/javascript"
          src="//t1.daumcdn.net/adfit/static/kp.js"
        ></script>
        <script
          async
          type="text/javascript"
          src="//wcs.naver.net/wcslog.js"
        ></script>
        <script
          async
          src="https://t1.kakaocdn.net/kakao_js_sdk/2.1.0/kakao.min.js"
          integrity="sha384-dpu02ieKC6NUeKFoGMOKz6102CLEWi9+5RQjWSV0ikYSFFd8M3Wp2reIcquJOemx"
          crossOrigin="anonymous"
        ></script>
        <script
          async
          type="text/javascript"
          src="https://cdn.megadata.co.kr/js/en_script/3.5/enliple_min3.5.js"
        ></script>
        <script
          async
          type="text/javascript"
          src="https://xpay.uplus.co.kr/xpay/js/xpay_crossplatform.js"
        ></script>
        <script
          dangerouslySetInnerHTML={{
            __html:
              '!function (w, d, t) { w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)}; ttq.load(\'CONFOA3C77U6GGR45ST0\'); ttq.page(); }(window, document, \'ttq\');',
          }}
        ></script>
      </Head>
      <NoSsrWrapper>
        <AdsScripts />
      </NoSsrWrapper>
      <PageContainer history={router}>
        <Component {...pageProps} history={router} />
      </PageContainer>
      <LayerPopupWrapper history={router} />
    </>
  );
}

export default wrapper.withRedux(MyApp);

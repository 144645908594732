const UserConstants = {
  boardName: '사용자후기',
  boardType: 'PublicBoard',
  pageSize: 10,
  listPage: 'wink/userpost',
  detailPage: 'wink/userpostview',
  modifyPage: 'wink/userpostwrite',
};

const CHECKLIST_SETTINGS = {
  LOAD_LIST: '기존 선택 단계와 동일',
  NEW_LIST: '새로운 단계 선택',
};

const HELLO_LEVEL = {
  안녕한글: 'K_HELLO_1',
  안녕수학: 'M_HELLO_1',
  Hello_English: 'ES_HELLO_1',
  마음동화: 'MIND_HELLO_1',
};

const INITIAL_LEVELS = {
  firstHalf: {
    4: { 한글: 'K_1_1', 수학: 'M_1_1', 스토리영어: 'ES_0_1', 파닉스: 'EP_1_1' },
    5: { 한글: 'K_1_1', 수학: 'M_1_1', 스토리영어: 'ES_0_1', 파닉스: 'EP_1_1' },
    6: { 한글: 'K_2_1', 수학: 'M_2_1', 스토리영어: 'ES_0_1', 파닉스: 'EP_1_1' },
    7: { 한글: 'K_2_2', 수학: 'M_3_1', 스토리영어: 'ES_0_1', 파닉스: 'EP_1_1' },
    8: { 한글: 'K_3_2', 수학: 'M_3_2', 스토리영어: 'ES_1_1', 파닉스: 'EP_1_1' },
    9: { 한글: 'K_4_1', 수학: 'M_4_2', 스토리영어: 'ES_1_1', 파닉스: 'EP_1_1' },
  },
  secondHalf: {
    4: { 한글: 'K_1_1', 수학: 'M_1_1', 스토리영어: 'ES_0_1', 파닉스: 'EP_1_1' },
    5: { 한글: 'K_1_1', 수학: 'M_1_1', 스토리영어: 'ES_0_1', 파닉스: 'EP_1_1' },
    6: { 한글: 'K_2_1', 수학: 'M_2_1', 스토리영어: 'ES_0_1', 파닉스: 'EP_1_1' },
    7: { 한글: 'K_3_1', 수학: 'M_3_1', 스토리영어: 'ES_0_1', 파닉스: 'EP_1_1' },
    8: { 한글: 'K_4_1', 수학: 'M_4_2', 스토리영어: 'ES_1_1', 파닉스: 'EP_1_1' },
    9: { 한글: 'K_4_1', 수학: 'M_4_2', 스토리영어: 'ES_1_1', 파닉스: 'EP_1_1' },
  },
};

const CHECKLIST_STUDY_NAME = {
  K_HELLO_1: {
    level: 0,
    experienceLevel: 1,
    name: '오늘의공부/한글/안녕한글/01회차/01일차',
  },
  M_HELLO_1: {
    level: 0,
    experienceLevel: 1,
    name: '오늘의공부/수학/안녕수학/01회차/01일차',
  },
  ES_HELLO_1: {
    level: 0,
    experienceLevel: 1,
    name: '오늘의공부/스토리영어/Hello_English/01회차/01일차',
  },
  MIND_HELLO_1: {
    level: 0,
    experienceLevel: 1,
    name: '오늘의공부/마음동화/01회차/01일차',
  },
  K_1_1: {
    level: 1,
    experienceLevel: 1,
    name: '오늘의공부/한글/01단계/01회차/01일차',
  },
  K_2_1: {
    level: 2,
    experienceLevel: 1,
    name: '오늘의공부/한글/02단계/01회차/01일차',
  },
  K_2_2: {
    level: 2,
    experienceLevel: 2,
    name: '오늘의공부/한글/02단계/21회차/01일차',
  },
  K_3_1: {
    level: 3,
    experienceLevel: 1,
    name: '오늘의공부/한글/03단계/01회차/01일차',
  },
  K_3_2: {
    level: 3,
    experienceLevel: 2,
    name: '오늘의공부/한글/03단계/21회차/01일차',
  },
  K_4_1: {
    level: 4,
    experienceLevel: 1,
    name: '오늘의공부/한글/04단계/01회차/01일차',
  },
  M_1_1: {
    level: 1,
    experienceLevel: 1,
    name: '오늘의공부/수학/01단계/01회차/01일차',
  },
  M_2_1: {
    level: 2,
    experienceLevel: 1,
    name: '오늘의공부/수학/02단계/06회차/01일차',
  },
  M_3_1: {
    level: 3,
    experienceLevel: 1,
    name: '오늘의공부/수학/03단계/02회차/01일차',
  },
  M_3_2: {
    level: 3,
    experienceLevel: 2,
    name: '오늘의공부/수학/03단계/26회차/01일차',
  },
  M_4_1: {
    level: 4,
    experienceLevel: 1,
    name: '오늘의공부/수학/04단계/01회차/01일차',
  },
  M_4_2: {
    level: 4,
    experienceLevel: 2,
    name: '오늘의공부/수학/04단계/16회차/01일차',
  },
  ES_0_1: {
    level: 0,
    experienceLevel: 1,
    name: '오늘의공부/스토리영어/00단계/01회차/01일차',
  },
  ES_1_1: {
    level: 1,
    experienceLevel: 1,
    name: '오늘의공부/스토리영어/01단계/01회차/01일차',
  },
  ES_2_1: {
    level: 2,
    experienceLevel: 1,
    name: '오늘의공부/스토리영어/02단계/01회차/01일차',
  },
  EP_1_1: {
    level: 1,
    experienceLevel: 1,
    name: '오늘의공부/파닉스/01단계/01회차/01일차',
  },
  EP_2_1: {
    level: 2,
    experienceLevel: 1,
    name: '오늘의공부/파닉스/02단계/01회차/01일차',
  },
};

const CHECKLIST_VALUES = [
  {
    title: '1. 수준별 한글(국어) 단계 선택',
    name: 'q1',
    subject: '한글',
    buttons: [
      {
        value: 'K_1_1',
        level: '1단계',
        label: '본격적인 한글 입문, 통문자 학습',
        description: [
          {
            label: '한글 통문자 학습',
            desc: '한글 학습 경험이 없는 상황에서 통문자 학습부터 시작',
          },
        ],
      },
      {
        level: '2단계',
        label: '한글 기본, 자음/모음 학습',
        buttons: [
          {
            value: 'K_2_1',
            level: '레벨1',
            label: '한글 기본 학습',
            description: [
              {
                label: '글자 분리 학습',
                desc: '낱말을 글자(음절) 단위로 분리하여 읽는 연습부터 차근차근 학습',
              },
            ],
          },
          {
            value: 'K_2_2',
            level: '레벨2',
            label: '한글 자음/모음 학습',
            description: [
              {
                label: '자모음 조합 학습, 낱말 읽기',
                desc: '글자를 이루는 자모음의 이름과 모양을 익히고 자모 조합 원리를 이해하는 학습과 흉내 내는 말 표현력 학습 병행',
              },
            ],
          },
        ],
      },
      {
        level: '3단계',
        label: '한글 완성, 초등 국어 준비',
        buttons: [
          {
            value: 'K_3_1',
            level: '레벨1',
            label: '한글 완성',
            description: [
              {
                label: '1학년 교과 수준 포함 받침 및 문장 학습',
                desc: '글자의 받침을 학습하고, 표현력 학습 및 다양한 구조의 문장 학습 병행',
              },
            ],
          },
          {
            value: 'K_3_2',
            level: '레벨2',
            label: '쓰기, 초등 국어 준비',
            description: [
              {
                label: '1학년 교과 수준 쓰기 연습',
                desc: '쌍자음 및 이중모음이 포함된 자모음 쓰기부터, 어려운 낱말(소리와 표기가 다른 낱말) 쓰기까지 맞춤법 기초 학습',
              },
            ],
          },
        ],
      },
      {
        value: 'K_4_1',
        level: '4단계',
        label: '초등 국어 발전',
        description: [
          {
            label: '2~3학년 교과 수준 국어 포함',
            desc: '문장 쓰기 학습과 함께 국어 문법 학습, 다양한 장르의 글 읽기 병행',
          },
        ],
      },
      {
        level: '5단계',
        label: '초등 국어 강화',
        comment:
          '* 초등 4학년 교과 수준 / 해당 단계는 국어 4단계를 완료한 경우 신청 가능',
        notice: true,
        description: [],
      },
    ],
  },
  {
    title: '2. 수준별 수학 단계 선택',
    name: 'q2',
    subject: '수학',
    buttons: [
      {
        value: 'M_1_1',
        level: '1단계',
        label: '본격적인 수학 입문, 수 기초 개념',
        description: [
          {
            label: '수 기초 개념 및 영역별 준비 학습',
            desc: '수학 학습 경험이 없는 상황에서 운필력(선 긋기) 훈련 및 수에 대한 기초 개념부터 차근차근 학습',
          },
        ],
      },
      {
        value: 'M_2_1',
        level: '2단계',
        label: '수학 입문2, 영역별 기초 학습',
        description: [
          {
            label: '두 자리 수 개념 및 영역별 기초 개념 학습',
            desc: '덧뺄셈의 기초 개념 학습과 높이, 무게, 거리 등에 대한 기초 개념 학습을 병행',
          },
        ],
      },
      {
        level: '3단계',
        label: '초등 수학 준비, 연산 포함',
        buttons: [
          {
            value: 'M_3_1',
            level: '레벨1',
            label: '연산 입문, 초등 수학 준비',
            description: [
              {
                label: '100까지의 수 확장, 덧셈 기초 학습',
                desc: '자릿값에 대한 기초 정립 및 덧셈 입문 학습, 규칙성(패턴) 학습으로 추론 능력 습득 *초등 1학년 교과 수준',
              },
            ],
          },
          {
            value: 'M_3_2',
            level: '레벨2',
            label: '연산 훈련, 초등 수학 발전',
            description: [
              {
                label: '덧셈 연습 및 뺄셈 기초 연습',
                desc: '받아올림/받아내림 없는 두 자리 수 ± 한 자리 수 반복 훈련, 수량을 자료화 하는 그래프 학습 *초등 2학년 교과 수준 내용 일부 포함',
              },
            ],
          },
        ],
      },
      {
        level: '4단계',
        label: '초등 수학 발전, 심화 개념',
        buttons: [
          {
            value: 'M_4_1',
            level: '레벨1',
            label: '연산 심화, 초등 수학 발전',
            description: [
              {
                label: '1~2학년 수준의 심화 덧뺄셈 학습',
                desc: '받아올림/받아내림 있는 두 자리 수 ± 한 자리 수, 시계 읽기 기초 학습, 스토리텔링형 문장제 학습 시작',
              },
            ],
          },
          {
            value: 'M_4_2',
            level: '레벨2',
            label: '세 자리 수 연산, 초등 수학 심화',
            description: [
              {
                label: '2~3학년 수준의 세 자리 수, 곱셈 학습',
                desc: '999까지의 수 확장, 받아올림/받아내림 1~2회 있는 두 자리 수끼리의 덧뺄셈, 시간 개념 및 곱셈 학습',
              },
            ],
          },
        ],
      },
      {
        level: '5단계',
        label: '초등 수학 강화',
        comment:
          '* 초등 4학년 교과 수준 / 해당 단계는 수학 4단계를 완료한 경우 신청 가능',
        notice: true,
        description: [],
      },
    ],
  },
  {
    title: '3. 수준별 영어 단계 선택',
    name: 'q3',
    subject: '스토리영어',
    buttons: [
      {
        value: 'ES_0_1',
        level: '첫걸음 단계',
        label: 'Basic Level, 4~6세 권장',
        description: [
          {
            label: '영어를 처음 접하는 4~6세 권장',
            desc: '텍스트 양이 적은 그림책으로 낱말과 간단한 생활 표현 위주의 기초 학습 *초등 3학년 영어 교과 수준 커버',
          },
        ],
      },
      {
        value: 'ES_1_1',
        level: '1단계',
        label: 'Intermediate Level, 6~8세 권장',
        description: [
          {
            label: '최소 1년 이상 영어 학습 경험이 있는 6~8세 권장',
            desc: '1, 2인 위주의 간단한 표현이 반복되는 짧은 이야기로 학습 *초등 3~4학년 영어 교과 수준 커버',
          },
        ],
      },
      {
        value: 'ES_2_1',
        level: '2단계',
        label: 'Advanced Level, 7~9세 권장',
        description: [
          {
            label:
              '윙크 영어 1단계를 마스터하거나 영어권에서 2년 이상 거주한 경험 있는 7~9세 권장',
            desc: '주변 생활에서 벌어지는 일을 주제로 다룬 이야기를 읽으며 조금 더 길어진 3인칭까지의 문장(표현)을 학습 *초등 3~5학년 영어 교과 수준 커버',
          },
        ],
      },
      {
        level: '3단계',
        label: 'Independent reading Level',
        comment:
          '* 초등 5학년 이상의 교과 수준 커버, 해당 단계는 2단계를 마스터하거나 영어를 잘 구사할 수 있는 9~10세 이상인 경우 신청 가능',
        notice: true,
        description: [],
      },
    ],
  },
  {
    title: '4. 수준별 파닉스 단계 선택',
    name: 'q4',
    subject: '파닉스',
    buttons: [
      {
        value: 'EP_1_1',
        level: '1단계',
        label: '파닉스 기초',
        description: [
          {
            label:
              '알파벳 A부터 Z까지의 기본 음가(소리)와 함께 해당 발음이 포함된 낱말 약 140개를 학습',
          },
        ],
      },
      {
        value: 'EP_2_1',
        level: '2단계',
        label: '파닉스 강화',
        description: [
          {
            label:
              'A부터 Z까지의 기본 음가 외에 그보다 높은 단계인 단모음, 장모음 등의 발음이 포함된 낱말 약 140개를 학습',
          },
        ],
      },
      {
        level: '3, 4단계',
        label: '',
        comment: '* 2단계를 완료한 경우 이어서 학습 진행 가능',
        notice: true,
        description: [],
      },
    ],
    description: {
      top: '총 4단계로 구성되어 있으며, A~Z까지의 알파벳 기본 음가를 모르면 1단계, 알고 있으면 2단계부터 4단계까지 순차적으로 학습합니다.',
    },
  },
];

const CHECKLIST_VALUES_PAID = [
  {
    title:
      '01. 윙크 “한글/국어” 학습 단계 설정을 위한 문항입니다. 해당하는 항목에 체크해 주세요.',
    name: 'q1',
    subject: '한글',
    buttons: [
      {
        value: '1',
        label: '1단계(한글 입문 단계, 한글 통문자 학습)',
        description: [
          { type: 'bold', label: '한글 학습 경험이 없는 상황' },
          { type: 'normal', label: '에서 통문자 학습부터 시작' },
        ],
      },
      {
        value: '2',
        label: '2단계(한글 기본 단계, 자음모음학습과 낱말읽기)',
        description: [
          { type: 'bold', label: '낱말을 띄엄띄엄 읽는 수준' },
          {
            type: 'normal',
            label: '에서 자음과 모음의 조합부터 차근차근 학습',
          },
        ],
      },
      {
        value: '3',
        label:
          '3단계(한글 완성 & 초등 국어 준비 단계, 1학년 교과 수준 국어 포함)',
        description: [
          { type: 'normal', label: '다양한 문장을 읽으며 ' },
          {
            type: 'bold',
            label:
              '문장의 구조에 대한 이해 학습과 받침이 있는 낱말 쓰기 학습 병행',
          },
        ],
      },
      {
        value: '4',
        label: '4단계(초등 국어 발전 단계, 2~3학년 교과 수준 국어 포함)',
        description: [
          { type: 'bold', label: '문장 쓰기 학습과 함께 국어 문법 학습, ' },
          { type: 'normal', label: '다양한 장르의 글 읽기 병행' },
        ],
      },
      {
        value: '5',
        label: '5단계(초등 국어 강화 단계, 4학년 교과 수준 국어 포함)',
        notice: true,
        description: [
          {
            type: 'bold',
            label: '문장의 형태를 문법적으로 이해하고 표현하는 학습',
          },
          {
            type: 'normal',
            label: '과 여러 장르의 글을 읽고 독해력 및 사고력 향상 학습 병행',
          },
          {
            type: 'underline',
            nextLine: true,
            label: '(윙크 국어 4단계를 학습 완료한 경우만 신청 가능)',
          },
        ],
      },
    ],
  },
  {
    title:
      '02. 윙크 “수학” 학습 단계 설정을 위한 문항입니다. 해당하는 항목에 체크해 주세요.',
    name: 'q2',
    subject: '수학',
    buttons: [
      {
        value: '1',
        label: '1단계(수학 입문 1단계, 수 기초 개념 및 영역별 준비 학습)',
        description: [
          { type: 'bold', label: '수학 학습 경험이 없는 상황' },
          {
            type: 'normal',
            label:
              '에서 운필력(선긋기) 훈련 및 수에 대한 기초 개념부터 차근차근 학습',
          },
        ],
      },
      {
        value: '2',
        label: '2단계(수학 입문 2단계, 두자리수 개념 및 영역별 기초 개념)',
        description: [
          { type: 'bold', label: '두 자리 수 학습. ' },
          {
            type: 'normal',
            label:
              '덧, 뺄셈의 기초 개념 학습과 높이, 무게, 거리 등에 대한 기초 개념 학습을 병행',
          },
        ],
      },
      {
        value: '3',
        label:
          '3단계(초등 수학 준비 단계, 1~2학년 교과 수준 연산 및 영역별 확장 개념 포함)',
        description: [
          {
            type: 'bold',
            label: '받아올림이나 받아내림이 없는 덧셈과 뺄셈을 반복 숙달',
          },
          {
            type: 'normal',
            label: '하며, 규칙성(패턴)학습으로 추론 능력까지 습득',
          },
        ],
      },
      {
        value: '4',
        label:
          '4단계(초등 수학 발전 단계, 2~3학년 교과 수준 연산 및 영역별 심화 개념 포함)',
        description: [
          {
            type: 'bold',
            label:
              '받아올림이나 받아내림이 있는 두 자리 수 덧셈과 뺄셈, 곱셈을 반복 숙달,',
          },
          { type: 'normal', label: ' 스토리텔링형 문장제 학습 시작' },
        ],
      },
      {
        value: '5',
        label: '5단계(초등 수학 강화 단계, 4학년 교과 수준 심화 연산 포함)',
        notice: true,
        description: [
          {
            type: 'bold',
            label:
              '받아올림이나 받아내림이 있는 세 자리 수 이상의 고난이도 사칙연산',
          },
          { type: 'normal', label: '과 분수, 소수의 개념 및 덧,뺄셈까지 터득' },
          {
            type: 'underline',
            nextLine: true,
            label: '(윙크 수학 4단계를 학습 완료한 경우만 신청 가능)',
          },
        ],
      },
    ],
    description: {
      top: '윙크 수학은 "수와 셈, 도형, 규칙성, 분류, 측정, 통계" 등 유아 수학의 모든 영역을 다루지만, 학부모님의 판단 편의를 위해 본 진단에서는 "수와 셈" 영역을 기준으로 진단합니다.',
    },
  },
  {
    title:
      '03. 윙크 “스토리 영어” 학습 단계 설정을 위한 문항입니다. 해당하는 항목에 체크해 주세요.',
    name: 'q3',
    subject: '스토리영어',
    buttons: [
      {
        value: '0',
        label:
          '첫걸음 단계 (Basic Level, 영어를 처음 접하는 4~6세 권장, 초등 3학년 영어 교과 수준 커버)',
        description: [
          {
            type: 'bold',
            label:
              '텍스트 양이 적은 그림책으로 낱말과 간단한 생활 표현 위주의 기초 학습. ',
          },
          {
            type: 'underline',
            label: '약 200개의 필수 단어와 약 80개의 기초 생활 표현 제공',
          },
        ],
      },
      {
        value: '1',
        label:
          '1단계 (Intermediate Level, 최소 1년 이상 영어 학습 경험있는 6~8세 권장, 초등 3~4학년 영어 교과 수준 커버)',
        description: [
          {
            type: 'bold',
            label:
              '1, 2인칭 위주의 간단한 표현이 반복되는 짧은 이야기로 학습. ',
          },
          {
            type: 'underline',
            label:
              '약 200개 이상의 필수 단어와 100개 이상의 생활 표현 문장 제공',
          },
        ],
      },
      {
        value: '2',
        label:
          '2단계 (Advanced Level, 윙크 영어 1단계를 마스터하거나 영어권에서 2년 이상 거주한 경험있는 7~9세 권장, 초등 3~5학년 영어 교과 수준 커버)',
        description: [
          {
            type: 'normal',
            label: '주변 생활에서 벌어지는 일을 주제로 다룬 이야기를 읽으며 ',
          },
          {
            type: 'bold',
            label: '조금 더 길어진 3인칭까지의 문장(표현)을 학습. ',
          },
          {
            type: 'underline',
            label:
              '약 250개 이상의 필수 단어와 약 100개정도의 생활 표현 문장 제공',
          },
        ],
      },
      {
        value: '3',
        label:
          '윙크 영어 3단계(Independent reading Level, 윙크 영어 2단계를 마스터하거나 영어를 잘 구사할 수 있는 9~10세, 초등 5학년 이상의 영어 교과 수준 커버) 이후는 1, 2단계 완료 후 순차적으로 제공됩니다.',
        notice: true,
        description: [
          {
            type: 'normal',
            label: '(학습 진행 중 수준이 검증되면 3단계로 변경 가능)',
          },
        ],
      },
    ],
  },
  {
    title:
      '04. 윙크 “파닉스” 영어 학습 단계 설정을 위한 문항입니다. 해당하는 항목에 체크해 주세요.',
    name: 'q4',
    subject: '파닉스',
    buttons: [
      {
        value: '1',
        label: '1단계 (파닉스 기초)',
        description: [
          { type: 'bold', label: '알파벳 A부터 Z까지의 기본 음가(소리)' },
          {
            type: 'normal',
            label: '와 함께 해당 발음이 포함된 낱말 약 140개를 학습',
          },
        ],
      },
      {
        value: '2',
        label: '2단계 (파닉스 강화)',
        description: [
          { type: 'normal', label: 'A부터 Z까지의 기본 음가 외에 ' },
          { type: 'bold', label: '그 보다 높은 단계인 단모음, 장모음 ' },
          { type: 'normal', label: '등의 발음이 포함된 낱말 약 140개를 학습' },
        ],
      },
    ],
    description: {
      top: '윙크 파닉스는 모두 4단계로 구성되며, A~Z까지의 알파벳 기본 음가를 모르면 1단계, 알고 있으면 2단계부터 4단계까지 순차적으로 학습을 합니다.',
    },
  },
];

export {
  UserConstants,
  CHECKLIST_SETTINGS,
  CHECKLIST_VALUES,
  CHECKLIST_VALUES_PAID,
  CHECKLIST_STUDY_NAME,
  INITIAL_LEVELS,
  HELLO_LEVEL,
};

import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Link from 'next/link';
import { DateFormat } from '../../../library/constants/Date';
import DesktopLayerPopup from '@/features/common/DesktopLayerPopup';
import ErrorLabel from '@/features/common/components/ErrorLabel';
import * as MemberAction from '../MemberAction';
import LoginPageInjector from '@/features/common/LoginPageInjector';
import SelectDateMadeOfSelectTag from '@/features/common/components/SelectDateMadeOfSelectTag';
import { Radio, RadioGroup } from '../../../library/Radio';
import ButtonBlockUtil from '../../../library/util/ButtonBlockUtil';
import { routes } from '@/constants/routes';

class MemberIdIssueFinishPop extends Component {
  constructor(props) {
    super(props);
    this.inputRef = {};

    this.state = {
      name: '',
      radio: 'female',
      dateOfBirth: '',
      disable: false,
      year: '',
      month: '',
      day: '',
      error: {
        name: false,
        dateOfBirth: false,
      },
    };

    this.onChange = this.onChange.bind(this);
    this.onRadio = this.onRadio.bind(this);
    this.validateChildInfoRegist = this.validateChildInfoRegist.bind(this);
    this.requestChildInfoRegist = this.requestChildInfoRegist.bind(this);
    this.onClose = this.onClose.bind(this);
    this.showErrorLabel = this.showErrorLabel.bind(this);
    this.hideErrorLabel = this.hideErrorLabel.bind(this);
    this.SelectDateCallback = this.SelectDateCallback.bind(this);

    this.ButtonBlockUtil = new ButtonBlockUtil();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if ('successRecommender' === nextProps.action) {
      DesktopLayerPopup.notice({
        message: '등록하였습니다.',
      });
      this.ButtonBlockUtil.stop();
      this.props.clearAction();
    } else if ('failRecommender' === nextProps.action) {
      DesktopLayerPopup.notice({
        message: '일치하는 회원이 없습니다.',
      });
      this.ButtonBlockUtil.stop();
      this.props.clearAction();
    } else if ('successChildInfoRegist' === nextProps.action) {
      DesktopLayerPopup.notice({
        message: '등록되었습니다.',
        callback: () => {
          DesktopLayerPopup.hide(this.props.layerKey);
        },
      });
      this.ButtonBlockUtil.stop();
      this.props.clearAction();
    } else if ('failChildInfoRegist' === nextProps.action) {
      DesktopLayerPopup.notice({
        message: '등록에 실패하였습니다.',
      });
      this.ButtonBlockUtil.stop();
      this.props.clearAction();
    }
  }

  onChange(e, value, type) {
    this.setState(
      {
        [type]: value,
      },
      () => {
        this.hideErrorLabel();
      }
    );
  }

  onRadio(value) {
    this.setState({
      radio: value,
    });
  }

  validateChildInfoRegist(isShowError) {
    //이름 없음
    if ('' === this.state.name.trim()) {
      this.showErrorLabel('name', isShowError);
      if (isShowError) {
        this.inputRef.name.focus();
      }
      return false;
    }
    //생년월일 체크
    if (
      '' === this.state.year ||
      '' === this.state.month ||
      '' === this.state.day
    ) {
      this.showErrorLabel('dateOfBirth', isShowError);

      return false;
    }
    if (
      moment(moment().format(DateFormat.half)).isBefore(this.state.dateOfBirth)
    ) {
      //어떻게 출력할지 문의필요
      return false;
    }
    return true;
  }

  requestChildInfoRegist(e) {
    e.preventDefault();
    let isShowError = true;
    if (!this.validateChildInfoRegist(isShowError)) {
      return;
    }
    if (this.ButtonBlockUtil.isBlocking()) {
      //2초동안 재요청올때는 이곳에 걸립니다.
      return;
    }

    //api 호출
    let { setStudentMemberAndLoginSecondary } = this.props;
    let { name, radio, year, month, day } = this.state;
    let isMale = 'male' === radio ? true : false;
    setStudentMemberAndLoginSecondary(
      name,
      isMale,
      moment(`${year}-${month}-${day}`).toDate()
    );
  }

  //타입과 일치하는 에러라벨만 출력됩니다.
  showErrorLabel(type, isShowError) {
    //isShowError가 false이면 아래 코드를 실행하지 않습니다.
    if (!isShowError) {
      return;
    }
    let errorState = { ...this.state.error };

    Object.keys(errorState).forEach((key, idx) => {
      if (key === type) {
        errorState[key] = true;
      } else {
        errorState[key] = false;
      }
    });

    this.setState({
      error: errorState,
    });
  }
  //모든 에러라벨을 숨깁니다.
  hideErrorLabel() {
    let errorState = { ...this.state.error };

    Object.keys(errorState).forEach((key, idx) => {
      errorState[key] = false;
    });

    this.setState({
      error: errorState,
    });
  }

  SelectDateCallback(date) {
    this.setState(
      {
        year: date.year,
        month: date.month,
        day: date.day,
      },
      () => {
        this.hideErrorLabel();
      }
    );
  }

  onClose() {
    DesktopLayerPopup.hide(this.props.layerKey);
  }

  render() {
    let { error } = this.state;

    return (
      <div className="layer-wrap large member" style={{ marginTop: '-280px' }}>
        <div className="layer-setting member-issue-finish-pop">
          <h2>회원정보 등록 완료</h2>
          {/* layer scroll */}
          <div className="layer-scroll">
            <div className="scroll member">
              <div className="regster-box">
                <strong>회원정보가 등록되었습니다.</strong>
                <p>
                  <strong>우리 아이의 연령 등 간단한 정보를 등록하시면!</strong>
                  <span>
                    <em>하나,</em> 아이의 단계에 맞는 학습 콘텐츠를 무료로 볼 수
                    있어요!
                  </span>
                  <span>
                    <em>둘,</em> 학부모님은 물론, 아이의 발달, 수준 등의 무료
                    검사도 받아볼 수 있어요!
                  </span>
                  지금 바로 등록하고 다양한 혜택을 무료로 받아보세요.
                </p>
              </div>
              <table className="form finish-form">
                <tbody>
                  <tr>
                    <th scope="row">이름</th>
                    <td>
                      <fieldset>
                        <legend>이름입력</legend>
                        <input
                          type="text"
                          name
                          title="이름 입력"
                          placeholder="이름을 입력해 주세요."
                          ref={(ref) => {
                            this.inputRef.name = ref;
                          }}
                          onChange={(e) => {
                            this.onChange(e, e.target.value, 'name');
                          }}
                        />
                      </fieldset>
                      {error.name && (
                        <ErrorLabel label={'이름을 입력해 주세요.'} />
                      )}
                    </td>
                  </tr>
                  <tr className="checkbox">
                    <th scope="row">성별</th>
                    <td>
                      <fieldset>
                        <legend>성별선택</legend>
                        <RadioGroup
                          name="gender"
                          selectedValue={'female'}
                          onChange={this.onRadio}
                          mode={'column'}
                        >
                          <div>
                            <Radio
                              value="female"
                              label="여자아이"
                              name="gender"
                            />
                            <Radio
                              value="male"
                              label="남자아이"
                              name="gender"
                            />
                          </div>
                        </RadioGroup>
                      </fieldset>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">생년월일</th>
                    <td>
                      <SelectDateMadeOfSelectTag
                        callback={this.SelectDateCallback}
                      />
                      {/* <SelectDate callback={this.SelectDateCallback}/> */}
                      {error.dateOfBirth && (
                        <ErrorLabel label={'생년월일을 선택해 주세요.'} />
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="btn-area">
            <Link href={routes.home} className="btn-type2">
              윙크 홈으로 가기
            </Link>
            <a
              href="#"
              className="btn-type2 on"
              onClick={this.requestChildInfoRegist}
            >
              아이 정보 등록
            </a>
          </div>
        </div>
        <button className="close" onClick={this.onClose}>
          팝업 닫기
        </button>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    clearAction: () => {
      dispatch(MemberAction.clearAction());
    },
    setStudentMemberAndLoginSecondary: (name, isMale, birthday) => {
      dispatch(
        MemberAction.setStudentMemberAndLoginSecondary(name, isMale, birthday)
      );
    },
    recommender: (recommend) => {
      dispatch(MemberAction.recommender(recommend));
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    action: state.MemberReducer.action,
    auth: state.MemberReducer.auth,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginPageInjector(MemberIdIssueFinishPop));

import axios from '@DanbiEduCorp/axios';

export default class ExternalServices {
  /*
	{
		"version":"3.4.1",
		"error":"",
		"msg":"",
		"count":1,
		"time":"0.001",
		"lang":"KO",
		"sort":"JIBEON",
		"type":"JIBEON+NUMS",
		"nums":"519-8",
		"cache":"MISS",
		"results":[{
			"postcode5":"12772",
			"postcode6":"464922",
			"ko_common":"경기도 광주시 오포읍",
			"ko_doro":"수레실길 53-6",
			"ko_jibeon":"능평리 519-8",
			"en_common":"Opo-eup, Gwangju-si, Gyeonggi-do",
			"en_doro":"53-6, Suresil-gil",
			"en_jibeon":"519-8, Neungpyeong-ri",
			"building_id":"4161025023105190008000001",
			"building_name":"롯데타운",
			"building_nums":"108동",
			"other_addresses":"",
			"road_id":"416104433306",
			"internal_id":"5774955"
		}]
	}
*/
  static getRoadAddress(query) {
    return axios({
      method: 'get',
      url:
        '//api.poesis.kr/post/search.php?pc=5&q=' + encodeURIComponent(query),
      responseType: 'json',
      withCredentials: false,
    });
  }
}

import moment from 'moment';
import PaymentServices from '../services/PaymentServices';
import { exception } from '../error/ExceptionAction';
import APICaller from '../APICaller';
import SpinController from '../spin/SpinController';
import { ShopProductSubjectList } from '../constants/Code';
import { DateFormat } from '../constants/Date';

export const ActionTypes = Object.freeze({
  COMPLETED_ACTION: '@@PAYMENT/COMPLETED_ACTION',

  INIT_ADD_PAYMENT: '@@PAYMENT/INIT_ADD_PAYMENT',
  SET_AUTO_PAYMENT: '@@PAYMENT/SET_AUTO_PAYMENT',
  SET_NOTIFY_EMAIL: '@@PAYMENT/SET_NOTIFY_EMAIL',
  SET_USER_INFO: '@@PAYMENT/SET_USER_INFO',
  PROCESS_PAYMENT: '@@PAYMENT/PROCESS_PAYMENT',
  SET_PAYMENT_PRODUCT: '@@PAYMENT/SET_PAYMENT_PRODUCT',
  SET_PAYMENT_PAYTYPE: '@@PAYMENT/SET_PAYMENT_PAYTYPE',
  SET_PAYMENT_CHECKLIST: '@@PAYMENT/SET_PAYMENT_CHECKLIST',

  REQUEST_ADD_AUTO_PAYMENT: '@@PAYMENT/REQUEST_ADD_AUTO_PAYMENT',
  ADD_AUTO_PAYMENT: '@@PAYMENT/ADD_AUTO_PAYMENT',
  FAIL_ADD_AUTO_PAYMENT: '@@PAYMENT/FAIL_ADD_AUTO_PAYMENT',

  REQUEST_CONFIRM_AUTO_PAYMENT: '@@PAYMENT/REQUEST_CONFIRM_AUTO_PAYMENT',
  CONFIRM_AUTO_PAYMENT: '@@PAYMENT/CONFIRM_AUTO_PAYMENT',
  CANCEL_AUTO_PAYMENT: '@@PAYMENT/CANCEL_AUTO_PAYMENT',
  CHANGE_AUTO_PAYMENT: '@@PAYMENT/CHANGE_AUTO_PAYMENT',
  REQUEST_CHANGE_AUTO_PAYMENT_CREDITCARD:
    '@@PAYMENT/REQUEST_CHANGE_AUTO_PAYMENT_CREDITCARD',
  CHANGE_AUTO_PAYMENT_CREDITCARD: '@@PAYMENT/CHANGE_AUTO_PAYMENT_CREDITCARD',
  REQUEST_UNPAYID_PAYMENT: '@PAYMENT/REQUEST_UNPAYID_PAYMENT',
  SUCCESS_UNPAYID_PAYMENT: '@PAYMENT/REQUEST_UNPAYID_PAYMENT',
  FAIL_UNPAYID_PAYMENT: '@PAYMENT/FAIL_UNPAYID_PAYMENT',

  INIT_PRODUCT: '@@PAYMENT/INIT_PRODUCT',
  INIT_COUPON: '@@PAYMENT/INIT_COUPON',
  INIT_APPLY_INFO: '@@PAYMENT/INIT_APPLY_INFO',
  INIT_CHECK_LIST: '@@PAYMENT/INIT_CHECK_LIST',

  MODIFY_DELIVERY_MESSAGE: '@@PAYMENT/MODIFY_DELIVERY_MESSAGE',
  GET_EXPERIENCE_COUNT: '@@PAYMENT/GET_EXPERIENCE_COUNT',
  POST_STUDY_STEP_DIAGNOSIS: '@@PAYMENT/POST_STUDY_STEP_DIAGNOSIS',
  APPLY_EXPERIENCE: '@@PAYMENT/APPLY_EXPERIENCE',
  SET_APPLY_TYPE: '@@PAYMENT/SET_APPLY_TYPE',

  GET_PRODUCT_LIST: '@@PAYMENT/GET_PRODUCT_LIST',
  REQUEST_GET_COUPON_LIST: '@@PAYMENT/REQUEST_GET_COUPON_LIST',
  REQUEST_GET_SHOP_PRODUCT_LIST: '@@PAYMENT/REQUEST_GET_SHOP_PRODUCT_LIST',
  SUCCESS_GET_SHOP_PRODUCT_LIST: '@@PAYMENT/SUCCESS_GET_SHOP_PRODUCT_LIST',
  GET_COUPON_LIST: '@@PAYMENT/GET_COUPON_LIST',
  SET_COUPONS: '@@PAYMENT/SET_COUPONS',
  ADD_COUPONS: '@@PAYMENT/ADD_COUPONS',

  INIT_DATA_FOR_NOT_LOGIN: '@@PAYMENT/INIT_DATA_FOR_NOT_LOGIN',
  SET_PAYMENT_SELECTED_STUDENT_INFO:
    '@@PAYMENT/SET_PAYMENT_SELECTED_STUDENT_INFO',

  REQUEST_AUTO_PAYMENT_CONTRACT_GET_LIST:
    '@@PAYMENT/REQUEST_AUTO_PAYMENT_CONTRACT_GET_LIST',
  SUCCESS_AUTO_PAYMENT_CONTRACT_GET_LIST:
    '@@PAYMENT/SUCCESS_AUTO_PAYMENT_CONTRACT_GET_LIST',

  REQUEST_AUTO_PAYMENT_DETAIL_GETLIST:
    '@@PAYMENT/REQUEST_AUTO_PAYMENT_DETAIL_GETLIST',
  SUCCESS_AUTO_PAYMENT_DETAIL_GETLIST:
    '@@PAYMENT/SUCCESS_AUTO_PAYMENT_DETAIL_GETLIST',

  REQUEST_AUTO_PAYMENT_GET_INFO: '@@PAYMENT/REQUEST_AUTO_PAYMENT_GET_INFO',
  SUCCESS_AUTO_PAYMENT_GET_INFO: '@@PAYMENT/SUCCESS_AUTO_PAYMENT_GET_INFO',

  INIT_PAYMENT_REDUCER: '@@PAYMENT/INIT_PAYMENT_REDUCER',
  //단과 > 종합로 변경
  REQUEST_CHANGE_CONTRACT: '@@PAYMENT/REQUEST_CHANGE_CONTRACT',
  SUCCESS_CHANGE_CONTRACT: '@@PAYMENT/SUCCESS_CHANGE_CONTRACT',
  FAIL_CHANGE_CONTRACT: '@@PAYMENT/FAIL_CHANGE_CONTRACT',

  //학생결제 이력조회
  REQUEST_STUDENT_PAYMENT_CONTRACT:
    '@@PAYMENT/REQUEST_STUDENT_PAYMENT_CONTRACT',
  SUCCESS_STUDENT_PAYMENT_CONTRACT:
    '@@PAYMENT/SUCCESS_STUDENT_PAYMENT_CONTRACT',
  FAIL_STUDENT_PAYMENT_CONTRACT: '@@PAYMENT/FAIL_STUDENT_PAYMENT_CONTRACT',
  CLEAR_STUDENT_PAYMENT_CONTRACT: '@@PAYMENT/FAIL_STUDENT_PAYMENT_CONTRACT',

  SET_PAYMENT_MODE: '@@PAYMENT/SET_PAYMENT_MODE',
  CLEAR_PAYMENT_MODE: '@@PAYMENT/CLEAR_PAYMENT_MODE',

  CLEAR_ACTION: '@@PAYMENT/CLEAR_ACTION',

  SET_LOG_ID: '@@PAYMENT/SET_LOG_ID',

  SET_SELECTED_STUDENT_INFO_LIST: '@@PAYMENT/SET_SELECTED_STUDENT_INFO_LIST',
  SET_STUDENT_INFO_LIST: '@@PAYMENT/SET_STUDENT_INFO_LIST',

  SET_RENTAL_BOOK_LIST_INFO: '@@PAYMENT/SET_RENTAL_BOOK_LIST_INFO',
});

export function initPayment(
  platform,
  client_origin,
  pg_code,
  notify_email,
  mobile_os_type = undefined
) {
  return {
    type: ActionTypes.INIT_ADD_PAYMENT,
    payload: {
      platform,
      client_origin,
      pg_code,
      notify_email,
      mobile_os_type,
    },
  };
}

export function completedAction() {
  return {
    type: ActionTypes.COMPLETED_ACTION,
  };
}

export function initApplyInfo() {
  return {
    type: ActionTypes.INIT_APPLY_INFO,
  };
}

export function initCheckList() {
  return {
    type: ActionTypes.INIT_CHECK_LIST,
  };
}

export function initCoupon() {
  return {
    type: ActionTypes.INIT_COUPON,
  };
}

export function initProduct() {
  return {
    type: ActionTypes.INIT_PRODUCT,
  };
}

export function setNotifyEmail(notifyEmail) {
  return {
    type: ActionTypes.SET_NOTIFY_EMAIL,
    payload: {
      notify_email: notifyEmail,
    },
  };
}

export function setUserInfo(userInfo) {
  return {
    type: ActionTypes.SET_USER_INFO,
    payload: {
      user_info: userInfo,
    },
  };
}

export function processPayment(isProcssPayment) {
  return {
    type: ActionTypes.PROCESS_PAYMENT,
    payload: {
      isProcssPayment: isProcssPayment,
    },
  };
}

export function setSelectedStudentInfoList(selectedStudentInfoList) {
  return (dispatch, getState) =>
    dispatch(() => {
      return dispatch({
        type: ActionTypes.SET_SELECTED_STUDENT_INFO_LIST,
        payload: {
          selectedStudentInfoList,
        },
      });
    });
}

export function getSelectedStudentInfo(selectedStudentInfo) {
  return (dispatch, getState) =>
    dispatch(() => {
      const studentInfo = getState().PaymentReducer.selectedStudentInfo;
      if (!studentInfo || studentInfo.id !== selectedStudentInfo.id) {
        dispatch({
          type: ActionTypes.INIT_CHECK_LIST,
          payload: {},
        });
      }

      return dispatch({
        type: ActionTypes.SET_PAYMENT_SELECTED_STUDENT_INFO,
        payload: {
          selectedStudentInfo,
        },
      });
    });
}

export function setStudentInfoList(studentInfoList) {
  return {
    type: ActionTypes.SET_STUDENT_INFO_LIST,
    payload: {
      studentInfoList,
    },
  };
}

export function setCheckList(checkList) {
  return {
    type: ActionTypes.SET_PAYMENT_CHECKLIST,
    payload: {
      checkList,
    },
  };
}

//productType은 Code.js의 ContractTwoYear로 설정하였습니다.
// 이전 / 다음의 기능이 있어 type값을 저장하여 이전 다음 했을때 상태를 유지해 줍니다.
export function setProduct(
  productInfo,
  amount,
  monthly,
  contract_years,
  contract_end_date,
  selectedProductItem
) {
  return {
    type: ActionTypes.SET_PAYMENT_PRODUCT,
    payload: {
      action: 'setPaymentProduct',
      productInfo,
      amount,
      monthly,
      contract_years,
      contract_end_date,
      selectedProductItem,
    },
  };
}

export function setPayType(payType) {
  return {
    type: ActionTypes.SET_PAYMENT_PAYTYPE,
    payload: {
      payType: payType,
    },
  };
}

export function setCoupon(selectCoupon, discountAmount) {
  return {
    type: ActionTypes.SET_COUPONS,
    payload: {
      selectCoupon,
      discountAmount,
    },
  };
}

export function addCoupon(coupon) {
  return (dispatch, getState) =>
    dispatch(() => {
      let coupons = '';
      let contractCoupon = '';
      if (coupon !== '') {
        contractCoupon = coupon;
      } else {
        contractCoupon =
          getState().PaymentReducer.selectedProductItem.contractCoupon;
      }

      let userCoupon;
      if (
        getState().PaymentReducer.selectCoupon.data &&
        getState().PaymentReducer.selectCoupon.data.name
      ) {
        userCoupon = getState().PaymentReducer.selectCoupon.data.name;
      }
      if (contractCoupon) {
        coupons += contractCoupon;
      }
      if (userCoupon) {
        if (coupons !== '') {
          coupons += ',';
        }
        coupons += userCoupon;
      }

      return dispatch({
        type: ActionTypes.ADD_COUPONS,
        payload: {
          coupons,
        },
      });
    });
}

export function setAutoPayment(
  isAutoPayment,
  buyer_ssn,
  installment,
  check_ssn,
  paynow_only = 'N'
) {
  return {
    type: ActionTypes.SET_AUTO_PAYMENT,
    payload: {
      isAutoPayment,
      installment,
      buyer_ssn,
      check_ssn,
      paynow_only,
    },
  };
}
export function addNaverPayAutoPayment(
  params,
  request_channel,
  delivery_message,
  levels,
  register_code = '',
  content_info
) {
  return (dispatch, getState) =>
    dispatch(() => {
      const { user_info } = getState().PaymentReducer;
      const addAutoParams = {
        ...params,
        delivery_message: delivery_message ? delivery_message : '',
        request_channel: request_channel,
        levels: { ...levels },
        levels_regist_date: moment().utc().format(),
        ...content_info,
      };
      if (register_code && register_code !== '') {
        addAutoParams.register_code = register_code;
      }

      if (content_info) {
        addAutoParams.is_use_content_info = true;
      }

      if (user_info.recommender_name) {
        addAutoParams.recommender_name = user_info.recommender_name;
      }

      if (user_info.branchoffice_code) {
        addAutoParams.branchoffice_code = user_info.branchoffice_code;
      }

      dispatch({
        type: ActionTypes.REQUEST_ADD_AUTO_PAYMENT,
        payload: {
          addAutoParams,
        },
      });

      return PaymentServices.addNaverPayPayment(addAutoParams)
        .then((response) => {
          return dispatch({
            type: ActionTypes.ADD_AUTO_PAYMENT,
            payload: {
              info: response.data,
            },
          });
        })
        .catch((e) => {
          dispatch({
            type: ActionTypes.FAIL_ADD_AUTO_PAYMENT,
            payload: {},
          });
          return dispatch(
            exception(e, '자동 결제 등록중 오류가 발생하였습니다.', 'goback')
          );
        });
    });
}

export function addAutoPayment(
  params,
  request_channel,
  delivery_message,
  levels,
  register_code = '',
  content_info
) {
  return (dispatch, getState) =>
    dispatch(() => {
      const { user_info } = getState().PaymentReducer;
      const addAutoParams = {
        ...params,
        delivery_message: delivery_message ? delivery_message : '',
        request_channel: request_channel,
        levels: { ...levels },
        levels_regist_date: moment().utc().format(),
        ...content_info,
      };
      if (register_code && register_code !== '') {
        addAutoParams.register_code = register_code;
      }

      if (content_info) {
        addAutoParams.is_use_content_info = true;
      }

      if (user_info.recommender_name) {
        addAutoParams.recommender_name = user_info.recommender_name;
      }

      if (user_info.branchoffice_code) {
        addAutoParams.branchoffice_code = user_info.branchoffice_code;
      }

      dispatch({
        type: ActionTypes.REQUEST_ADD_AUTO_PAYMENT,
        payload: {
          addAutoParams,
        },
      });

      return PaymentServices.addAutoPayment(addAutoParams)
        .then((response) => {
          return dispatch({
            type: ActionTypes.ADD_AUTO_PAYMENT,
            payload: {
              info: response.data,
            },
          });
        })
        .catch((e) => {
          dispatch({
            type: ActionTypes.FAIL_ADD_AUTO_PAYMENT,
            payload: {},
          });
          return dispatch(
            exception(e, '자동 결제 등록중 오류가 발생하였습니다.', 'goback')
          );
        });
    });
}

export function changeCreditCard(contractId, params) {
  return (dispatch, getState) =>
    dispatch(() => {
      dispatch({
        type: ActionTypes.REQUEST_CHANGE_AUTO_PAYMENT_CREDITCARD,
        payload: {
          contractId,
          params,
        },
      });
      return PaymentServices.changeCreditCard(contractId, params).then(
        (response) => {
          return dispatch({
            type: ActionTypes.CHANGE_AUTO_PAYMENT_CREDITCARD,
            payload: {
              info: response.data,
            },
          });
        }
      );
    });
}

// server_tester : tests_for_19404_change_credit_card.py - ChangeCreditCardTest.test_change_credit_card_pre_condition
export function unpaidPayments(contractId) {
  return (dispatch, getState) =>
    dispatch(() => {
      dispatch({ type: ActionTypes.REQUEST_UNPAYID_PAYMENT });
      return PaymentServices.unpaidPayments(
        contractId,
        moment().format(DateFormat.half)
      )
        .then((response) => {
          dispatch({
            type: ActionTypes.SUCCESS_UNPAYID_PAYMENT,
            payload: {
              unpaid: response.data,
            },
          });
        })
        .catch((e) => {
          dispatch({ type: ActionTypes.FAIL_UNPAYID_PAYMENT });
          return dispatch(exception(e, '미납건 처리중 오류가 발생하였습니다.'));
        });
    });
}

// server_tester : tests_for_auto_payment.py - AutoPaymentContractTest.test_paymentl_credit_card_monthly_billing
// server_tester : tests_for_795_payment_product_and_coupon.py - PaidProductAndCouponTest.test_product_and_coupon
export function confirmAutoPayment(payId, params) {
  return (dispatch, getState) =>
    dispatch(() => {
      dispatch({
        type: ActionTypes.REQUEST_CONFIRM_AUTO_PAYMENT,
        payload: {},
      });
      return PaymentServices.confirmAutoPayment(payId, params)
        .then((response) => {
          return dispatch({
            type: ActionTypes.CONFIRM_AUTO_PAYMENT,
            payload: {
              info: response.data,
            },
          });
        })
        .catch((e) => {
          return dispatch(
            exception(e, '자동 결제 등록중 오류가 발생하였습니다.')
          );
        });
    });
}

export function confirmNaverPayAutoPayment(contractId, params) {
  return (dispatch, getState) =>
    dispatch(() => {
      dispatch({
        type: ActionTypes.REQUEST_CONFIRM_AUTO_PAYMENT,
        payload: {},
      });
      return PaymentServices.confirmNaverPayPayment(contractId, params)
        .then((response) => {
          return dispatch({
            type: ActionTypes.CONFIRM_AUTO_PAYMENT,
            payload: {
              info: response.data,
            },
          });
        })
        .then(() => {
          return PaymentServices.getAutoPaymentContractInfo(contractId).then(
            (response) => {
              return dispatch({
                type: ActionTypes.SUCCESS_AUTO_PAYMENT_GET_INFO,
                payload: {
                  autoPaymentInfo: response.data,
                },
              });
            }
          );
        })
        .catch((e) => {
          return dispatch(
            exception(e, '자동 결제 등록중 오류가 발생하였습니다.')
          );
        });
    });
}
// server_tester : tests_for_auto_payment.py - AutoPaymentContractTest.test_paymentl_credit_card_monthly_billing
export function cancelAutoPayment(payId) {
  return (dispatch, getState) =>
    dispatch(() => {
      return PaymentServices.cancelAutoPayment(payId)
        .then((response) => {
          return dispatch({
            type: ActionTypes.CANCEL_AUTO_PAYMENT,
            payload: {
              info: response.data,
            },
          });
        })
        .catch((e) => {
          return dispatch(
            exception(e, '자동 결제 취소중 오류가 발생하였습니다.')
          );
        });
    });
}

// 사용하지 않음 //
export function changeAutoPayment(payId, amount) {
  return (dispatch, getState) =>
    dispatch(() => {
      return PaymentServices.changeAutoPayment(payId, amount)
        .then((response) => {
          return dispatch({
            type: ActionTypes.CHANGE_AUTO_PAYMENT,
            payload: {
              info: response.data,
            },
          });
        })
        .catch((e) => {
          return dispatch(
            exception(e, '자동 결제 금액 변경중 오류가 발생하였습니다.')
          );
        });
    });
}

// server_tester : tests_for_3767_inbound_call_experience_application.py - InboundCallExperienceApplication.test_inbound_call_experience_application
// 체험횟수 카운트
export function getExperienceCount(studentList) {
  return (dispatch, getState) =>
    dispatch(() => {
      let arrApis = [];
      if (studentList && studentList.length !== 0) {
        arrApis = studentList.map((student) => {
          return PaymentServices.getExperienceCount(student.id);
        });
      }
      return APICaller.all(...arrApis)
        .then((arrApisResponse) => {
          let arrCountList = [];
          let experienceCount = {};
          arrCountList = Object.keys(arrApisResponse).map(
            (key) => arrApisResponse[key].data.count
          );
          arrCountList.forEach((count, index) => {
            experienceCount[studentList[index].id] = count;
          });
          return dispatch({
            type: ActionTypes.GET_EXPERIENCE_COUNT,
            payload: {
              experienceCount: experienceCount,
            },
          });
        })
        .catch((e) => {
          return dispatch(
            exception(e, '체험횟수 조회 중 오류가 발생하였습니다.')
          );
        });
    });
}

// server_tester : tests_for_3767_inbound_call_experience_application.py - InboundCallExperienceApplication.test_inbound_call_experience_application
// 체험학습 신청 (학생아이디, 배송정보, 체널) channel = 학부모앱, PC, 모바일 웹 / CODE.js 에 정리 함
export function applyStudy(
  studentId,
  levels,
  channel,
  data,
  hasExperiencePreConsultWork,
  register_code = null
) {
  return (dispatch, getState) =>
    dispatch(() => {
      SpinController.showSpin(20000);
      let params = {
        has_experience_pre_consult_work: hasExperiencePreConsultWork,
        delivery_message: data,
        request_channel: channel,
        ...levels,
      };
      if (register_code) {
        params.register_code = register_code;
      }
      return PaymentServices.applyExperience(studentId, params)
        .then(() => {
          const logId = getState().PaymentReducer.logId;
          if (logId) {
            PaymentServices.deleteTemporaryInformationActor(
              getState().PaymentReducer.logId
            ).catch((e) => {
              console.error(e);
            });
          }

          return dispatch({
            type: ActionTypes.APPLY_EXPERIENCE,
          });
        })
        .catch((e) => {
          return dispatch(
            exception(e, '체험학습 신청 중 오류가 발생하였습니다.')
          );
        });
    });
}

export function setApplyType(applyType) {
  return (dispatch, getState) =>
    dispatch(() => {
      return dispatch({
        type: ActionTypes.SET_APPLY_TYPE,
        payload: {
          applyType,
        },
      });
    });
}

// server_tester : tests_for_795_payment_product_and_coupon.py - PaidProductAndCouponTest.test_product_and_coupon
export function getProductList() {
  return (dispatch, getState) =>
    dispatch(() => {
      let arrApis = [];
      arrApis = ShopProductSubjectList.map((item) => {
        return PaymentServices.getProductInfo(item);
      });
      APICaller.all(...arrApis).then((productInfo) => {
        let productList = [];
        productList = Object.keys(productInfo).map(
          (key) => productInfo[key].data
        );

        return dispatch({
          type: ActionTypes.GET_PRODUCT_LIST,
          payload: {
            productList,
          },
        });
      });
    });
}

// server_tester : tests_for_795_payment_product_and_coupon.py - PaidProductAndCouponTest.test_product_and_coupon
export function getCouponList(parentId, productId) {
  return (dispatch, getState) =>
    dispatch(() => {
      dispatch({
        type: ActionTypes.REQUEST_GET_COUPON_LIST,
        payload: {},
      });
      return PaymentServices.getCouponListByProductNo(parentId, productId)
        .then((inventoriesInfo) => {
          //let arrApis = []
          let results = inventoriesInfo.data.results;
          let couponList = results.map((coupon) => {
            let couponData = { data: coupon.sdata.coupon_detail };
            couponData.data.name = coupon.name;
            return couponData;
          });
          return dispatch({
            type: ActionTypes.GET_COUPON_LIST,
            payload: {
              couponList,
            },
          });
        })
        .catch((e) => {
          return dispatch(
            exception(e, '인벤토리정보 및 쿠폰 조회 중 오류가 발생하였습니다.')
          );
        });
    });
}

// server_tester : tests_for_993_adjust_delivery_info.py - AdjustDeliveryInfo.test_adjust_delivery_info
export function modifyDeliveryMessage(issueId, message) {
  return (dispatch, getState) =>
    dispatch(() => {
      return PaymentServices.modifyDeliveryMessage(issueId, message).then(
        (response) => {
          return dispatch({
            type: ActionTypes.MODIFY_DELIVERY_MESSAGE,
          });
        }
      );
    });
}

export function initDataForNotLogin() {
  return {
    type: ActionTypes.INIT_DATA_FOR_NOT_LOGIN,
  };
}

export function initPaymentReducer() {
  return {
    type: ActionTypes.INIT_PAYMENT_REDUCER,
    payload: {
      action: 'initPaymentReducer',
    },
  };
}

// server_tester : tests_for_1095_payment_list.py - PaymentListTest.test_contract_list
export function getAutoPaymentContractList(parentActorId) {
  return (dispatch, getState) => {
    const { autoPaymentList } = getState().PaymentReducer;
    dispatch({
      type: ActionTypes.REQUEST_AUTO_PAYMENT_CONTRACT_GET_LIST,
      payload: {},
    });

    if (
      autoPaymentList.lastUpdate &&
      autoPaymentList.lastUpdate > Date.now() - 1000 * 60
    ) {
      return dispatch({
        type: ActionTypes.SUCCESS_AUTO_PAYMENT_CONTRACT_GET_LIST,
        payload: {
          autoPaymentList: autoPaymentList,
        },
      });
    }
    return PaymentServices.getAutoPaymentContractListWithDetailList(
      parentActorId
    )
      .then((response) => {
        return dispatch({
          type: ActionTypes.SUCCESS_AUTO_PAYMENT_CONTRACT_GET_LIST,
          payload: {
            autoPaymentList: {
              ...response.data,
              lastUpdate: Date.now(),
            },
          },
        });
      })
      .catch((e) => {
        return dispatch(
          exception(e, '결제 내역 조회중 오류가 발생하였습니다.')
        );
      });
  };
}

// server_tester : tests_for_auto_payment.py - AutoPaymentContractTest.test_paymentl_credit_card_monthly_billing
export function getAutoPaymentInfo(contractId) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.REQUEST_AUTO_PAYMENT_GET_INFO,
      payload: {},
    });

    return PaymentServices.getAutoPaymentContractInfo(contractId)
      .then((response) => {
        return dispatch({
          type: ActionTypes.SUCCESS_AUTO_PAYMENT_GET_INFO,
          payload: {
            autoPaymentInfo: response.data,
          },
        });
      })
      .catch((e) => {
        return dispatch(
          exception(e, '결제 내역 조회중 오류가 발생하였습니다.')
        );
      });
  };
}

// server_tester : tests_for_1095_payment_list.py - PaymentListTest.test_contract_list
export function getAutoPaymentDetailList(contractId) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.REQUEST_AUTO_PAYMENT_DETAIL_GETLIST,
      payload: {},
    });
    return PaymentServices.getAutoPaymentDetailList(contractId)
      .then((response) => {
        return dispatch({
          type: ActionTypes.SUCCESS_AUTO_PAYMENT_DETAIL_GETLIST,
          payload: {
            autoPaymentDetailList: response.data,
          },
        });
      })
      .catch((e) => {
        return dispatch(
          exception(e, '결제 내역 조회중 오류가 발생하였습니다.')
        );
      });
  };
}

// server_tester : tests_for_1095_payment_list.py - PaymentListTest.test_contract_list
//학생 결제이력조회
export function getStudentPaymentContractListInfo(studentList) {
  return (dispatch, getState) =>
    dispatch(() => {
      dispatch({
        type: ActionTypes.REQUEST_STUDENT_PAYMENT_CONTRACT,
      });
      let arrApis = [];
      if (studentList && studentList.length !== 0) {
        arrApis = studentList.map((student) => {
          return PaymentServices.getStudentPaymentContract(student.id);
        });
      }
      return APICaller.all(...arrApis)
        .then((response) => {
          let resList = [];
          let contractListInfo = {};
          resList = Object.keys(response).map((key) => {
            return response[key].data.results;
          });
          studentList.forEach((studentInfo, index) => {
            contractListInfo[studentInfo.id] = resList[index];
          });

          dispatch({
            type: ActionTypes.SUCCESS_STUDENT_PAYMENT_CONTRACT,
            payload: {
              studentPaymentContractListInfo: contractListInfo,
            },
          });
        })
        .catch((e) => {
          dispatch({ type: ActionTypes.FAIL_STUDENT_PAYMENT_CONTRACT });
          return dispatch(
            exception(e, '학생 결제이력조회중 오류가 발생하였습니다.')
          );
        });
    });
}

export function clearStudnetPaymentContract() {
  return (dispatch, getState) =>
    dispatch(() => {
      dispatch({
        type: ActionTypes.CLEAR_STUDENT_PAYMENT_CONTRACT,
      });
    });
}

export function setPaymentMode(mode) {
  return (dispatch, getState) =>
    dispatch(() => {
      dispatch({
        type: ActionTypes.SET_PAYMENT_MODE,
        payload: {
          paymentMode: mode,
        },
      });
    });
}

export function clearPaymentMode() {
  return (dispatch, getState) =>
    dispatch(() => {
      dispatch({
        type: ActionTypes.CLEAR_PAYMENT_MODE,
      });
    });
}

export function setLogId(id) {
  return (dispatch, getState) =>
    dispatch(() => {
      dispatch({
        type: ActionTypes.SET_LOG_ID,
        payload: {
          log_id: id,
        },
      });
    });
}

export function applySimple() {
  return (dispatch, getState) =>
    dispatch(() => {
      const logId = getState().PaymentReducer.logId;
      if (logId) {
        PaymentServices.deleteTemporaryInformationActor(logId).catch((e) => {
          console.error(e);
        });
      }

      dispatch({
        type: ActionTypes.APPLY_EXPERIENCE,
      });
    });
}

export function getRentalBookListInfo(authIds) {
  return (dispatch, getState) =>
    dispatch(() => {
      if (authIds && authIds.length) {
        return PaymentServices.getRentalBookListForPremium(
          authIds.join(',')
        ).then(({ data }) => {
          let rentalBookListInfo = {};

          if (data && Array.isArray(data)) {
            rentalBookListInfo = data.reduce((acc, cur) => {
              if (acc[cur.auth_id] && acc[cur.auth_id].length) {
                acc[cur.auth_id].push(...cur.rental_books_info);
              } else {
                acc[cur.auth_id] = cur.rental_books_info;
              }
              return acc;
            }, {});
          }

          return dispatch({
            type: ActionTypes.SET_RENTAL_BOOK_LIST_INFO,
            payload: {
              rentalBookListInfo: rentalBookListInfo,
            },
          });
        });
      }
    });
}

import moment from 'moment';
import { values } from './index';

const thisYear = moment().startOf('year');

export const service = {
  isProduction: () => process.env.NEXT_PUBLIC_SERVICE_ENV === 'production',
  isStaging: () => process.env.NEXT_PUBLIC_SERVICE_ENV === 'staging',
  isProductionMerchant: () =>
    process.env.NEXT_PUBLIC_SERVICE_ENV === 'production' ||
    process.env.NEXT_PUBLIC_SERVICE_ENV === 'staging',
  getPayWay: (modelType) => {
    if (!modelType) {
      return '신용카드 자동 결제';
    }

    const type = modelType.toString();

    switch (type) {
      case '4':
        return '신용카드 자동 결제';
      case '5':
        return 'ARS 신용카드 자동 결제';
      case '6':
        return '가상계좌 정기 결제';
      case '100':
        return '현금 결제';
      case '401':
        return '네이버페이 정기 결제';
      default:
        break;
    }
  },

  isNaverPayBilling: (billingTypeId) => {
    return billingTypeId === 401;
  },

  toMoneyFormat: (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  },

  getAge: (date) => {
    if (!date || !moment(date).isValid()) {
      return '';
    }
    const age = thisYear.diff(moment(date).startOf('year'), 'year') + 1;
    return `${moment(date).format(values.format.DATE_FORMAT)} / ${age}세`;
  },

  getUserName: (actor) => {
    if (!Object.keys(actor).length) {
      return '';
    }
    const userName = actor?.authUsername ?? false;

    if (!userName || userName.search('@noid') + 1) {
      return '';
    }
    return service.getMasking(userName);
  },

  getMasking: (str, number = 5) => {
    if (!str) {
      return;
    }
    const noPattern = str.slice(0, number);
    const isPattern = str.slice(number, -1).replace(/\w/g, '*');

    return `${noPattern}${isPattern}`;
  },

  makeMdn: (mdn) => {
    return mdn && mdn.indexOf('-') < 0
      ? mdn.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, '$1-$2-$3')
      : mdn;
  },

  getTimes: (gap) => {
    let time = gap;
    const ms = ('000' + (time % 1000)).substr(-3);
    time = Math.floor(time / 1000);
    const ss = ('00' + (time % 60)).substr(-2);
    time = Math.floor(time / 60);
    const mm = ('00' + (time % 60)).substr(-2);
    time = Math.floor(time / 60);
    const hh = ('00' + (time % 60)).substr(-2);

    return { hh, mm, ss, ms };
  },

  getIosUrl: (url) => {
    if (url.endsWith('.webm')) {
      return url.replace('/u/', '/ut/') + '.mp4';
    }
    return url;
  },
  getIosPoster: (url) => {
    if (url.endsWith('.webm')) {
      return url.replace('/u/', '/ut/') + '.jpg';
    }
    return url;
  },

  getPurchaseName(productName, contractYears, contractModelType) {
    if (contractModelType === 100) {
      return contractYears
        ? `${productName}(${contractYears}년약정)`
        : `${productName}`;
    }

    return contractYears
      ? `${productName}(${contractYears}년약정)`
      : `${productName}(무약정)`;
  },

  getPaymentProgress(autoPaymentInfo, paymentCount) {
    if (autoPaymentInfo.model_type === 100) {
      return '';
    }
    return `${paymentCount}/${
      autoPaymentInfo.contract_years
        ? parseInt(autoPaymentInfo.contract_years, 10) * 12
        : '무약정'
    }`;
  },

  getRecentModifiedDate: (index, modifiedInfoList) => {
    if (!modifiedInfoList) {
      return '';
    }

    const recentModifiedDate = modifiedInfoList.find((modifiedInfo) =>
      modifiedInfo.modifiedIndexList.find(
        (modifiedIndex) => index === modifiedIndex
      )
    );

    return recentModifiedDate?.modifiedDate ?? '';
  },

  getRecentModifiedImageUrl: (index, sectionInfo) => {
    const recentModifiedDate = service.getRecentModifiedDate(
      index,
      sectionInfo.modifiedInfoList
    );

    return recentModifiedDate
      ? `${sectionInfo.prefix}_${index}_${recentModifiedDate}.png`
      : `${sectionInfo.prefix}_${index}.png`;
  },

  getSubjectNextButtonUrl: (subject) => {
    if (!subject) {
      return '';
    }
    return `https://s.wink.co.kr/pc/renewalV3/subject/${subject}/next_button.png`;
  },

  updateArrayStateSpecificIndexValue: (prev, idx, value) => {
    const newState = [...prev];
    newState[idx] = value;
    return newState;
  },

  getAmountCoupons: (coupons, contractYears) => {
    return coupons
      .filter((coupon) => {
        return coupon.discountType === '정액';
      })
      .sort((a, b) => {
        const aDiscount =
          a.discountAmount *
          (a.applicableMaxMonths === 0
            ? contractYears * 12
            : a.applicableMaxMonths);
        const bDiscount =
          b.discountAmount *
          (b.applicableMaxMonths === 0
            ? contractYears * 12
            : b.applicableMaxMonths);

        if (aDiscount < bDiscount) return 1;
        if (aDiscount > bDiscount) return -1;

        if (moment(a.endDate).isAfter(b.endDate)) return 1;
        if (moment(a.endDate).isBefore(b.endDate)) return -1;

        if (moment(a.createdDate).isAfter(b.createdDate)) return 1;
        if (moment(a.createdDate).isBefore(b.createdDate)) return -1;

        return 0;
      });
  },
  getPercentCoupons: (coupons, contractYears, discountedPrice) => {
    return coupons
      .filter((coupon) => {
        return coupon.discountType === '정률';
      })
      .sort((a, b) => {
        const aDiscount =
          discountedPrice *
          a.discountRate *
          (a.applicableMaxMonths === 0
            ? contractYears * 12
            : a.applicableMaxMonths);
        const bDiscount =
          discountedPrice *
          b.discountRate *
          (b.applicableMaxMonths === 0
            ? contractYears * 12
            : b.applicableMaxMonths);

        if (aDiscount < bDiscount) return 1;
        if (aDiscount > bDiscount) return -1;

        if (moment(a.endDate).isAfter(b.endDate)) return 1;
        if (moment(a.endDate).isBefore(b.endDate)) return -1;

        if (moment(a.createdDate).isAfter(b.createdDate)) return 1;
        if (moment(a.createdDate).isBefore(b.createdDate)) return -1;

        return 0;
      });
  },
  birthdayValidator: (value) => {
    const birthdayRegex =
      /^(?:\d{4}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])|\d{4}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01]))$/;
    return (
      birthdayRegex.test(value) &&
      moment(value).isValid() &&
      moment(value).isBetween('2010-01-01', '2030-12-31', '[]')
    );
  },
  showProjectVersion: () => {
    if (!window?.__NEXT_DATA__?.buildId) {
      return;
    }

    const buildId = window.__NEXT_DATA__.buildId;

    try {
      if (buildId === 'development') {
        return console.log(
          '%c' +
            '\n' +
            ' _       ___       __  \n' +
            '| |     / (_)___  / /__\n' +
            '| | /| / / / __ \\/ //_/\n' +
            '| |/ |/ / / / / / ,<   \n' +
            '|__/|__/_/_/ /_/_/|_|  \n' +
            '                       \n' +
            'env : development' +
            '\n',
          'color: #ef3a7a; font-weight: bold'
        );
      }

      console.log(
        '%c' +
          '\n' +
          ' _       ___       __  \n' +
          '| |     / (_)___  / /__\n' +
          '| | /| / / / __ \\/ //_/\n' +
          '| |/ |/ / / / / / ,<   \n' +
          '|__/|__/_/_/ /_/_/|_|  \n' +
          '                       \n' +
          'build time: ' +
          buildId +
          '\n',
        'color: #ef3a7a; font-weight: bold'
      );
    } catch (e) {
      console.error(e);
    }
  },
  getIsDev: () => {
    return location.hostname.toLowerCase().indexOf('wink.co.kr') === -1;
  },
};

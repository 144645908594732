import DeliveryASServices from '../services/DeliveryASServices';
import { exception } from '../error/ExceptionAction';
import StudyServices from '../services/StudyServices';

export const ActionTypes = Object.freeze({
  REQUEST_DELIVERY_GETLIST: '@@DELIVERY/REQUEST_DELIVERY_GETLIST',
  SUCCESS_DELIVERY_GETLIST: '@@DELIVERY/SUCCESS_DELIVERY_GETLIST',
  FAIL_DELIVERY_GETLIST: '@@DELIVERY/FAIL_DELIVERY_GETLIST',

  REQUEST_AS_GETLIST: '@@AS/REQUEST_AS_GETLIST',
  SUCCESS_AS_GETLIST: '@@AS/SUCCESS_AS_GETLIST',
  REQUEST_AS_GETINFO: '@@AS/REQUEST_AS_GETINFO',
  SUCCESS_AS_GETINFO: '@@AS/SUCCESS_AS_GETINFO',

  GET_MATERIAL_LIST: '@@AS/GET_MATERIAL_LIST',
  APPEND_MATERIAL_LIST: '@@AS/APPEND_MATERIAL_LIST',
  GET_SUBMATERIAL_LIST: '@@AS/GET_SUBMATERIAL_LIST',
  APPEND_SUBMATERIAL_LIST: '@@AS/APPEND_SUBMATERIAL_LIST',

  GET_ETC_ITEM_LIST: '@@AS/GET_ETC_ITEM_LIST',
  APPEND_ETC_ITEM_LIST: '@@AS/APPEND_ETC_ITEM_LIST',

  GET_DEVICE_ACCESSORY_ITEM_LIST: '@@AS/GET_DEVICE_ACCESSORY_ITEM_LIST',
  APPEND_DEVICE_ACCESSORY_ITEM_LIST: '@@AS/APPEND_DEVICE_ACCESSORY_ITEM_LIST',
});

// server_tester : tests_for_1166_as_and_delivery_history.py - CSRAsListTest.test_as_list
export function getDeliveryList(parentActorId) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.REQUEST_DELIVERY_GETLIST,
      payload: {},
    });
    return DeliveryASServices.getDeliveryList(parentActorId)
      .then((response) => {
        dispatch({
          type: ActionTypes.SUCCESS_DELIVERY_GETLIST,
          payload: {
            deliveryList: response.data.results,
          },
        });
      })
      .catch((e) => {
        return dispatch(exception(e, '배송정보 조회중 오류가 발생하였습니다.'));
      });
  };
}

// server_tester : tests_for_1166_as_and_delivery_history.py - CSRAsListTest.test_as_list
export function getAsList(parentActorId) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.REQUEST_AS_GETLIST,
      payload: {},
    });
    return DeliveryASServices.getAsList(parentActorId)
      .then((response) => {
        dispatch({
          type: ActionTypes.SUCCESS_AS_GETLIST,
          payload: {
            asList: response.data.results,
          },
        });
      })
      .catch((e) => {
        return dispatch(exception(e, 'AS내역 조회중 오류가 발생하였습니다.'));
      });
  };
}

export function getAsInfo(issueId) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.REQUEST_AS_GETINFO,
      payload: {},
    });
    return DeliveryASServices.getAsInfo(issueId)
      .then((response) => {
        dispatch({
          type: ActionTypes.SUCCESS_AS_GETINFO,
          payload: {
            asInfo: response.data.results[0],
          },
        });
      })
      .catch((e) => {
        return dispatch(exception(e, 'AS내역 조회중 오류가 발생하였습니다.'));
      });
  };
}

export function getMaterialList(itemIds) {
  return (dispatch) => {
    return StudyServices.getItemsByIdList(itemIds)
      .then((response) => {
        dispatch({
          type: ActionTypes.GET_MATERIAL_LIST,
          payload: {
            materialList: response.data.results,
          },
        });
      })
      .catch((e) => {
        return dispatch(
          exception(e, '배송 교재정보 조회중 오류가 발생하였습니다.')
        );
      });
  };
}

export function appendMaterialList(itemIds) {
  return (dispatch) => {
    return StudyServices.getItemsByIdList(itemIds)
      .then((response) => {
        dispatch({
          type: ActionTypes.APPEND_MATERIAL_LIST,
          payload: {
            materialList: response.data.results,
          },
        });
      })
      .catch((e) => {
        return dispatch(
          exception(e, '배송 교재정보 조회중 오류가 발생하였습니다.')
        );
      });
  };
}

export function getSubMaterialList(itemIds) {
  return (dispatch) => {
    return StudyServices.getItemsByIdList(itemIds)
      .then((response) => {
        dispatch({
          type: ActionTypes.GET_SUBMATERIAL_LIST,
          payload: {
            subMaterialList: response.data.results,
          },
        });
      })
      .catch((e) => {
        return dispatch(
          exception(e, '배송 교재정보 조회중 오류가 발생하였습니다.')
        );
      });
  };
}

export function appendSubMaterialList(itemIds) {
  return (dispatch) => {
    return StudyServices.getItemsByIdList(itemIds)
      .then((response) => {
        dispatch({
          type: ActionTypes.APPEND_SUBMATERIAL_LIST,
          payload: {
            subMaterialList: response.data.results,
          },
        });
      })
      .catch((e) => {
        return dispatch(
          exception(e, '배송 교재정보 조회중 오류가 발생하였습니다.')
        );
      });
  };
}

export function getEtcItemList(itemIds) {
  return (dispatch) => {
    return StudyServices.getItemsByIdList(itemIds)
      .then((response) => {
        dispatch({
          type: ActionTypes.GET_ETC_ITEM_LIST,
          payload: {
            etcItemList: response.data.results,
          },
        });
      })
      .catch((e) => {
        return dispatch(
          exception(e, '배송 교재정보 조회 중 오류가 발생하였습니다.')
        );
      });
  };
}

export function appendEtcItemList(itemIds) {
  return (dispatch) => {
    return StudyServices.getItemsByIdList(itemIds)
      .then((response) => {
        dispatch({
          type: ActionTypes.APPEND_ETC_ITEM_LIST,
          payload: {
            etcItemList: response.data.results,
          },
        });
      })
      .catch((e) => {
        return dispatch(
          exception(e, '배송 교재정보 조회 중 오류가 발생하였습니다.')
        );
      });
  };
}

export function getDeviceAccessoryItemList(itemIds) {
  return (dispatch) => {
    return StudyServices.getItemsByIdList(itemIds)
      .then((response) => {
        dispatch({
          type: ActionTypes.GET_DEVICE_ACCESSORY_ITEM_LIST,
          payload: {
            deviceAccessoryItemList: response.data.results,
          },
        });
      })
      .catch((e) => {
        return dispatch(
          exception(e, '배송 교재정보 조회 중 오류가 발생하였습니다.')
        );
      });
  };
}

export function appendDeviceAccessoryItemList(itemIds) {
  return (dispatch) => {
    return StudyServices.getItemsByIdList(itemIds)
      .then((response) => {
        dispatch({
          type: ActionTypes.APPEND_DEVICE_ACCESSORY_ITEM_LIST,
          payload: {
            deviceAccessoryItemList: response.data.results,
          },
        });
      })
      .catch((e) => {
        return dispatch(
          exception(e, '배송 교재정보 조회 중 오류가 발생하였습니다.')
        );
      });
  };
}

import moment from 'moment';
import Async from '../../library/Async';
import UserServices from '../../library/services/UserServices';
import Session from '../../library/Session';
import Storage from '../../library/Storage';
import { StorageKey, ActorType } from '../../library/constants/Code';
import * as ExceptionAction from '../../library/error/ExceptionAction';
import * as MemberAction from '@/features/member/MemberAction';

// server_test : tests_for_50_parent_account.py - LoginTest.test_login_by_username_password
//																								RegisterTest.test_simple_case
//																								RegisterStudentTest.test_simple_case
export function loginUser(info) {
  return (dispatch, getState) =>
    dispatch(() => {
      return Async(function* (userName, password, callback) {
        dispatch({
          type: MemberAction.ActionTypes.PROCESS_LOGIN,
          payload: {},
        });
        Session.login(info.auth, info.id, undefined, undefined, undefined);

        //가족정보 획득
        let family = yield UserServices.getOrCreateFamily(
          info.id,
          info.category_tag_name
        );

        //모든 자녀의 간략한 정보
        let resStudentList = yield UserServices.getMemberListByModelType(
          family.data.id,
          ActorType.Student
        );
        let studentList = resStudentList.data.results;
        //홈쇼핑에서 유입된 인원은 자녀에 이름이 없어 filter시키도록합니다.
        let filtedStudentList = studentList.filter((item) => {
          if (item.auth_human_name) {
            return true;
          }
          return false;
        });

        //자녀가 1명이라도 등록되었다면 1번째 자녀 데이터를 가저옵니다.
        if (0 !== filtedStudentList.length) {
          let studentId = filtedStudentList[0].id;
          let studentInfo = yield UserServices.loginSecondary(
            info.id,
            studentId
          );
          localStorage.setItem(StorageKey.STUDENT_ACTORID, studentId);

          Session.setAndModifyUserInfoParams({
            familyId: family.data.id,
            studentId: studentInfo.data.id,
            lastLoginTime: moment().format('YYYY-MM-DD HH:mm:ss'),
          });

          dispatch({
            type: MemberAction.ActionTypes.ALL_FAMILY,
            payload: {
              action: '',
              family: family.data,
              studentList: filtedStudentList,
              studentInfo: studentInfo.data,
            },
          });
          //return {family:family.data,studentList:studentList }
        }
        //자녀가 한명도 없으면
        else {
          Session.setAndModifyUserInfoParams({
            familyId: family.data.id,
            lastLoginTime: moment().format('YYYY-MM-DD HH:mm:ss'),
          });
          dispatch({
            type: MemberAction.ActionTypes.FAMILY,
            payload: {
              action: '',
              family: family.data,
              studentList: [],
              studentInfo: {},
            },
          });
        }

        const authToken = yield UserServices.getAuthToken(info.auth);
        Storage.deleteItem(StorageKey.ID_SAVE);
        Storage.setLoginToken(authToken.data.auth_token);
        return dispatch(MemberAction.successLogin(info.auth_detail, info));
      }, info)
        .then(() => {})
        .catch((e) => {
          dispatch(
            ExceptionAction.exception(
              e,
              '로그인이 실패 하였습니다.<br />아이디 또는 비밀번호를 확인해주세요.'
            )
          );
        });
    });
}

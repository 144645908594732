export const fetch = Object.freeze({
  FETCH_START: 'FETCH/FETCH_START',
  FETCH_END: 'FETCH/FETCH_END',
  POST_START: 'FETCH/POST_START',
  POST_END: 'FETCH/POST_END',
  CACHE: 'FETCH/CACHE',
  LIST: 'FETCH/LIST',
  APPEND_LIST: 'FETCH/APPEND_LIST',
  MULTIPLE_LIST: 'FETCH/MULTIPLE_LIST',
  APPEND_MULTIPLE_LIST: 'FETCH/APPEND_MULTIPLE_LIST',
  UPDATE_MULTIPLE_LIST: 'FETCH/UPDATE_MULTIPLE_LIST',
  GET: 'FETCH/GET',
  UPDATE: 'FETCH/UPDATE',
  UPDATE_LIST: 'FETCH/UPDATE_LIST',
  DELETE: 'FETCH/DELETE',
  RESET_MULTIPLE_LIST: 'FETCH/RESET_MULTIPLE_LIST',
  RESET_LIST: 'FETCH/RESET_LIST',
});

export default fetch;

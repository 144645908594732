import React, { Component } from 'react';

import DesktopLayerPopup from '@/features/common/DesktopLayerPopup';
import classNames from 'classnames';
import Timer from '@/features/common/components/Timer';
import OnetimeConfirmContent from '@/features/common/components/OnetimeConfirmContent';
import { KeyCode } from '@/library/constants/KeyCode';
import MyPageInjector from '../mypagemain/MyPageInjector';

class MyPageKidInfoPhoneNumberPop extends Component {
  constructor(props) {
    super(props);

    this.onChangePasswords = this.onChangePasswords.bind(this);
    this.onClickCheckPhone = this.onClickCheckPhone.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onClickSendSMS = this.onClickSendSMS.bind(this);
    this.timeoutCallback = this.timeoutCallback.bind(this);

    this.state = {
      passwords: '',
      onTimePasswordCount: 0,
      timerOn: false,
    };
  }

  timeoutCallback() {
    DesktopLayerPopup.notice({
      message:
        '인증 유효시간을 초과하였습니다.다시 인증번호 발송 버튼을 눌러주세요.',
    });
    this.setState({ timerOn: false });
  }

  onClickSendSMS(e) {
    e.preventDefault();
    const { actor } = this.props;

    this.openOneTimePasswordLayerPopup();

    this.props.oneTimePassword(actor.auth_detail.human_mdn, true);
  }

  openOneTimePasswordLayerPopup = () => {
    const { actor } = this.props;

    return DesktopLayerPopup.confirm({
      message: <OnetimeConfirmContent />,
      buttonLabel1: '문자로 다시 받기',
      callback: (type) => {
        this.setState((prevState) => ({
          onTimePasswordCount: prevState.onTimePasswordCount + 1,
          timerOn: true,
        }));

        if ('CANCEL' === type) {
          this.props.oneTimePassword(actor.auth_detail.human_mdn, false);
        }
      },
    });
  };

  onClickCheckPhone(e) {
    e.preventDefault();
    if (this.state.passwords !== '') {
      const { actor } = this.props;
      this.props
        .checkOneTimePassword(actor.auth_detail.human_mdn, this.state.passwords)
        .then((response) => {
          if (response.payload.action === 'failCheckOneTimePassword') {
            DesktopLayerPopup.notice({
              message: '인증번호가 일치하지 않습니다.',
            });
            return;
          }
          this.props.successCheckUser();
        })
        .then(() => {
          this.props.clearMemberAction();
        });
      return;
    }

    if (!this.modal) {
      this.modal = DesktopLayerPopup.notice({
        message: '인증번호를 입력해 주세요.',
      });
      return;
    }

    DesktopLayerPopup.hide(this.modal);
    this.modal = null;
  }

  onChangePasswords(e) {
    this.setState({ passwords: e.target.value });
  }

  onKeyDown(e) {
    if (e.keyCode === KeyCode.ENTER) {
      this.onClickCheckPhone(e);
    }
  }

  render() {
    const { actor } = this.props;
    const { onTimePasswordCount, timerOn } = this.state;

    return (
      <div
        className="layer-wrap large"
        style={{ marginTop: '-200px', overflow: 'visible' }}
      >
        <h2>휴대폰번호 인증</h2>
        <table className="form">
          <tbody>
            <tr>
              <th scope="row"></th>
              <td>
                <fieldset className="phone">
                  <div className="item">
                    <input
                      type="text"
                      className="disabled"
                      value={actor.auth_detail.human_mdn}
                      disabled
                      style={{ width: 304 }}
                    />
                  </div>
                  <div className="item">
                    <a
                      href="#"
                      className={classNames('btn-type form', {
                        on: 0 === onTimePasswordCount,
                      })}
                      id="mypage-add-kids-certification"
                      onClick={this.onClickSendSMS}
                    >
                      {0 === onTimePasswordCount
                        ? '인증번호 발송'
                        : '인증번호 재발송'}
                    </a>
                    <input
                      type="text"
                      maxLength="6"
                      className={classNames({
                        disabled: 0 === onTimePasswordCount,
                      })}
                      placeholder="인증번호 입력"
                      disabled={0 === onTimePasswordCount}
                      value={this.state.passwords}
                      onChange={this.onChangePasswords}
                      onKeyDown={this.onKeyDown}
                    />
                    <Timer
                      label=" 유효시간 "
                      format={'mm:ss'}
                      resetId={onTimePasswordCount}
                      on={timerOn}
                      timeoutCallback={this.timeoutCallback}
                    />
                  </div>
                </fieldset>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="btn-area">
          <a href="#" className="btn-type2" onClick={this.props.onClosePopup}>
            취소
          </a>
          <a
            href="#"
            className="btn-type2 confirm"
            onClick={this.onClickCheckPhone}
          >
            확인
          </a>
        </div>
        <button className="close" onClick={this.props.onClosePopup}>
          팝업 닫기
        </button>
      </div>
    );
  }
}

export default MyPageInjector()(MyPageKidInfoPhoneNumberPop);

import { GoogleTagManager } from './GoogleTagManager';
import { GaScript } from './GaScript';
import { Enliple } from './Enliple';
import { AceCounter } from './AceCounter';
import { ADN } from './ADN';
import KakaoDaum from './KakaoDaum';
import Naver from './Naver';

const AdsScripts = () => {
  return (
    <>
      <GoogleTagManager />
      {/*<Navis />*/}
      <AceCounter />
      <ADN />
      <KakaoDaum />
      <GaScript />
      <Enliple />
      <Naver />
    </>
  );
};

export default AdsScripts;

import moment from 'moment';
import { ActorType } from '../../library/constants/Code';
import AuxServices from '../../library/services/AuxServices';
import UserServices from '../../library/services/UserServices';
import Session from '../../library/Session';
import Async from '../../library/Async';
import APICaller from '../../library/APICaller';
import Storage from '../../library/Storage';
import * as ExceptionAction from '../../library/error/ExceptionAction';
import { StorageKey } from '../../library/constants/Code';
import { DateFormat } from '../../library/constants/Date';
import { RegExp } from '../../library/constants/RegExp';

const tag = 'member';
export const ActionTypes = Object.freeze({
  EXCEPTION: 'EXCEPTION',
  LOGIN: `${tag}/LOGIN`,
  FAIL_LOGIN: `${tag}/FAIL_LOGIN`,
  REGISTER: `${tag}/REGISTER`,
  FAIL_REGISTER: `${tag}/FAIL_REGISTER`,
  SEND_EMAIL: `${tag}/SEND_EMAIL`,
  MODIFY_ACTION: `${tag}/MODIFY_ACTION`,
  MODIFY_AUTHS: `${tag}/MODIFY_AUTHS`,
  ALL_FAMILY: `${tag}/ALL_FAMILY`,
  FAMILY: `${tag}/FAMILY`,
  STUDENT: `${tag}/STUDENT`,
  LOGIN_STEP: `${tag}/LOGIN_STEP`,
  SET_STUDENT_MEMBER: `${tag}/SET_STUDENT_MEMBER`,
  SELECTED_STUDENT: `${tag}/SELECTED_STUDENT`,
  MDN_TOKEN: `${tag}/MDN_TOKEN`,
  CLEAR_ACTION: `${tag}/CLEAR_ACTION`,
  LOGOUT: `${tag}/LOGOUT`,
  GET_STUDENT_MEMBER: `${tag}/GET_STUDENT_MEMBER`,

  SUCCESS_ONETIME_PASSWORD: `${tag}/SUCCESS_ONETIME_PASSWORD`,
  SUCCESS_ADD_USER: `${tag}/SUCCESS_ADD_USER`,
  SUCCESS_ID_DUPLICATE_CHECK: `${tag}/SUCCESS_ID_DUPLICATE_CHECK`,
  SUCCESS_PHONE_DUPLICATE_CHECK: `${tag}/SUCCESS_PHONE_DUPLICATE_CHECK`,
  FAIL_CHECK_ONETIME_PASSWORD: `${tag}/FAIL_CHECK_ONETIME_PASSWORD`,
  SUCCESS_SET_STUDENT_MEMBER_AND_LOGIN_SECONDARY: `${tag}/SUCCESS_SET_STUDENT_MEMBER_AND_LOGIN_SECONDARY`,
  FAIL_RECOMMENDER: `${tag}/FAIL_RECOMMENDER`,
  SUCCESS_MODIFY_PARENT_CATEGORY_TAG: `${tag}/SUCCESS_MODIFY_PARENT_CATEGORY_TAG`,
  CHANGE_AUTH: `${tag}/CHANGE_AUTH`,
  PROCESS_LOGIN: `${tag}/PROCESS_LOGIN`,
  SET_NOID_POPUPFLAG: `${tag}/SET_NOID_POPUPFLAG`,
});

export function successLogin(auth, actor) {
  return {
    type: ActionTypes.LOGIN,
    payload: {
      action: 'successLogin',
      message: '',
      auth,
      actor,
    },
  };
}

// export function loginUser_Test(userName, password, isMaintainLogin, actorType){
// 	return (dispatch, getState) => dispatch(() => {
// 		Session.login(1, 1, '결제테스트', '결제테스트', actorType);

// 		if (userName === 'paytest' && password === 'pay123qwe') {
// 			dispatch(loginUser('ljymill', '!@ljy3827', false, actorType));
// 		} else {
// 			dispatch({
// 				type:ActionTypes.FAIL_LOGIN,
// 				payload:{
// 					action:'failLogin'
// 				}
// 			})
// 			dispatch(ExceptionAction.exception({response:{status:401, data:{detail:'로그인 실패하였습니다.'}}}, '로그인이 실패 하였습니다.<br />아이디 또는 비밀번호를 확인해주세요.'))
// 		}
// 		// dispatch(loginUser(userName, password, false, actorType));
// 	});
// }

// server_test : 1) tests_for_50_parent_account.py - LoginTest.test_login_by_username_password
//																									 RegisterTest.test_simple_case
//																									 RegisterStudentTest.test_simple_case
export function loginUser(userName, password, isSave, actorType) {
  return (dispatch, getState) =>
    dispatch(() => {
      return Async(
        function* (userName, password, callback) {
          dispatch({
            type: ActionTypes.PROCESS_LOGIN,
            payload: {},
          });
          const res = yield UserServices.login(userName, password, actorType);
          //Session.login(res.actor.data.auth, res.actor.data.id, res.auth.data.username, res.auth.data.human_name, res.actor.data.model_type,{isAdmin:res.auth.data.is_admin});
          Session.login(
            res.actor.data.auth,
            res.actor.data.id,
            undefined,
            undefined,
            undefined
          );

          //가족정보 획득
          let family = yield UserServices.getOrCreateFamily(
            res.actor.data.id,
            res.actor.data.category_tag_name
          );

          //모든 자녀의 간략한 정보
          let resStudentList = yield UserServices.getMemberListByModelType(
            family.data.id,
            ActorType.Student
          );
          let studentList = resStudentList.data.results;
          //홈쇼핑에서 유입된 인원은 자녀에 이름이 없어 filter시키도록합니다.
          let filtedStudentList = studentList.filter((item) => {
            if (item.auth_human_name) {
              return true;
            }
            return false;
          });

          //자녀가 1명이라도 등록되었다면 1번째 자녀 데이터를 가저옵니다.
          if (0 !== filtedStudentList.length) {
            let studentId = filtedStudentList[0].id;
            let studentInfo = yield UserServices.loginSecondary(
              res.actor.data.id,
              studentId
            );
            localStorage.setItem(StorageKey.STUDENT_ACTORID, studentId);

            Session.setAndModifyUserInfoParams({
              familyId: family.data.id,
              studentId: studentInfo.data.id,
              lastLoginTime: moment().format('YYYY-MM-DD HH:mm:ss'),
            });

            dispatch({
              type: ActionTypes.ALL_FAMILY,
              payload: {
                action: '',
                family: family.data,
                studentList: filtedStudentList,
                studentInfo: studentInfo.data,
              },
            });
            //return {family:family.data,studentList:studentList }
          }
          //자녀가 한명도 없으면
          else {
            Session.setAndModifyUserInfoParams({
              familyId: family.data.id,
              lastLoginTime: moment().format('YYYY-MM-DD HH:mm:ss'),
            });
            dispatch({
              type: ActionTypes.FAMILY,
              payload: {
                action: '',
                family: family.data,
                studentList: [],
                studentInfo: {},
              },
            });
          }

          const authToken = yield UserServices.getAuthToken(res.auth.data.id);
          if (isSave) {
            Storage.set(StorageKey.ID_SAVE, userName);
          } else {
            Storage.deleteItem(StorageKey.ID_SAVE);
          }
          Storage.setLoginToken(authToken.data.auth_token);
          return dispatch(successLogin(res.auth.data, res.actor.data));
        },
        userName,
        password
      )
        .then(() => {})
        .catch((e) => {
          // dispatch({
          // 	type:ActionTypes.FAIL_LOGIN,
          // 	payload:{
          // 		action:'failLogin'
          // 	}
          // })
          dispatch(
            ExceptionAction.exception(
              e,
              '로그인이 실패 하였습니다.<br />아이디 또는 비밀번호를 확인해주세요.'
            )
          );
        });
    });
}

// server_test : tests_for_50_parent_account.py - LoginTest.test_login_by_human_mdn
//																								RegisterTest.test_simple_case
//																								RegisterStudentTest.test_simple_case
export function phoneLoginUser(
  phone,
  name,
  certificationNumber,
  isSave,
  actorType
) {
  return (dispatch, getState) =>
    dispatch(() => {
      return Async(
        function* (phone, name, certificationNumber, actorType) {
          dispatch({
            type: ActionTypes.PROCESS_LOGIN,
            payload: {},
          });
          const res = yield UserServices.phoneLogin(
            phone,
            name,
            certificationNumber,
            actorType
          );
          //Session.login(res.actor.data.auth, res.actor.data.id, res.auth.data.username, res.auth.data.human_name, res.actor.data.model_type,{isAdmin:res.auth.data.is_admin});
          Session.login(
            res.actor.data.auth,
            res.actor.data.id,
            undefined,
            undefined,
            undefined
          );

          //가족정보 획득
          let family = yield UserServices.getOrCreateFamily(
            res.actor.data.id,
            res.actor.data.category_tag_name
          );

          //모든 자녀의 정보
          let resStudentList = yield UserServices.getMemberListByModelType(
            family.data.id,
            ActorType.Student
          );
          let studentList = resStudentList.data.results;
          //홈쇼핑에서 유입된 인원은 자녀에 이름이 없어 filter시키도록합니다.
          let filtedStudentList = studentList.filter((item) => {
            if (item.auth_human_name) {
              return true;
            }
            return false;
          });
          //자녀가 1명이라도 등록되었다면 1번째 자녀 데이터를 가저옵니다.
          if (0 !== filtedStudentList.length) {
            const studentId = getStudentId(filtedStudentList[0].id);
            let studentInfo = yield UserServices.loginSecondary(
              res.actor.data.id,
              studentId
            );
            Storage.set(StorageKey.STUDENT_ACTORID, studentId);
            Session.setAndModifyUserInfoParams({
              familyId: family.data.id,
              studentId: studentInfo.data.id,
              lastLoginTime: moment().format(DateFormat.full),
            });
            dispatch({
              type: ActionTypes.ALL_FAMILY,
              payload: {
                action: '',
                family: family.data,
                studentList: filtedStudentList,
                studentInfo: studentInfo.data,
              },
            });
          }
          //자녀가 한명도 없으면
          else {
            Session.setAndModifyUserInfoParams({
              familyId: family.data.id,
              lastLoginTime: moment().format(DateFormat.full),
            });
            dispatch({
              type: ActionTypes.FAMILY,
              payload: {
                action: '',
                family: family.data,
                studentList: [],
                studentInfo: {},
              },
            });
          }

          //로그인 유지할지 않할지
          const authToken = yield UserServices.getAuthToken(res.auth.data.id);
          if (isSave) {
            //토큰 생성하는 api호출 및 로컬스토리지에 저장해야함
            Storage.set(StorageKey.PHONE_SAVE, phone);
          } else {
            Storage.deleteItem(StorageKey.PHONE_SAVE);
          }
          //리덕스저장
          Storage.setLoginToken(authToken.data.auth_token);
          dispatch(successLogin(res.auth.data, res.actor.data));
        },
        phone,
        name,
        certificationNumber,
        actorType
      )
        .then(() => {})
        .catch((e) => {
          dispatch(
            ExceptionAction.exception(
              e,
              '로그인이 실패 하였습니다.<br />인증번호 또는 이름을 확인해주세요.'
            )
          );
          // dispatch({
          // 	type:ActionTypes.FAIL_LOGIN,
          // 	payload:{
          // 		action:'failLogin'
          // 	}
          // })
        });
    });
}

// server_test : tests_for_50_parent_account.py - LoginTest.test_login_by_auth_token
//																								RegisterTest.test_simple_case
//																								RegisterStudentTest.test_simple_case
export function authTokenLogin(token, actorType, failCallback) {
  return (dispatch, getState) =>
    dispatch(() => {
      return Async(function* (token) {
        dispatch({
          type: ActionTypes.PROCESS_LOGIN,
          payload: {},
        });
        //캐시를 받아오지 못한 상태에서 post를 호출하여 에러가 나는걸 방지합니다.
        yield AuxServices.getAuxFileTypes();

        const res = yield UserServices.authTokenLogin(token, actorType);
        //Session.login(res.actor.data.auth, res.actor.data.id, res.auth.data.username, res.auth.data.human_name, res.actor.data.model_type,{isAdmin:res.auth.data.is_admin});
        Session.login(
          res.actor.data.auth,
          res.actor.data.id,
          undefined,
          undefined,
          undefined
        );

        //가족정보 획득
        let family = yield UserServices.getOrCreateFamily(
          res.actor.data.id,
          res.actor.data.category_tag_name
        );

        //모든 자녀의 간략한 정보
        let resStudents = yield UserServices.getMemberListByModelType(
          family.data.id,
          ActorType.Student
        );
        let studentList = resStudents.data.results;
        //홈쇼핑에서 유입된 인원은 자녀에 이름이 없어 filter시키도록합니다.
        let filtedStudentList = studentList.filter((item) => {
          if (item.auth_human_name) {
            return true;
          }
          return false;
        });
        //자녀가 1명이라도 등록되었다면 1번째 자녀 데이터를 가저옵니다.
        if (0 !== filtedStudentList.length) {
          const studentId = getStudentId(filtedStudentList[0].id);
          let studentInfo = yield UserServices.loginSecondary(
            res.actor.data.id,
            studentId
          );
          Storage.set(StorageKey.STUDENT_ACTORID, studentId);
          Session.setAndModifyUserInfoParams({
            familyId: family.data.id,
            studentId: studentInfo.data.id,
            lastLoginTime: moment().format('YYYY-MM-DD HH:mm:ss'),
          });
          dispatch({
            type: ActionTypes.ALL_FAMILY,
            payload: {
              action: '',
              family: family.data,
              studentList: filtedStudentList,
              studentInfo: studentInfo.data,
            },
          });
        }
        //자녀가 한명도 없으면
        else {
          Session.setAndModifyUserInfoParams({
            familyId: family.data.id,
            lastLoginTime: moment().format('YYYY-MM-DD HH:mm:ss'),
          });
          dispatch({
            type: ActionTypes.FAMILY,
            payload: {
              action: '',
              family: family.data,
              studentList: [],
              studentInfo: {},
            },
          });
        }
        Storage.setLoginToken(token);
        dispatch(successLogin(res.auth.data, res.actor.data));
      }, token)
        .then(() => {})
        .catch((e) => {
          //만약 자동로그인이 실패하면 삭제
          Storage.deleteItem(StorageKey.STUDENT_ACTORID);
          dispatch({
            type: ActionTypes.FAIL_LOGIN,
            payload: {
              action: 'failLogin',
            },
          });
        });
    });
}

export function clearAction() {
  return (dispatch, getState) => {
    return dispatch({
      type: ActionTypes.CLEAR_ACTION,
    });
  };
}

// server_test : tests_for_50_parent_account.py - RegisterTest.test_simple_case
export function oneTimePassword(mdn, isKakaoTalk) {
  return (dispatch, getState) => {
    return UserServices.oneTimePassword(mdn, isKakaoTalk)
      .then((res) => {
        dispatch({
          type: ActionTypes.SUCCESS_ONETIME_PASSWORD,
          payload: {
            action: 'successOneTimePassword',
          },
        });
      })
      .catch((e) => {
        dispatch(
          ExceptionAction.exception(
            e,
            'oneTimePassword 요청중 오류가 발생하였습니다.'
          )
        );
      });
  };
}

// server_test : tests_for_50_parent_account.py - RegisterTest.test_simple_case
export function addUser(
  username,
  password,
  human_name,
  human_mdn,
  mdn_token,
  data,
  actorType
) {
  return (dispatch, getState) =>
    dispatch(() => {
      return Async(
        function* (username, password, human_name, human_mdn, mdn_token, data) {
          //회원가입
          yield UserServices.createAuths(
            username,
            password,
            human_name,
            human_mdn,
            mdn_token,
            data
          );
          //로그인
          const res = yield UserServices.login(username, password, actorType);

          //로그인을 유지 시킵니다.
          const authToken = yield UserServices.getAuthToken(res.auth.data.id);
          Storage.setToken(authToken.data.auth_token);
          Storage.setLoginToken(authToken.data.auth_token);
          //세션 로그인
          //Session.login(res.actor.data.auth, res.actor.data.id, username, res.auth.data.human_name, res.actor.data.model_type,{isAdmin:res.auth.data.is_admin});
          Session.login(
            res.actor.data.auth,
            res.actor.data.id,
            undefined,
            undefined,
            undefined
          );
          //세션 lastLoginTime
          Session.setAndModifyUserInfoParams({
            lastLoginTime: moment().format(DateFormat.full),
          });
          dispatch(successLogin(res.auth.data, res.actor.data));
        },
        username,
        password,
        human_name,
        human_mdn,
        mdn_token,
        data
      )
        .then(() => {
          dispatch({
            type: ActionTypes.SUCCESS_ADD_USER,
            payload: {
              action: 'successAddUser',
            },
          });
        })
        .catch((e) => {
          dispatch(
            ExceptionAction.exception(
              e,
              '회원 가입중 오류가 발생하였습니다. 정보를 다시 확인해주세요.'
            )
          );
        });
    });
}

//id중복을 확인합니다.
// server_test : tests_for_50_parent_account.py - RegisterTest.test_simple_case
export function idDuplicateCheck(id, openDuplicatedModal) {
  return (dispatch, getState) => {
    return UserServices.idDuplicateCheck(id)
      .then((res) => {
        if (0 === res.data.count) {
          dispatch({
            type: ActionTypes.SUCCESS_ID_DUPLICATE_CHECK,
            payload: {
              action: 'successIdDuplicateCheck',
            },
          });
        } else {
          //중복된 id
          if (openDuplicatedModal) {
            openDuplicatedModal(id);
          }
        }
      })
      .catch((e) => {
        dispatch(
          ExceptionAction.exception(e, 'id중복 조회중 오류가 발생하였습니다.')
        );
      });
  };
}

//phone중복을 확인합니다.
// server_test : tests_for_50_parent_account.py - RegisterTest.test_simple_case
export function phoneDuplicateCheck(phoneNum, openDuplicatedModal) {
  return (dispatch, getState) => {
    return UserServices.phoneDuplicateCheck(phoneNum)
      .then((res) => {
        if (0 === res.data.count) {
          dispatch({
            type: ActionTypes.SUCCESS_PHONE_DUPLICATE_CHECK,
            payload: {
              action: 'successPhoneDuplicateCheck',
            },
          });
        } else {
          if (openDuplicatedModal) {
            openDuplicatedModal(phoneNum);
          }
        }
      })
      .catch((e) => {
        dispatch(
          ExceptionAction.exception(
            e,
            'phone중복 조회중 오류가 발생하였습니다.'
          )
        );
      });
  };
}

// server_test : tests_for_50_parent_account.py - RegisterTest.test_simple_case
export function checkOneTimePassword(mdn, certificationNumber) {
  return (dispatch, getState) => {
    return UserServices.checkMdn(mdn, certificationNumber)
      .then((res) => {
        if (res.data.mdn_token) {
          return dispatch({
            type: ActionTypes.MDN_TOKEN,
            payload: {
              action: 'successCheckOneTimePassword',
              carrier: res.data.mdn_token,
            },
          });
        } else {
          return dispatch({
            type: ActionTypes.FAIL_CHECK_ONETIME_PASSWORD,
            payload: {
              action: 'failCheckOneTimePassword',
            },
          });
        }
      })
      .catch((e) => {
        return dispatch({
          type: ActionTypes.FAIL_CHECK_ONETIME_PASSWORD,
          payload: {
            action: 'failCheckOneTimePassword',
          },
        });
      });
  };
}

// server_test : tests_for_1566_add_student_member.py - AddStudentMemberTest.test_add_student_member
//							 tests_for_50_parent_account.py - RegisterStudentTest.test_simple_case
export function setStudentMemberAndLoginSecondary(name, isMale, birthday) {
  return (dispatch, getState) =>
    dispatch(() => {
      return Async(
        function* (name, isMale, birthday) {
          let state = getState().MemberReducer;
          let id = state.actor.id;
          let parent_category_tag_name = state.actor.category_tag_name;
          //가족정보 획득
          let family = yield UserServices.getOrCreateFamily(
            id,
            parent_category_tag_name
          );
          //학생등록
          yield UserServices.addStudentMember(
            family.data.id,
            name,
            isMale,
            birthday
          );

          //모든 자녀의 정보
          let resStudents = yield UserServices.getMemberListByModelType(
            family.data.id,
            ActorType.Student
          );
          let studentList = resStudents.data.results;
          //홈쇼핑에서 유입된 인원은 자녀에 이름이 없어 filter시키도록합니다.
          let filtedStudentList = studentList.filter((item) => {
            if (item.auth_human_name) {
              return true;
            }
            return false;
          });
          //자녀가 1명이라도 등록되었다면 1번째 자녀 데이터를 가저옵니다.
          if (0 !== filtedStudentList.length) {
            const studentId = getStudentId(filtedStudentList[0].id);
            //let studentId = studentList[0].id;
            //해당자녀의 디테일 정보
            let studentInfo = yield UserServices.loginSecondary(id, studentId);
            Storage.set(StorageKey.STUDENT_ACTORID, studentId);
            Session.setAndModifyUserInfoParams({
              familyId: family.data.id,
              studentId: studentInfo.data.id,
            });
            dispatch({
              type: ActionTypes.ALL_FAMILY,
              payload: {
                action: 'successChildInfoRegist',
                family: family.data,
                studentList: filtedStudentList,
                studentInfo: studentInfo,
              },
            });
          }
        },
        name,
        isMale,
        birthday
      )
        .then(() => {
          setTimeout(() => {
            return dispatch({
              type: ActionTypes.SUCCESS_SET_STUDENT_MEMBER_AND_LOGIN_SECONDARY,
              payload: {
                action: 'successSetStudentMemberAndLoginSecondary',
              },
            });
          }, 200);
        })
        .catch((e) => {
          return dispatch(
            ExceptionAction.exception(e, '자녀 등록중 오류가 발생하였습니다.')
          );
        });
    });
}

// server_test : tests_for_50_parent_account.py - RegisterRecommenderTest.test_search_by_username_and_exist
export function recommender(recommend) {
  return (dispatch, getState) =>
    dispatch(() => {
      return Async(function* () {
        let memberReducer = getState().MemberReducer;
        let auth = { ...memberReducer.auth };

        const promiseAll = [UserServices.idDuplicateCheck(recommend)];
        //phone 중복 체크는 문자가 들어가면 에러납니다.
        if (RegExp.PHONE.test(recommend)) {
          promiseAll.push(UserServices.phoneDuplicateCheck(recommend));
        }
        const promiseAllResult = yield APICaller.all(...promiseAll);

        let keys = Object.keys(promiseAllResult);

        for (let i = 0; i < keys.length; i++) {
          if (promiseAllResult[i].data.count === 1) {
            auth.recommend = promiseAllResult[i].data.results[0].id;
            const modifyiedAuths = yield UserServices.modifyAuths(
              auth.id,
              auth
            );
            return dispatch({
              type: ActionTypes.MODIFY_AUTHS,
              payload: {
                action: 'successRecommender',
                auth: modifyiedAuths.data,
              },
            });
          }
        }

        return dispatch({
          type: ActionTypes.FAIL_RECOMMENDER,
          payload: {
            action: 'failRecommender',
          },
        });
      })
        .then(() => {})
        .catch((e) => {
          dispatch({
            type: ActionTypes.FAIL_RECOMMENDER,
            payload: {
              action: 'failRecommender',
            },
          });
        });
    });
}

export function selectedStudent(loginStudentInfo) {
  return (dispatch, getState) => {
    let studentList = getState().MemberReducer.studentList;
    let targetIndex = -1;
    studentList.forEach((item, index) => {
      if (item.id === loginStudentInfo.id) {
        targetIndex = index;
      }
    });
    if (-1 !== targetIndex) {
      Storage.set(StorageKey.STUDENT_ACTORID, loginStudentInfo.id);
      Session.setAndModifyUserInfoParams({
        studentId: loginStudentInfo.id,
      });
      return dispatch({
        type: ActionTypes.SELECTED_STUDENT,
        payload: {
          studentInfo: loginStudentInfo,
        },
      });
    }
  };
}

export function logout() {
  return (dispatch, getState) =>
    dispatch(() => {
      return UserServices.logout()
        .then((res) => {
          Storage.logout();
          Session.logout();

          return dispatch({
            type: ActionTypes.LOGOUT,
            payload: {
              action: 'logout',
            },
          });
        })
        .catch((e) => {
          dispatch(
            ExceptionAction.exception(
              e,
              '로그아웃 요청중 에러가 발생하였습니다.'
            )
          );
        });
    });
}

// 명희 추가
// server_test : tests_for_50_parent_account.py - RegisterStudentTest.test_simple_case
export function getStudentList(familyId, studentId) {
  return (dispatch, getState) =>
    dispatch(() => {
      return UserServices.getMemberListByModelType(
        familyId,
        ActorType.Student
      ).then((response) => {
        let studentList = response.data.results;
        let studentInfo;
        //홈쇼핑에서 유입된 인원은 자녀에 이름이 없어 filter시키도록합니다.
        let filtedStudentList = studentList.filter((item) => {
          if (item.auth_human_name) {
            return true;
          }
          return false;
        });
        filtedStudentList.forEach((info) => {
          if (info.id === studentId) {
            studentInfo = info;
          }
        });

        if (!studentInfo) {
          studentInfo = studentList[0];
        }

        return dispatch({
          type: ActionTypes.GET_STUDENT_MEMBER,
          payload: {
            studentList: filtedStudentList,
            studentInfo: studentInfo,
          },
        });
      });
    });
}

// server_test : tests_for_1566_add_student_member.py - AddStudentMemberTest.test_add_student_member
//							 tests_for_50_parent_account.py - RegisterStudentTest.test_simple_case
export function setStudentMember(name, isMale, birthday) {
  return (dispatch, getState) => {
    let familyId = getState().MemberReducer.family.id;
    let studentList = getState().MemberReducer.studentList;
    return UserServices.addStudentMember(familyId, name, isMale, birthday)
      .then((res) => {
        let index = -1;
        studentList.forEach((item, idx) => {
          if (item.auth_detail.human_name === res.data.auth_detail.human_name) {
            index = idx;
          }
        });
        if (-1 === index) {
          studentList.push(res.data);
        } else {
          studentList[index] = res.data;
        }

        return dispatch({
          type: ActionTypes.SET_STUDENT_MEMBER,
          payload: {
            action: 'successSetStudentMember',
            studentList: studentList,
          },
        });
      })
      .catch((e) => {
        return dispatch(
          ExceptionAction.exception(e, '학생등록중 오류가 발생하였습니다.')
        );
      });
  };
}

// server_test : test_student_modify.py - StudentRemoveTest.test_student_remove(test_student_remove2, test_regular_student_remove3)
export function deleteChild(actorId, authId, targetIndex) {
  return (dispatch, getState) =>
    dispatch(() => {
      return (
        UserServices.deleteActor(actorId)
          .then(() => {
            return UserServices.deleteAuths(authId).then(() => {
              let studentList = [...getState().MemberReducer.studentList];
              studentList.splice(targetIndex, 1);
              dispatch({
                type: ActionTypes.SET_STUDENT_MEMBER,
                payload: {
                  action: 'successDeleteChild',
                  studentList: studentList,
                },
              });
              //자녀가 아무도 없으면 정보를 제거 합니다.
              if (0 === studentList.length) {
                dispatch({
                  type: ActionTypes.SELECTED_STUDENT,
                  payload: {
                    studentInfo: {},
                  },
                });
              }
            });
          })

          // let promissArr = [UserServices.deleteActor(actorId), UserServices.deleteAuths(authId)]
          // return APICaller.all(...promissArr)
          // .then((res)=>{
          // 	let studentList = [...getState().MemberReducer.studentList];
          // 	studentList.splice(targetIndex, 1);
          // 	dispatch({
          // 		type:ActionTypes.SET_STUDENT_MEMBER,
          // 		payload:{
          // 			action:'successDeleteChild',
          // 			studentList:studentList
          // 		}
          // 	})
          // 	//자녀가 아무도 없으면 정보를 제거 합니다.
          // 	if(0 === studentList.length){
          // 		dispatch({
          // 			type:ActionTypes.SELECTED_STUDENT,
          // 			payload:{
          // 				studentInfo:{}
          // 			}
          // 		})
          // 	}
          // })
          .catch((e) => {
            dispatch(
              ExceptionAction.exception(
                e,
                'deleteChild 요청중 에러가 발생하였습니다.'
              )
            );
          })
      );
    });
}

// server_test : tests_for_50_parent_account.py - RegisterNoidTest.test_simple_case
export function modifyStudents(studentList, indexObject) {
  return (dispatch, getState) =>
    dispatch(() => {
      let promissArr = studentList.map((item) => {
        let params = {
          human_name: item.auth_detail.human_name,
          is_male: item.auth_detail.is_male,
          birthday: item.auth_detail.birthday,
        };

        return UserServices.modifyAuths(item.auth_detail.id, params);
      });
      return APICaller.all(...promissArr)
        .then((res) => {
          let oldStudent = [...getState().MemberReducer.studentList];
          Object.keys(indexObject).forEach((target, index) => {
            oldStudent[target] = studentList[index];
          });
          dispatch({
            type: ActionTypes.SET_STUDENT_MEMBER,
            payload: {
              action: 'successModifyStudents',
              studentList: oldStudent,
            },
          });
        })
        .catch((e) => {
          dispatch(
            ExceptionAction.exception(
              e,
              'modifyStudents 요청중 에러가 발생하였습니다.'
            )
          );
        });
    });
}

export function changeChild(actorId, studentId) {
  return (dispatch, getState) => {
    return UserServices.loginSecondary(actorId, studentId)
      .then((studentInfo) => {
        return dispatch({
          type: ActionTypes.STUDENT,
          payload: {
            action: 'successChangeChild',
            studentInfo: studentInfo.data,
          },
        });
      })
      .catch((e) => {
        return dispatch(
          ExceptionAction.exception(e, '자녀 변경중 오류가 발생하였습니다.')
        );
      });
  };
}

export function modifyParentCategoryTag(category_tag_name) {
  return (dispatch, getState) =>
    dispatch(() => {
      let actorId = getState().MemberReducer.actor.id;
      return AuxServices.getParentCategoryTag(category_tag_name)
        .then((res) => {
          UserServices.modifyActors(actorId, {
            category_tag: res.data.id,
          }).then((res2) => {
            dispatch({
              type: ActionTypes.SUCCESS_MODIFY_PARENT_CATEGORY_TAG,
              payload: {
                action: 'successModifyParentCategoryTag',
              },
            });
          });
        })
        .catch((e) => {
          return dispatch(
            ExceptionAction.exception(
              e,
              '양육자 구분변경 요청중 에러가 발생하였습니다.'
            )
          );
        });
    });
}

// server_test : tests_for_50_parent_account.py - RegisterNoidTest.test_simple_case
export function changeAuth(authData) {
  return (dispatch, getState) =>
    dispatch(() => {
      return Async(function* () {
        let auth = getState().MemberReducer.auth;
        let params = {
          username: auth.username,
          human_name: auth.human_name,
          human_mdn: auth.human_mdn,
          data: auth.data,
        };
        //mdn_token이 있어야 auth데이터를 바꿜수있습니다.
        Object.keys(authData).forEach((item, index) => {
          params[item] = authData[item];
        });
        //auth수정요청
        yield UserServices.modifyAuths(auth.id, params);
        //변경된 auth로 로그인 요청
        const res = yield UserServices.login(
          authData.username,
          authData.password,
          ActorType.Parent
        );
        //변경된 토큰 가저오기
        const authToken = yield UserServices.getAuthToken(auth.id);
        Storage.setToken(authToken.data.auth_token);
        //세션 로그인
        //Session.login(res.actor.data.auth, res.actor.data.id, res.auth.data.username, res.auth.data.human_name, res.actor.data.model_type,{isAdmin:res.auth.data.is_admin});
        Session.login(
          res.actor.data.auth,
          res.actor.data.id,
          undefined,
          undefined,
          undefined
        );
        dispatch(successLogin(res.auth.data, res.actor.data));
        //액션
      })
        .then(() => {
          dispatch({
            type: ActionTypes.CHANGE_AUTH,
            payload: {
              action: 'changeAuth',
            },
          });
        })
        .catch((e) => {
          dispatch(
            ExceptionAction.exception(
              e,
              'changeAuth 요청중 에러가 발생하였습니다.'
            )
          );
        });
    });
}

function getStudentId(firstStudentId) {
  let student_actor_id = Storage.get(StorageKey.STUDENT_ACTORID);
  if (student_actor_id) {
    return parseInt(student_actor_id, 10);
  }
  return firstStudentId;
}

export function setNoIdPopupFlag(noIdPopupFlag) {
  return (dispatch) =>
    dispatch(() => {
      return dispatch({
        type: ActionTypes.SET_NOID_POPUPFLAG,
        payload: {
          noIdPopupFlag: noIdPopupFlag,
        },
      });
    });
}

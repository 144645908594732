import Script from 'next/script';

export const ADN = () => {
  return (
    <>
      {/*ADN 크로징 설치 */}
      <Script
        id={'adn-closing-panel'}
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
          var adn_panel_param = adn_panel_param || [];
        adn_panel_param.push([{
        ui:'101001',
        ci:'1010010001',
        gi:'9791'
      }]);
        `,
        }}
      ></Script>
      <Script
        type="text/javascript"
        async
        src="//fin.rainbownine.net/js/adn_closingad_1.1.3.js"
      ></Script>

      {/*ADN Tracker[공통] start*/}
      <Script
        id={'adn-tracker'}
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
          var adn_param = adn_param || [];
        adn_param.push([{
        ui:'101001',
        ut:'Home'
      }]);
        `,
        }}
      ></Script>
      <Script
        type="text/javascript"
        async
        src="//fin.rainbownine.net/js/adn_tags_1.0.0.js"
      ></Script>
    </>
  );
};

const TEXT_TYPE = {
  normal: 'normal',
  emphasis: 'emphasis',
  emphasisBlue: 'emphasis-blue',
  bold: 'bold',
};

const ORIGINAL_PRICE = {
  엄마표종합반프리미엄2년: 169000,
  엄마표종합반프리미엄1년: 189000,
  엄마표종합반2년: 144000,
  엄마표종합반1년: 164000,
  '엄마표한글・수학1년': 104000,
  '엄마표한글・수학2년': 94000,
  엄마표영어1년: 104000,
  엄마표영어2년: 94000,
  종합반프리미엄2년: 199000,
  종합반프리미엄1년: 219000,
  종합반2년: 174000,
  종합반1년: 194000,
  '한글・수학1년': 124000,
  '한글・수학2년': 114000,
  영어1년: 134000,
  영어2년: 124000,
};

const PRODUCT_SELECT = {
  otherProductInfo: {
    단과반한글수학1년학습: {
      priceGap: '5만원',
    },
    단과반한글수학2년학습: {
      priceGap: '4만원',
    },
    단과반영어1년학습: {
      priceGap: '4만원',
    },
    단과반영어2년학습: {
      priceGap: '3만원',
    },
    종합반2년약정: {
      priceGap: '2만 5천원',
      booksCount: 120,
      booksClassCount: 48,
    },
    종합반1년약정: {
      priceGap: '2만 5천원',
      booksCount: 60,
      booksClassCount: 24,
    },
    엄마표2단과1년학습: {
      priceGap: '4만원',
    },
    엄마표2단과2년학습: {
      priceGap: '3만원',
    },
    엄마표1단과1년학습: {
      priceGap: '4만원',
    },
    엄마표1단과2년학습: {
      priceGap: '3만원',
    },
    엄마표종합반2년학습: {
      priceGap: '2만 5천원',
      booksCount: 120,
      booksClassCount: 24,
    },
    엄마표종합반1년학습: {
      priceGap: '2만 5천원',
      booksCount: 60,
      booksClassCount: 12,
    },
  },
  notice: {
    wink: {
      title: '※ 윙크학습이란?',
      className: 'wink',
      list: [
        '✓ 윙크 시청각 학습기로 개념을 이해하고 자기화합니다.',
        '✓ 학습지를 풀고, 영어책을 읽으며 복습합니다.',
        '✓ 틀린 문제는 자동 인식 기능을 통해 풀이 영상을 확인할 수 있으며, 오답노트(확인학습) 기능을 통해 간편하게 짚어줄 수도 있어 완전 학습이 가능합니다.',
        '✓ 매주 ‘윙크 학습 전문 선생님’과 학습기를 통해 화상으로 만나 배운 것을 다지고 확인하고 학부모님과는 정기적인 상담을 통해 학습컨설턴트의 역할도 같이 해 드립니다.',
      ],
    },
    parent: {
      title: '※ 엄마표 윙크학습이란?',
      className: 'parent',
      list: [
        '✓ 윙크 시청각 학습기로 개념을 이해하고 자기화합니다.',
        '✓ 학습지를 풀고, 영어책을 읽으며 복습합니다.',
        '✓ 틀린 문제는 자동 인식 기능을 통해 풀이 영상을 확인할 수 있으며, 오답노트(확인학습) 기능을 통해 간편하게 짚어줄 수도 있어 완전 학습이 가능합니다.',
        '✓ 필요 시, ‘윙크학습 플래너’가 학부모님께 연락을 드려 아이의 학습 상황에 대한 안내와 학습 동기 부여를 위한 코칭을 진행합니다.',
      ],
    },
  },
  productCard: {
    wink: [
      {
        key: '종합반프리미엄',
        contractYears: 2,
        isRecommended: true,
        isDefault: true,
        name: [
          { type: TEXT_TYPE.normal, text: '가장 많은 학부모님들이 선택하는' },
          { type: TEXT_TYPE.bold, text: '윙크 종합반 프리미엄 2년 학습' },
        ],
        product: [
          {
            type: TEXT_TYPE.bold,
            text: '상품 : 윙크표 한·수·영 학습 + 윙크 독서프로그램',
          },
        ],
        infos: [
          {
            type: TEXT_TYPE.normal,
            text: '- 한·수·영 학습 프로그램 (커리큘럼, 지면교재 최다 제공)',
          },
          {
            type: TEXT_TYPE.normal,
            text: '- 학습 전문 선생님의 매주 수업 (수업 시수 최다)',
          },
          {
            type: TEXT_TYPE.normal,
            text: '- 연령별 필독서 120권 대여 (실물도서 제공 유일)',
          },
          {
            type: TEXT_TYPE.normal,
            text: '\u00A0\u00A0\u00A0┗ 독서 전문 선생님의 1:1 책 읽어주는 수업 포함',
          },
        ],
        labels: [
          {
            type: TEXT_TYPE.emphasis,
            text: '약정 혜택으로 월 교재비 4만원 할인',
          },
          {
            type: TEXT_TYPE.emphasis,
            text: '2년약정으로 연간 24만원 추가 할인 혜택 적용',
          },
        ],
      },
      {
        key: '종합반프리미엄',
        contractYears: 1,
        name: [{ type: TEXT_TYPE.bold, text: '윙크 종합반 프리미엄 1년 학습' }],
        product: [
          {
            type: TEXT_TYPE.bold,
            text: '상품 : 윙크표 한·수·영 학습 + 윙크 독서프로그램',
          },
        ],
        infos: [
          {
            type: TEXT_TYPE.normal,
            text: '- 한·수·영 학습 프로그램 (커리큘럼, 지면교재 최다 제공)',
          },
          {
            type: TEXT_TYPE.normal,
            text: '- 학습 전문 선생님의 매주 수업 (수업 시수 최다)',
          },
          {
            type: TEXT_TYPE.normal,
            text: '- 연령별 필독서 60권 대여 (실물도서 제공 유일)',
          },
          {
            type: TEXT_TYPE.normal,
            text: '\u00A0\u00A0\u00A0┗ 독서 전문 선생님의 1:1 책 읽어주는 수업 포함',
          },
        ],
        labels: [
          {
            type: TEXT_TYPE.emphasis,
            text: '약정 혜택으로 월 교재비 4만원 할인',
          },
        ],
      },
      {
        key: '종합반',
        contractYears: 2,
        name: [{ type: TEXT_TYPE.bold, text: '윙크 종합반 2년 학습' }],
        product: [
          { type: TEXT_TYPE.bold, text: '상품 : 윙크표 한·수·영 학습' },
        ],
        infos: [
          {
            type: TEXT_TYPE.normal,
            text: '- 한·수·영 학습 프로그램 (커리큘럼, 지면교재 최다 제공)',
          },
          {
            type: TEXT_TYPE.normal,
            text: '- 학습 전문 선생님의 월 4회 수업(수업 시수 최다)',
          },
        ],
        labels: [
          {
            type: TEXT_TYPE.emphasis,
            text: '약정 혜택으로 월 교재비 4만원 할인',
          },
          {
            type: TEXT_TYPE.emphasis,
            text: '2년약정으로 연간 24만원 추가 할인 혜택 적용',
          },
        ],
      },
      {
        key: '종합반',
        contractYears: 1,
        name: [{ type: TEXT_TYPE.bold, text: '윙크 종합반 1년 학습' }],
        product: [
          { type: TEXT_TYPE.bold, text: '상품 : 윙크표 한·수·영 학습' },
        ],
        infos: [
          {
            type: TEXT_TYPE.normal,
            text: '- 한·수·영 학습 프로그램 (커리큘럼, 지면교재 최다 제공)',
          },
          {
            type: TEXT_TYPE.normal,
            text: '- 학습 전문 선생님의 월 4회 수업(수업 시수 최다)',
          },
        ],
        labels: [
          {
            type: TEXT_TYPE.emphasis,
            text: '약정 혜택으로 월 교재비 4만원 할인',
          },
        ],
      },
      {
        key: '한글・수학',
        contractYears: 2,
        name: [{ type: TEXT_TYPE.bold, text: '윙크 한글•수학 2년 학습' }],
        product: [
          { type: TEXT_TYPE.bold, text: '상품 : 윙크표 한글·수학 학습' },
        ],
        infos: [
          {
            type: TEXT_TYPE.normal,
            text: '- 한·수 학습 프로그램 (커리큘럼, 지면교재 최다 제공)',
          },
          {
            type: TEXT_TYPE.normal,
            text: '- 학습 전문 선생님의 월 2회 수업(수업 시수 최다)',
          },
        ],
        labels: [
          {
            type: TEXT_TYPE.emphasis,
            text: '약정 혜택으로 월 교재비 2만원 할인',
          },
          {
            type: TEXT_TYPE.emphasis,
            text: '2년약정으로 연간 12만원 추가 할인 혜택 적용',
          },
        ],
      },
      {
        key: '한글・수학',
        contractYears: 1,
        name: [{ type: TEXT_TYPE.bold, text: '윙크 한글•수학 1년 학습' }],
        product: [
          { type: TEXT_TYPE.bold, text: '상품 : 윙크표 한글·수학 학습' },
        ],
        infos: [
          {
            type: TEXT_TYPE.normal,
            text: '- 한·수 학습 프로그램 (커리큘럼, 지면교재 최다 제공)',
          },
          {
            type: TEXT_TYPE.normal,
            text: '- 학습 전문 선생님의 월 2회 수업(수업 시수 최다)',
          },
        ],
        labels: [
          {
            type: TEXT_TYPE.emphasis,
            text: '약정 혜택으로 월 교재비 2만원 할인',
          },
        ],
      },
      {
        key: '영어',
        contractYears: 2,
        name: [
          { type: TEXT_TYPE.bold, text: '윙크 스토리영어&파닉스 2년 학습' },
        ],
        product: [
          {
            type: TEXT_TYPE.bold,
            text: '상품 : 윙크표 스토리영어&파닉스 학습',
          },
        ],
        infos: [
          {
            type: TEXT_TYPE.normal,
            text: '- 스토리영어&파닉스 학습 프로그램 (커리큘럼, 지면교재 최다 제공)',
          },
          {
            type: TEXT_TYPE.normal,
            text: '- 학습 전문 선생님의 월 2회 수업(수업 시수 최다)',
          },
        ],
        labels: [
          {
            type: TEXT_TYPE.emphasis,
            text: '약정 혜택으로 월 교재비 2만원 할인',
          },
          {
            type: TEXT_TYPE.emphasis,
            text: '2년약정으로 연간 12만원 추가 할인 혜택 적용',
          },
        ],
      },
      {
        key: '영어',
        contractYears: 1,
        name: [
          { type: TEXT_TYPE.bold, text: '윙크 스토리영어&파닉스 1년 학습' },
        ],
        product: [
          {
            type: TEXT_TYPE.bold,
            text: '상품 : 윙크표 스토리영어&파닉스 학습',
          },
        ],
        infos: [
          {
            type: TEXT_TYPE.normal,
            text: '- 스토리영어&파닉스 학습 프로그램 (커리큘럼, 지면교재 최다 제공)',
          },
          {
            type: TEXT_TYPE.normal,
            text: '- 학습 전문 선생님의 월 2회 수업(수업 시수 최다)',
          },
        ],
        labels: [
          {
            type: TEXT_TYPE.emphasis,
            text: '약정 혜택으로 월 교재비 2만원 할인',
          },
        ],
      },
    ],
    parent: [
      {
        key: '엄마표종합반프리미엄',
        contractYears: 2,
        isRecommended: true,
        isDefault: true,
        name: [
          { type: TEXT_TYPE.normal, text: '가장 많은 학부모님들이 선택하는' },
          { type: TEXT_TYPE.bold, text: '엄마표 종합반 프리미엄 2년 학습' },
        ],
        product: [
          {
            type: TEXT_TYPE.bold,
            text: '상품 : 엄마표 한·수·영 학습 + 윙크 독서프로그램',
          },
        ],
        infos: [
          {
            type: TEXT_TYPE.normal,
            text: '- 한·수·영 학습 프로그램 (커리큘럼, 지면교재 최다 제공)',
          },
          {
            type: TEXT_TYPE.normal,
            text: '- 연령별 필독서 120권 대여 (실물도서 제공 유일)',
          },
          {
            type: TEXT_TYPE.normal,
            text: '- 독서 전문 선생님의 1:1 책 읽어주는 수업',
          },
        ],
        labels: [
          {
            type: TEXT_TYPE.emphasis,
            text: '약정 혜택으로 월 교재비 4만원 할인',
          },
          {
            type: TEXT_TYPE.emphasis,
            text: '2년약정으로 연간 24만원 추가 할인 혜택 적용',
          },
        ],
      },
      {
        key: '엄마표종합반프리미엄',
        contractYears: 1,
        name: [
          { type: TEXT_TYPE.bold, text: '엄마표 종합반 프리미엄 1년 학습' },
        ],
        product: [
          {
            type: TEXT_TYPE.bold,
            text: '상품 : 엄마표 한·수·영 학습 + 윙크 독서프로그램',
          },
        ],
        infos: [
          {
            type: TEXT_TYPE.normal,
            text: '- 한·수·영 학습 프로그램 (커리큘럼, 지면교재 최다 제공)',
          },
          {
            type: TEXT_TYPE.normal,
            text: '- 연령별 필독서 월 60권 대여 (실물도서 제공 유일)',
          },
          {
            type: TEXT_TYPE.normal,
            text: '- 독서 전문 선생님의 1:1 책 읽어주는 수업',
          },
        ],
        labels: [
          {
            type: TEXT_TYPE.emphasis,
            text: '약정 혜택으로 월 교재비 4만원 할인',
          },
        ],
      },
      {
        key: '엄마표종합반',
        contractYears: 2,
        name: [{ type: TEXT_TYPE.bold, text: '엄마표 종합반 2년 학습' }],
        product: [{ type: TEXT_TYPE.bold, text: '상품 : 한·수·영 학습' }],
        infos: [
          {
            type: TEXT_TYPE.normal,
            text: '- 한·수·영 학습 프로그램 (커리큘럼, 지면교재 최다 제공)',
          },
        ],
        labels: [
          {
            type: TEXT_TYPE.emphasis,
            text: '약정 혜택으로 월 교재비 4만원 할인',
          },
          {
            type: TEXT_TYPE.emphasis,
            text: '2년약정으로 연간 24만원 추가 할인 혜택 적용',
          },
        ],
      },
      {
        key: '엄마표종합반',
        contractYears: 1,
        name: [{ type: TEXT_TYPE.bold, text: '엄마표 종합반 1년 학습' }],
        product: [{ type: TEXT_TYPE.bold, text: '상품 : 한·수·영 학습' }],
        infos: [
          {
            type: TEXT_TYPE.normal,
            text: '- 한·수·영 학습 프로그램 (커리큘럼, 지면교재 최다 제공)',
          },
        ],
        labels: [
          {
            type: TEXT_TYPE.emphasis,
            text: '약정 혜택으로 월 교재비 4만원 할인',
          },
        ],
      },
      {
        key: '엄마표한글・수학',
        contractYears: 2,
        name: [{ type: TEXT_TYPE.bold, text: '엄마표 한글•수학 2년 학습' }],
        product: [{ type: TEXT_TYPE.bold, text: '상품 : 한글·수학 학습' }],
        infos: [
          {
            type: TEXT_TYPE.normal,
            text: '- 한글(국어), 수학 학습 프로그램 (커리큘럼, 지면교재 최다 제공)',
          },
        ],
        labels: [
          {
            type: TEXT_TYPE.emphasis,
            text: '약정 혜택으로 월 교재비 2만원 할인',
          },
          {
            type: TEXT_TYPE.emphasis,
            text: '2년약정으로 연간 12만원 추가 할인 혜택 적용',
          },
        ],
      },
      {
        key: '엄마표한글・수학',
        contractYears: 1,
        name: [{ type: TEXT_TYPE.bold, text: '엄마표 한글•수학 1년 학습' }],
        product: [{ type: TEXT_TYPE.bold, text: '상품 : 한글·수학 학습' }],
        infos: [
          {
            type: TEXT_TYPE.normal,
            text: '- 한글(국어), 수학 학습 프로그램 (커리큘럼, 지면교재 최다 제공)',
          },
        ],
        labels: [
          {
            type: TEXT_TYPE.emphasis,
            text: '약정 혜택으로 월 교재비 2만원 할인',
          },
        ],
      },
      {
        key: '엄마표영어',
        contractYears: 2,
        name: [
          { type: TEXT_TYPE.bold, text: '엄마표 스토리영어&파닉스 2년 학습' },
        ],
        product: [
          { type: TEXT_TYPE.bold, text: '상품 : 스토리영어&파닉스 학습' },
        ],
        infos: [
          {
            type: TEXT_TYPE.normal,
            text: '- 스토리영어&파닉스 학습 프로그램 (커리큘럼, 지면교재 최다 제공)',
          },
        ],
        labels: [
          {
            type: TEXT_TYPE.emphasis,
            text: '약정 혜택으로 월 교재비 2만원 할인',
          },
          {
            type: TEXT_TYPE.emphasis,
            text: '2년약정으로 연간 12만원 추가 할인 혜택 적용',
          },
        ],
      },
      {
        key: '엄마표영어',
        contractYears: 1,
        name: [
          { type: TEXT_TYPE.bold, text: '엄마표 스토리영어&파닉스 1년 학습' },
        ],
        product: [
          { type: TEXT_TYPE.bold, text: '상품 : 스토리영어&파닉스 학습' },
        ],
        infos: [
          {
            type: TEXT_TYPE.normal,
            text: '- 스토리영어&파닉스 학습 프로그램 (커리큘럼, 지면교재 최다 제공)',
          },
        ],
        labels: [
          {
            type: TEXT_TYPE.emphasis,
            text: '약정 혜택으로 월 교재비 2만원 할인',
          },
        ],
      },
    ],
  },
};

const REGIST_CHILD_ANSWER_TYPE = {
  IS_ONE_MORE_REQUEST: 'isOneMoreRequest',
};

export { PRODUCT_SELECT, ORIGINAL_PRICE, TEXT_TYPE, REGIST_CHILD_ANSWER_TYPE };

export const LEVEL_CHOICE = {
  beginner: 0,
  manual: 1,
};

export const LEVEL_CHOICE_MANUALLY = {
  hello: 0,
  custom: 1,
};

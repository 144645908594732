import Script from 'next/script';
import { GaValues } from '../utils/gaservices';

export const GaScript = () => {
  return (
    <>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${GaValues.GA_TRACKING_ID}`}
      />
      <Script
        id="ga4_init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${GaValues.GA_TRACKING_ID}', {
            page_path: window.location.pathname
          });
        `,
        }}
      />
    </>
  );
};

import Async from '../../../library/Async';
import UserServices from '../../../library/services/UserServices';
import AuxServices from '../../../library/services/AuxServices';
import { ActorType } from '../../../library/constants/Code';
import Session from '../../../library/Session';
import Storage from '../../../library/Storage';
import * as ExceptionAction from '../../../library/error/ExceptionAction';
import { ActionTypes as MemberActionTypes } from 'src/features/member/MemberAction';

let TAG = 'mypage';
export const ActionTypes = Object.freeze({
  CLEAR_ACTION: `${TAG}/CLEAR_ACTION`,
  CHANGE_ACTION: `${TAG}/CHANGE_ACTION`,
  WITHDRWAL: `${TAG}/WITHDRWAL`,
  EXCEPTION: `${TAG}/EXCEPTION`,
});

function successLogin(auth, actor) {
  return {
    type: MemberActionTypes.LOGIN,
    payload: {
      action: 'successLogin',
      message: '',
      auth,
      actor,
    },
  };
}

export function clearAction() {
  return (dispatch, getState) => {
    return dispatch({
      type: ActionTypes.CLEAR_ACTION,
    });
  };
}

//정보 수정
export function modifyAuths(authData, categoryTagName, customAction) {
  return (dispatch, getState) =>
    dispatch(() => {
      return Async(function* () {
        let auth = getState().MemberReducer.auth;
        let params = {
          username: auth.username,
          human_name: auth.human_name,
          human_mdn: auth.human_mdn,
          data: auth.data,
        };
        //mdn_token이 있어야 auth데이터를 바꿜수있습니다.
        Object.keys(authData).forEach((item, index) => {
          params[item] = authData[item];
        });
        //auth수정요청
        yield UserServices.modifyAuths(auth.id, params);
        //변경된 토큰 가저오기
        if (categoryTagName) {
          let actorId = getState().MemberReducer.actor.id;
          yield modifyInnerParentCategoryTag(actorId, categoryTagName);
        }
        const authToken = yield UserServices.getAuthToken(auth.id);
        Storage.setToken(authToken.data.auth_token);
        //변경된 auth로 로그인 요청
        const res = yield UserServices.authTokenLogin(
          authToken.data.auth_token,
          ActorType.Parent
        );
        Session.login(
          res.actor.data.auth,
          res.actor.data.id,
          res.auth.data.username,
          res.auth.data.human_name,
          res.actor.data.model_type,
          { isAdmin: res.auth.data.is_admin }
        );
        Storage.setLoginToken(authToken.data.auth_token);
        return dispatch(successLogin(res.auth.data, res.actor.data));
      })
        .then(() => {
          return dispatch({
            type: ActionTypes.CHANGE_ACTION,
            payload: {
              action: customAction ? customAction : 'changeAuth',
            },
          });
        })
        .catch((e) => {
          if (e.response && e.response.data && e.response.data?.human_email) {
            //중복 이메일 입니다.
            dispatch({
              type: ActionTypes.CHANGE_ACTION,
              payload: {
                action: 'emailDuplicate',
              },
            });
          } else {
            return dispatch(
              ExceptionAction.exception(
                e,
                'changeAuth 요청중 에러가 발생하였습니다.'
              )
            );
          }
        });
    });
}

export function checkOneTimePasswordAndModifyAuths(
  mdn,
  certificationNumber,
  authData
) {
  return (dispatch, getState) =>
    dispatch(() => {
      return UserServices.checkMdn(mdn, certificationNumber)
        .then((res) => {
          if (res.data.mdn_token) {
            return dispatch(modifyAuths(authData));
          }
        })
        .catch((e) => {
          return dispatch(
            ExceptionAction.exception(
              e,
              'checkOneTimePasswordAndModifyAuths 요청중 에러가 발생하였습니다.'
            )
          );
        });
    });
}

export function modifyAuthData(authData, option = {}) {
  return (dispatch, getState) =>
    dispatch(() => {
      let auth = getState().MemberReducer.auth;
      let params = {
        data: {},
      };
      Object.keys(authData).forEach((item, index) => {
        params.data[item] = authData[item];
      });
      return UserServices.modifyAuths(auth.id, params).then((res) => {
        if (!option.noAction) {
          dispatch({
            type: ActionTypes.CHANGE_ACTION,
            payload: {
              action: 'changedAuthData',
            },
          });
        }
      });
    });
}

export function checkAuth(oldPassword) {
  return (dispatch, getState) =>
    dispatch(() => {
      const auth = getState().MemberReducer.auth;
      return UserServices.login(auth.username, oldPassword, ActorType.Parent)
        .then((res) => {
          return dispatch({
            type: ActionTypes.CHANGE_ACTION,
            payload: {
              action: 'successCheckAuth',
            },
          });
        })
        .catch((e) => {
          return dispatch({
            type: ActionTypes.CHANGE_ACTION,
            payload: {
              action: 'failCheckAuth',
            },
          });
        });
    });
}

export function changePW(password) {
  return (dispatch, getState) =>
    dispatch(() => {
      let auth = getState().MemberReducer.auth;
      return Async(function* () {
        yield UserServices.modifyAuths(auth.id, { password });
        //변경된 auth로 로그인 요청
        const res = yield UserServices.login(
          auth.username,
          password,
          ActorType.Parent
        );
        //변경된 토큰 가저오기
        const authToken = yield UserServices.getAuthToken(auth.id);
        Storage.setToken(authToken.data.auth_token);

        Session.login(
          res.actor.data.auth,
          res.actor.data.id,
          res.auth.data.username,
          res.auth.data.human_name,
          res.actor.data.model_type,
          { isAdmin: res.auth.data.is_admin }
        );
        Storage.setLoginToken(authToken.data.auth_token);
        dispatch(successLogin(res.auth.data, res.actor.data));
      })
        .then(() => {
          dispatch({
            type: ActionTypes.CHANGE_ACTION,
            payload: {
              action: 'successChangePW',
            },
          });
        })
        .catch((e) => {
          return dispatch(
            ExceptionAction.exception(
              e,
              'changePW 요청중 에러가 발생하였습니다.'
            )
          );
        });
    });
}

function modifyInnerParentCategoryTag(actorId, category_tag_name) {
  return new Promise((resolve, reject) => {
    AuxServices.getParentCategoryTag(category_tag_name)
      .then((res) => {
        UserServices.modifyActors(actorId, { category_tag: res.data.id })
          .then((res2) => {
            resolve('ok');
          })
          .catch(() => {
            reject('fail');
          });
      })
      .catch(() => {
        reject('fail');
      });
  });
}

export function modifyParentCategoryTag(category_tag_name) {
  return (dispatch, getState) =>
    dispatch(() => {
      let actorId = getState().MemberReducer.actor.id;
      return AuxServices.getParentCategoryTag(category_tag_name)
        .then((res) => {
          UserServices.modifyActors(actorId, {
            category_tag: res.data.id,
          }).then((res2) => {
            dispatch({
              type: ActionTypes.CHANGE_ACTION,
              payload: {
                action: 'successModifyParentCategoryTag',
              },
            });
          });
        })
        .catch((e) => {
          return dispatch(
            ExceptionAction.exception(
              e,
              '양육자 구분변경 요청중 에러가 발생하였습니다.'
            )
          );
        });
    });
}

export function setWithdrawal(authId, reason) {
  return (dispatch, getState) =>
    dispatch(() => {
      const postData = {
        reason,
      };
      return UserServices.withdrawal(authId, postData).then((response) => {
        return dispatch({
          type: ActionTypes.WITHDRWAL,
        });
      });
    });
}

import { ActionTypes } from './FileUploadAction';

const initializeState = {
  isSuccess: false,
  isError: false,
  fileInfo: null,
  requester: '',

  isStudyBookFileSuccess: false,
  isStudyBookFileError: false,
  studyBookFileInfo: null,
};

export default function FileUploadReducer(state = initializeState, action) {
  switch (action.type) {
    case ActionTypes.REQUEST_FILE_UPLOAD:
      return {
        ...state,
        isSuccess: false,
        isError: false,
        fileInfo: null,
        requester: action.payload.requester,
      };
    case ActionTypes.ERROR_FILE_UPLOAD:
      return {
        ...state,
        isSuccess: false,
        isError: true,
        fileInfo: null,
        requester: action.payload.requester,
      };
    case ActionTypes.SUCCESS_FILE_UOLOAD:
      return {
        ...state,
        isSuccess: true,
        isError: false,
        fileInfo: action.payload.fileInfo,
        requester: action.payload.requester,
      };

    case ActionTypes.REQUEST_STUDY_BOOK_FILE_UPLOAD:
      return {
        ...state,
        isStudyBookFileSuccess: false,
        isStudyBookFileError: false,
        studyBookFileInfo: null,
      };
    case ActionTypes.ERROR_STUDY_BOOK_FILE_UPLOAD:
      return {
        ...state,
        isStudyBookFileSuccess: false,
        isStudyBookFileError: true,
        studyBookFileInfo: null,
      };
    case ActionTypes.SUCCESS_STUDY_BOOK_FILE_UPLOAD:
      return {
        ...state,
        isStudyBookFileSuccess: true,
        isStudyBookFileError: false,
        studyBookFileInfo: action.payload.studyBookFileInfo,
      };

    default:
      return { ...initializeState };
  }
}

import APICaller from '../APICaller';
import axios from '@DanbiEduCorp/axios';

export default class FileServices {
  static fileUpload(formData, onUploadProgress) {
    const url = '/aux/files/';

    return APICaller.fileUpload(url, formData, onUploadProgress);
  }

  static bookFileUpload(formData) {
    let url = 'https://vision.danbi.biz/upload';
    if (window.location.host !== 'student.wink.co.kr') {
      url = 'https://vision.wink.co.kr/upload';
    }

    return axios({
      method: 'post',
      url: url,
      responseType: 'text',
      data: formData,
      headers: { 'content-type': 'multipart/form-data' },
      withCredentials: false,
    });
  }
}

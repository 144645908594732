import React, { Component } from 'react';
import { connect } from 'react-redux';
import DesktopLayerPopup from '@/features/common/DesktopLayerPopup';
import MemberInfoRegisterPop from './MemberInfoRegisterPop';
import ParentsUtil from '../../../library/util/ParentsUtil';

class MemberIdIssuePop extends Component {
  constructor(props) {
    super(props);

    this.onClickRegist = this.onClickRegist.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  onClickRegist(e) {
    e.preventDefault();
    DesktopLayerPopup.modal(<MemberInfoRegisterPop />);
    DesktopLayerPopup.hide(this.props.layerKey);
  }

  onClose() {
    DesktopLayerPopup.hide(this.props.layerKey);
  }

  render() {
    const { actor } = this.props;
    return (
      <div className="layer-wrap member small" style={{ marginTop: '-175px' }}>
        <div className="layer-center register-info">
          <h2>회원정보 등록 안내</h2>
          <strong>
            <em>{ParentsUtil.getActorHumanName(actor)}</em>님! 윙크 아이디가
            아직 없으시네요.
          </strong>
          <p>
            윙크 <strong>아이디와 회원정보</strong>를 등록해주시면
            <br />
            <strong>
              더 많은 윙크 서비스를 유용하게 이용하실 수 있습니다.
            </strong>
          </p>
          <p>윙크 아이디와 회원정보를 등록하시겠어요?</p>
          <a href="#" className="btn-type2 on" onClick={this.onClickRegist}>
            아이디 · 회원정보 등록
          </a>
        </div>
        <button className="close" onClick={this.onClose}>
          팝업 닫기
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    actor: state.MemberReducer.actor,
  };
};

export default connect(mapStateToProps, null)(MemberIdIssuePop);

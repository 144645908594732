import APICaller from '../APICaller';

export default class BoardServices {
  static getBoardTypes() {
    const url = '/board/board_types/?offset=0&limit=1000';

    return APICaller.getCache(url);
  }

  static getBoardPostTypes() {
    const url = '/board/post_types/?offset=0&limit=1000';

    return APICaller.getCache(url);
  }

  static getBoardNames(boardId) {
    const url = `/board/boards/?model_type=${boardId}`;

    return APICaller.getCache(url);
  }

  static getBoardInfoByName(boardType, name) {
    const url = `/board/board_types/${boardType}/get_named/?name=${encodeURIComponent(
      name
    )}`;

    return APICaller.get(url);
  }

  static getPostList(boardId, params) {
    const url = `/board/boards/${boardId}/posts/`;

    return APICaller.get(url, params);
  }

  static getPostLikeList(actorId, postIds) {
    const url =
      `/account/actors/${actorId}/actor_post_relations/?model_type=1&post__in=` +
      postIds.join(',');

    return APICaller.get(url);
  }

  static getPostInfo(postId, isReadCount = true) {
    const url = `/board/posts/${postId}/`;
    let params = { inc_read_count: isReadCount.toString() };

    return APICaller.get(url, params);
  }

  static getPostFileList(postId) {
    const url = `/board/posts/${postId}/files/`;

    return APICaller.get(url);
  }

  static getPostListByPostId(boardId, postIdList) {
    const url =
      `/board/boards/${boardId}/posts/?id__in=` +
      postIdList.join(',') +
      '&parent__isnull=false';

    return APICaller.get(url);
  }

  static getTagMembers(tagId) {
    const url = `/aux/tags/${tagId}/members/`;

    return APICaller.get(url);
  }

  static getCacheTagMembers(tagId) {
    const url = `/aux/tags/${tagId}/members/`;

    return APICaller.getCache(url);
  }

  static addPost(boardId, postType, title, text, html, data) {
    const url = '/board/posts/';
    const postData = {
      model_type: postType,
      board: boardId,
      title,
      text,
      html,
      data,
    };

    return APICaller.post(url, postData);
  }

  static addAlbumPost(boardId, postType, fileUrl, data) {
    const url = '/board/posts/';
    const postData = {
      model_type: postType,
      board: boardId,
      url: fileUrl,
      data,
    };

    return APICaller.post(url, postData);
  }

  static modifyPost(boardId, postId, postType, title, text, html, data) {
    const url = `/board/posts/${postId}/modify/`;
    let postData = {
      model_type: postType,
      board: boardId,
      title,
      html,
      data,
    };

    if (text) {
      postData.text = text;
    }

    return APICaller.post(url, postData);
  }

  static removePost(postId) {
    const url = `/board/posts/${postId}/remove/`;

    return APICaller.post(url);
  }

  static hidePost(postId) {
    const url = `/board/posts/${postId}/hide/`;

    return APICaller.post(url);
  }

  static showPost(postId) {
    const url = `/board/posts/${postId}/show/`;

    return APICaller.post(url);
  }

  static modifyPostData(postId, data) {
    const url = `/board/posts/${postId}/modify/`;

    const param = {
      data,
    };

    return APICaller.post(url, param);
  }

  static getRelatedPostList(postId) {
    const url = `/board/posts/${postId}/related_posts/`;

    return APICaller.get(url);
  }

  static addChildPost(boardId, postType, parentPostId, html, text, data) {
    const url = '/board/posts/';

    const postData = {
      board: boardId,
      model_type: postType,
      parent: parentPostId,
      html,
      text,
      data,
    };

    return APICaller.post(url, postData);
  }

  static getChildPostInfo(childPostId, isReadCount = true) {
    const url = `/board/posts/${childPostId}/`;
    let params = { inc_read_count: isReadCount.toString() };

    return APICaller.get(url, params);
  }

  static modifyChildPost(boardId, postType, postId, html, text, data) {
    const url = `/board/posts/${postId}/modify/`;
    let postData = {
      model_type: postType,
      board: boardId,
      html,
      data,
    };

    if (text) {
      postData.text = text;
    }
    return APICaller.post(url, postData);
  }

  static removeChildPost(childPostId) {
    const url = `/board/posts/${childPostId}/remove/`;

    return APICaller.post(url);
  }

  static getChildPostList(postId, offset, limit, options = null) {
    const url = `/board/posts/${postId}/childs/`;
    let params = { offset, limit };
    if (options !== null) {
      params = { ...params, ...options };
    }

    return APICaller.get(url, params);
  }

  static getChildPostListByActorId(postId, actorId) {
    const url = `/board/posts/${postId}/childs/`;
    let params = { offset: 0, limit: 1, actor: actorId };

    return APICaller.get(url, params);
  }

  static likePost(postId) {
    const url = `/board/posts/${postId}/like/`;

    return APICaller.post(url);
  }

  static unlikePost(postId) {
    const url = `/board/posts/${postId}/unlike/`;

    return APICaller.post(url);
  }

  static recommendPost(postId, isNoticed = true) {
    const url = `/board/posts/${postId}/recommend/`;
    let params = { is_noticed: isNoticed };

    return APICaller.post(url, params);
  }

  static getCommentList(postId, offset, limit, query = {}) {
    const url = `/board/posts/${postId}/comments/`;
    let params = { offset, limit, ...query };

    return APICaller.get(url, params);
  }

  static getCommentChildList(parentCommentId, offset, limit) {
    const url = `/board/comments/${parentCommentId}/childs/`;
    let params = { offset, limit };

    return APICaller.get(url, params);
  }

  static addComment(collectCommentInfo) {
    const url = '/board/comments/';

    return APICaller.post(url, collectCommentInfo);
  }

  static modifyComment(commentId, collectCommentInfo) {
    const url = `/board/comments/${commentId}/modify/`;

    return APICaller.post(url, collectCommentInfo);
  }

  static removeComment(commentId) {
    const url = `/board/comments/${commentId}/remove/`;
    return APICaller.post(url);
  }
  //공개 비공개 처리
  static postSetStatus(postId, status) {
    const url = `/board/posts/${postId}/set_status/`;
    let params = { status };
    return APICaller.post(url, params);
  }

  static getPostCacheCategoryTag(category_tag_name) {
    const url = `/aux/tag_types/PostCategoryTag/get_named/?name=${encodeURIComponent(
      category_tag_name
    )}`;
    return APICaller.get(url);
  }

  static setAccuseComment(commentId) {
    const url = `/board/comments/${commentId}/accuse/`;
    return APICaller.post(url);
  }

  static getChildStoryPostList(params, is_noticed) {
    const url = `/board/posts/?model_type=6&status=7&is_noticed=${is_noticed}`;
    return APICaller.get(url, params);
  }
}

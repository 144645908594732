'use client';

import React, { Component } from 'react';
import Link from 'next/link';
import LoginInjector from '../../LoginInjector';
import { values } from 'src/features/main/configs';
import { routes } from 'src/constants/routes';
import DanbiInfo from '@/library/constants/DanbiInfo';

class RightFloating extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isBannerClosed: false,
      hiddenDonation: false,
    };
    this.changeBannerTop = this.changeBannerTop.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.changeBannerTop);
    window.addEventListener('resize', this.changeBannerHorizontal);
    // window.addEventListener('scroll', this.toggleDonation);

    this.changeBannerHorizontal();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.changeBannerTop);
    window.removeEventListener('resize', this.changeBannerHorizontal);
    // window.addEventListener('scroll', this.toggleDonation);
  }

  toggleDonation = () => {
    const mainContent5Top = document
      .querySelector('#main-content-5')
      ?.getBoundingClientRect().top;
    if (mainContent5Top < 0) {
      this.setState({ hiddenDonation: true });
    } else {
      this.setState({ hiddenDonation: false });
    }
  };

  changeBannerTop() {
    const { topBannerHeight = 0 } = this.props;
    this.floatingBannerRef.style.top = `${Math.max(
      230 + topBannerHeight - window.scrollY,
      90
    )}px`;
  }

  changeBannerHorizontal = () => {
    if (this.floatingBannerRef && this.floatingBannerRef.style) {
      if (document.body.clientWidth - window.scrollX < 1700) {
        this.floatingBannerRef.style.left = null;
        this.floatingBannerRef.style.right = '10px';
      } else {
        const headerRight = document
          .querySelector('#gnb > ul > li:last-child')
          .getBoundingClientRect().right;
        this.floatingBannerRef.style.left = `${headerRight + 10}px`;
        this.floatingBannerRef.style.right = null;
      }
    }
  };

  renderDonation() {
    return (
      <div className="lay-donation">
        <img src={values.rightFloating.donation} alt="" />
        <Link
          className="lay-donation-link"
          href="https://naver.me/xuiqCT1m"
          target="_blank"
        >
          <img
            src="https://s.wink.co.kr/marketing/homepage/floating/donation-request.png"
            alt=""
          />
        </Link>
      </div>
    );
  }

  renderAdditionalInfo() {
    const { isBannerClosed } = this.state;

    if (isBannerClosed) {
      return null;
    }
    return (
      <div className="lay-additional-info">
        {this.renderReferenceRoom()}
        <hr className="lay-floating-divider" />
        {this.renderGroupRequest()}
        <hr className="lay-floating-divider" />
        {this.renderCSCenter()}
      </div>
    );
  }

  renderReferenceRoom() {
    return (
      <div className="lay-reference-room">
        <img src={values.rightFloating.referenceRoom} alt="" />
        <Link
          className="lay-reference-room-link"
          href={`${routes.referenceRoom}?subject=total`}
        >
          <img src={values.rightFloating.referenceRoomButton} alt="" />
        </Link>
      </div>
    );
  }

  renderGroupRequest() {
    return (
      <div className="lay-group-request">
        <div className="lay-group-request-text">
          <p>어린이집, 유치원</p>
          <p>단체/해외</p>
        </div>
        <Link
          className="lay-group-request-link"
          href={`${values.rightFloating.groupRequest}?subject=total`}
          target="_blank"
        >
          <img src={values.rightFloating.groupRequestButton} alt="" />
        </Link>
      </div>
    );
  }

  onClickFloatingMenu(path, externalProps) {
    if (externalProps) {
      window.open(path, externalProps.target, externalProps.features);
    } else {
      this.props.history.push(path);
    }
  }

  renderFloatingMenu() {
    return (
      <ul className="floating-menu-list">
        {values.rightFloatingMenu.map((menu) => (
          <li
            key={menu.id}
            id={menu.id}
            className={menu.className}
            onClick={() =>
              this.onClickFloatingMenu(menu.path, menu.externalProps)
            }
          >
            <span>
              <img src={menu.iconSrc} alt={menu.text} />
            </span>
            {menu.text}
          </li>
        ))}
      </ul>
    );
  }

  renderCSCenter() {
    return (
      <div className="lay-cs-center">
        <div className="lay-cs-center-text">
          <p>윙크 학부모님 공감센터</p>
          <p>평일 10:00~20:00</p>
          <p className="cs-center-tel">{DanbiInfo.고객센터전화번호}</p>
        </div>
      </div>
    );
  }

  render() {
    const { topBannerHeight } = this.props;

    return (
      <div className="lay-floating-wrapper">
        <div
          className="lay-floating"
          ref={(ref) => {
            this.floatingBannerRef = ref;
          }}
          style={{ top: `${topBannerHeight + 230}px` }}
        >
          {!this.state.hiddenDonation && this.renderDonation()}
          {this.renderAdditionalInfo()}
        </div>
      </div>
    );
  }
}

export default LoginInjector(RightFloating);

import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import Link from 'next/link';

import * as BoardAction from '../../../../library/board/BoardAction';
import LoginInjector from '../../LoginInjector';

import RightFloating from './RightFloating';

import * as PaymentAction from '../../../../library/payment/PaymentAction';
import { fetch } from '../../../../redux/actions';
import { api } from '../../configs';
import { GNB_MENU } from 'src/constants/menu';
import { routes } from 'src/constants/routes';
import { getPageName } from '@/utils/miscUtils';

const mapStateToProps = ({
  fetch,
  BoardReducer,
  PaymentReducer,
  MemberReducer,
}) => {
  const studentPaymentList = fetch?.multipleList?.studentPaymentList ?? {};
  return {
    postCategoryTags: BoardReducer.postCategoryTags['육아교육백과'],
    studentInfo: MemberReducer.studentInfo,
    autoPaymentList: PaymentReducer.autoPaymentList,
    parentInfo: MemberReducer.actor,
    studentPaymentList,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getPostCategory: () => {
      dispatch(BoardAction.getPostCategory('육아교육백과'));
    },
    getMultipleList: (list) => dispatch(fetch.multipleList(list)),
    getAutoPaymentContractList: (parentActorId) =>
      dispatch(PaymentAction.getAutoPaymentContractList(parentActorId)),
  };
};

class Header extends Component {
  constructor(props) {
    super(props);

    this.onMouseEnterMenu = this.onMouseEnterMenu.bind(this);
    this.onMouseLeaveMenu = this.onMouseLeaveMenu.bind(this);
    this.onClickMove = this.onClickMove.bind(this);
    this.onClickLoginAfterPush = this.onClickLoginAfterPush.bind(this);

    this.state = {
      showTeacherMenu: true,
      mounted: false,
    };
  }

  async componentDidMount() {
    this.setState({ mounted: true });
    await this.getPaymentList();
    await this.getStudentPaymentList();
  }

  async componentDidUpdate(prevProps, prevState) {
    const { studentInfo, parentInfo } = this.props;
    if (
      studentInfo.id !== prevProps.studentInfo.id ||
      parentInfo.id !== prevProps.parentInfo.id
    ) {
      await this.getPaymentList();
      await this.getStudentPaymentList();
      return;
    }
  }

  setFlag(value) {
    this.setState({
      showTeacherMenu: value,
    });
  }

  getPaymentList() {
    const { autoPaymentList, parentInfo } = this.props;
    if (
      (autoPaymentList.results && autoPaymentList.results.length) ||
      !parentInfo.id
    ) {
      return Promise.resolve();
    }

    return this.props.getAutoPaymentContractList(parentInfo.id);
  }

  getStudentPaymentList() {
    const { autoPaymentList, studentInfo } = this.props;

    const autoPayment = (autoPaymentList?.results ?? []).find(
      (payment) => payment.student === studentInfo.id
    );

    if (!autoPayment) {
      this.setFlag(true);
      return;
    }

    const apiObj = api.getPaymentDetail(autoPayment.id, { limit: 1 });

    return this.props
      .getMultipleList([
        { id: 'studentPaymentList', url: apiObj.url, params: apiObj.params },
      ])
      .then(() => {
        const { studentPaymentList } = this.props;
        if (!studentPaymentList.results || !studentPaymentList.results.length) {
          this.setFlag(true);
          return;
        }

        const productInfo =
          studentPaymentList?.results?.[0]?.data?.LGD_PRODUCTINFO ?? '';

        return this.setFlag(!/엄마표/.test(productInfo));
      });
  }

  onMouseEnterMenu(e) {
    e.stopPropagation();
    e.preventDefault();

    const target = e.currentTarget;
    target.classList.add('on');
  }

  onMouseLeaveMenu(e, test) {
    if (test) {
      return test.classList.remove('on');
    }
    const target = e.currentTarget;

    target.classList.remove('on');
  }

  onClickMove(e, url) {
    e.preventDefault();
    this.props.history.push(url);
  }

  onClickLoginAfterPush(e, url) {
    e.preventDefault();
    this.props.history.loginAfterPush(url);

    return false;
  }

  renderSubMenu(subMenuList, path) {
    const { mounted } = this.state;
    if (!subMenuList) {
      return null;
    }

    return (
      <ul>
        <div>
          {subMenuList.map((subMenu, idx) => {
            const [_, __, subPath] = subMenu.path.href.split('/');
            const isActive = subPath?.startsWith(path);
            return (
              <li key={idx} className={subMenu.className}>
                {subMenu.requireLogin ? (
                  <Link
                    href={'#'}
                    className={classNames({ current: mounted && isActive })}
                    onClick={(e) =>
                      this.onClickLoginAfterPush(e, subMenu.path.href)
                    }
                  >
                    {subMenu.content}
                  </Link>
                ) : (
                  <Link
                    {...subMenu.path}
                    className={classNames({ current: mounted && isActive })}
                  >
                    {subMenu.content}
                  </Link>
                )}
              </li>
            );
          })}
        </div>
      </ul>
    );
  }

  renderMenu(pagename) {
    const { mounted } = this.state;
    const [_, path, subPath] = pagename?.split('/') ?? ['', '', ''];
    return (
      <ul>
        <li className="main-logo">
          <Link href={routes.home} id="nav-logo">
            <img
              src="https://us.wink.co.kr/pc/renewalV3/common/logo-highres.png"
              id="main-logo"
              alt="logo"
            />
          </Link>
        </li>
        {GNB_MENU.map((menu, idx) => {
          const isActive = path
            ? menu.path.href.slice(1).startsWith(path)
            : menu.path.href === '/';
          return (
            <li
              key={idx}
              className={classNames(
                { current: mounted && isActive },
                menu.className
              )}
              onMouseEnter={this.onMouseEnterMenu}
              onMouseLeave={this.onMouseLeaveMenu}
            >
              <Link {...menu.path}>{menu.content}</Link>
              {this.renderSubMenu(menu.children, subPath)}
            </li>
          );
        })}
      </ul>
    );
  }

  render() {
    const pagename = getPageName(this.props.history, { noQueries: true });
    return (
      <nav id="gnb">
        {this.renderMenu(pagename)}
        <RightFloating {...this.props} />
      </nav>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginInjector(Header));

import ExternalServices from '../services/ExternalServices';
import * as ExceptionAction from '../error/ExceptionAction';
import { DISPLAY_STATUS } from './PostNumberConstants';

const TAG = 'POSTNUMBER';

export const ActionTypes = Object.freeze({
  INIT_ROAD_ADDRESS: `${TAG}/INIT_ROAD_ADDRESS`,
  GET_ROAD_ADDRESS: `${TAG}/GET_ROAD_ADDRESS`,
  GET_ROAD_ADDRESS_NO_LIST: `${TAG}/GET_ROAD_ADDRESS_NO_LIST`,
});

export function initRoadAddress() {
  return {
    type: ActionTypes.INIT_ROAD_ADDRESS,
    payload: {
      addressState: DISPLAY_STATUS.displayStatusInit,
    },
  };
}

export function getRoadAddress(query) {
  return (dispatch, getState) =>
    dispatch(() => {
      return ExternalServices.getRoadAddress(query)
        .then((response) => {
          if (response.data.count === 0) {
            return dispatch({
              type: ActionTypes.GET_ROAD_ADDRESS_NO_LIST,
              payload: {
                addressState: DISPLAY_STATUS.displayStatusNoList,
              },
            });
          } else {
            return dispatch({
              type: ActionTypes.GET_ROAD_ADDRESS,
              payload: {
                addressData: response.data.results,
                addressState: DISPLAY_STATUS.displayStatusDisplayingList,
              },
            });
          }
        })
        .catch((e) => {
          return dispatch(
            ExceptionAction.exception(
              e,
              '우편번호 조회 중 오류가 발생하였습니다.'
            )
          );
        });
    });
}

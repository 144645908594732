import Async from '../Async';
import UserServices from '../services/UserServices';
import AuxServices from '../services/AuxServices';
import PaymentServices from '../services/PaymentServices';
import IssueTrackerServices from '../services/IssueTrackerServices';
import {
  ActorType,
  ApplyChannel,
  ItemTopCategoryTagName,
  WorkType,
  WorkTypeName,
} from '../constants/Code';
import Storage from '../Storage';
import Session from '../Session';
import * as ExceptionAction from '../error/ExceptionAction';
import ParentUtil from '../util/ParentsUtil';
import StudyServices from '../services/StudyServices';

const tag = 'MEMBER_JOIN';
export const ActionTypes = Object.freeze({
  CLEAR_ACTION: `${tag}/CLEAR_ACTION`,
  SUCCESS_ONE_TIME_PASSWORD: `${tag}/SUCCESS_ONE_TIME_PASSWORD`,
  PHONE_DUPLICATE: `${tag}/PHONE_DUPLICATE`,
  FAIL_CHECK_ONE_TIME_PASSWORD: `${tag}/FAIL_CHECK_ONE_TIME_PASSWORD`,
  SUCCESS_GET_AUTH_TOKEN: `${tag}/SUCCESS_GET_AUTH_TOKEN`,
  SUCCESS_GET_MDN_TOKEN: `${tag}/SUCCESS_GET_MDN_TOKEN`,

  SUCCESS_HALF_CREATE_AUTH: `${tag}/SUCCESS_HALF_CREATE_AUTH`,
  FAIL_HALF_CREATE_AUTHS: `${tag}/FAIL_HALF_CREATE_AUTHS`,
  SUCCESS_PHONE_LOGIN: `${tag}/SUCCESS_PHONE_LOGIN`,
  FAIL_LOGIN: `${tag}/FAIL_LOGIN`,
  LOGOUT: `${tag}/LOGOUT`,

  PROCESS_PHONE_LOGIN: `${tag}/PROCESS_PHONE_LOGIN`,
  SUCCESS_TOKEN_LOGIN: `${tag}/SUCCESS_TOKEN_LOGIN`,
  SUCCESS_TOKEN_LOGIN_PASS: `${tag}/SUCCESS_TOKEN_LOGIN_PASS`,
  FAIL_TOKEN_LOGIN: `${tag}/FAIL_TOKEN_LOGIN`,

  SUCCESS_CREATE_AUTHS_FOR_EXPERIENCE: `${tag}/SUCCESS_CREATE_AUTHS_FOR_EXPERIENCE`,
  FAIL_CREATE_AUTHS_FOR_EXPERIENCE: `${tag}/FAIL_CREATE_AUTHS_FOR_EXPERIENCE`,
  SUCCESS_CREATE_FAMILY: `${tag}/SUCCESS_CREATE_FAMILY`,
  SUCCESS_SET_STUDENT_MEMBER: `${tag}/SUCCESS_SET_STUDENT_MEMBER`,
  POST_STUDY_STEP_DIAGNOSIS: `${tag}/POST_STUDY_STEP_DIAGNOSIS`,
  SUCCESS_APPLY_EXPERIENCE: `${tag}/SUCCESS_APPLY_EXPERIENCE`,
  FAIL_CREATE_FAMILY_AND_APPLY_EXPERIENCE: `${tag}/FAIL_CREATE_FAMILY_AND_APPLY_EXPERIENCE`,

  STUDY_APPLIED: `${tag}/STUDY_APPLIED`,
  SUCCESS_CSRAVILABLE_WORK_COMPLETE: `${tag}/SUCCESS_CSRAVILABLE_WORK_COMPLETE`,

  SET_HUMAN_NAME: `${tag}/SET_HUMAN_NAME`,
  SET_ADDRESS: `${tag}/SET_ADDRESS`,
  SET_HUMAN_MDN: `${tag}/SET_HUMAN_MDN`,
  SET_DELIVERY_MESSAGE: `${tag}/SET_DELIVERY_MESSAGE`,
  SET_DELIVERY_INFO: `${tag}/SET_DELIVERY_INFO`,
  FAIL_MODIFY_AUTH: `${tag}/FAIL_MODIFY_AUTH`,
  FAIL_MODIFY_AUTH_FOR_HOMESHOPPING: `${tag}/FAIL_MODIFY_AUTH_FOR_HOMESHOPPING`,
  PROCESS_MODIFY_ACTOR_CATEGORY_TAG_NAME:
    'PROCESS_MODIFY_ACTOR_CATEGORY_TAG_NAME',

  CLEAR_MDN_TOKEN: `${tag}/CLEAR_MDN_TOKEN`,
  CLEAR_AUTH_ACTOR: `${tag}/CLEAR_AUTH_ACTOR`,
  CLEAR_DELIVERY_INFO: `${tag}/CLEAR_DELIVERY_INFO`,
  CLEAR_ERROR_STEP: `${tag}/CLEAR_ERROR_STEP`,

  REQUEST_GET_STUDENT_LIST: `${tag}/REQUEST_GET_STUDENT_LIST`,
  SUCCESS_GET_STUDENT_LIST: `${tag}/SUCCESS_GET_STUDENT_LIST`,
  FAIL_GET_STUDENT_LIST: `${tag}/FAIL_GET_STUDENT_LIST`,

  SET_LOG_ID: `${tag}/SET_LOG_ID`,
  DELETE_DROP_OUT: `${tag}/DELETE_DROP_OUT`,
  GET_TODAY_STUDY_MAP: `${tag}/GET_TODAY_STUDY_MAP`,
});

export function clearAction() {
  return (dispatch, getState) => {
    return dispatch({
      type: ActionTypes.CLEAR_ACTION,
    });
  };
}

// server_tester : tests_for_50_parent_account.py LoginTest.test_login_by_human_mdn
export function phoneDuplicateCheckAndOneTimePasswordForMemberJoin(
  humanName,
  humanMdn,
  isKakaoTalk
) {
  return (dispatch, getState) => {
    return UserServices.phoneDuplicateCheck(humanMdn)
      .then((res) => {
        if (0 === res.data.count) {
          return UserServices.oneTimePassword(humanMdn, isKakaoTalk).then(
            (res) => {
              dispatch({
                type: ActionTypes.SUCCESS_ONE_TIME_PASSWORD,
                payload: {
                  existAuth: false,
                  action: 'successOneTimePassword',
                },
              });
            }
          );
        } else {
          return UserServices.oneTimePassword(humanMdn, isKakaoTalk).then(
            (res) => {
              dispatch({
                type: ActionTypes.SUCCESS_ONE_TIME_PASSWORD,
                payload: {
                  existAuth: true,
                  action: 'successOneTimePassword',
                },
              });
            }
          );
        }
      })
      .catch((e) => {
        dispatch(
          ExceptionAction.exception(
            e,
            'phone중복 조회 또는 인증번호발송 중 오류가 발생하였습니다.'
          )
        );
        dispatch({
          type: ActionTypes.PHONE_DUPLICATE,
          payload: {
            action: 'PhoneDuplicate',
          },
        });
      });
  };
}

// server_tester : tests_for_50_parent_account.py LoginTest.test_login_by_human_mdn
export function checkOneTimePasswordForMemberJoin(
  human_name,
  human_mdn,
  certificationNumber
) {
  return (dispatch, getState) => {
    const existAuth = getState().MemberJoinReducer.existAuth;
    if (existAuth === false) {
      return UserServices.checkMdn(human_mdn, certificationNumber)
        .then((res) => {
          if (res.data.mdn_token) {
            return dispatch({
              type: ActionTypes.SUCCESS_GET_MDN_TOKEN,
              payload: {
                action: 'successCheckOneTimePassword',
                mdn_token: res.data.mdn_token,
              },
            });
          } else {
            return dispatch({
              type: ActionTypes.FAIL_CHECK_ONE_TIME_PASSWORD,
              payload: {
                action: 'failCheckOneTimePassword',
              },
            });
          }
        })
        .catch((e) => {
          return dispatch({
            type: ActionTypes.FAIL_CHECK_ONE_TIME_PASSWORD,
            payload: {
              action: 'failCheckOneTimePassword',
            },
          });
        });
    } else {
      return UserServices.phoneLogin(
        human_mdn,
        human_name,
        certificationNumber,
        ActorType.Parent
      )
        .then((res) => {
          dispatch({
            type: ActionTypes.PROCESS_PHONE_LOGIN,
            payload: {
              auth: res.auth.data,
              actor: res.actor.data,
            },
          });
          dispatch({
            type: ActionTypes.SUCCESS_GET_MDN_TOKEN,
            payload: {
              action: 'successCheckOneTimePassword',
              mdn_token: res.auth.data.human_mdn,
            },
          });
          return UserServices.getOrCreateFamily(
            res.actor.data.id,
            res.actor.data.category_tag_name
          ).then((familyRes) => {
            dispatch({
              type: ActionTypes.REQUEST_GET_STUDENT_LIST,
            });
            return UserServices.getMemberListByModelType(
              familyRes.data.id,
              ActorType.Student
            ).then((resStudentList) => {
              dispatch({
                type: ActionTypes.SUCCESS_GET_STUDENT_LIST,
                payload: {
                  studentList: resStudentList.data.results,
                },
              });
            });
          });
        })
        .catch((ex) => {
          console.log('ex', ex);
          if (ex.response && ex.response.status === 499) {
            return dispatch({
              type: ActionTypes.FAIL_CHECK_ONE_TIME_PASSWORD,
              payload: {
                action: 'failNoMatchAuthName',
              },
            });
          } else {
            return dispatch({
              type: ActionTypes.FAIL_CHECK_ONE_TIME_PASSWORD,
              payload: {
                action: 'failCheckOneTimePassword',
              },
            });
          }
        });
    }
  };
}

// server_tester : tests_for_50_parent_account.py LoginTest.test_login_by_human_mdn
//폰번호 중복확인, 인증번호발송
export function phoneDuplicateCheckAndOneTimePassword(human_mdn) {
  return (dispatch, getState) => {
    return UserServices.phoneDuplicateCheck(human_mdn)
      .then((res) => {
        if (0 === res.data.count) {
          return UserServices.oneTimePassword(human_mdn).then((res) => {
            dispatch({
              type: ActionTypes.SUCCESS_ONE_TIME_PASSWORD,
              payload: {
                action: 'successOneTimePassword',
              },
            });
          });
        } else {
          dispatch({
            type: ActionTypes.PHONE_DUPLICATE,
            payload: {
              action: 'PhoneDuplicate',
            },
          });
        }
      })
      .catch((e) => {
        dispatch(
          ExceptionAction.exception(
            e,
            'phone중복 조회 또는 인증번호발송 중 오류가 발생하였습니다.'
          )
        );
        dispatch({
          type: ActionTypes.PHONE_DUPLICATE,
          payload: {
            action: 'PhoneDuplicate',
          },
        });
      });
  };
}

// server_tester : tests_for_50_parent_account.py LoginTest.test_login_by_human_mdn
//인증번호 체크
export function checkOneTimePassword(human_mdn, certificationNumber) {
  return (dispatch, getState) => {
    return UserServices.checkMdn(human_mdn, certificationNumber)
      .then((res) => {
        if (res.data.mdn_token) {
          dispatch({
            type: ActionTypes.SUCCESS_GET_MDN_TOKEN,
            payload: {
              action: 'successCheckOneTimePassword',
              mdn_token: res.data.mdn_token,
            },
          });
        } else {
          dispatch({
            type: ActionTypes.FAIL_CHECK_ONE_TIME_PASSWORD,
            payload: {
              action: 'failCheckOneTimePassword',
            },
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: ActionTypes.FAIL_CHECK_ONE_TIME_PASSWORD,
          payload: {
            action: 'failCheckOneTimePassword',
          },
        });
      });
  };
}

/*
	param :{
		human_name:'학부모이름',
		category_tag_name:'엄마',
		human_mdn:'휴대폰번호',
		mdn_token:'인증번호토큰',
		human_postal_code:'우편번호',
		human_address:'주소',
		human_detail_address:'상세주소',
		human_email:'이메일',
		childName:'자녀명',
		childIsMale:true,
		childBirth:2011-09-23,
		checkList:{
			'한글':1,
			'수학':2,
			'스토리영어':3,
			'파닉스':4,
		},
		deliveryMessage:'경비실에 맞겨주세요.',
		register_code:'어디서 가입했는지',
		data:{
			category_tag_name:'엄마'
			emailIndex:0,
			terms_agreement:{
				deliveryAgreement:true
			}
		}
	}
*/

export function createAuths(param) {
  return (dispatch, getState) => {
    return UserServices.createAuthsForExperience(param)
      .then((resForCreateAuth) => {
        dispatch({ type: ActionTypes.SUCCESS_CREATE_AUTHS_FOR_EXPERIENCE });

        UserServices.createTemporaryInformationActorWithMdnAuth({
          information_type: 'ExperienceDropOut',
          parent_auth_human_name: param.human_name,
          parent_auth_human_mdn: param.human_mdn,
          student_human_postal_code: param.human_postal_code,
          student_human_address: param.human_address,
          student_human_detail_address: param.human_detail_address,
          registered_date: param.regist_date,
          request_channel: param.request_channel,
          mdn_token: param.mdn_token,
        })
          .then((response) => {
            dispatch({
              type: ActionTypes.SET_LOG_ID,
              payload: {
                log_id: response,
              },
            });
          })
          .catch((e) => {
            console.error(e);
          });
        UserServices.authTokenLogin(
          resForCreateAuth.data.auth_token,
          ActorType.Parent
        )
          .then((resForAuthTokenLogin) => {
            dispatch({
              type: ActionTypes.SUCCESS_TOKEN_LOGIN_PASS,
              payload: {
                action: 'successTokenLoginPass',
                actor: resForAuthTokenLogin.actor.data,
              },
            });
          })
          .catch((e) => {
            dispatch({
              type: ActionTypes.FAIL_AUTH_TOKEN_LOGIN,
              payload: {
                action: 'failAuthTokenLogin',
              },
            });
          });
      })
      .catch((e) => {
        dispatch(
          ExceptionAction.exception(e, '회원가입도중 에러가 발생하였습니다.')
        );
      });
  };
}

// server_tester : tests_for_50_parent_account.py RegisterTest.test_simple_case
export function createAuthsForExperience(param) {
  return (dispatch, getState) => {
    return UserServices.createAuthsForExperience(param)
      .then((resForCreateAuth) => {
        dispatch({ type: ActionTypes.SUCCESS_CREATE_AUTHS_FOR_EXPERIENCE });
        UserServices.authTokenLogin(
          resForCreateAuth.data.auth_token,
          ActorType.Parent
        )
          .then((resForAuthTokenLogin) => {
            dispatch({ type: ActionTypes.SUCCESS_TOKEN_LOGIN_PASS });
            param.actorId = resForAuthTokenLogin.actor.data.id;
            return dispatch(createFamilyAndApplyExperience(param));
          })
          .catch((e) => {
            dispatch({
              type: ActionTypes.FAIL_AUTH_TOKEN_LOGIN,
              payload: {
                action: 'failAuthTokenLogin',
              },
            });
          });
      })
      .catch((e) => {
        dispatch(
          ExceptionAction.exception(e, '회원가입도중 에러가 발생하였습니다.')
        );
      });
  };
}

/*
	param : {
		actorId:11,
		childName:'자녀이름',
		childIsMale:false,
		childBirth:'2011-09-23',
		category_tag_name:'엄마'
		checkList:{
			'한글':1,
			'수학':2,
			'스토리영어':3,
			'파닉스':4,
		},
		deliveryMessage:'경비실에 맞겨주세요.',
		register_code:'어디서 가입했는지',
	}
*/

// server_tester : test_experience_case_by_case.py-CSRExperienceIssueGeneralFlowTest.test_csr_experience_Issue_general_flow
export function createFamilyAndApplyExperience(param) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.SET_DELIVERY_INFO,
      payload: {
        human_name: param.human_name,
        address: param.human_address,
        address_detail: param.human_detail_address,
        postal_code: param.human_postal_code,
        human_email: param.human_email,
        human_mdn: param.human_mdn,
        deliveryMessage: param.deliveryMessage,
        hasExperiencePreConsultWork: param.has_experience_pre_consult_work,
      },
    });
    let studentList = getState().MemberJoinReducer.studentList;
    //학생이름 중복 확인
    let isOverLap = ParentUtil.getArrTargetItem(
      studentList,
      'auth_human_name',
      param.childName
    );

    let step = '';
    return Async(function* () {
      step = 'SUCCESS_CREATE_FAMILY';
      //가족생성
      let family = yield UserServices.getOrCreateFamily(
        param.actorId,
        param.category_tag_name
      );
      dispatch({ type: ActionTypes.SUCCESS_CREATE_FAMILY });
      step = 'SUCCESS_SET_STUDENT_MEMBER';
      //자녀생성
      let resForStudent;
      if (isOverLap) {
        resForStudent = yield UserServices.setStudentMember(
          family.data.id,
          param.childName,
          param.childIsMale,
          param.childBirth
        );
      } else {
        resForStudent = yield UserServices.addStudentMember(
          family.data.id,
          param.childName,
          param.childIsMale,
          param.childBirth
        );
      }

      dispatch({ type: ActionTypes.SUCCESS_SET_STUDENT_MEMBER });
      step = 'SUCCESS_APPLY_EXPERIENCE';
      //체험신청

      let channel = ApplyChannel.Mobile;
      if (
        location.hostname === 'm.danbi.biz' ||
        location.hostname === 'm.wink.co.kr'
      ) {
        channel = ApplyChannel.Mobile;
      } else if (
        location.hostname === 'parent.danbi.biz' ||
        location.hostname === 'parent.wink.co.kr'
      ) {
        channel = ApplyChannel.App;
      } else if (
        location.hostname === 'www.danbi.biz' ||
        location.hostname === 'www.wink.co.kr'
      ) {
        channel = ApplyChannel.Pc;
      }

      let params = {
        delivery_message: param.deliveryMessage,
        request_channel: channel,
        has_experience_pre_consult_work: param.has_experience_pre_consult_work,
        ...param.checkList,
      };

      if (param.register_code) {
        params.register_code = param.register_code;
      }

      yield PaymentServices.applyExperience(resForStudent.data.id, params);
      dispatch({
        type: ActionTypes.SUCCESS_APPLY_EXPERIENCE,
        payload: {
          action: 'successApplyExperience',
        },
      });
    })
      .then(() => {
        const logId = getState().MemberJoinReducer.logId;
        if (logId) {
          UserServices.deleteTemporaryInformationActor(logId).catch((e) => {
            console.error(e);
          });
        }
      })
      .catch((e) => {
        dispatch(
          ExceptionAction.exception(e, '체험신청도중 에러가 발생하였습니다.')
        );
        dispatch({
          type: ActionTypes.FAIL_CREATE_FAMILY_AND_APPLY_EXPERIENCE,
          payload: {
            errorStep: step,
          },
        });
      });
  };
}

// server_tester : test_experience_case_by_case.py-MarketingGeneralFlowTest._skip_test_marketing_general_flow
export function createFamilyAndApplyExperienceForHomeShopping(param, issue) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.SET_DELIVERY_INFO,
      payload: {
        human_name: param.human_name,
        address: param.human_address,
        address_detail: param.human_detail_address,
        postal_code: param.human_postal_code,
        human_email: param.human_email,
        human_mdn: param.human_mdn,
        deliveryMessage: param.deliveryMessage,
      },
    });
    //let studentList = getState().MemberJoinReducer.studentList;
    //학생이름 중복 확인
    //let isOverLap = ParentUtil.getArrTargetItem(studentList, 'auth_human_name', param.childName)

    let step = '';
    return Async(function* () {
      step = 'SUCCESS_CREATE_FAMILY_FOR_HOME_FOR_HOMESHOPPING';
      //가족생성
      let family = yield UserServices.getOrCreateFamily(
        param.actorId,
        param.category_tag_name
      );
      dispatch({ type: ActionTypes.SUCCESS_CREATE_FAMILY });
      step = 'SUCCESS_SET_STUDENT_MEMBER_FOR_HOMESHOPPING';
      //자녀생성
      let resForStudent;

      resForStudent = yield UserServices.setStudentMember(
        family.data.id,
        param.childName,
        param.childIsMale,
        param.childBirth
      );

      dispatch({ type: ActionTypes.SUCCESS_SET_STUDENT_MEMBER });
      step = 'POST_STUDY_STEP_DIAGNOSIS_FOR_HOMESHOPPING';
      //학생 진단
      yield PaymentServices.postStudyStepDiagnosis(
        resForStudent.data.id,
        param.checkList
      );
      dispatch({ type: ActionTypes.POST_STUDY_STEP_DIAGNOSIS });
      step = 'SUCCESS_APPLY_EXPERIENCE_FOR_HOMESHOPPING';
      //체험신청
      let channel = ApplyChannel.Mobile;
      if (
        location.hostname === 'm.danbi.biz' ||
        location.hostname === 'm.wink.co.kr'
      ) {
        channel = ApplyChannel.Mobile;
      } else if (
        location.hostname === 'parent.danbi.biz' ||
        location.hostname === 'parent.wink.co.kr'
      ) {
        channel = ApplyChannel.App;
      } else if (
        location.hostname === 'www.danbi.biz' ||
        location.hostname === 'www.wink.co.kr'
      ) {
        channel = ApplyChannel.Pc;
      }
      if (issue) {
        //이슈로 work조회 하고 맞는 워크인지 확인후 맞으면 work완료 처리
        const issueInfo = yield IssueTrackerServices.getIssueInfo(issue);
        if (issueInfo.data && issueInfo.data.current_work_type) {
          if (
            issueInfo.data.current_work_type ===
            WorkType.CSRExperienceInfoReplyWork
          ) {
            let data = null;
            yield IssueTrackerServices.completeWork(
              issueInfo.data.current_work,
              WorkTypeName.CSRAvailableWinkServiceCheckWork,
              data
            );
            dispatch({
              type: ActionTypes.SUCCESS_CSRAVILABLE_WORK_COMPLETE,
              payload: {
                action: 'successCSRAvilableWorkComplete',
              },
            });
          } else {
            //이미 처리되었습니다.
            dispatch({
              type: ActionTypes.STUDY_APPLIED,
              payload: {
                action: 'studyApplied',
              },
            });
          }
        }
      } else {
        let params = {
          has_experience_pre_consult_work: param.hasExperiencePreConsultWork,
          delivery_message: param.deliveryMessage,
          request_channel: channel,
        };
        if (param.register_code) {
          params.register_code = param.register_code;
        }
        yield PaymentServices.applyExperience(resForStudent.data.id, params);
        dispatch({
          type: ActionTypes.SUCCESS_APPLY_EXPERIENCE,
          payload: {
            action: 'successApplyExperience',
          },
        });
      }
    }).catch((e) => {
      dispatch(
        ExceptionAction.exception(e, '체험신청도중 에러가 발생하였습니다.')
      );
      dispatch({
        type: ActionTypes.FAIL_CREATE_FAMILY_AND_APPLY_EXPERIENCE,
        payload: {
          errorStep: step,
        },
      });
    });
  };
}

// server_tester : test_experience_case_by_case.py-CSRExperienceIssueGeneralFlowTest.test_csr_experience_Issue_general_flow
export function modifyAuth(
  authId,
  params,
  exprienceParams,
  category_tag_name = false
) {
  return (dispatch, getState) => {
    return UserServices.modifyAuths(authId, params)
      .then((res) => {
        if (category_tag_name) {
          let actorId = getState().MemberJoinReducer.actor.id;
          modifyInnerParentCategoryTag(actorId, category_tag_name)
            .then(() => {
              return dispatch(createFamilyAndApplyExperience(exprienceParams));
            })
            .catch(() => {
              return dispatch(createFamilyAndApplyExperience(exprienceParams));
            });
        } else {
          return dispatch(createFamilyAndApplyExperience(exprienceParams));
        }
      })
      .catch((e) => {
        dispatch(
          ExceptionAction.exception(e, '체험신청도중 에러가 발생하였습니다.')
        );
        dispatch({ type: ActionTypes.FAIL_MODIFY_AUTH });
      });
  };
}

// server_tester : test_experience_case_by_case.py-MarketingGeneralFlowTest._skip_test_marketing_general_flow
export function modifyAuthForHomeShopping(
  authId,
  params,
  exprienceParams,
  category_tag_name = false,
  issue = 0
) {
  return (dispatch, getState) => {
    return UserServices.modifyAuths(authId, params)
      .then((res) => {
        if (category_tag_name) {
          let actorId = getState().MemberJoinReducer.actor.id;
          modifyInnerParentCategoryTag(actorId, category_tag_name)
            .then(() => {
              dispatch(
                createFamilyAndApplyExperienceForHomeShopping(
                  exprienceParams,
                  issue
                )
              );
            })
            .catch(() => {
              dispatch(
                createFamilyAndApplyExperienceForHomeShopping(
                  exprienceParams,
                  issue
                )
              );
            });
        }
        //return dispatch(createFamilyAndApplyExperienceForHomeShopping(exprienceParams, issue));
      })
      .catch((e) => {
        dispatch(
          ExceptionAction.exception(e, '체험신청도중 에러가 발생하였습니다.')
        );
        dispatch({ type: ActionTypes.FAIL_MODIFY_AUTH_FOR_HOMESHOPPING });
      });
  };
}

// server_tester : test_experience_case_by_case.py
function modifyInnerParentCategoryTag(actorId, category_tag_name) {
  return new Promise((resolve, reject) => {
    AuxServices.getParentCategoryTag(category_tag_name)
      .then((res) => {
        UserServices.modifyActors(actorId, { category_tag: res.data.id })
          .then((res2) => {
            resolve('ok');
          })
          .catch(() => {
            reject('fail');
          });
      })
      .catch(() => {
        reject('fail');
      });
  });
}

// server_tester : test_experience_case_by_case.py
export function modifyParentCategoryTag(category_tag_name) {
  return (dispatch, getState) =>
    dispatch(() => {
      let actorId = getState().MemberJoinReducer.actor.id;
      if (actorId) {
        return AuxServices.getParentCategoryTag(category_tag_name)
          .then((res) => {
            UserServices.modifyActors(actorId, {
              category_tag: res.data.id,
            }).then((res2) => {
              dispatch({
                type: ActionTypes.PROCESS_MODIFY_ACTOR_CATEGORY_TAG_NAME,
                payload: {
                  action: 'successModifyParentCategoryTag',
                  category_tag_name: category_tag_name,
                },
              });
            });
          })
          .catch((e) => {
            return dispatch(
              ExceptionAction.exception(
                e,
                '양육자 구분변경 요청중 에러가 발생하였습니다.'
              )
            );
          });
      }
    });
}

// server_tester : test_experience_case_by_case.py
// ---------------------
//비로그인 가입 & 휴대폰 인증문자 발송
export function halfCreateAuthsAndOneTimePassword(human_name, human_mdn) {
  return (dispatch, getState) => {
    return Async(function* () {
      yield UserServices.halfCreateAuths(human_name, human_mdn);
      dispatch({
        type: ActionTypes.SUCCESS_HALF_CREATE_AUTH,
        payload: {
          action: 'successHalfCreateAuth',
        },
      });

      yield UserServices.oneTimePassword(human_mdn);
      dispatch({
        type: ActionTypes.SUCCESS_ONE_TIME_PASSWORD,
        payload: {
          action: 'successOneTimePassword',
        },
      });
    }).catch((e) => {
      dispatch({
        type: ActionTypes.FAIL_HALF_CREATE_AUTHS,
        payload: {
          action: 'failHalfCreateAuths',
        },
      });
    });
  };
}

// server_tester : test_experience_case_by_case.py
//폰로그인 & 토큰획득
export function phoneLoginAndGetToken(
  human_mdn,
  human_name,
  certificationNumber
) {
  return (dispatch, getState) =>
    dispatch(() => {
      return Async(function* () {
        //로그인
        const res = yield UserServices.phoneLogin(
          human_mdn,
          human_name,
          certificationNumber,
          ActionTypes.Parent
        );

        Session.login(
          res.actor.data.auth,
          res.actor.data.id,
          res.auth.data.username,
          res.auth.data.human_name,
          res.actor.data.model_type,
          { isAdmin: res.auth.data.is_admin }
        );

        dispatch({
          type: ActionTypes.SUCCESS_PHONE_LOGIN,
          payload: {
            action: 'successPhoneLogin',
          },
        });
        //토큰획득
        const resAuthToken = yield UserServices.getAuthToken(res.auth.data.id);
        dispatch({
          type: ActionTypes.SUCCESS_GET_AUTH_TOKEN,
          payload: {
            action: 'successGetAuthToken',
            auth_token: resAuthToken.data.auth_token,
          },
        });
      }).catch((e) => {
        dispatch({
          type: ActionTypes.FAIL_LOGIN,
          payload: {
            action: 'failLogin',
          },
        });
      });
    });
}

// server_tester : test_experience_case_by_case.py
//폰로그인
export function phoneLogin(human_mdn, human_name, certificationNumber) {
  return (dispatch, getState) =>
    dispatch(() => {
      return UserServices.phoneLogin(
        human_mdn,
        human_name,
        certificationNumber,
        ActionTypes.Parent
      )
        .then((res) => {
          Session.login(
            res.actor.data.auth,
            res.actor.data.id,
            res.auth.data.username,
            res.auth.data.human_name,
            res.actor.data.model_type,
            { isAdmin: res.auth.data.is_admin }
          );
          dispatch({
            type: ActionTypes.SUCCESS_PHONE_LOGIN,
            payload: {
              action: 'successPhoneLogin',
            },
          });
        })
        .catch((e) => {
          dispatch(
            ExceptionAction.exception(e, 'phoneLogin중 에러가 발생하였습니다.')
          );
        });
    });
}

// server_tester : test_experience_case_by_case.py
//로그아웃
export function logout() {
  return (dispatch, getState) =>
    dispatch(() => {
      return UserServices.logout()
        .then((res) => {
          Storage.logout();
          Session.logout();
          return dispatch({
            type: ActionTypes.LOGOUT,
            payload: {
              action: 'logout',
            },
          });
        })
        .catch((e) => {
          dispatch(
            ExceptionAction.exception(
              e,
              '로그아웃 요청중 에러가 발생하였습니다.'
            )
          );
        });
    });
}

// server_tester : test_experience_case_by_case.py
export function authTokenLogin(token, actorType) {
  return (dispatch, getState) =>
    dispatch(() => {
      return Async(function* () {
        //캐시를 받아오지 못한 상태에서 post를 호출하여 에러가 나는걸 방지합니다.
        yield AuxServices.getAuxFileTypes();

        const res = yield UserServices.authTokenLogin(token, actorType);

        dispatch({
          type: ActionTypes.SUCCESS_TOKEN_LOGIN,
          payload: {
            action: 'successLogin',
            auth: res.auth.data,
            actor: res.actor.data,
          },
        });
        const familyRes = yield UserServices.getOrCreateFamily(
          res.actor.data.id,
          res.actor.data.category_tag_name
        );
        dispatch({ type: ActionTypes.REQUEST_GET_STUDENT_LIST });
        const resStudentList = yield UserServices.getMemberListByModelType(
          familyRes.data.id,
          ActorType.Student
        );
        dispatch({
          type: ActionTypes.SUCCESS_GET_STUDENT_LIST,
          payload: {
            studentList: resStudentList.data.results,
          },
        });
      }).catch((e) => {
        dispatch({
          type: ActionTypes.FAIL_TOKEN_LOGIN,
          payload: {
            action: 'failTokenLogin',
          },
        });
      });
    });
}

export function clearMdnToken() {
  return (dispatch, getState) =>
    dispatch(() => {
      dispatch({
        type: ActionTypes.CLEAR_MDN_TOKEN,
      });
    });
}

export function clearAuthActor() {
  return (dispatch, getState) =>
    dispatch(() => {
      dispatch({
        type: ActionTypes.CLEAR_AUTH_ACTOR,
      });
    });
}

export function clearDeliveryInfo() {
  return (dispatch, getState) =>
    dispatch(() => {
      dispatch({
        type: ActionTypes.CLEAR_DELIVERY_INFO,
      });
    });
}

export function clearErrorStep() {
  return (dispatch, getState) =>
    dispatch(() => {
      dispatch({
        type: ActionTypes.CLEAR_ERROR_STEP,
      });
    });
}

export function successApplyExperience() {
  return (dispatch) =>
    dispatch(() => {
      dispatch({
        type: ActionTypes.SUCCESS_APPLY_EXPERIENCE,
        payload: {
          action: 'successApplyExperience',
        },
      });
    });
}

export function deleteDropOut() {
  return (dispatch, getState) =>
    dispatch(() => {
      const logId = getState().MemberJoinReducer.logId;
      if (logId) {
        UserServices.deleteTemporaryInformationActor(logId)
          .then(() => {
            dispatch({
              type: ActionTypes.DELETE_DROP_OUT,
              payload: {
                logId: 0,
              },
            });
          })
          .catch((e) => {
            console.error(e);
          });
      }
    });
}

const flattenTree = (tree, mapper) => {
  const result = [];

  function traverse(node) {
    if (node.members) {
      node.members.forEach((child) => {
        const mappedChild =
          typeof mapper === 'function' ? mapper(child) : child;
        result.push(mappedChild);
        traverse(child);
      });
    }
  }

  tree.forEach(traverse);

  return result;
};

const listToMap = (list) => {
  return list.reduce((prev, curr) => {
    return { ...prev, [curr.name]: curr.id };
  }, {});
};

export function getTodayStudyMap() {
  return (dispatch, getState) =>
    dispatch(() => {
      StudyServices.getContentCategoryTag(ItemTopCategoryTagName.오늘의공부)
        .then((response) => {
          const todayStudyTree = response.data.sdata.hierachy.members;
          const todayStudyList = flattenTree(todayStudyTree, (item) => ({
            id: item.id,
            name: item.name,
          }));
          const todayStudyMap = listToMap(todayStudyList);

          dispatch({
            type: ActionTypes.GET_TODAY_STUDY_MAP,
            payload: {
              todayStudyMap,
            },
          });
        })
        .catch((e) => {
          console.error(e);
        });
    });
}

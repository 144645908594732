import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as FakeLoginAction from './FakeLoginAction';

const FakeLoginInjector =
  (stateToProps = null, dispatchToProps = null) =>
  (WrapperComponent) => {
    const HOCComponent = class _HOCComponent extends Component {
      render() {
        const newProps = { ...this.props };

        return <WrapperComponent {...newProps} />;
      }
    };
    const mapDispatchToProps = (dispatch, ownProps) => {
      let props = {};
      if (dispatchToProps !== null) {
        props = dispatchToProps(dispatch, ownProps);
      }
      return {
        fakeLogin: (username, password, paretActorId, actorType) => {
          dispatch(
            FakeLoginAction.fakeLogin(
              username,
              password,
              paretActorId,
              actorType
            )
          );
        },
        getFakeLoginLogList: (employeeActorId) => {
          dispatch(FakeLoginAction.getFakeLoginLogList(employeeActorId));
        },
        ...props,
      };
    };

    const mapStateToProps = (state, ownProps) => {
      let props = {};
      if (stateToProps !== null) {
        props = stateToProps(state, ownProps);
      }
      return {
        employeeAuth: state.FakeLoginReducer.employeeAuth,
        fakeLoginInfo: state.FakeLoginReducer.fakeLoginInfo,
        fakeLoginLogList: state.FakeLoginReducer.fakeLoginLogList,
        ...props,
      };
    };
    return connect(mapStateToProps, mapDispatchToProps)(HOCComponent);
  };

export default FakeLoginInjector;
